import React from "react"

export function CorrectTags({groupDoc, correctPlayers}){
    if(correctPlayers){
        return(
            <div className="correct-tag-list">
                    {Array.isArray(correctPlayers) && correctPlayers.map((uid) => (
                        <React.Fragment key={uid}>
                            {groupDoc && groupDoc.players.map((player) => {
                                if(uid === player.uid){
                                    return(
                                        <React.Fragment key={player.uid}>
                                            <div className="correct-tag">
                                                <span className="viewer-text">{player.nickName}</span>
                                            </div>
                                        </React.Fragment>
                                    )
                                }else{
                                    return null
                                }
                            })}
                        </React.Fragment>
                        )
                    )}
                </div>
        )
    }
}