import { auth } from "../config/firebaseConfig";
import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { signOut } from 'firebase/auth'

export const useSignOut = () => {
    const { dispatch } = useAuthContext()
    const [isPending, setIsPending] = useState(false)

    const trySignOut = () => {
        setIsPending(true)
        signOut(auth)
        .then(() => {
            dispatch({ type: "LOGOUT"})
            setIsPending(false)
        })
        .catch((error) => {
            console.log(error.message)
            setIsPending(false)
        })
    }
    return { trySignOut, isPending }
}
