export function getActiveQwestoQuestion(qwestoDoc, gameDoc){
    const questionIndex = gameDoc.activeQuestionIndex
    if(questionIndex === "NONE"){
        return null
    }
    const currentQuestionID = qwestoDoc.questionSequence[questionIndex - 1]

    let newQwestoLength = 0
    let activeQuestion = null

    // Find the right question using rdb
    Object.keys(qwestoDoc.qwestoQuestions).forEach((key) => {
        if(parseInt(key) === parseInt(currentQuestionID)){
            activeQuestion = {id: parseInt(key), ...qwestoDoc.qwestoQuestions[key]}
        }
        newQwestoLength += 1
    })
    
    const props = {
        length: newQwestoLength,
        index: questionIndex
    }

    activeQuestion = {...activeQuestion, props: props}

    if(questionIndex > newQwestoLength){
        activeQuestion = null
    }

    return activeQuestion
}