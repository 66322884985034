import QwestoBig from "../../assets/img/QwestoBig";
import Footer from "../../components/layout/Footer";

export default function AboutUs(){

    return(
        <div className="content-page">
            <div className="content-container min-height max-width flex-start large-gap">
                <div className="column gap">
                    <h1 className="text-xlarge">Over ons</h1>
                    <h3>Wij van Qwesto houden wel van een avond vol gezelligheid. Dit gaat vaak gepaard met spellen en quizzen, zoals de welbekende pubquiz. Helaas zijn deze vaak te standaard en onpersoonlijk. Zelf een pubquiz maken is leuker maar kost enorm veel tijd. Dit liet ons achter met de vraag: Waarom heeft nog niemand een eenvoudige manier bedacht om persoonlijke spellen te spelen met vrienden en familie?</h3>
                    <p className="grey-text">Zo ontstond het idee voor Qwesto, een platform dat het spelen van persoonlijke quizzen voor iedereen super gemakkelijk maakt. Met Qwesto kunnen vrienden en familieleden eenvoudig foto’s, video’s en anekdotes insturen, op basis hiervan wordt direct een quiz gegenereerd. De organisator bepaalt hoelang de deze duurt en hoe spicy de vragen zullen zijn, hierdoor is de quiz perfect afgestemd op jouw groep. Zo garanderen wij een glimlach voor iedereen! </p>
                    <p className="grey-text">Na flink veel getest te hebben met onze eigen vrienden en familie zijn we het er over uit, Qwesto is het gezelligste spel van Nederland. Nu is het aan jou om dit te gaan ervaren. </p>
                    <div className="column">
                    </div>
                </div>
                        <QwestoBig className="icon big no-grow"/>
            </div>
            <Footer/>
        </div>
    )
}


