import { useAuthContext } from "../../../hooks/useAuthContext";
import "./Controller.css"
import ArrowRight from "../../../assets/img/ArrowRight";
import ArrowLeft from "../../../assets/img/ArrowLeft";
import { useNextQwestoQuestion } from "../../../hooks/useNextQwestoQuestion";
import { useSkipQwestoQuestion } from "../../../hooks/useSkipQwestoQuestion";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import { useGoBackQwestoQuestion } from "../../../hooks/useGoBackQwestoQuestion";

export default function HostControls({qwestoDoc, groupDoc, gameDoc, activeQuestion, children}) {
    const { user } = useAuthContext()
    const {nextIsPending, tryNextQuestion} = useNextQwestoQuestion()
    const {skipIsPending, trySkipQuestion} = useSkipQwestoQuestion()
    const {backIsPending, tryGoBackQuestion} = useGoBackQwestoQuestion()

    if(activeQuestion && groupDoc.host.uid === user.uid){
        return(
            <div className="host-controls">
                {(groupDoc.qwestoStarted && gameDoc.activeQuestionIndex !== 1 && !(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN")) ?
                    <button className="btn host-control" onClick={() => {tryGoBackQuestion(groupDoc, gameDoc)}} disabled={backIsPending}>{backIsPending ? <QwestoSmall className="load"/> : <ArrowLeft className="icon"/>}</button>
                :
                    <button className="btn host-control disabled"><ArrowLeft className="icon"/></button>
                }
                {children}
                {(gameDoc.stage === "RESULTS" || gameDoc.stage === "STATS") ?
                    <button className="btn host-control" onClick={() => {tryNextQuestion(groupDoc, gameDoc, qwestoDoc)}} disabled={nextIsPending}>{nextIsPending ? <QwestoSmall className="load"/> : <ArrowRight className="icon"/>}</button>
                :
                    <>
                        {(gameDoc.stage === "ANSWERING") ?
                                <button className="btn host-control" onClick={() => {trySkipQuestion(activeQuestion, gameDoc, groupDoc, qwestoDoc)}} disabled={skipIsPending}>{skipIsPending ? <QwestoSmall className="load"/> : "Skip"}</button>
                            :
                                <button className="btn host-control disabled" disabled>{skipIsPending ? <QwestoSmall className="load"/> : "Skip"}</button>
                        }
                    </>
                }
            </div>
        )
    }else{
        return(
            <div className="host-controls">
                {children}
            </div>
        )
    }
}
