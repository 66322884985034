import Footer from "../../components/layout/Footer";
import FAQList from "./FAQList";

export default function FAQ(){

    return(
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container center">
                    <FAQList large/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


