export default function Spicy(props) {
    return (
      <svg viewBox="0 0 500 500" {...props}>
    <g
      id="SVGRepo_iconCarrier"
      transform="matrix(14.912975, 0, 0, 14.912975, 5.971448, 11.105793)"
    >
      <path
        className="st0"
        d="M19,8.1c0-2,1.1-3.8,2.8-5.1"
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.5,
        }}
      />
      <path
        className="st0"
        d="M15.1,12v2.7c0,5.1-2.3,9.9-6.1,13L7.3,29l4.1-1.5C17.7,25.2,22,19,22,12v0c0-2-1.6-3.6-3.5-3.6h0 C16.6,8.4,15.1,10,15.1,12z"
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.5,
        }}
      />
    </g>
  </svg>
    );
  }