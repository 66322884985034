import { NavLink } from "react-router-dom";
import BackButton from "../../components/input/BackButton";
import FilterSteps from "../products/FilterSteps";

export default function ArticleDisplayer({article, preview, noPhoto, noFilter}){

    function scrollToSection(id){
        const section = document.getElementById(id)
        let sectionPosition = section.getBoundingClientRect();
        window.scrollTo(sectionPosition.left, sectionPosition.top + window.scrollY - (window.innerWidth < 800 ? 20 : 100));
    }

    return(
        <>
            {!noPhoto &&
                <div className="content-field banner">
                    <div className="article-banner">
                        <img src={article.photoURL} alt="" className="article-banner-img"/>
                        {!preview &&
                            <div className="content-container">
                                <BackButton to={-1} responsive/>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className={`content-field white ${noPhoto ? "" : "top-overflow"}`}>
                <div className="content-container flex-start not-relative">
                    <div className="article-row">
                        <div className="article-sections">
                            <div className="article-section">
                                <h1 className="text-xlarge">{article.title}</h1>
                                {article.date && <span className="text-small">{article.date}</span>}
                            </div>
                            {article.sections?.map((section, index) => (
                                <section className={`article-section ${(index !== 0 && section.title) ? "margin-top" : ""}`} id={section.title} key={index}>
                                    {section.title &&
                                        <h2 className="text-medium">{section.title}</h2>
                                    }
                                    {section.text &&
                                        <p className="text-small grey-text" key={index} dangerouslySetInnerHTML={{ __html: section.text }}/>
                                    }
                                    {section.items &&
                                        <ol className={`article-list-section ${section.dots ? section.dots : ""}`}>
                                            {section.items?.map((item, index) => (
                                                <li key={index}>
                                                    <h3>{item.title}</h3>
                                                    <p className="text-small grey-text" key={index} dangerouslySetInnerHTML={{ __html: item.text }}/>
                                                </li>
                                            ))}
                                        </ol>
                                    }
                                </section>
                            ))}
                            {!preview && !noFilter &&
                                <section className="column gap center call-to-action">        
                                    <span className="text-large">Stel je Qwesto samen</span>
                                    <FilterSteps/>
                                </section>
                            }
                        </div>
                        <div className="article-navigation">
                            <span className="text-small">Overzicht</span>
                            {article.sections?.map((section, index) => {
                                if(section.title){
                                    return(
                                        <NavLink onClick={() => {scrollToSection(section.title)}} key={index} className="article-navigation-link">{section.title}</NavLink>
                                    )
                                }else{
                                    return null
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};