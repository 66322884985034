import { useEffect } from "react";
import { useContext } from "react";
import { GameLogicContext } from "../context/GameLogicContext.js";

export function useGameLogicContext(logicType){
    const {activeQuestion, countdown, isResumed, answeredProps, gameConnection, setLogicType} = useContext(GameLogicContext)

    useEffect(() => {
        if(logicType){
            setLogicType(logicType)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logicType])

    return {activeQuestion, countdown, isResumed, answeredProps, gameConnection}
}