import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBeXJ2U-1hRf4sZ8kl6DKboo0FvgwmRsJU",
    authDomain: "qwesto-dbece.firebaseapp.com",
    databaseURL: "https://qwesto-dbece-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "qwesto-dbece",
    storageBucket: "qwesto-dbece.appspot.com",
    messagingSenderId: "1616633272",
    appId: "1:1616633272:web:c5b23842679094ac16c34d",
    measurementId: "G-TJSWFJH9TS"
  };

// init app
export const app = initializeApp(firebaseConfig)

// init services
export const db = getFirestore(app)
export const rdb = getDatabase(app)
export const auth = getAuth()
export const storage = getStorage(app)
export const functions = getFunctions(app)
if(false){
  console.log("Using functions emulator!");
  connectFunctionsEmulator(functions, "localhost", 5002);
}
