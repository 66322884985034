import PopUp from "../../components/layout/PopUp"

export function NoGameConnection(){
    return(
        <PopUp visible unclosable>
            <div className="column center">
                <span className="text-small">Connectie zoeken...</span>
                <span className="text-xxsmall grey-text">Controleer je internet verbinding of ververs je scherm</span>
            </div>
        </PopUp>
    )
}