import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { useNavigate } from "react-router-dom";

export function useReplay(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError} = useMessageContext()
    const navigate = useNavigate()

    async function tryReplay(user, groupDoc){    
        if(!user){
            navigate("/login?redirect=/product")
        }else if(!isPending){
            setIsPending(true)
            let line_items = []
            groupDoc.product.line_items.forEach((item) => {
                line_items.push({price: item.price.id, quantity: item.quantity })
            })

            const checkoutRef = collection(db, "users", user.uid, "checkout_sessions")
            const checkoutDocRef = await addDoc(checkoutRef, {
                line_items: line_items,
                mode: "payment",
                success_url: (window.location.origin + `/groups/${groupDoc.qwestoID}`),
                cancel_url: window.location.origin + `/groups/${groupDoc.qwestoID}`,
                metadata: {
                    replay: true,
                    qwestoID: groupDoc.qwestoID,
                    productID: groupDoc.product.id,
                    groupName: groupDoc.groupName,
                    uid: user.uid,
                    filters: JSON.stringify(groupDoc.product.filters.default)
                }
            })
            onSnapshot(checkoutDocRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    setIsPending(false)
                    console.log(error.message);
                    setNewError("Er is iets misgegaan");
                }
                if (url) {
                    window.location.assign(url);
                }
            })
        }
    }

    return { isPending, tryReplay }
}
