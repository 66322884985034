import { Outlet } from "react-router-dom";
import { GameLogicContextProvider } from "./GameLogicContext";

export const GameLogicLayout = () => {
    return(
        <GameLogicContextProvider>
            <Outlet/>
        </GameLogicContextProvider>
    )
}

