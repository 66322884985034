import { CollectionControls } from './CollectionControls';
import { FilterControls } from '../FilterControls';
import { QuestionList } from './QuestionList';
import { useEffect, useState } from "react"
import SelectionBar from "../../../components/input/SelectionBar"
import Loader from "../../../components/loaders/Loader"
import { defaultFilterObject } from "../../../data/constants"
import { useCollection } from "../../../hooks/useCollection"
import "../Manager.css"
import { QuestionCreator } from './QuestionCreator';
import { filterUsingLabels } from '../../../utils/pure/filterUsingLabels';

export default function QuestionManager(){
    const { documents: poolsProdCol } = useCollection("pools")
    const { documents: poolsDraftCol } = useCollection("pools_draft")
    const [pools, setPools] = useState(null)
    const [collectionType, setCollectionType] = useState("draft")
    const [poolIndex, setPoolIndex] = useState(1)
    const [currentPool, setCurrentPool] = useState(null)
    const [filteredPool, setFilteredPool] = useState(null)
    const [filters, setFilters] = useState(defaultFilterObject)
    const [controlsLocked, setControlsLocked] = useState(true)

    useEffect(() => {
        if(poolsProdCol && poolsDraftCol){
            const allPools = []
            const currentPoolCol = (collectionType === "draft" ? poolsDraftCol : poolsProdCol)
            currentPoolCol.forEach((poolDoc) => {
                let questionID = 0
                poolDoc.pool.forEach((question) => {
                    question.id = questionID
                    questionID++
                })
                allPools.push(poolDoc)
            })
            setPools(allPools)
        }
    }, [poolsProdCol, poolsDraftCol, collectionType])

    useEffect(() => {
        setPoolIndex(1)
    }, [collectionType])
    

    useEffect(() => {
        if(pools){
            setCurrentPool(pools[poolIndex - 1])
        }
    }, [poolIndex, pools])

    useEffect(() => {
        if(currentPool){
            setFilteredPool({pool: filterUsingLabels(currentPool.pool, filters), id: currentPool.id})
        }
    }, [filters, currentPool])

    if(!poolsProdCol || !poolsDraftCol || !currentPool){
        return(
            <Loader/>
        )
    }

    return(
        <div className="content-page full">
            <div className="content-field">
                <div className="content-container no-margin flex-start">
                    <div className="manager-controls">
                        <CollectionControls controlsLocked={controlsLocked} setControlsLocked={setControlsLocked} collectionType={collectionType} setCollectionType={setCollectionType}  />
                        {pools &&
                            <SelectionBar options={pools.map((pool) => (pool.id))} value={poolIndex} setValue={setPoolIndex} unselectable/>
                        }
                        <FilterControls filters={filters} setFilters={setFilters}/>
                        <div className="row stay">
                            <h3>{filteredPool ? filteredPool.pool.length : "Geen"} vragen gevonden</h3>
                            <QuestionCreator currentPool={currentPool} collectionType={collectionType}/>
                        </div>
                    </div>
                    <QuestionList filteredPool={filteredPool} currentPool={currentPool} collectionType={collectionType}/>
                </div>
            </div>
        </div>
    )
};