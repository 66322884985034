import "../viewer/Viewer.css"
import ScoreViewer from '../viewer/ScoreViewer';
import QuestionViewer from '../viewer/QuestionViewer';
import ScoreStage from "../viewer/ScoreStage";

export default function PhoneViewerViewer({activeQuestion, groupDoc, qwestoDoc, gameDoc}){
    return(
        <>
            {activeQuestion &&
                <div className="viewer-container phone-viewer" style={{ 
                    "--viewport-height": "calc(100svh - 134px)", 
                    "--question-height": "calc(7svh)",
                    "--question-total-height": "calc(7svh + var(--question-total-padding))" 
                }}>
                    {(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN" || gameDoc.stage === "ANSWERING" || gameDoc.stage === "RESULTS") ?
                        <QuestionViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc} qwestoDoc={qwestoDoc}/>
                    :
                        <>
                        {gameDoc.stage === "FINISHED" ?
                            <ScoreStage groupDoc={groupDoc} gameDoc={gameDoc}/>
                        :
                            <ScoreViewer groupDoc={groupDoc} gameDoc={gameDoc}/>
                        }
                        </>
                    }
                </div>
            }
        </>
    )
}
