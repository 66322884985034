import React from "react";
import { useState } from 'react';
import PopUp from '../../../components/layout/PopUp';
import DropDown from '../../../components/input/DropDown';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebaseConfig';
import { useMessageContext } from '../../../hooks/useMessageContext';
import { mapLabels } from "../../../utils/pure/mapLabels";
import { structureTemplateObject } from "../../../data/constants";

export function StructureCreator({structures}){
    const [structureOpen, setStructureOpen] = useState(false)
    const [structureData, setStructureData] = useState(structureTemplateObject)
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [isSaving, setIsSaving] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()
console.log(structureData);
    function handleNextStructureData(category, type, value){
        const nextStructureData = {...structureData}
        nextStructureData[category][type] = value
        let count = 0
        Object.keys(nextStructureData).forEach((category) => {
            switch (category) {
                case "common":
                    Object.entries(nextStructureData[category]).forEach(([type, number]) => {
                        if(number !== null){
                            count = count + parseInt(number) 
                        }
                    }) 
                    break;
                case "chosen":
                case "dummy":
                    count = count + parseInt(nextStructureData[category].target) 
                    break;
                default:
                    break;
            }
        })
        setTotalQuestions(count)
        setStructureData(nextStructureData)
    }

    async function handleSaveStructure(e){
        e.preventDefault()
        setIsSaving(true)
        const structureRef = doc(db, "shop", "structures")
        try {
            await updateDoc(structureRef, {
                list: arrayUnion({...structureData, id: structures.length})
            })
            setNewInfo("Succes a mattie")
            setStructureOpen(false)
            setIsSaving(false)
        } catch (error) {
            console.log(error);
            setNewError("Er ging iets fout")
            setIsSaving(false)
        }
    }

    return(
        <>
            <div className="question-creator">
                <button className="btn black small round" onClick={() => {setStructureOpen(true)}}>+</button>
            </div>
            <PopUp title="Add structure" visible={structureOpen} setVisible={setStructureOpen} large unclosable>
                <form className="column gap">
                    <span className="text-large">Totaal aantal vragen: {totalQuestions}</span>
                    <div className="row align-flex-start">
                        {Object.keys(structureTemplateObject).map((category) => {
                                if(category === "labels"){
                                    return(
                                        <div className="column" key={category}>
                                            <span className="text-medium">{category}</span>
                                            {Object.keys(structureTemplateObject[category]).sort().map((type) => (
                                                <div className="column" key={type}>
                                                    <span className="text-small grey-text">{type}</span>
                                                    <DropDown 
                                                        message="Labels" 
                                                        options={mapLabels((() => {
                                                            switch(type){
                                                                case "o":
                                                                    return [1]
                                                                case "a":
                                                                    return [1,2,3,4,5]
                                                                default:
                                                                    return [1,2,3]
                                                            }
                                                        })())} 
                                                        selectedOption={structureData[category][type] ? mapLabels(structureData[category][type]) : []}
                                                        setSelectedOption={(value) => {handleNextStructureData(category, type, value)}}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div className="column" key={category}>
                                            <span className="text-medium">{category}</span>
                                            {Object.keys(structureTemplateObject[category]).sort().map((type) => (
                                                <div className="column" key={type}>
                                                    <span className="text-small grey-text">{type}</span>
                                                    <input type="number" id="question"
                                                        step={["dummy", "chosen"].includes(category) ? .1 : 1}
                                                        required
                                                        min={0}
                                                        value={structureData[category][type]}
                                                        onChange={(e) => {handleNextStructureData(category, type, e.target.value)}}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                            }
                        )}
                    </div>
                    <div className="row stay">
                        <button className="btn black" type="button" onClick={() => {setStructureOpen(false)}}>Annuleren</button>
                        <button className="btn-gr" type="submit" disabled={isSaving} onClick={(e) => {handleSaveStructure(e)}}>{isSaving ? "Werkende..." : "Opslaan"}</button>
                    </div>
                </form>
            </PopUp>
        </>
    )
}