import Footer from "../../components/layout/Footer";
import Redirect from "../../components/layout/Redirect";
import ProductForm from "./ProductForm";
import ProductInfo from "./ProductInfo";
import { useEffect, useState } from "react";
import { useProductContext } from "../../hooks/useProductContext";
import Loader from "../../components/loaders/Loader";
import "./Product.css"
import SEO from "../../components/utils/SEO";
import FAQList from "../info/FAQList";
import { phoneMockupURL, PRODUCT_IMAGE_URLS, TVMockupURL } from "../../data/constants";
import VideoEmbedder from "../../components/layout/VideoEmbedder";
import ImageSlider from "../../components/layout/ImageSlider";

export default function ProductDetails() {
    const {products, noProductData} = useProductContext()
    const [layoutChange, setLayoutChange] = useState(window.innerWidth < 1000)
    
    useEffect(() => {
        const handleResize = () => {
            setLayoutChange(window.innerWidth < 1000);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);    

    if(!products){
        return(
            <Loader/>
        )
    }

    if(noProductData || !products[0]){
        return(
            <Redirect to="/products" message="Dit product bestaat niet" arrow="left" action="Terug" />
        )
    }

    return(
        <>
        <SEO title={products[0].name} description={"Personaliseer je Qwesto en voltooi je aankoop"}/>
        <div className="content-page overflow">
            <div className="content-field white">
                <div className="content-container max-width-wide">
                    <div className="product-row">
                        <div className="product-column">
                            <div className="column gap">
                                
                            {layoutChange && <ProductForm product={products[0]}/>}
                            <ImageSlider images={PRODUCT_IMAGE_URLS}/> 
                            </div>
                            <ProductInfo product={products[0]}/>
                        </div>
                        {!layoutChange && <ProductForm product={products[0]}/>}
                    </div>
                    <div className="product-explanation">
                        <div className="product-explanation-column">
                            <h2>2 manieren om te weergeven</h2>
                            <div className="row stay gap no-grow text-align-left">
                                <img src={TVMockupURL} alt="" className="product-explanation-img"/>
                                <div className="column no-gap">
                                    <h3>Met een groot scherm</h3>
                                    <p className="grey-text">Toon de Qwesto op één TV of beamer en gebruik elke telefoon als afstandbediening. Ga met z'n allen voor het scherm zitten en je kunt beginnen!</p>
                                </div>
                            </div>
                            <div className="column center">
                                <span className="grey-text">of</span>
                            </div>
                            <div className="row stay gap no-grow text-align-left">
                                <img src={phoneMockupURL} alt="" className="product-explanation-img"/>
                                <div className="column no-gap">
                                    <h3>Zonder een groot scherm</h3>
                                    <p className="grey-text">Geen groot scherm ter beschikking? Toon dan de Qwesto op ieders telefoon en gebruik elke telefoon als afstandbediening en weergever tegelijkertijd. Het maakt niet uit waar of hoe je zit, je kunt op elk moment beginnen!</p>
                                </div>
                            </div>
                        </div>
                        <div className="product-explanation-column">
                            <div className="column-no-gap">
                                <h2>Hoe speel ik een Qwesto?</h2>
                                <p className="grey-text">Bekijk de video voor een snelle uitleg</p>
                            </div>
                            <VideoEmbedder src={`https://www.youtube.com/embed/BSNAO0PxKhs`} title="explanation"/>
                        </div>
                        <FAQList small/> 
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        </>
    )
}