import React from "react";
import Check from "../../../assets/img/Check";

export function CorrectPlayer({photoURL, check, nickName, fixedAmount}){
    return(
        <div className={`correct-player ${fixedAmount ? "fixed-amount" : ""}`}>
            <img src={photoURL} alt="" className="correct-player-img" draggable="false" />
            <div className="correct-player-label">
                {check && <Check className="icon" alt="" />}
                <img src={photoURL} className="correct-player-avatar" alt="" />
                <span className="viewer-text">{nickName}</span>
            </div>
        </div>
    )
}
