import "./Avatar.css"
import React from 'react'

export default function Avatar({src, border}) {
  return (
    <>
    {border ? 
    <div className="avatar border">
      <img src={src} alt="" className="img"/>
    </div>
    :
    <div className="avatar">
      <img src={src} alt="" className="img"/>
    </div>
    }
    </>
  )
}
