import ImageInput from "../../../components/input/ImageInput"

export function BestPhoto({ photo, setPhoto, photoURL, setPhotoURL}){

    return(
        <div className="border-column grow center">  
            <h4>Upload een foto die het beste bij de vraag past</h4>            
            <div className="row stay center grow">   
                <div className="img-input-field">
                    <ImageInput aspect={1} title="Kies de leukste foto" message=" " photoURL={photoURL} setPhoto={setPhoto} setPhotoURL={setPhotoURL} className="intake"/>
                    <p className="grey-text">{photo ? 1 : 0}/1</p>
                </div>
            </div>       
        </div>
    )
}