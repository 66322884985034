import { useLocation, useNavigate } from "react-router-dom";
import SignUpForm from "../../components/authforms/SignUpForm"
import Footer from "../../components/layout/Footer"
import SEO from "../../components/utils/SEO";

export default function SignUp() {
    const location = useLocation();
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    return(
        <>
        <SEO title={"Aanmelden"} description={"Meld je aan om een Qwesto te maken of spelen."}/>
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container min-height">
                    <div className="column center">
                        <SignUpForm redirect={redirect}/>
                        <span className="text-small grey-text">of</span>
                        <button className="btn black round" onClick={() => navigate(`/login${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ""}`)}>Inloggen</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        </>
    )
};