import React from "react";
import { NavLink } from "react-router-dom";
import ArrowRight from "../../assets/img/ArrowRight";
import ArticleItem from "./ArticleItem";
import { BLOG_POSTS } from "../../data/blog";


export default function ArticleList({small}){

    if(BLOG_POSTS.length === 0){
        return(
            <div className="column center">
                <h2>Geen posts gevonden</h2>
            </div>
        )
    }

    return(
        <>
            {small ? 
                <>
                    <div className="column center">
                        <h2>Onze laatste blog posts</h2>
                    </div>
                    <div className="article-list small">
                        {BLOG_POSTS.reverse().slice(0, 3).map((article) => (
                            <React.Fragment key={article.id}>
                                <ArticleItem article={article}/>
                            </React.Fragment>
                        ))}
                    </div>
                    <NavLink to="/blog" className="btn text">Alle posts <ArrowRight className="icon"/></NavLink>
                </>
            :
                <div className="article-list">
                    {BLOG_POSTS.reverse().map((article) => (
                        <React.Fragment key={article.id}>
                            <ArticleItem article={article}/>
                        </React.Fragment>
                    ))}
                </div>
            }
        </>
    )
};