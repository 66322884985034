import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import React from "react";
import SelectionBar from "../../../components/input/SelectionBar";
import { db } from "../../../config/firebaseConfig";
import { useMessageContext } from "../../../hooks/useMessageContext";


export function CollectionControls({controlsLocked, setControlsLocked, collectionType, setCollectionType}){
    const {setNewError, setNewInfo} = useMessageContext()

    async function syncCollections(source, target){
        const sourceCollectionRef = collection(db, source);
        try {
            const querySnapshot = await getDocs(sourceCollectionRef);
    
            querySnapshot.forEach(async (snap) => {
                const data = snap.data();
                const poolType = snap.id;
        
                // Create a new document in the target collection using a transaction
                const targetDocRef = doc(db, target, poolType)
                try {
                    await setDoc(targetDocRef, data);
                    setNewInfo(`Document ${poolType} synchronized successfully`);
                } catch (error) {
                    setNewError(`Error synchronizing document ${poolType}:`, error);
                }
            });
        } catch (error) {
            console.log(error);
            setNewError('Error retrieving documents:', error)
        }
        setNewInfo("Synchronized successfully")
    }

    return(
        <div className={`collection-controls ${controlsLocked ? "locked" : ""}`} onClick={() => {setControlsLocked(!controlsLocked)}}>
            <button className="btn black no-grow" onClick={() => {syncCollections("pools_draft", "pools")}}>Sync draft to production</button>
            <SelectionBar options={["Draft", "Production"]} customValues={["draft", "production"]} value={collectionType} setValue={setCollectionType} round unselectable />
            <button className="btn no-grow" onClick={() => {syncCollections("pools", "pools_draft")}}>Sync production to draft</button>
        </div>
    )
}