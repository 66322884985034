import { useState } from "react";
import PopUp from "../../../components/layout/PopUp";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useSkipIntakeQuestion } from "../../../hooks/useSkipIntakeQuestion";

export default function Skipper({qwestoID, activeQuestion}) {
    const {user} = useAuthContext()
    const {skipIsPending, trySkipQuestion} = useSkipIntakeQuestion()
    const [skipVisible, setSkipVisible] = useState(false)

    async function handleSkip(){
        await trySkipQuestion(qwestoID, activeQuestion, user)
        setSkipVisible(false)
    }

    return(         
        <>
        <PopUp visible={skipVisible} setVisible={setSkipVisible} >
            <div className="border-column center">
                <h3>Weet je zeker dat je de vraag wilt overslaan?</h3>
                <p className="grey-text">Door vragen over te slaan wordt je Qwesto korter en misschien krijg je wel minpunten!</p>
            </div>
            <div className="border-column no-border">
                <div className="row stay">
                    <button className="btn-gr" onClick={() => {setSkipVisible(false)}}>Nee</button>
                    <button className="btn" onClick={() => {handleSkip()}} disabled={skipIsPending}>Ja</button>
                </div>
            </div>
        </PopUp>
        <button className="btn text" onClick={() => {setSkipVisible(true)}}>
            Overslaan
        </button>
        </>        
    )
}