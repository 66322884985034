import Check from "../../../assets/img/Check";
import Exit from "../../../assets/img/Exit";

export default function ControlBarAvatarLabels({gameDoc, answeredProps, activeQuestion, index}) {

    if((gameDoc.stage === "RESULTS" || gameDoc.stage === "STATS") && answeredProps.answered[index]){
        switch(activeQuestion.type){
            case "BATTLE":
                return null
            default:
                if(gameDoc.pointsEarned[answeredProps.answered[index].uid] > 0){
                    return(
                        <Check className="icon correct"/>
                    )
                }else{
                    return(
                        <Exit className="icon wrong"/>
                    )
                }
        }
    }
    
}
