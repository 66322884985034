import WhatsApp from "../../assets/img/WhatsApp";
import Footer from "../../components/layout/Footer";

export default function Contact(){

    return(
        <div className="content-page">
            <div className="content-container min-height max-width center large-gap">
                        <div className="column large-gap">
                            <div className="column no-gap">
                                <h1 className="text-xlarge">Contact</h1>
                                <span className="text-small">Voor vragen, opmerkingen of klachten</span>
                            </div>
                            <div className="column">
                                <button className="btn black no-grow round" onClick={() => window.location = "mailto:support@qwesto.nl"}>Mail ons</button>
                                <button className="btn green no-grow round" onClick={() => window.open("https://wa.me/31657201393?text=", "_blank")}>Chat met ons <WhatsApp className="icon"/></button>
                            </div>
                        </div>
            </div>
            <Footer/>
        </div>
    )
}


