import { onValue, ref } from 'firebase/database';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import { db, rdb } from '../config/firebaseConfig';
import { useAuthContext } from '../hooks/useAuthContext';

export const GroupDataContext = createContext();

export const GroupDataContextProvider = ({ children }) => {
	const {user} = useAuthContext()
	const [props, setProps] = useState({
		type: null,
		qwestoID: null
	});
	const defaultGroupData = {
		group: null,
		qwesto: null,
		game: null,
		intake: null,
		multiple: null
	}
	const [groupData, setGroupData] = useState(defaultGroupData)
	const [noGroupData, setNoGroupData] = useState(false)

	function setNewProps(newProps){
		if(newProps !== props){
			setGroupData((currentData) => ({
				...currentData,
				group: null,
				qwesto: null,
				game: null,
				intake: null,
			}))
			setProps((currentProps) => ({
				...currentProps,
				...newProps
			}))
		}
	}

	function updateGroupData(key, value){
		setGroupData((currentData) => {
			let newGroupData = {
				...currentData,	
				[key]: value
			}
			return newGroupData
		})
	}

	function getRDBdata(collection, type){
		const docRef = ref(rdb, `${collection}/` + props.qwestoID)
		const unsub = onValue(docRef, (doc) => {
			if(doc.val()){
				updateGroupData(type, {...doc.val(), id: doc.key})
			}else{
				updateGroupData(type, null)
				setNoGroupData(true)
			}
		}, error => {
			updateGroupData(type, null)
			setNoGroupData(true)
			console.log(error);
		})
		return unsub
	}

	function getDBData(collection, type){
		const docRef = doc(db, collection, props.qwestoID)
		const unsub = onSnapshot(docRef, (doc) => {
				if(doc.data()){
					updateGroupData(type, {...doc.data(), id: doc.id})
				}else{
					updateGroupData(type, null)
					setNoGroupData(true)
				}
			}, error => {
				updateGroupData(type, null)
				setNoGroupData(true)
				console.log(error);
			}
		)
		return unsub
	}

	function getMultipleGroupData(type){
		const q = query(collection(db, "groups"), where("playersAllowed", "array-contains", user.uid))
		const unsub = onSnapshot(q, (snapshot) => {
			let results = []
			snapshot.docs.forEach(doc => {
				if(doc.data().players.some(player => player.uid === user.uid)){
					if(type === "ALL_ACTIVE_GROUPS"){
						if(doc.data().product.qwestoIsDeleted === false){
							results.push({...doc.data(), id: doc.id})
						}
					}else{
						results.push({...doc.data(), id: doc.id})
					}
				}
			})
			if(results.length > 0){
				updateGroupData("multiple", results)
				setNoGroupData(false)
			}else{
				updateGroupData("multiple", [])
				setNoGroupData(false)
			}
		}, error => {
			console.log(error);
			setNoGroupData(true)
		})
		return unsub
	}

	useEffect(() => {
		if(user && props.type && (props.type === "ALL_GROUPS" || props.type === "ALL_ACTIVE_GROUPS")){
			setNoGroupData(false)
			const unsub = getMultipleGroupData(props.type)
			return () => {unsub()}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, user])

	useEffect(() => {
		if(user && props.type && props.qwestoID && props.type === "GROUP_AND_INTAKE_DATA"){
			setNoGroupData(false)
			const unsub = getDBData("groups", "group")
			return () => unsub()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, user])

	useEffect(() => {
		if(user && props.type && props.qwestoID && props.type === "GROUP_AND_GAME_DATA"){
			setNoGroupData(false)
			const unsubs = [
				getDBData("groups", "group"),
				getDBData("qwestos", "qwesto"),
				getRDBdata("games", "game")
			]

			return () => {
				unsubs.forEach((unsub) => {unsub()})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, user])

	useEffect(() => {
		if(user && groupData.group && props.type && props.qwestoID && props.type === "GROUP_AND_INTAKE_DATA" && groupData.group.groupIsConfirmed && !groupData.group.product.qwestoIsDeleted){
			setNoGroupData(false)
			const unsub = getRDBdata("intakes", "intake")
			return () => unsub()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupData.group, props, user])

	return (
		<GroupDataContext.Provider value={{groupData, noGroupData, setNewProps}}>
			{children}
		</GroupDataContext.Provider>
	);
};
