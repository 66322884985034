import { useNavigate } from "react-router-dom"
import { httpsCallable } from "firebase/functions";
import "./Manager.css"
import { functions } from "../../config/firebaseConfig";

export default function Manager(){
    const navigate = useNavigate()

    async function functionTester(){
        const test = httpsCallable(functions, "handleTest")
        try {
            await test()
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container center gap">
                    <h1>Managers</h1>
                    <div className="row">
                        <button className="btn" onClick={() => {navigate("question")}}>Question manager</button>
                        <button className="btn" onClick={() => {navigate("structures")}}>Structure manager</button>
                        <button className="btn" onClick={() => {navigate("article")}}>Article manager</button>
                        <button className="btn" onClick={() => {functionTester()}}>Function tester</button>
                    </div>
                    <h2>💯</h2>
                </div>
            </div>
        </div>
    )
};