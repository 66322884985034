import { ref, update } from "firebase/database"
import { rdb } from "../../../config/firebaseConfig"
import { getMostFrequentAnswer } from "../getMostFrequentAnswer"
import { getPlayerResults } from "../getPlayerResults"
import { finishQwesto } from "./finishQwesto";

export async function deactivateQwestoQuestion(activeQuestion, gameDoc, groupDoc, qwestoDoc){
    try{
        let updateObject;
        switch (activeQuestion.type){
            case "MOSTLIKELY":
                    let correctAnswers = []
                    Object.keys(gameDoc.answers).forEach((uid) => {
                        if(gameDoc.answers[uid] !== "EMPTY"){
                            correctAnswers.push(gameDoc.answers[uid])
                        }
                    })
                    const correctAnswer = getMostFrequentAnswer(correctAnswers)
                    gameDoc.correctAnswer = correctAnswer
                    updateObject = {
                        ...getPlayerResults(activeQuestion, groupDoc, gameDoc),
                        correctAnswer: correctAnswer.length > 0 ? correctAnswer : "EMPTY",
                        stage: "RESULTS"
                    }
                    await update(ref(rdb, "games/" + groupDoc.qwestoID), updateObject)
                break;
            case "SKIPPED":
                    const playerResults = getPlayerResults(activeQuestion, groupDoc, gameDoc)
                    updateObject = {
                        ...playerResults,
                        stage: "STATS"
                    }
                    if(gameDoc.activeQuestionIndex === Object.keys(qwestoDoc.qwestoQuestions).length){
                        await finishQwesto(groupDoc.qwestoID, playerResults)
                    }else{
                        await update(ref(rdb, "games/" + groupDoc.qwestoID), updateObject)
                    }
                break;
            default:
                    updateObject = {
                        ...getPlayerResults(activeQuestion, groupDoc, gameDoc),
                        stage: "RESULTS"
                    }
                    await update(ref(rdb, "games/" + groupDoc.qwestoID), updateObject)
                break;
        }
    }catch(error){
        console.log(error);
        throw new Error("Could not deactivate question")
    }
}