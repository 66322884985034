import { getPlayersByPoints } from '../../../utils/pure/getPlayersByPoints';
import { useState, useEffect } from "react";
import ScoreList from './ScoreList';

export default function ScoreViewer({groupDoc, gameDoc, small}){
    const [stats, setStats] = useState(null)

    useEffect(() => {
        if(gameDoc){
            if(gameDoc.stage === "STATS"){
                setStats(getPlayersByPoints(gameDoc))
            }
        }
    }, [gameDoc])

    if(small){
        return(
            <div className="column center gap grow">
                <span className="text-large">Tussenstand</span>
                <div className="score-board">
                    {stats && <ScoreList stats={stats} groupDoc={groupDoc}/>}
                </div>
            </div>
        )
    }else{
        return(
            <div className="content-field score-viewer">
                <div className="question slide-up before">Tussenstand</div>
                <div className="correct-answer no-margin">
                    <div className="score-board">
                        {stats && <ScoreList stats={stats} groupDoc={groupDoc}/>}
                    </div>
                </div>
            </div>
        )
    }
}
