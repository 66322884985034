import React from "react";
export function LabelDisplay({label, values}){
    return( 
        <div className="label-display">
            <span className="text-xxsmall grey-text">{label}</span>
            <div className="row stay no-gap">
                {values.sort().map((value, index) => (
                    <div className="label-value" key={index}>
                        {value}
                    </div>
                ))}
            </div>
        </div>
    )
}