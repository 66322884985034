export default function WhatDoYouHearIcon(props) {
    return (
        <svg
    viewBox="0 0 500 500"
    width={437.043}
    height={383.831}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
        <g
        data-name="Communication / Chat_Circle_Dots"
        transform="translate(15.19 23.857) scale(.68628)"
        />
        <g
        data-name="Communication / Chat_Circle_Close"
        transform="translate(15.19 23.857) scale(.68628)"
        />
        <g
        data-name="Communication / Chat_Circle_Check"
        transform="translate(15.19 23.857) scale(.68628)"
        />
        <g
        data-name="Media / Volume_Minus"
        transform="translate(38.178 89.287) scale(.6235)"
        />
        <g data-name="Warning / Help">
        <path
            data-name="Vector"
            d="M459.48 168.58c6.25-19.25 17.7-36.39 33.08-49.53a109.616 109.616 0 0 1 54.08-24.95c19.99-3.17 40.47-.75 59.17 6.99 18.69 7.74 34.87 20.5 46.77 36.87 11.9 16.37 19.04 35.7 20.63 55.87 1.59 20.17-2.44 40.39-11.63 58.42-9.19 18.03-23.16 33.18-40.41 43.75a109.73 109.73 0 0 1-57.33 16.17v27.42m1.36 109.71v2.74h-2.73v-2.74h2.73Zm-349.19-63.35 55.84 69.1c29.11 36.02 43.67 54.03 56.42 55.99a33.339 33.339 0 0 0 29.71-10.5c8.69-9.54 8.69-32.7 8.69-79.01V123.24c0-46.32 0-69.47-8.69-79.01a33.345 33.345 0 0 0-29.71-10.51c-12.75 1.96-27.31 19.97-56.42 56l-55.84 69.1c-5.88 7.28-8.82 10.92-12.46 13.53a33.23 33.23 0 0 1-10.67 5.09c-4.32 1.18-9 1.18-18.36 1.18h-47.44c-25.21 0-37.81 0-48 3.36a66.679 66.679 0 0 0-42.39 42.39c-3.36 10.19-3.36 22.79-3.36 48s0 37.81 3.36 48a66.679 66.679 0 0 0 42.39 42.39c10.19 3.36 22.79 3.36 48 3.36h47.44c9.36 0 14.03 0 18.36 1.18a33.43 33.43 0 0 1 10.67 5.09c3.64 2.62 6.58 6.26 12.46 13.54v.02Z"
            style={{
            fill: "none",
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "66.67px",
            }}
            transform="translate(32.273 88.81) scale(.6235)"
        />
        </g>
    </svg>
    );
}