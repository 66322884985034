import { useNavigate } from "react-router-dom"
import ArrowLeft from "../../assets/img/ArrowLeft"
import "./BackButton.css"

export default function BackButton({to, responsive, sticky, top}){
    const navigate = useNavigate()
    return(
        <button className={`btn back${responsive ? " responsive" : ""}${sticky ? " sticky" : ""}`} style={{top: top ? top : "revert-layer"}} onClick={() => {navigate(to)}}>
            <ArrowLeft className="icon"/>
        </button>
    )
}