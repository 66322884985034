import { Helmet } from "react-helmet"

export default function SEO({title, description, canonical, noindex}){
    return(
        <Helmet>
            {noindex && <meta name="robots" content="noindex"/>}
            <title>{title}</title>
            <meta name="description" content={description}/>
            {canonical &&
                <link rel="canonical" href={`https://qwesto.nl/${canonical}`} />
            }
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content=" http://euro-travel-example.com/thumbnail.jpg"/>
            <meta name="twitter:card" content="summary_large_image"/>
        </Helmet>
    )
};