import { useEffect, useMemo } from "react"
import { useGroupDataContext } from "../../hooks/useGroupDataContext"
import Footer from "../../components/layout/Footer"
import GroupListNew from "./GroupListNew"
import Redirect from "../../components/layout/Redirect"
import Loader from "../../components/loaders/Loader"
import Check from "../../assets/img/Check"
import { PRODUCT_CLOSE_UP } from "../../data/constants"
import ContentFieldBanner from "../../components/layout/ContentFieldBanner"
import { useLocation } from "react-router-dom"
import { useUserContext } from "../../hooks/useUserContext"
import { getUserPaymentByID } from "../../utils/pure/payments/getUserPaymentByID"
import { firePurchaseEvent } from "../../utils/pure/tracking/firePurchaseEvent"

export default function PaymentSuccess(){
    const {userDoc} = useUserContext()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const session_id = searchParams.get("session_id");

    useEffect(() => {
        if(userDoc && session_id){
            const paymentData = getUserPaymentByID(userDoc, session_id);
            if(paymentData)
                firePurchaseEvent(paymentData);
        }
    }, [session_id, userDoc])

    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "ALL_GROUPS",
        qwestoID: null
    }), []))

    if(noGroupData){
        return(
            <Redirect to="/groups" message="Oeps, er is iets misgegaan." action="Terug" arrow="left"/>
        )
    }

    if(!groupData.multiple){
        return(
            <Loader/>
        )
    }

    return(
        <div className="content-page">
                <div className="content-container no-margin min-height">
                    <ContentFieldBanner src={PRODUCT_CLOSE_UP} sticky={true}>
                        <div className="content-container center">
                            <Check className="icon massive"/>
                            <span className="text-large">Bedankt voor je aankoop!</span>
                            <span className="text-xsmall">Bekijk je nieuwe groepen hieronder:</span>
                        </div>
                    </ContentFieldBanner>
                    <div className="content-container max-width">
                        <div className="group-list">
                            <GroupListNew groupDocs={groupData.multiple} noData={noGroupData}/>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}