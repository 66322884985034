let wakeLock = null

export async function requestWakeLock(){
    if ("wakeLock" in navigator) {
        try {
            wakeLock = await navigator.wakeLock.request("screen");
        } catch (error) {
            console.log(error.message);
        }
    } else {
        console.log("Wake lock is not supported in this browser.");
    }
}

export function releaseWakeLock(){
    if (wakeLock) {
        wakeLock.release().then(() => {
            console.log("Wake lock released.");
        }).catch((error) => {
            console.log("Failed to release wake lock:", error.message);
        });
        wakeLock = null;
    } else {
        console.log("No wake lock to release.");
    }
}

