import { Link } from "react-router-dom"
import Avatar from "../../components/layout/Avatar"
import QwestoBig from "../../assets/img/QwestoBig";
import "./MyQwesto.css"
import { useAuthContext } from "../../hooks/useAuthContext";
import React, { useEffect } from "react";
import { FAVOURITE_GROUP_URL, DEFAULT_GROUP_URL } from "../../data/constants";
import { useState } from "react";
import { useNavigationBarContext } from "../../hooks/useNavigationBarContext";
import Group from "../../assets/img/Group";

export default function GroupListActive({groupDocs, noData}){
    const {user} = useAuthContext()
    const {setFilterVisible} = useNavigationBarContext()
    const [activeGroups, setActiveGroups] = useState([])

    useEffect(() => {
        if(groupDocs){
            let activeList = []
            groupDocs.forEach((group) => {
                if(!group.product.qwestoIsDeleted){
                    activeList.push(group)
                }
            })
            setActiveGroups(activeList)
        }
    }, [groupDocs])

    if(!noData && activeGroups.length > 0 && user){
        return(
            <>
                <h2>{activeGroups.length} {activeGroups.length !== 0 ? (activeGroups.length > 1 ? "actieve groepen" : "actieve groep") : "actieve groepen"}</h2>
                {activeGroups && activeGroups.map(group => (
                    <React.Fragment key={group.id}>
                        <Link to={`/groups/${group.id}`} className={"group-item " + (group.groupIsInitialized ? "" : "new")}>
                            {!group.groupIsInitialized &&
                                <div className="group-new">
                                    <span className="text-small">Nieuw</span>
                                </div>
                            }
                            <QwestoBig className="group-badge"/>
                            <img className="group-item-img" src={group.groupPhotoURL ? group.groupPhotoURL : DEFAULT_GROUP_URL} alt="Product"/>
                            <div className="group-item-label">
                                <div className="column">
                                    <h2>{group.groupName}</h2>
                                    <p className="grey-text">{group.host.uid === user.uid ? "Georganiseerd door jou" : `Georganiseerd door ${group.host.nickName}`}</p>
                                </div>
                                <div className="row stay flex-start">
                                    <div className="avatar-list">
                                        {group.players.slice(0,5).map(player => (
                                            <Avatar src={player.photoURL} key={player.uid}/>
                                        ))}
                                    </div>
                                    <p className="grey-text center">{group.players.length} speler{group.players.length > 1 ? "s" : ""}</p>
                                </div>
                            </div>
                        </Link>
                    </React.Fragment>
                ))
                }
            </>
            )
        }else{
            return(
                <>
                    <h2>0 actieve groepen</h2>
                    <button className="add-group-container" onClick={() => {setFilterVisible(true)}}>
                        <img src={FAVOURITE_GROUP_URL} alt="" className="add-group-container-img" />
                        <div className="add-group-container-label">
                            <Group className="icon massive"/>
                            <span className="text-small">+ voeg een groep toe</span>
                        </div>
                    </button>
                </>
            )
        }
}