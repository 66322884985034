export default function BestAnswerIcon(props) {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 666 666"
            {...props}
        >
            <g
            id="Communication_Chat_Circle_Dots"
            data-name="Communication / Chat_Circle_Dots"
            >
            <path
                id="Vector"
                d="M183.33 593.06c44.05 25.4 95.16 39.94 149.66 39.94C498.68 633 633 498.69 633 333S498.69 33 333 33 33 167.31 33 333c0 54.5 14.53 105.61 39.94 149.66l.1.17c2.44 4.24 3.68 6.38 4.24 8.4.53 1.9.67 3.62.54 5.59-.14 2.12-.86 4.31-2.32 8.71l-25.63 76.88-.03.1c-5.41 16.22-8.11 24.33-6.18 29.74 1.68 4.71 5.41 8.43 10.12 10.11 5.39 1.92 13.47-.77 29.62-6.15l.2-.08 76.88-25.63c4.38-1.46 6.6-2.2 8.72-2.34 1.97-.14 3.67.04 5.58.57 2.03.56 4.16 1.79 8.42 4.25l.14.08Z"
                style={{
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 66,
                }}
            />
            </g>
            <path
            className="cls-2"
            d="M257.74 559.72c-15.89 0-28.81-13.67-28.81-30.47V488.3c0-39.38 29.81-71.43 66.44-71.43h8.81v-24.79c-22.68-5.96-43.51-18.89-59.7-37.11h-24.37c-47.01 0-85.26-41.23-85.26-91.9v-61.43c0-16.8 12.93-30.48 28.81-30.48h46.44v-10.48c0-16.8 12.93-30.48 28.81-30.48h188.15c15.89 0 28.81 13.67 28.81 30.48v10.48h46.44c15.89 0 28.81 13.67 28.81 30.48v61.43c0 50.67-38.25 91.9-85.26 91.9h-24.37c-16.19 18.22-37.02 31.15-59.7 37.11v24.79h8.81c36.64 0 66.44 32.04 66.44 71.43v40.95c0 16.8-12.93 30.47-28.81 30.47H257.71Zm121.7-60.95V488.3c0-5.78-3.95-10.48-8.82-10.48h-75.26c-4.86 0-8.81 4.7-8.81 10.48v10.47h92.89Zm-111.7-235.7c0 39.65 29.27 71.9 65.26 71.9s65.26-32.25 65.26-71.9v-71.9H267.74v71.9Zm188.15 0a142.73 142.73 0 0 1-3.26 30.02c11.99-3.38 20.89-15.56 20.89-30.02v-30.95h-17.63v30.95Zm-263.41 0c0 14.46 8.9 26.64 20.89 30.02-2.14-9.81-3.23-19.85-3.26-29.99v-30.98h-17.63v30.95Z"
            />
            <path
            className="cls-2"
            d="M502.33 181.17h-56.44v-20.48c0-11.31-8.42-20.48-18.81-20.48H238.93c-10.39 0-18.81 9.17-18.81 20.48v20.48h-56.44c-10.39 0-18.81 9.17-18.81 20.48v61.43c0 45.23 33.69 81.9 75.26 81.9h28.97c17.02 20.66 39.96 34.45 65.1 39.11v42.79h-18.81c-31.17 0-56.44 27.5-56.44 61.43v40.95c0 11.31 8.42 20.48 18.81 20.48h150.52c10.39 0 18.81-9.17 18.81-20.48v-40.95c0-33.92-25.27-61.43-56.44-61.43h-18.81v-42.79c25.14-4.66 48.08-18.45 65.1-39.11h28.97c41.56 0 75.26-36.67 75.26-81.9v-61.43c0-11.31-8.42-20.48-18.81-20.48ZM220.11 304.02c-20.78 0-37.63-18.33-37.63-40.95v-40.95h37.63v40.95c.04 13.96 2.27 27.81 6.59 40.95h-6.59Zm150.52 163.8c10.39 0 18.81 9.17 18.81 20.48v20.48H276.55V488.3c0-11.31 8.42-20.48 18.81-20.48h75.26Zm37.63-204.75c0 45.23-33.69 81.9-75.26 81.9s-75.26-36.67-75.26-81.9v-81.9h150.52v81.9Zm75.26 0c0 22.62-16.85 40.95-37.63 40.95h-6.59c4.32-13.14 6.54-26.99 6.59-40.95v-40.95h37.63v40.95Z"
            />
        </svg>
    );
}