import { sendEmailVerification } from "firebase/auth"
import { useState } from "react"
import { useMessageContext } from "./useMessageContext"

export function useSendVerification(){
    const { setNewError, setNewInfo } = useMessageContext()
    const [emailPending, setEmailpending] = useState(false)

    async function trySendVerification(user){
        setEmailpending(true)
        sendEmailVerification(user)
        .then(
            setNewInfo("We hebben een nieuwe verficatie gestuurd naar je email.")
        ).catch( error => {
            setNewError(error)
        })
        await new Promise(resolve => setTimeout(resolve, 60000)); 
        setEmailpending(false)
    }
            
    return { emailPending, trySendVerification }
}