export default function GuessWhoIcon(props) {
    return (
        <svg
    viewBox="0 0 666.67 666.67"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
        <g data-name="Communication / Chat_Circle_Dots" />
        <g data-name="User / User_02">
        <path
            data-name="Vector"
            d="M489.09 477.5c0-53.76-69.73-68.38-155.75-68.38s-155.75 14.62-155.75 68.38m155.74-155.75c-53.76 0-97.35-43.58-97.35-97.35 0-53.77 43.58-97.35 97.35-97.35 53.77 0 97.35 43.58 97.35 97.35 0 53.77-43.58 97.35-97.35 97.35ZM183.66 593.39c44.05 25.4 95.16 39.94 149.66 39.94 165.69 0 300.01-134.31 300.01-300s-134.31-300-300-300-300 134.32-300 300c0 54.5 14.53 105.61 39.94 149.66l.1.17c2.44 4.24 3.68 6.38 4.24 8.4.53 1.9.67 3.62.54 5.59-.14 2.12-.86 4.31-2.32 8.71L50.2 582.74l-.03.1c-5.41 16.22-8.11 24.33-6.18 29.74 1.68 4.71 5.41 8.43 10.12 10.11 5.39 1.92 13.47-.77 29.62-6.15l.2-.08 76.88-25.63c4.38-1.46 6.6-2.2 8.72-2.34 1.97-.14 3.67.04 5.58.57 2.03.56 4.16 1.79 8.42 4.25l.14.08h-.01Z"
            style={{
            fill: "none",
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "66.67px",
            }}
        />
        </g>
    </svg>
    );
  }