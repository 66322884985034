import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import "./DropDown.css"

export default function DropDown({message, options, selectedOption, setSelectedOption}){
    const [selectedOptionData, setSelectedOptionData] = useState(selectedOption)

    useEffect(() => {
        if(selectedOptionData){
            let values = []
            selectedOptionData.forEach((data) => {
                values.push(data.value)
            })
            setSelectedOption(values)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptionData])

    useEffect(() => {
        if(!selectedOption){
            setSelectedOptionData(null)
        }
    }, [selectedOption])
    

    const styles = {
        control: (base, state) => ({
            ...base,
            background: "var(--light-white)",
            borderColor: state.isHovered ? "var(--dark-white)" : "var(--mid-white)",
            boxShadow: 'none'
        }),
        multiValue: base => ({
            ...base,
            background: "var(--mid-white)"
        })
    };

    return(
        <div className="drop-down">
            <Select
                placeholder={<div>{message}</div>}
                defaultValue={null}
                value={selectedOptionData}
                onChange={setSelectedOptionData}
                options={options}
                isMulti
                styles={styles}
                theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                border: "none",
                colors: {
                    ...theme.colors,
                    primary25: 'var(--mid-white)',
                    primary: 'var(--dark-white)',
                },
                })}
            />
        </div>
    );
}