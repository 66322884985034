import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Result } from "./Result";
import { questionPoints } from "../../../data/constants";
import { useSubmitAnswer } from "../../../hooks/useSubmitAnswer";
import TextOption from "./TextOption";
import Sent from "./Sent";
import QwestoSmall from "../../../assets/img/QwestoSmall";

export function BestAnswerController({activeQuestion, gameDoc, groupDoc}){
    const { user } = useAuthContext()
    const {submitIsPending, trySubmitAnswer} = useSubmitAnswer()
    const [input, setInput] = useState("")
    const [answers, setAnswers] = useState()

    function onSubmitInput(e){
        e.preventDefault()
        trySubmitAnswer(groupDoc.qwestoID, user.uid, {
            answer: input,
            vote: "EMPTY" 
        })
    }
    function onSubmitVote(voteUID){
        trySubmitAnswer(groupDoc.qwestoID, user.uid, {
            answer: gameDoc.answers[user.uid].answer,
            vote: voteUID
        })
    }

    useEffect(() => {
        let newAnswers = []
        Object.entries(gameDoc.answers).forEach(([uid, answer]) => {
            if(uid !== user.uid){
                if(answer !== "EMPTY"){
                    newAnswers.push({
                        ...answer,
                        uid: uid
                    })
                }else{
                    newAnswers.push("EMPTY")
                }
            }
        })
        setAnswers(newAnswers)
    }, [gameDoc.answers, user])

    if(gameDoc.stage === "ANSWERING"){
        return(
            <>
                {gameDoc.answers[user.uid] === "EMPTY" ? 
                    <form className="container no-grow" onSubmit={(e) => {onSubmitInput(e)}}>
                        <div className="border-column gap">    
                            <h4>Verzin het leukste antwoord op de vraag</h4>          
                            <textarea type="text"
                                required 
                                disabled={submitIsPending}
                                minLength={1}
                                maxLength={50}
                                onChange={(e) => {
                                        setInput(e.target.value)}
                                    }
                                value={input}
                                className="grow"
                            />
                            <p className="grey-text">{input.length}/50</p>
                            <button className="btn-gr no-grow" disabled={submitIsPending}>{submitIsPending ? <QwestoSmall className="load"/> : "Versturen"}</button>
                        </div>
                    </form>
                :
                    <>
                        {gameDoc.answers[user.uid].vote === "EMPTY" ?
                            <div className="option-list">
                                {answers && !submitIsPending && answers.map((option, index) => {
                                    if(option.answer === "EMPTY" || option === "EMPTY"){
                                        return(
                                            <TextOption text={"Aan het typen..."} key={index} disabled/>
                                        )
                                    }else{
                                        return(
                                            <TextOption text={option.answer} onClick={() => {onSubmitVote(option.uid)}} key={index}/>
                                        )
                                    }
                                })}
                            </div>
                        :
                            <Sent/>
                        }
                    </>
                }
            </>
        )
    }else{
        let votes = gameDoc.pointsEarned[user.uid] / questionPoints.BESTANSWER
        return(
            <>
                {votes === 0 ?
                    <Result type="wrong" message="Je hebt 0 stemmen ontvangen" gameDoc={gameDoc} uid={user.uid}/>
                :
                    <Result type="correct" message={votes > 1 ? `Je hebt ${votes} stemmen ontvangen` : "Je hebt 1 stem ontvangen"} gameDoc={gameDoc} uid={user.uid}/>
                }
            </>
        )
    }
}