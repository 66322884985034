import { useMemo } from "react"
import { Navigate, useParams } from "react-router-dom"
import Redirect from "../../../components/layout/Redirect"
import Loader from "../../../components/loaders/Loader"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useGameLogicContext } from "../../../hooks/useGameLogicContext"
import { useGroupDataContext } from "../../../hooks/useGroupDataContext"
import Lobby from "../controller/Lobby"
import { NoGameConnection } from "../NoGameConnection"
import "./PhoneViewer.css"
import PhoneViewerContainer from "./PhoneViewerContainer"

export default function PhoneViewer(){
    const {qwestoID} = useParams()
    const {user} = useAuthContext()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "GROUP_AND_GAME_DATA",
        qwestoID: qwestoID
    }), [qwestoID]))
    const {activeQuestion, isResumed, gameConnection} = useGameLogicContext("PHONE_VIEWER")

    if(noGroupData){
        return(
            <Redirect to="/groups" message={"Deze Qwesto bestaat niet"} action="Terug" arrow="left"/>
            )
        }
        
    if(!groupData.qwesto || !groupData.game || !groupData.group){
        return(
            <Loader/>
        )
    }

    if(groupData.group.phoneViewer === false){
        return(
            <Navigate to={`/groups/${qwestoID}`}/>
        )
    }

    if(!groupData.group.groupIsConfirmed){
        return(
            <Redirect to={`/groups/${qwestoID}`} message={"Nog niet beschikbaar"} action="Terug" arrow="left"/>
        )
    }

    return(
        <div className="content-page full">
            {(isResumed || groupData.group.host.uid !== user.uid) &&
                <>
                    {!gameConnection && <NoGameConnection/>}
                    {groupData.group.qwestoStarted ? 
                        <PhoneViewerContainer activeQuestion={activeQuestion} groupDoc={groupData.group} qwestoDoc={groupData.qwesto} gameDoc={groupData.game}/>
                    :
                        <Lobby group={groupData.group}/>
                    }
                </>
            }
        </div>
    )
}