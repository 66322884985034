export function getMostFrequentAnswer(answers){
    const count = {}; // create an object to store the counts of each value

    // loop through the intakeAnswersay and count the occurrences of each value
    for(let i = 0; i < answers.length; i++){
        if (count[answers[i]]) {
            count[answers[i]]++; // if the value already exists in the count object, increment its count
        } else {
            count[answers[i]] = 1; // if the value doesn't exist in the count object, add it with a count of 1
        }
    }

    let maxCount = 0;
    let mostFrequent = [];

    // loop through the count object to find the value(s) that appear the most
    for(const [key, value] of Object.entries(count)){
        if (value > maxCount) {
            maxCount = value;
            mostFrequent = [key];
        } else if (value === maxCount) {
            mostFrequent.push(key); // if the value has the same count as the current maximum, add it to the answers
        }
    }

    return mostFrequent
}