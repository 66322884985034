export function getGroupProgress(groupDoc, intakeData){
    if(!groupDoc.intakeQuestions){
        return null
    }
    const commonQuestionsLength = Object.keys(groupDoc.intakeQuestions.commonQuestions).length
    let totalDummyQuestionsLength = 0
    let totalIndexes = 0
    if(intakeData.indexes){
        groupDoc.players.forEach((player) => {
            totalDummyQuestionsLength += Object.keys(groupDoc.intakeQuestions[player.uid]).length
            if(intakeData.indexes[player.uid]){
                totalIndexes += intakeData.indexes[player.uid][0] === "FINISHED" ? 0 : intakeData.indexes[player.uid].length
            }
        })
    }
    const totalLength = (commonQuestionsLength * groupDoc.players.length) + totalDummyQuestionsLength
    const progress = Math.round(((totalLength - totalIndexes) / totalLength) * 100)

    return {commonQuestionsLength, progress}
}