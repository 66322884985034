import React, { useEffect, useState } from "react";
import { getPlayersByVotes } from "../../../utils/pure/getPlayersByVotes";
import { QuestionBar } from "./QuestionBar";
import { CorrectPlayer } from "./CorrectPlayer";
import { getPlayerByUID } from "../../../utils/pure/groups/getPlayerByUID";

export function BestAnswerViewer({activeQuestion, gameDoc, groupDoc}){
    const [currentAnswer, setCurrentAnswer] = useState(null)
    const [animate, setAnimate] = useState(false)
    const [carouselIsActive, setCarouselIsActive] = useState(false)

    useEffect(() => {
        if(gameDoc.stage === "RESULTS" && !carouselIsActive){
            async function onStartCarousel(array){
                setCarouselIsActive(true)
                for(let i = array.length - 1; i >= 0; i--){
                    setAnimate(false)
                    setCurrentAnswer({...array[i], ...getPlayerByUID(array[i].uid, groupDoc)})
                    await new Promise(resolve => setTimeout(resolve, 4000))
                    if(i !== 0){
                        setAnimate(true)
                        await new Promise(resolve => setTimeout(resolve, 500))
                    }
                }
            }
            const result = getPlayersByVotes(gameDoc.answers, true)
            onStartCarousel(result)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameDoc, carouselIsActive, activeQuestion.answers])

    return(
        <>
            <QuestionBar question={activeQuestion.question} slideUp={gameDoc.stage === "RESULTS"}/>
            {gameDoc.stage === "RESULTS" &&
                <div className="correct-answer wrap">
                    {currentAnswer &&
                        <div key={currentAnswer.uid} className={animate ? "carousel-item swipe-out" : "carousel-item swipe-in"}>
                            <div className="text-bubble">
                                <span className="viewer-text">{gameDoc.answers[currentAnswer.uid].answer ? gameDoc.answers[currentAnswer.uid].answer : "..."}</span>
                                <span className={`viewer-text s bg-text ${currentAnswer.votes === 0 ? " red" : " green"}`}>{currentAnswer.votes}x</span>
                            </div>
                            <CorrectPlayer nickName={currentAnswer.nickName} photoURL={currentAnswer.photoURL} fixedAmount/>
                        </div>
                    }
                </div>
            }
        </>
    )
}