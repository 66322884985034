import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import PopUp from "../../../components/layout/PopUp";
import CardList from "./CardList";
import ImageInput from "../../../components/input/ImageInput";
import ArrowRight from "../../../assets/img/ArrowRight";
import { useInitGroup } from "../../../hooks/useInitGroup";
import { DEFAULT_GROUP_URL } from "../../../data/constants";
import SelectionBar from "../../../components/input/SelectionBar";
import GroupSettings from "./GroupSettings";

export default function GroupNavigation({groupProgress, groupDoc, intakeData}){
    const { user } = useAuthContext()
    const { qwestoID } = useParams()
    const {changeIsPending, tryInitGroup} = useInitGroup()
    const [welcomeOpen, setWelcomeOpen] = useState(false)
    const [groupPhoto, setGroupPhoto] = useState(null)
    const [groupPhotoURL, setGroupPhotoURL] = useState(null)
    const [phoneViewer, setPhoneViewer] = useState(null)

    useEffect(() => {
        if(groupDoc && !groupDoc.groupIsInitialized && groupDoc.host.uid === user.uid){
            setWelcomeOpen(true)
        }
    }, [groupDoc, user])
    

    return(
    <>
        <PopUp visible={welcomeOpen} setVisible={setWelcomeOpen} unclosable>
            <div className="border-column center">
                <h2>Welkom bij je Qwesto, {user.displayName}!</h2>
            </div>
            <div className="border-column no-border">
                <h3>Groepsfoto</h3>
                <p className="grey-text">Upload een groepsfoto die perfect is voor {groupDoc.groupName}</p>
                <ImageInput aspect={16/9} title="Groepsfoto" setPhoto={setGroupPhoto} photoURL={groupPhotoURL} setPhotoURL={setGroupPhotoURL} />
            </div>
            <div className="border-column no-border">
                <h3>Heeft je groep een groot scherm ter beschikking?</h3>
                <p className="grey-text">Kies "Ja" als je groep naast alle telefoons nog beschikt over een extra scherm om de Qwesto op te spelen: <Link to="/blog/gebruik-schermen" target="_blank" rel="noopener noreferrer">meer uitleg</Link></p>
                <SelectionBar options={["Ja", "Nee"]} customValues={[false, true]} value={phoneViewer} setValue={setPhoneViewer}/>
            </div>
            <div className="border-column">
                {!changeIsPending ? <button className="btn-gr" onClick={() => {tryInitGroup(qwestoID, groupPhoto, setWelcomeOpen, phoneViewer)}}>Verder <ArrowRight className="icon"/></button> : 
                <button className="btn-gr" disabled><QwestoSmall className="load"/></button>} 
            </div>
        </PopUp>

        <div className="group-banner-container">
            {groupDoc.host.uid === user.uid &&
                <GroupSettings groupDoc={groupDoc}/>
            }
            <img className="group-banner-img" src={groupDoc.groupPhotoURL ? groupDoc.groupPhotoURL : DEFAULT_GROUP_URL} alt=""/>
            <div className="content-container center">
                <h1 className="group-title">{groupDoc.groupName}</h1>
                <h4>{groupDoc.host.uid === user.uid ? "Georganiseerd door jou" : `Georganiseerd door ${groupDoc.host.nickName}`}</h4> 
                <CardList groupProgress={groupProgress} groupDoc={groupDoc} intakeData={intakeData}/>
            </div>
        </div>
    </>
    )
}
