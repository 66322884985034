import { increment } from "firebase/database"
import { questionPoints } from "../../data/constants"
import { getMaxPlayerPosition } from "./getMaxPlayerPosition"

export function getPlayerResults(activeQuestion, groupDoc, gameDoc){
    const gameDocObject = {
        points: {},
        pointsEarned: {},
    }

    function setPositionedResults(type, player){
        if(((Array.isArray(gameDoc.correctAnswer) && gameDoc.correctAnswer.includes(gameDoc.answers[player.uid].answer)) || gameDoc.correctAnswer === gameDoc.answers[player.uid].answer)
        && gameDoc.answers[player.uid].position !== "EMPTY"
        && gameDoc.answers[player.uid].position < (getMaxPlayerPosition(groupDoc.players) + 1)){
            gameDocObject.points[player.uid] = increment(questionPoints[type][gameDoc.answers[player.uid].position - 1])
            gameDocObject.pointsEarned[player.uid] = questionPoints[type][gameDoc.answers[player.uid].position - 1]
        }else{
            gameDocObject.points[player.uid] = increment(0)
            gameDocObject.pointsEarned[player.uid] = 0
        }
    }

    function setEqualResults(type, player){
        if(gameDoc.answers[player.uid] === gameDoc.correctAnswer){
            gameDocObject.points[player.uid] = increment(questionPoints[type])
            gameDocObject.pointsEarned[player.uid] = questionPoints[type]
        }else{
            gameDocObject.points[player.uid] = increment(0)
            gameDocObject.pointsEarned[player.uid] = 0
        }
    }

    function setVotedResults(type, player, branched){
        let votes = 0
        Object.keys(gameDoc.answers).forEach((uid) => {
            if(branched){
                if(gameDoc.answers[uid].vote === player.uid){
                    votes++
                }
            }else if(gameDoc.answers[uid] === player.uid){
                votes++
            }
            
        })
        gameDocObject.points[player.uid] = increment(votes * questionPoints[type])
        gameDocObject.pointsEarned[player.uid] = votes * questionPoints[type]
    }

    function setIncludeResults(type, player){
        if(gameDoc.correctAnswer.includes(gameDoc.answers[player.uid])){
            gameDocObject.points[player.uid] = increment(questionPoints[type])
            gameDocObject.pointsEarned[player.uid] = questionPoints[type]
        }else{
            gameDocObject.points[player.uid] = increment(0)
            gameDocObject.pointsEarned[player.uid] = 0
        }
    }

    function setBattleResults(activeQuestion, player){
        const randomUID = activeQuestion.random
        const chosenUID = activeQuestion.chosen
        if(player.uid === randomUID || player.uid === chosenUID){
            let randomVotes = 0
            let chosenVotes = 0
            const type = activeQuestion.type
            Object.entries(gameDoc.answers).forEach(([uid, answer]) => {
                if(answer.includes(chosenUID)){
                    chosenVotes++
                }
                if(answer.includes(randomUID)){
                    randomVotes++
                }
            })
            if(player.uid === randomUID && (randomVotes === chosenVotes || randomVotes > chosenVotes)){
                gameDocObject.points[randomUID] = increment(questionPoints[type])
                gameDocObject.pointsEarned[randomUID] = questionPoints[type]
            }else if(player.uid === chosenUID && (randomVotes === chosenVotes || chosenVotes > randomVotes) ){
                gameDocObject.points[chosenUID] = increment(questionPoints[type])
                gameDocObject.pointsEarned[chosenUID] = questionPoints[type]                
            }else{
                gameDocObject.points[player.uid] = increment(0)
                gameDocObject.pointsEarned[player.uid] = 0
            }
        }else{
            gameDocObject.points[player.uid] = increment(0)
            gameDocObject.pointsEarned[player.uid] = 0
        }
    }

    function setSkippedResults(activeQuestion, player){
        if(activeQuestion.dummy === player.uid){
            gameDocObject.points[player.uid] = increment(questionPoints["SKIPPED"])
            gameDocObject.pointsEarned[player.uid] = questionPoints["SKIPPED"]
        }else{
            gameDocObject.points[player.uid] = increment(0)
            gameDocObject.pointsEarned[player.uid] = 0
        }
    }

    // Update points
    groupDoc.players.forEach((player) => {
        switch(activeQuestion.type){
            case "MOSTLIKELY":
                setIncludeResults(activeQuestion.type, player)
                break;
            case "BESTPHOTO": 
                setVotedResults(activeQuestion.type, player)
                break;
            case "BESTANSWER": 
                setVotedResults(activeQuestion.type, player, true)
                break;
            case "WHATISTRUE":
            case "GUESSWHO":
                setEqualResults(activeQuestion.type, player)
                break;
            case "PHOTOREVEAL":
            case "WHATDOYOUSEE":
            case "WHATDOYOUHEAR":
                setPositionedResults(activeQuestion.type, player)
                break;
            case "BATTLE":
                setBattleResults(activeQuestion, player)
                break;
            case "SKIPPED":
                setSkippedResults(activeQuestion, player)
                break;
            default:
                console.log("No type recognized :(");
                gameDocObject.points[player.uid] = increment(0)
                gameDocObject.pointsEarned[player.uid] = 0
        }
    })
    return gameDocObject
}