import { Link } from "react-router-dom"
import Footer from "../../components/layout/Footer"
import SEO from "../../components/utils/SEO"

export default function NotFound(){
    // useEffect(() => {
    //     if(window.location.pathname !== "/404"){
    //         window.location.href = "/404";
    //     }
    // }, [])

    return(
    <>
        <SEO title={"404"} description={"Pagina niet gevonden."} noindex/>
        <div className="content-page">
            <div className="content-container min-height center">
                <div className="column no-grow">
                    <h1 className="text-xlarge">404</h1>
                    <span className="text-small">Oeps, we kunnen deze pagina niet vinden...</span>
                    <Link to="/" className="btn black round no-grow no-text-decoration">Naar huis</Link>
                    <Link to="/contact" className="btn text round no-grow">Contact</Link>
                </div>
            </div>
            <Footer/>
        </div>
    </>
    )
}