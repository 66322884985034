import { GOOGLE_REVIEWS } from "../../data/reviews"
import Star from "../../assets/img/Star"
import React from "react"

export default function Reviews() {

    return(
        <div className="review-container">
            <div className="review-list">
                {[...GOOGLE_REVIEWS, ...GOOGLE_REVIEWS].map((review, index) => (
                    <a href={review.reviewLink} target="_blank" rel="noreferrer" className="review-item" key={index}>
                        <img className="review-avatar" src={review.userPhotoURL} alt=""/>
                        <div className="stars">
                            {Array(5).fill().map((_, starIndex) => (
                                <React.Fragment key={starIndex}>
                                    <Star className={`review-star ${starIndex < review.reviewStars ? "filled" : ""}`}/>
                                </React.Fragment>
                            ))}
                        </div>
                        <span className="text-xxsmall grey-text">via Google</span>
                        <div className="column no-gap">
                            <span className="text-medium">{review.userName}</span>
                            <div className="review-text">
                                <span className="text-xsmall grey-text">"{review.reviewText}"</span>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}