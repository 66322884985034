import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import QwestoSmall from '../../../assets/img/QwestoSmall';
import ArrowRight from '../../../assets/img/ArrowRight';
import { whatIsTrueDefault } from "../../../data/constants";
import { useNextIntakeQuestion } from "../../../hooks/useNextIntakeQuestion";
import { useEffect } from "react";

export default function Submitter({submitIsPending, setSubmitIsPending, activeQuestion, intakeLength, intakeIndex, whatIsTrue, textAnswer, setTextAnswer, setWhatIsTrue, photo, setPhoto, setPhotoURL, video, setVideo, setVideoURL, selectedOption, setSelectedOption, metadata, setMetadata}) {
    const {qwestoID} = useParams() 
    const {tryNextQuestion, isPending} = useNextIntakeQuestion()
    const {user} = useAuthContext()

    function onResetValues(){
        setPhoto(null)
        setPhotoURL(null)
        setVideo(null)
        setVideoURL(null)
        setSelectedOption(null)
        setTextAnswer("")
        setWhatIsTrue(whatIsTrueDefault)
        setMetadata(null)
    }

    useEffect(() => {
        if(isPending === true){
            setSubmitIsPending(true)
        }else{
            setSubmitIsPending(false)
        }
    }, [isPending, setSubmitIsPending])
    

    async function onNextQuestion(e){
        e.preventDefault()
        //check if intake is finished
        if(intakeIndex < intakeLength){
            await tryNextQuestion(user, qwestoID, activeQuestion, onResetValues, whatIsTrue, textAnswer, photo, video, selectedOption, metadata)
        }
    }

    return(                 
        <div className="intake-control-next">
            {!submitIsPending ?
                <button className="btn-gr" onClick={onNextQuestion}>Volgende<ArrowRight className="icon"/></button>:
                <button className="btn-gr" disabled><QwestoSmall className="load"/></button>}
        </div>
    )
}