import { Link } from "react-router-dom"
import Avatar from "../../components/layout/Avatar"
import QwestoBig from "../../assets/img/QwestoBig";
import "./MyQwesto.css"
import { useAuthContext } from "../../hooks/useAuthContext";
import React, { useEffect } from "react";
import { DEFAULT_GROUP_URL } from "../../data/constants";
import { useState } from "react";
import Exit from "../../assets/img/Exit";
import { Helmet } from "react-helmet";

export default function GroupListNew({groupDocs, noData}){
    const {user} = useAuthContext()
    const [newGroups, setNewGroups] = useState([])

    useEffect(() => {
        if(groupDocs){
            let newList = []
            groupDocs.forEach((group) => {
                if(!group.groupIsInitialized){
                    newList.push(group)
                }
            })
            setNewGroups(newList)
        }
    }, [groupDocs])

    if(!noData && newGroups.length > 0 && user){
        return(
                <>
                    <Helmet>
                        
                    </Helmet>
                    <div className="border-column large-gap">
                        {newGroups.map(group => (
                            <React.Fragment key={group.id}>
                                <Link to={`/groups/${group.id}`} className="group-item new">
                                    <div className="group-new">
                                        <span className="text-small">Nieuw</span>
                                    </div>
                                    <QwestoBig className="group-badge"/>
                                    <img className="group-item-img" src={group.groupPhotoURL ? group.groupPhotoURL : DEFAULT_GROUP_URL} alt="Product"/>
                                    <div className="group-item-label">
                                        <div className="column">
                                            <h2>{group.groupName}</h2>
                                            <p className="grey-text">{group.host.uid === user.uid ? "Georganiseerd door jou" : `Georganiseerd door ${group.host.nickName}`}</p>
                                        </div>
                                        <div className="row stay flex-start">
                                            <div className="avatar-list">
                                                {group.players.slice(0,5).map(player => (
                                                    <Avatar src={player.photoURL} key={player.uid}/>
                                                ))}
                                            </div>
                                            <p className="grey-text center">{group.players.length} speler{group.players.length > 1 ? "s" : ""}</p>
                                        </div>
                                    </div>
                                </Link>
                            </React.Fragment>
                        ))}
                        <span className="text-xxsmall grey-text">Klik op een groep om te beginnen</span>
                    </div>
                </>
            )
        }else{
            return(
                <div className="column center no-grow">
                    <Exit className="icon big grey-text"/>
                    <span className="text-small">Geen nieuwe Qwesto gevonden</span>
                    <span className="text-xsmall grey-text">Dit kan even duren, als je denkt dat er iets fout is gegaan <Link to="/contact">neem contact met ons op.</Link></span>
                </div>
            )
        }
}