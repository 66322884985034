import { useEffect } from "react";
import { ref, set} from 'firebase/database';
import { rdb } from "../../../config/firebaseConfig";
import { QuestionBar } from "./QuestionBar";
import { CorrectText } from "./CorrectText";

export function WhatIsTrueViewer({activeQuestion, gameDoc, groupDoc, qwestoDoc}){
    const correctAnswer = qwestoDoc.qwestoQuestions[activeQuestion.id].firstTrue
    
    useEffect(() => {
        set(ref(rdb, "games/" + groupDoc.qwestoID + "/correctAnswer"), correctAnswer)
    }, [correctAnswer, groupDoc.qwestoID])

    return(
        <>
            <QuestionBar question={activeQuestion.question} slideUp={gameDoc.stage === "RESULTS"}/>
            {gameDoc.stage === "RESULTS" &&
                <div className="correct-answer center">
                    <CorrectText text={correctAnswer}/>
                </div>
            }
        </>
    )
}