import { useEffect, useState } from "react";
import Info from "../../../assets/img/Info";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import PopUp from "../../../components/layout/PopUp";
import { intakeInstructions } from "../../../data/content";
import BestPhotoIcon from "../../../assets/img/question_types/BestPhotoIcon";
import WhatDoYouSeeIcon from "../../../assets/img/question_types/WhatDoYouSeeIcon";
import PhotoRevealIcon from "../../../assets/img/question_types/PhotoRevealIcon";
import GuessWhoIcon from "../../../assets/img/question_types/GuessWhoIcon";
import WhatDoYouHearIcon from "../../../assets/img/question_types/WhatDoYouHearIcon";
import WhatIsTrueIcon from "../../../assets/img/question_types/WhatIsTrueIcon";

export default function Instructions({activeQuestion, intakeIndex}){
    const [activeInstructions, setActiveInstructions] = useState(null)
    const [instructionVisible, setInstructionVisible] = useState(false)
    const [typeIsViewed, setTypeIsViewed] = useState({
        "INTRODUCTION": false,
        "BESTPHOTO": false,
        "BESTANSWER": false,
        "WHATISTRUE": false,
        "GUESSWHO": false,
        "PHOTOREVEAL": false
    })

    function handleInstructions(){
        setActiveInstructions(intakeInstructions[activeQuestion.type])
        setInstructionVisible(!instructionVisible)
        setTypeIsViewed((state) => ({
            ...state,
            [activeQuestion.type]: true
        }))
    }

    function handleIntroduction(){
        setActiveInstructions(intakeInstructions[activeQuestion.type])
        setTypeIsViewed((state) => ({
            ...state,
            "INTRODUCTION": true,
            [activeQuestion.type]: true,
        }))
    }

    useEffect(() => {
        if(intakeIndex === 0){
            setActiveInstructions(intakeInstructions.intake)
            setInstructionVisible(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(typeIsViewed[activeQuestion.type] === false && typeIsViewed["INTRODUCTION"] === true){
            handleInstructions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuestion, intakeIndex])

    return(
        <>
        <button className="btn info" onClick={() => {handleInstructions()}}><Info className="icon"/></button>
        {activeInstructions &&
            <PopUp visible={instructionVisible} setVisible={setInstructionVisible} unclosable
                banner={
                    <div className="column center">
                        {(() => {
                            if(activeInstructions.type === "INTRODUCTION"){
                                return <QwestoSmall className="icon big"/>
                            }
                            switch(activeQuestion.type){
                                case "BESTPHOTO":
                                    return <BestPhotoIcon className="icon massive"/>
                                case "WHATDOYOUSEE":
                                    return <WhatDoYouSeeIcon className="icon massive"/>
                                case "WHATDOYOUHEAR":
                                    return <WhatDoYouHearIcon className="icon massive"/>
                                case "WHATISTRUE":
                                    return <WhatIsTrueIcon className="icon massive"/>
                                case "PHOTOREVEAL":
                                    return <PhotoRevealIcon className="icon massive"/>
                                case "GUESSWHO":
                                    return <GuessWhoIcon className="icon massive"/>
                                default:
                            }
                        })()}
                        <span className="text-large">{activeInstructions.title}</span>
                    </div>
                }>
                <div className="column center gap">
                    {activeInstructions.paragraphs && activeInstructions.paragraphs.map((paragraph) => {
                        return(
                            <div className="column no-gap" key={paragraph.label}>
                                <h3>{paragraph.label}</h3>
                                <p className="grey-text">{paragraph.content}</p>
                            </div>
                        )
                    })}
                    <p className="grey-text">{activeInstructions.mention}</p>
                    <button className="btn black" onClick={() => {
                        if(typeIsViewed.INTRODUCTION){
                            handleInstructions()
                        }else{
                            handleIntroduction()
                        }
                    }}>Begrepen</button>
                </div>
            </PopUp>
        }
        </>
    )
}