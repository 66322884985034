import React from "react";
import Profile from "./Profile"
import Actions from "./Actions"
import { useUserContext } from '../../hooks/useUserContext';
import { useGroupDataContext } from "../../hooks/useGroupDataContext";
import "./dashboard.css"
import Loader from "../../components/loaders/Loader";
import Redirect from "../../components/layout/Redirect";
import SEO from "../../components/utils/SEO";
import { useMemo } from "react";

export default function Dashboard(){
    const {userDoc} = useUserContext()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "ALL_ACTIVE_GROUPS",
        qwestoID: null
    }), []))
    
    if(noGroupData){
        return(
            <Redirect message="Er is iets misgegaan"/>
        )
    }

    if(!userDoc || groupData.multiple == null){
        return(
            <Loader/>
    )}


    return(
    <>
        <SEO title={"Profiel"} description={"Personaliseer je profiel voor het spelen van een Qwesto."}/>
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container flex-start max-width">
                    <div className="dashboard-list">
                        <h1>Profiel</h1>
                        <Profile userDoc={userDoc} groupDocs={groupData.multiple}/>
                        <Actions groupDocs={groupData.multiple}/>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

