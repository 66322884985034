import "./ImageSlider.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowLeft from "../../assets/img/ArrowLeft";
import ArrowRight from "../../assets/img/ArrowRight";

function NextArrow(props){
    const { onClick } = props;
    return (
        <button className="image-slider-arrow right" onClick={onClick}>
            <ArrowRight className="icon"/>
        </button>
    );
}

function PrevArrow(props){
    const { onClick } = props;
    return(
        <button className="image-slider-arrow left" onClick={onClick}>
            <ArrowLeft className="icon"/>
        </button>
    );
}

export default function ImageSlider({images}){
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>
    };

    return(
        <div className="image-slider-container">

        <Slider {...settings}>
            {images && images.map((image, index) => (
                <div className="image-slider-slide" key={index}>
                    <img src={image} className="image-slider-img" alt=""/>
                </div>
            ))}
        </Slider>
        </div>
    );
}