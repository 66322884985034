import { useEffect } from 'react';
import { useMessageContext } from '../hooks/useMessageContext';

export default function IsOnline() {
    const { setNewError, setNewInfo} = useMessageContext()

    useEffect(() => {
        function handleOnline() {
        setNewInfo("Je bent weer verbonden met het internet.")
        }

        function handleOffline() {
        setNewError("Je bent niet verbonden met het internet.")
        }

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
        };
    });
}