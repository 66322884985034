import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useUserContext } from "./hooks/useUserContext";
import { PrivateContext } from "./context/PrivateContext";
import { LoggedOutContext } from "./context/LoggedOutContext";
import { MessageContextProvider } from "./context/MessageContext";
import { GroupDataContextProvider } from "./context/GroupDataContext";
import { GameLogicLayout } from "./context/GameLogicLayout";
import { NavigationBarLayout } from "./context/NavigationBarLayout.js";

import CheckOnline from "./config/CheckOnline"
import ScrollToTop from "./utils/components/ScrollToTop.js"
import Dashboard from "./pages/dashboard/Dashboard";
import GroupDetails from "./pages/groups/details/GroupDetails";
import Intake from "./pages/groups/intake/Intake";
import MyQwesto from "./pages/groups/MyQwesto";
import Invite from "./pages/groups/invite/Invite";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Home from "./pages/home/Home";
import Product from "./pages/products/Product";
import Loader from "./components/loaders/Loader";
import Viewer from "./pages/groups/viewer/Viewer";
import PhoneViewer from "./pages/groups/phone_viewer/PhoneViewer";
import Controller from "./pages/groups/controller/Controller";
import Legals from "./pages/legal/Legals.js";
import Manager from "./pages/manager/Manager";
import NotFound from "./pages/404/NotFound";
import HowItWorks from "./pages/info/HowItWorks.js";
import Articles from "./pages/blog/Articles";
import Article from "./pages/blog/Article.js";
import ArticleManager from "./pages/manager/content/ArticleManager.js";
import QuestionManager from "./pages/manager/question/QuestionManager.js";
import StructureManager from "./pages/manager/structures/StructureManager.js";
import FAQ from "./pages/info/FAQ";
import Contact from "./pages/info/Contact.js";
import PaymentSuccess from "./pages/groups/PaymentSuccess.js";
import { GameAudioLayout } from "./context/GameAudioLayout.js";
import AboutUs from "./pages/info/AboutUs.js";
import Cookies from "./components/input/Cookies.js";

function App() {
	const { authIsReady } = useAuthContext()
	const { loadingUserDoc } = useUserContext()

	if(!authIsReady || loadingUserDoc){
		return (
		<div className="index">
			<Loader/>
		</div>
		)
	}
	if(authIsReady){
		return(
			<div className="index" id="index">
				<MessageContextProvider>
					<GroupDataContextProvider>
						<BrowserRouter>
							<CheckOnline/>
							<ScrollToTop/>
							<Cookies/>
							<Routes>
								<Route element={<PrivateContext/>}>
									<Route path="/groups/:qwestoID/intake" element={<Intake/>}/>
										<Route element={<GameAudioLayout/>}>
											<Route element={<GameLogicLayout/>}>
												<Route path="/groups/:qwestoID/viewer" element={<Viewer/>}/>
												<Route path="/groups/:qwestoID/phone-viewer" element={<PhoneViewer/>}/>
												<Route path="/groups/:qwestoID/controller" element={<Controller/>}/>
											</Route>
										</Route>
									<Route path="/manager/question" element={<QuestionManager/>}/>
									<Route path="/manager/article" element={<ArticleManager/>}/>
									<Route path="/manager/structures" element={<StructureManager/>}/>
								</Route>

								<Route element={<NavigationBarLayout/>}>
									<Route element={<PrivateContext/>}>
										<Route path="/manager" element={<Manager/>}/>
										<Route path="/groups/:qwestoID" element={<GroupDetails/>}/>
										<Route path="/dashboard" element={<Dashboard/>}/>              
										<Route path="/payment-success" element={<PaymentSuccess/>}/>              
									</Route>
									
									<Route path="/groups" element={<MyQwesto/>}/>
									<Route path="/product" element={<Product/>}/>
									<Route path="/faq" element={<FAQ/>}/>
									<Route path="/about-us" element={<AboutUs/>}/>
									<Route path="/contact" element={<Contact/>}/>
									<Route path="/how-it-works" element={<HowItWorks/>}/>
									<Route path="/blog" element={<Articles/>}/>
									<Route path="/disclaimer" element={<Legals pathID="disclaimer"/>}/>
									<Route path="/privacy" element={<Legals pathID="privacy"/>}/>
									<Route path="/cookies" element={<Legals pathID="cookies"/>}/>
									<Route path="/terms" element={<Legals pathID="terms"/>}/>
									<Route path="/blog/:pathID" element={<Article/>}/>
									<Route path="/invite" element={<Invite/>}/>
									<Route path="/" element={<Home/>}/>
									<Route path="/home/:target" element={<Home/>}/>
									<Route path="/home" element={<Home/>}/>
									<Route path="*" element={<NotFound/>}/>

									<Route element={<LoggedOutContext/>}>
										<Route path="/login" element={<SignIn/>}/>
										<Route path="/sign-up" element={<SignUp/>}/>
										<Route path="/reset-password" element={<ResetPassword/>} />
									</Route>
								</Route>
							</Routes> 
						</BrowserRouter>
					</GroupDataContextProvider>
				</MessageContextProvider>
			</div>
		);
	}
}

export default App;
