import { useAuthContext } from "../../../hooks/useAuthContext";
import "./Controller.css"
import Redirect from "../../../components/layout/Redirect";
import { useStartQwesto } from "../../../hooks/useStartQwesto";
import QwestoSmall from "../../../assets/img/QwestoSmall";

export default function Lobby({group}) {
    const { user } = useAuthContext()
    const {startIsPending, tryStartQwesto} = useStartQwesto()

    function handleStartQwesto(){
        if(!startIsPending){
            tryStartQwesto(group)
        }
    }

    return(
        <div className="content-field">
            <div className="contentlabel no-margin center no-grow">
                <h1>{group.groupName}</h1>
                <h4>Controller</h4>
            </div>

            <div className="contentlabel small center">
                {group.groupIsConfirmed ? 
                    <div className="column gap">
                        <h3>{user.uid === group.host.uid ? "Wachten tot jij de Qwesto start" : `Wacht totdat ${group.host.nickName} de Qwesto heeft gestart`}</h3>
                        {user.uid === group.host.uid && 
                            <button className="btn-gr" onClick={handleStartQwesto} disabled={startIsPending}>{!startIsPending ? "Start" : <QwestoSmall className="load"/>}</button>
                        }
                    </div>
                :
                    <Redirect to="/groups" message={"De Qwesto is nog niet bevestigd"} action="Terug" arrow="left" small/>
                }
            </div>
        </div>
    )
}
