import { useEffect, useState } from "react"
import { useCollection } from "../../../hooks/useCollection"
import "../Manager.css"
import Loader from "../../../components/loaders/Loader"
import SelectionBar from "../../../components/input/SelectionBar"
import { ArticleCreator } from "./ArticleCreator"

export default function ArticleManager(){
    const { documents: contentCol } = useCollection("content")
    const [lists, setLists] = useState(null)
    const [listIndex, setListIndex] = useState(1)
    const [currentList, setCurrentList] = useState(null)
    const [supported, setSupported] = useState(false)

    useEffect(() => {
        if(contentCol){
            const newLists = []
            contentCol.forEach((contentDoc) => {
                let contentID = 0
                contentDoc.list?.forEach((content) => {
                    content.id = contentID
                    contentID++
                })
                newLists.push(contentDoc)
            })
            setLists(newLists)
        }
    }, [contentCol])

    useEffect(() => {
        const canvas = document.createElement('canvas');
        const supportWebP = canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        console.log('Support WebP:', supportWebP);
        setSupported(supportWebP)
    }, [])

    useEffect(() => {
        if(lists){
            setCurrentList(lists[listIndex - 1])
        }
    }, [lists, listIndex])

    if(!lists || !currentList){
        return(
            <Loader/>
        )
    }

    if(!supported){
        return(
            "open ff in chrome, safari werkt niet met webp"
        )
    }

    return(
        <div className="content-page full">
            <div className="content-field">
                <div className="content-container no-margin flex-start">
                    <div className="manager-controls">
                        <SelectionBar options={lists.map((list) => (list.id))} value={listIndex} setValue={setListIndex} unselectable/>

                        <div className="row stay">
                            <h3>{currentList.list ? currentList.list.length : "Geen"} gevonden</h3>
                            <ArticleCreator currentList={currentList}/>
                        </div>
                    </div>
                </div>
                <div className="manager-list">
                    {currentList.list?.map((article) => (
                        <div className="manager-item" key={article.id}>
                            <span>{article.id}</span>
                            <span>{article.title}</span>
                            <span>{article.pathID}</span>
                            {article.photoURL ? <img src={article.photoURL} alt={article.title} className="icon big"/> : <span>Geen foto</span>}
                            <ArticleCreator currentList={currentList} currentArticle={article} edit/>
                        </div>)
                    )}
                </div>
            </div>
        </div>
    )

};