import Footer from "../../components/layout/Footer";
import SEO from "../../components/utils/SEO";
import ArticleList from "./ArticleList";
import "./Articles.css"

export default function Articles(){
    return(
        <>
            <SEO title="Artikelen" description="Lees onze laatste posts hier."/>
            <div className="content-page">
                <div className="content-field white">
                    <div className="content-container flex-start max-width">
                        <h1>Blog</h1>
                        <p className="grey-text">Bekijk hier onze laatste posts</p>
                        <ArticleList />
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
};