import BackButton from "../../../components/input/BackButton"
import { getPlayerPosition } from "../../../utils/pure/getPlayerPosition"

export default function ScoreStage({user, gameDoc, qwestoID}){
    const endPosition = getPlayerPosition(user.uid, gameDoc)

    if(endPosition){
        return(
            <>
                <BackButton to={`/groups/${qwestoID}`}/>
                <h1>#{endPosition}</h1>
                <h3>Je bent {endPosition}e geworden</h3>
            </>
        )
    }
};
