import Close from "../../assets/img/Close";
import Spicy from "../../assets/img/Spicy";
import Group from "../../assets/img/Group";
import Time from "../../assets/img/Time";
import { filterInfo, filterOptions } from "../../data/constants";
import Hide from "../../assets/img/Hide";

export default function FilterInfo({filters}){
    const filterInfoList = [
        {
            icon: <Close className="filter-info-icon"/>,
            title: "Groepsband",
            value: filterOptions.bond[filters.bond - 1],
            description: filterInfo.bond[filters.bond - 1]
        },
        {
            icon: <Group className="filter-info-icon"/>,
            title: "Groepsomvang",
            value: filters.players + "p",
            description: <>
                {filters.players > 3 ? `Geschikt om te spelen met 3-${filters.players} personen` : `Geschikt om te spelen met ${filters.players} personen`}
            </>
        },
        {
            icon: <Hide className="filter-info-icon"/>,
            title: "Leeftijd",
            value: filters.age && filters.age.includes(1) ? "18-" : "18+",
            description: <>
                Geschikt voor personen {filters.age && filters.age.includes(1) ? " onder de 18" : " ouder dan 18"}
            </>
        },
        {
            icon: <Spicy className="filter-info-icon"/>,
            title: "Spicyness",
            value: filterOptions.spicyness[filters.spicyness - 1],
            description: filterInfo.spicyness[filters.spicyness - 1]
        },
        {
            icon: <Time className="filter-info-icon"/>,
            title: "Speelduur",
            value: filterOptions.duration[filters.duration - 1],
            description: `Met deze Qwesto ben je ongeveer ${filterOptions.duration[filters.duration - 1]} zoet`
        },
    ]

    return(
        <div className="filter-info-list">
            {filterInfoList.map((filter, index) => (
                <div className="filter-info-row" key={index}>
                    <div className="filter-info-column">
                        {filter.icon}
                        <span className="text-xxsmall">{filter.value}</span>
                    </div>
                    <div className="column no-gap ">
                        <div className="row stay no-grow">
                            <span className="text-small">{filter.title}</span>
                        </div>
                        <p className="grey-text">
                            {filter.description}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}