import charlotteSwedish_1 from "../../assets/sound/countdown/charlotteSwedish_1.mov"
import charlotteSwedish_2 from "../../assets/sound/countdown/charlotteSwedish_2.mov"
import charlotteSwedish_3 from "../../assets/sound/countdown/charlotteSwedish_3.mov"
import clydeGreek_1 from "../../assets/sound/countdown/clydeGreek_1.mov"
import clydeGreek_2 from "../../assets/sound/countdown/clydeGreek_2.mov"
import clydeGreek_3 from "../../assets/sound/countdown/clydeGreek_3.mov"
import clydePolish_1 from "../../assets/sound/countdown/clydePolish_1.mov"
import clydePolish_2 from "../../assets/sound/countdown/clydePolish_2.mov"
import clydePolish_3 from "../../assets/sound/countdown/clydePolish_3.mov"
import danielGerman_1 from "../../assets/sound/countdown/danielGerman_1.mov"
import danielGerman_2 from "../../assets/sound/countdown/danielGerman_2.mov"
import danielGerman_3 from "../../assets/sound/countdown/danielGerman_3.mov"
import elliDutch_1 from "../../assets/sound/countdown/elliDutch_1.mov"
import elliDutch_2 from "../../assets/sound/countdown/elliDutch_2.mov"
import elliDutch_3 from "../../assets/sound/countdown/elliDutch_3.mov"
import finDutch_1 from "../../assets/sound/countdown/finDutch_1.mov"
import finDutch_2 from "../../assets/sound/countdown/finDutch_2.mov"
import finDutch_3 from "../../assets/sound/countdown/finDutch_3.mov"
import giovanniItalian_1 from "../../assets/sound/countdown/giovanniItalian_1.mov"
import giovanniItalian_2 from "../../assets/sound/countdown/giovanniItalian_2.mov"
import giovanniItalian_3 from "../../assets/sound/countdown/giovanniItalian_3.mov"
import josephSpanish_1 from "../../assets/sound/countdown/josephSpanish_1.mov"
import josephSpanish_2 from "../../assets/sound/countdown/josephSpanish_2.mov"
import josephSpanish_3 from "../../assets/sound/countdown/josephSpanish_3.mov"
import matildaFrench_1 from "../../assets/sound/countdown/matildaFrench_1.mov"
import matildaFrench_2 from "../../assets/sound/countdown/matildaFrench_2.mov"
import matildaFrench_3 from "../../assets/sound/countdown/matildaFrench_3.mov"
import serenaEnglish_1 from "../../assets/sound/countdown/serenaEnglish_1.mov"
import serenaEnglish_2 from "../../assets/sound/countdown/serenaEnglish_2.mov"
import serenaEnglish_3 from "../../assets/sound/countdown/serenaEnglish_3.mov"


export const countdownArrays = [
        [charlotteSwedish_1, charlotteSwedish_2, charlotteSwedish_3],
        [clydeGreek_1, clydeGreek_2, clydeGreek_3],
        [clydePolish_1, clydePolish_2, clydePolish_3],
        [danielGerman_1, danielGerman_2, danielGerman_3],
        [elliDutch_1, elliDutch_2, elliDutch_3],
        [finDutch_1, finDutch_2, finDutch_3],
        [giovanniItalian_1, giovanniItalian_2, giovanniItalian_3],
        [josephSpanish_1, josephSpanish_2, josephSpanish_3],
        [matildaFrench_1, matildaFrench_2, matildaFrench_3],
        [serenaEnglish_1, serenaEnglish_2, serenaEnglish_3]
    ]

export function getRandomCountdownArray(arrays){
    return arrays[Math.floor(Math.random() * arrays.length)]
}