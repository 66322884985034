export function handleShareClick(type, message, setNewInfo){
    switch(type){
        case "WHATSAPP":
            const messageToShare = message;
            const encodedMessage = encodeURIComponent(messageToShare);
            const whatsappLink = `https://wa.me/?text=${encodedMessage}`;
            window.open(whatsappLink, "_blank");
            break;
        case "COPY":
            navigator.clipboard.writeText(message)
            setNewInfo && setNewInfo("We hebben de link gekopiëerd, je kunt hem nu delen met je groep")
            break;
        default:
            console.log("No type");
            break;
    }
};