import { functions } from "../config/firebaseConfig"
import { useMessageContext } from "./useMessageContext";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { fileToBase64 } from "../utils/pure/fileToBase64";

export function useChangeProfilePhoto(){
    const {setNewError, setNewInfo} = useMessageContext()
    const [photoIsPending, setPhotoIsPending] = useState(false)

    async function tryChangeProfilePhoto(user, photo){
        if(photoIsPending){
            return
        }
        setPhotoIsPending(true)
        try {
            const handleChangeProfilePhoto = httpsCallable(functions, "handleChangeProfilePhoto")
            await handleChangeProfilePhoto({
                uid: user.uid,
                profilePhoto: await fileToBase64(photo)
            })
            setNewInfo("Je profielfoto is aangepast")
        } catch (error) {
            console.log(error);
            setNewError("Er is iets misgegaan")
        }
        setPhotoIsPending(false)
    }
    
    return {photoIsPending, tryChangeProfilePhoto}
}