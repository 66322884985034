export function setGoogleTags(){
    // Insert the <script> tag into the <head>
    if(!document.getElementById('gtm-head-script')){
        const script = document.createElement('script');
        script.id = 'gtm-head-script';
        script.innerHTML = `
            (function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
                j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
            })(window, document, 'script', 'dataLayer', 'GTM-PCLJ2QWG');
        `;
        document.head.appendChild(script);
    }

    // Insert the <noscript> tag into the <body>
    if(!document.getElementById('gtm-noscript')){
        const noscript = document.createElement('noscript');
        noscript.id = 'gtm-noscript';
        noscript.innerHTML = `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PCLJ2QWG"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;
        document.body.appendChild(noscript);
    }
}

