export default function InfoBar({user, gameDoc, groupDoc, qwestoDoc, small}) {

    return(
        <div className={"info-bar" + (small ? " small" : "")}>
            <div className="row stay center no-gap">
                <img src={user.photoURL} alt="" className="avatar" />
                <span className="text-small">{groupDoc.players[groupDoc.players.findIndex((player) => player.uid === user.uid)].nickName}</span>
            </div>
            <div className="row stay no-gap">
                <div className="info-bar-progress">
                    {gameDoc.activeQuestionIndex}/{Object.keys(qwestoDoc.qwestoQuestions).length}
                </div>
                {gameDoc.points && <span className="info-bar-points">{gameDoc.points[user.uid]}</span>}
            </div>
        </div>
    )
}
