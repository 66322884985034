import "./ProgressionBar.css"

export default function ProgressionBar({progress, small}) {
    const progression = {
        width: `${progress}%`,
    }

    return (
        <div className={`progression-bar ${small ? "small" : ""}`}>
            <div style={progression} className="progression-bar-progression">
            </div>
        </div>
    )
}
