import { getPlayersByPoints } from "./getPlayersByPoints";

export function getPlayerPosition(uid, gameDoc){
    const players = getPlayersByPoints(gameDoc)
    let position = null
    players.forEach((player, index) => {
        if(player.uid === uid){
            position = index + 1
        }
    })
    return position
}