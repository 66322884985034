import Check from "../../assets/img/Check";
import Exit from "../../assets/img/Exit";

export default function ComparisonValue({value}){
    return(
        <>
            {value === true ?
                <Check className="icon correct"/>
            :
                <Exit className="icon"/>
            }
        </>
    )
}