import React, { useEffect, useState } from "react"
import Cropper from "react-easy-crop"
import Image from "../../assets/img/Image"
import Exit from "../../assets/img/Exit"
import { generateCroppedImage } from '../../hooks/useCrop'
import { useMessageContext } from "../../hooks/useMessageContext"
import "./ImageInput.css"
import ReactDOM  from "react-dom"
import QwestoSmall from "../../assets/img/QwestoSmall"

export default function ImageInput({aspect, shape, avatar, title, message, setPhoto, photoURL, setPhotoURL, className, onSubmit, closeURL, fileType}){
    const { setNewError } = useMessageContext()
    const [quality, setQuality] = useState(1)
    const [zoom, setZoom] = useState(1)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [openCrop, setOpenCrop] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [croppedArea, setCroppedArea] = useState(null)
    const [ratio, setRatio] = useState(null)

    useEffect(() => {
        if(aspect === 1){
            setRatio("square")
        }
        if(aspect === 16/9){
            setRatio("rect")
        }
    }, [aspect])
    

    const onCropComplete = (_croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onUseAsAvatar = async () => {
        setIsPending(true)
        const canvas = await generateCroppedImage(photoURL, croppedArea);
        const blob = await new Promise((resolve) => 
            canvas.toBlob(resolve, fileType ? fileType : "image/jpeg", quality)
        );
    
        if(setPhoto){
            setPhoto(blob)
        }
        if(onSubmit){
            await onSubmit(blob)
        }
        setPhotoURL(URL.createObjectURL(blob))
        URL.revokeObjectURL(blob) 
    
        setOpenCrop(false)
        setIsPending(false)
    };

    const handleFileChange = async (e) => {
        let selectedPicture = e.target.files[0]

        if(!selectedPicture){
            setNewError("Geen bestand geselecteerd.")
            return
        }
        if(!selectedPicture.type.includes("image")){
            setNewError("Het geselecteerde bestand moet een foto zijn.")
            return
        }
        if(selectedPicture.size < 1000000){
            setQuality(1)
        }else if(selectedPicture.size < 2000000){
            setQuality(0.5)
        }else if(selectedPicture.size < 10000000){
            setQuality(0.25)
        }else if(selectedPicture.size < 100000000){
            setQuality(0.1)
        }else if(selectedPicture.size < 1000000000){
            setQuality(0.01)
        }else if(selectedPicture.size > 10000000000){
            setNewError("De bestandgrootte moet kleiner zijn dan 100GB")
            return
        }
        setPhotoURL(URL.createObjectURL(selectedPicture))
        URL.revokeObjectURL(selectedPicture)
        setOpenCrop(true)
    }

    return(
        <>
            <input type="file" id="imageInput" accept="image/jpeg, image/png, image/webp"
                onChange={handleFileChange}
                onClick={(e)=> { 
                    e.target.value = null
                }}
            /> 
            {photoURL ? 
                <label htmlFor="imageInput" className={`img-input ${ratio} ${className} ${avatar ? "avatar" : ""} active`}>
                    <div className="img-input-label">
                        <Image className="icon"/>
                    </div>
                    <img src={photoURL} alt="" className="img-input-img"/>
                </label>
            :
                <label htmlFor="imageInput" className={`img-input ${ratio} ${className} ${avatar ? "avatar" : ""}`}>
                    <Image className="icon"/>
                    {message ? message : "Selecteer een foto" } 
                </label>
            }  
            
            {openCrop && ReactDOM.createPortal(
                <div className="media-input">
                    <Cropper
                        image={photoURL}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        showGrid={false}
                        cropShape={shape}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        disableAutomaticStylesInjection={true}
                    />
                    <div className="media-input-controls">
                        <span className="text-small">{title}</span>
                        <div className="row stay gap">
                            <button className="btn-gr" onClick={onUseAsAvatar}>{!isPending ? "Gebruiken" : <QwestoSmall className="load"/>}</button>
                            <button className="close-btn" onClick={() => {
                                setOpenCrop(false)
                                setPhotoURL(closeURL)
                                if(setPhoto){
                                    setPhoto(null)}
                                }
                            }><Exit className="icon"/></button>
                        </div>
                    </div>
                </div>, 
                document.querySelector("#index"))
            }  
        </>
    )
}
