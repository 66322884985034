import {useState} from "react";
import {increment, ref, update} from 'firebase/database';
import {rdb} from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";
import { finishQwesto } from "../utils/pure/groups/finishQwesto";

export function useNextQwestoQuestion(){
    const [isPending, setIsPending] = useState(false)
    const { setNewError } = useMessageContext()

    async function tryNextQuestion(groupDoc, gameDoc, qwestoDoc){
        if(!isPending){
            try{
                if(gameDoc.stage === "RESULTS"){
                    if(gameDoc.activeQuestionIndex === Object.keys(qwestoDoc.qwestoQuestions).length){
                        await finishQwesto(groupDoc.qwestoID, null)
                    }else{
                        await update(ref(rdb, "games/" + groupDoc.qwestoID), {stage: "STATS"})
                    }
                }else{
                    const gameDocObject = {
                        activeQuestionIndex: increment(1),
                        answers: {},
                        correctAnswer: "EMPTY",
                        stage: "INTRODUCTION"
                    }

                    groupDoc.players.forEach((player) => {
                        gameDocObject.answers[player.uid] = "EMPTY"
                    })

                    await update(ref(rdb, "games/" + groupDoc.qwestoID), gameDocObject)
                }
            }catch(error){
                setNewError("Er is iets misgegaan")
                console.log(error);
            }
            setIsPending(false)
        }
    }

    return { isPending, tryNextQuestion }
}