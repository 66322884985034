import {useState} from "react";
import "./Viewer.css"
import ArrowRight from '../../../assets/img/ArrowRight';
import Fullscreen from '../../../assets/img/Fullscreen';
import { openFullscreen } from "../../../utils/pure/openFullscreen";
import { closeFullscreen } from "../../../utils/pure/closeFullscreen";
import { useStartQwesto } from "../../../hooks/useStartQwesto";
import { useSkipQwestoQuestion } from "../../../hooks/useSkipQwestoQuestion";
import { useNextQwestoQuestion } from "../../../hooks/useNextQwestoQuestion";
import ControlBarAvatars from "./ControlBarAvatars";
import { useGoBackQwestoQuestion } from "../../../hooks/useGoBackQwestoQuestion";
import BackButton from "../../../components/input/BackButton";

export default function ControlBar({activeQuestion, groupDoc, qwestoDoc, gameDoc, qwestoProps}) {
    const element = document.documentElement;
    const [isFullscreen, setIsFullscreen] = useState(false)
    const {startIsPending, tryStartQwesto} = useStartQwesto()
    const {skipIsPending, trySkipQuestion} = useSkipQwestoQuestion()
    const {nextIsPending, tryNextQuestion} = useNextQwestoQuestion()
    const {backIsPending, tryGoBackQuestion} = useGoBackQwestoQuestion()

    function handleFullscreen(){
        if(isFullscreen){
            closeFullscreen(document)
            setIsFullscreen(false)
        }else{
            openFullscreen(element)
            setIsFullscreen(true)
        }
    }

    return(
        <div className="control-bar">
            <div className="control-bar-row left">
                {qwestoProps && (qwestoProps.index === qwestoProps.length) && !(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN") && activeQuestion && 
                    <BackButton to={`/groups/${groupDoc.qwestoID}`} sticky top={100}/>
                }
                <button className="btn white no-grow small" onClick={() => {handleFullscreen()}}><Fullscreen className="icon no-margin" fullscreen={isFullscreen.toString()}/></button>
                {groupDoc.qwestoStarted && gameDoc.activeQuestionIndex !== 1 && !(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN") &&
                    <button className="btn white no-grow small" onClick={() => {tryGoBackQuestion(groupDoc, gameDoc)}} disabled={backIsPending}>Vorige</button>
                }
            </div>

            {qwestoProps && !(qwestoProps.index === qwestoProps.length) && !(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN") && activeQuestion && 
                <ControlBarAvatars gameDoc={gameDoc} activeQuestion={activeQuestion}/>
            }

            <div className="control-bar-row right">
                {groupDoc.qwestoStarted ?
                    <>
                        {activeQuestion && 
                        <>  
                            {qwestoProps && !(qwestoProps.index === qwestoProps.length && (gameDoc.stage === "STATS")) &&
                            <>
                                {gameDoc.stage === "ANSWERING" &&
                                    <button className="btn white no-grow small" onClick={() => {trySkipQuestion(activeQuestion, gameDoc, groupDoc, qwestoDoc)}} disabled={skipIsPending}>Skip</button>
                                }
                                {(gameDoc.stage === "RESULTS" || gameDoc.stage === "STATS") &&
                                    <button className="btn white no-grow small" onClick={() => {tryNextQuestion(groupDoc, gameDoc, qwestoDoc)}} disabled={nextIsPending}><ArrowRight className="icon"/></button>
                                }
                            </>
                            }
                        </>
                        }
                    </>
                :
                    <button className="btn white" onClick={() => tryStartQwesto(groupDoc)} disabled={startIsPending}>Starten</button>
                }
                {qwestoProps && <span className="viewer-text xs">{qwestoProps.index}/{qwestoProps.length}</span>}
            </div>
        </div>
    )
}
