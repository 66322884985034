export function getPlayersReady(connections){
    const playersReady = []
    Object.entries(connections).forEach(([key, value]) => {
        if(key === "VIEWER") return
        if(value > 0){
            if(!playersReady.includes(key)){
                playersReady.push(key)
            }
        }else{
            playersReady.filter(player => player !== key)
        }
    })
    return playersReady
}