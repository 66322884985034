import Check from "../../../assets/img/Check"
import Exit from "../../../assets/img/Exit"
import ImageInput from "../../../components/input/ImageInput"

export function WhatDoYouSee({whatIsTrue, setWhatIsTrue, setPhoto, photoURL, setPhotoURL}){

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setWhatIsTrue(previousState => ({
            ...previousState,
            [name]: value
        }))
    }

    return(    
    <>
        <div className="border-column center">
            <ImageInput aspect={1} title="Kies de leukste foto" message=" " photoURL={photoURL} setPhoto={setPhoto} setPhotoURL={setPhotoURL} className="intake"/>
            <p className="grey-text">{photoURL ? 1 : 0}/1</p>
        </div>
        <div className="column gap">
            <div className="border-column no-padding no-border center">
                <Check className="icon correct"/><h4>Geef een juiste omschrijving</h4>
                <input type="text"
                    required 
                    onChange={handleInputChange}
                    maxLength={70}
                    value={whatIsTrue.firstTrue}
                    name="firstTrue"
                />
            </div>
            <div className="border-column no-border center">
                <Exit className="icon wrong"/><h4>Geef twee onjuiste omschrijvingen</h4>
                <input type="text"
                    required 
                    onChange={handleInputChange}
                    maxLength={70}
                    value={whatIsTrue.firstFalse}
                    name="firstFalse"
                />

                <input type="text"
                    required 
                    onChange={handleInputChange}
                    maxLength={70}
                    value={whatIsTrue.secondFalse}
                    name="secondFalse"
                    />
            </div>
        </div>
    </>
    )
}