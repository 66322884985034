import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { shuffleArray } from "../../../utils/pure/shuffleArray";
import { questionPoints } from "../../../data/constants";
import { Result } from "./Result";
import { useSubmitAnswer } from "../../../hooks/useSubmitAnswer";
import ImageOption from "./ImageOption";
import Sent from "./Sent";

export function BestPhotoController({activeQuestion, gameDoc, groupDoc}){
    const { user } = useAuthContext()
    const {submitIsPending, trySubmitAnswer} = useSubmitAnswer()
    const [answers, setAnswers] = useState()

    useEffect(() => {
        let newAnswers = []
        activeQuestion.answers.forEach((answer) => {
            if(answer.uid !== user.uid){
                newAnswers.push(answer)
            }
        })
        setAnswers(shuffleArray(newAnswers))
    }, [activeQuestion.answers, user])

    if(gameDoc.stage === "ANSWERING"){
        return(
            <>
                {gameDoc.answers[user.uid] === "EMPTY" ? 
                    <div className="option-list">
                        {answers && !submitIsPending && answers.map((option) => (
                            <ImageOption src={option.photoURL} onClick={() => {trySubmitAnswer(groupDoc.qwestoID, user.uid, option.uid)}} key={option.uid}/>
                        ))}
                    </div>
                :
                    <Sent/>
                }
            </>
        )
    }else{
        let votes = gameDoc.pointsEarned[user.uid] / questionPoints.BESTPHOTO
        return(
            <>
                {gameDoc.pointsEarned[user.uid] === 0 ?
                    <Result type="wrong" message="Je hebt 0 stemmen ontvangen" gameDoc={gameDoc} uid={user.uid}/>
                :
                    <Result type="correct" message={votes > 1 ? `Je hebt ${votes} stemmen ontvangen` : "Je hebt 1 stem ontvangen"} gameDoc={gameDoc} uid={user.uid}/>
                }
            </>
        )
    }
}