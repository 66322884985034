export function getActiveIntakeQuestion(groupDoc, intakeData, uid){
    const intakeIndexes = intakeData.indexes[uid]
    let question = null
    let index = null
    let length = null

    if(groupDoc.groupIsConfirmed){
        let commonLength = null
        let dummyLength = null

        //check whether the first question-id in the player's indexes is in common or dummy questions
        Object.keys(groupDoc.intakeQuestions.commonQuestions).forEach((key) => {
            if(intakeIndexes && parseInt(key) === intakeIndexes[0]){
                question = {id: key, ...groupDoc.intakeQuestions.commonQuestions[key]}
            }
            commonLength += 1
        })
        Object.keys(groupDoc.intakeQuestions[uid]).forEach((key) => {
            if(intakeIndexes && parseInt(key) === intakeIndexes[0]){
                question = {id: key, ...groupDoc.intakeQuestions[uid][key]}
            }
            dummyLength += 1
        })
        
        index = (commonLength + dummyLength) - (intakeIndexes !== "FINISHED" ? intakeIndexes.length : 0)
        length = commonLength + dummyLength
    }

    return [question, index, length]
}