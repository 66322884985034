export const GOOGLE_REVIEWS = [
    {
        userPhotoURL: "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Freview-pf-1.png?alt=media&token=5ac87968-bc7a-4073-9414-64ad2e938055",
        userName: "Anouk D.",
        reviewLink: "https://g.co/kgs/8pT9zfs",
        reviewText: "Hele avond schik van gehad!! Zeker voor deze prijs iets wat we vaker gaan doen.",
        reviewStars: 5
    },
    {
        userPhotoURL: "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Freview-pf-2.png?alt=media&token=78996224-669f-4fb2-8ca3-e80ad21b1393",
        userName: "Michel L.",
        reviewLink: "https://maps.app.goo.gl/chfZJ5h4jGSruXf29",
        reviewText: "Met een aantal vrienden dit spel gespeeld. Flink gelachen door de persoonlijke vragen en super handig dat er nu een stuk makkelijker een quiz gemaakt kan worden die persoonlijk is",
        reviewStars: 5
    },
    {
        userPhotoURL: "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Freview-pf-3.png?alt=media&token=7b479673-74a7-4409-930a-249d67fe08e8",
        userName: "Tijn van H.",
        reviewLink: "https://maps.app.goo.gl/XZwnh4s4xzSYqMy38",
        reviewText: "Laatst met 8 vrienden gespeeld. Enorm gelachen vooral om de vragen met fotos en filmpjes",
        reviewStars: 5
    },
    {
        userPhotoURL: "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Freview-pf-4.png?alt=media&token=0de4f22f-db17-4009-b9db-8d5f5aa1b681",
        userName: "Miranda V.",
        reviewLink: "https://maps.app.goo.gl/GWXBpVCci35c4MBdA",
        reviewText: "Op nieuwjaarsdag gespeeld met mijn familie (zeven spelers tussen de 18 tot 81 jaar). Na invullen van 7 vragen hadden we een persoonlijke quiz van 31 vragen. Gevarieerd en heel erg leuk! Je kunt vooraf een aantal keuzes maken in duur en inhoud. Zeer geslaagd en absolute aanrader!",
        reviewStars: 5
    },
    {
        userPhotoURL: "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Freview-pf-5.png?alt=media&token=0f40bbc3-d99a-407c-90cb-a1e6f46668a1",
        userName: "Tijn S.",
        reviewLink: "https://maps.app.goo.gl/Mvz3Eeva1hEMPkXZ9",
        reviewText: "Net een spel van een half uur gespeeld met een paar vrienden. Je begint met het invullen van een paar vragen wat niet zoveel werk is, dit gaat best snel. Voor heel goedkoop kun je een hele leuke avond...",
        reviewStars: 5
    },
]