import { useState } from "react"
import SignInForm from "../../../components/authforms/SignInForm"
import SignUpForm from "../../../components/authforms/SignUpForm"
import PopUp from "../../../components/layout/PopUp"
import { DEFAULT_GROUP_URL } from "../../../data/constants"
import { DEFAULT_USER_PHOTO_URL } from "../../../data/constants";

export default function InviteSignedOut(){
    const [signInVisible, setSignInVisible] = useState(false)
    const [signUpVisible, setSignUpVisible] = useState(false)

    return(
        <>
            <div className="group-banner-container">
                <img className="group-banner-img dark" src={DEFAULT_GROUP_URL} alt=""/> 
                <div className="content-container min-height center large-gap">
                    <div className="column center">
                        <img src={DEFAULT_USER_PHOTO_URL} className="avatar big center" alt="" height="100px"/>
                        <span className="text-small bold">???</span>
                        <span className="text-small">heeft je uitgenodigd voor</span>
                        <span className="text-xlarge">"???"</span>
                    </div>
                    <div className="container box-shadow no-grow">
                        <div className="column">
                            <button className="btn-gr" onClick={() => {setSignUpVisible(true)}}>Meld je aan</button>
                            <span className="text-small grey-text">of</span>
                            <button className="btn black" onClick={() => {setSignInVisible(true)}}>Log in</button>
                            <span className="text-xxsmall">om de uitnodiging te zien</span>
                        </div>
                    </div>
                </div>
            </div>
            <PopUp title={"Aanmelden"} visible={signUpVisible} setVisible={setSignUpVisible}>
                <SignUpForm popUp/>
            </PopUp>
            <PopUp title={"Inloggen"} visible={signInVisible} setVisible={setSignInVisible}>
                <SignInForm popUp/>
            </PopUp>
        </>
    )
}
