import React, { useEffect, useState } from "react";
import { getPlayersByVotes } from "../../../utils/pure/getPlayersByVotes";
import { getPlayerByUID } from "../../../utils/pure/groups/getPlayerByUID";
import { QuestionBar } from "./QuestionBar";

export function BestPhotoViewer({activeQuestion, gameDoc, groupDoc}){
    const [currentAnswer, setCurrentAnswer] = useState(null)
    const [animate, setAnimate] = useState(false)
    const [carouselIsActive, setCarouselIsActive] = useState(false)

    useEffect(() => {
        if(gameDoc.stage === "RESULTS" && !carouselIsActive){
            async function onStartCarousel(answers){
                setCarouselIsActive(true)
                for(let i = answers.length - 1; i >= 0; i--){
                    setAnimate(false)
                    setCurrentAnswer({...answers[i], ...getPlayerByUID(answers[i].uid, groupDoc)})
                    await new Promise(resolve => setTimeout(resolve, 5000))
                    if(i !== 0){
                        setAnimate(true)
                        await new Promise(resolve => setTimeout(resolve, 500))
                    }
                }
            }
            const answers = getPlayersByVotes(gameDoc.answers, false)
            onStartCarousel(answers)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameDoc, groupDoc.players, carouselIsActive, activeQuestion.answers])

    return(
        <>
            <QuestionBar question={activeQuestion.question} slideUp={gameDoc.stage === "RESULTS"}/>
            <div className={`correct-answer ${gameDoc.stage === "RESULTS" ? "" : "static"}`}>
                {activeQuestion.answers.map((answer, index) => (
                    <div className={`carousel-item ${answer.uid === currentAnswer?.uid ? (animate ? "swipe-out" : "swipe-in") : ""}`} key={index}>
                        <div className="photo-answer">
                            <img src={activeQuestion.answers[activeQuestion.answers.findIndex(a => a.uid === answer.uid)].photoURL} alt="" className="photo-answer-img"/>
                            <div className="photo-answer-label">
                                <span className="viewer-text">{currentAnswer?.nickName}</span>
                                <span className={`viewer-text ${currentAnswer?.votes === 0 ? "bg-text red" : "bg-text green"}`}>{currentAnswer?.votes}x</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}