import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { db } from "../config/firebaseConfig"
import { doc, runTransaction } from "firebase/firestore";

export function useChangeNickname(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    async function tryChangeNickname(groupDoc, qwestoID, profileData, newNickName){
        if(newNickName === profileData.nickName){
            setNewError("Deze naam gebruik je al")
            return
        }
        if(!groupDoc.groupIsConfirmed){
            setIsPending(true)
            try {
                const groupDocRef = doc(db, "groups", qwestoID)
                await runTransaction(db, async (transaction) => {
                    const groupDocSnap = await transaction.get(groupDocRef)
                    const groupDocData = groupDocSnap.data()
                    const players = groupDocData.players
                    const playerIndex = players.findIndex(player => player.uid === profileData.uid)
                    players[playerIndex].nickName = newNickName

                    if(profileData.uid === groupDoc.host.uid){
                        transaction.update(groupDocRef, {
                            players: players,
                            "host.nickName": newNickName,
                        });
                    }else{
                        transaction.update(groupDocRef, {
                            players: players,
                        });
                    }
                });
                setNewInfo("Je hebt een nieuwe bijnaam, " + newNickName + "!")
                setIsPending(false)
            } catch (error) {
                console.log(error);
                setNewError("Er is een fout opgetreden")
                setIsPending(false)
            }
        }else{
            setNewError("Je kan je naam niet veranderen als je groep is bevestigd")
            setIsPending(false)
        }
    }

    return { isPending, tryChangeNickname }
}
