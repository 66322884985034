import "./VideoEmbedder.css"

export default function VideoEmbedder({src, title}) {
    return (
        <iframe
            src={src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
            className="video-embedder"
        />
    )
}