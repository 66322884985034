import { ProgressionSteps } from '../../../components/layout/ProgressionSteps';
import ProgressionBar from "../../../components/layout/ProgressionBar";
import PlayerList from "./PlayerList";
import { useEffect, useState } from 'react';

import Check from '../../../assets/img/Check';
import Pencil from '../../../assets/img/Pencil';
import Play from '../../../assets/img/Play';
import Replay from '../../../assets/img/Replay';
import Group from '../../../assets/img/Group';

export default function GroupInfo({groupProgress, commonLength, groupDoc, intakeData}){
    const [activeIndex, setActiveIndex] = useState(0)
    const steps = [
        {
            icon: <Group className="icon"/>,
            text: "Uitnodigen", 
            info: "De host nodigt alle spelers uit, dit kan via WhatsApp of de uitnodigingslink. Je hebt minimaal 3 spelers nodig om te kunnen spelen."
        },
        {
            icon: <Check className="icon"/>,
            text: "Bevestigen", 
            info: "Zodra alle gewenste spelers in de groep zitten, kan de host de groep bevestigen."
        },
        {
            icon: <Pencil className="icon"/>,
            text: "Invullen", 
            info: "Iedere speler moet een kort vragenlijstje invullen, deze worden omgetoverd tot de Qwesto."
        },
        {
            icon: <Play className="icon"/>,
            text: "Spelen", 
            info: "Als alle spelers klaar zijn met het invullen van het vragenlijstje kan de Qwesto beginnen! Spelen kan op een groot scherm of met enkel telefoons. De host kan dit instellen."
        },
        {
            icon: <Replay className="icon"/>,
            text: "Nog eentje?", 
            info: "Was de Qwesto geslaagd? De host kan op deze pagina een nieuwe Qwesto starten met dezelfde groep, lekker makkelijk!."
        }
    ]

    useEffect(() => {
        if(groupDoc.groupIsInitialized) setActiveIndex(1)
        if(groupDoc.players.length === groupDoc.product.properties.maxPlayers) setActiveIndex(2)
        if(groupDoc.groupIsConfirmed) setActiveIndex(3)
        if(groupProgress === 100) setActiveIndex(4)
        if(groupDoc.qwestoFinished === true) setActiveIndex(5)
    }, [groupDoc, groupProgress])

    return(
    <div className="content-field">
        <div className="contentlabel no-margin flex-start">
            <div className="column flex-start gap">
                {!groupDoc.product.qwestoIsDeleted && groupDoc.groupIsConfirmed &&
                    <div className="content-section">
                        <div className="content-section-label">
                            <span className="text-small">Voortgang invullen</span>
                            <span className="text-small grey-text">{groupProgress ? groupProgress : 0}%</span>
                        </div>
                        <ProgressionBar progress={groupProgress}/>
                    </div>
                }
                <div className="content-section">
                    <div className="content-section-label">
                        <span className="text-small">Spelers</span><span className="text-small grey-text">{groupDoc.players.length}/{groupDoc.product.properties.maxPlayers}</span>
                    </div>
                    <PlayerList commonLength={commonLength} groupDoc={groupDoc} intakeData={intakeData}/>
                </div>
                <div className="content-section">
                    <div className="content-section-label">
                        <span className="text-small">Stappen</span>
                        <span className="text-small grey-text">Stap {activeIndex}</span>
                    </div>
                    <ProgressionSteps steps={steps} activeIndex={activeIndex}/>
                </div>
            </div>
        </div>
    </div>
    )
};