import { doc, onSnapshot,  } from 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import { db } from '../config/firebaseConfig';
import { useAuthContext } from '../hooks/useAuthContext';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [loadingUserDoc, setLoadingUserDoc] = useState(false);
  const [userDoc, setUserDoc] = useState(null);

  useEffect(() => {
    if (!user) return; //no user, no business

    setLoadingUserDoc(true);
    const docRef = doc(db, "users", user.uid)
    const unsub = onSnapshot(docRef, (doc) => {
        setUserDoc(doc.data())
        setLoadingUserDoc(false)
      },
      error => {
        console.log(error);
        setLoadingUserDoc(false)
      }
    )
    return () => unsub();
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        loadingUserDoc: loadingUserDoc,
        userDoc: userDoc
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
