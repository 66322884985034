export const DEFAULT_GROUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2FGroup-mockup.jpg?alt=media&token=35c19ea0-3d45-4d33-a00b-922a7c0f6d09"
export const DEFAULT_USER_PHOTO_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fdefault.jpeg?alt=media&token=76bd5815-5260-492a-8b32-11f0c6762871"
export const phoneMockupURL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2FPhone-mockup.webp?alt=media&token=a001eeb3-7380-444e-87c6-58b2dcd58871"
export const TVMockupURL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2FTV-mockup.webp?alt=media&token=34efe61d-d76a-40dc-baf0-16570351bde9"
export const FAVOURITE_GROUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Ffavourite-group.webp?alt=media&token=2e859833-c057-4870-81ee-76564734672a"
export const INTAKE_MOCKUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fintake-mockup.webp?alt=media&token=f03e8d64-0960-410c-b135-eeeae7f3bf95"
export const VIEWER_MOCKUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fviewer-mockup.webp?alt=media&token=f5d1f65c-9fdb-4907-b3f9-3cbf597266a9"
export const SCORE_MOCKUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fscore-mockup.webp?alt=media&token=8143c0b3-491a-4ff4-aa03-0db802abe41c"
export const PRODUCT_CLOSE_UP = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fproduct-close-up.webp?alt=media&token=4720fb21-4ef5-4777-bdf1-7401d17f1b72"
export const PRODUCT_GROUP_SETTING = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fproduct-group-setting.webp?alt=media&token=728b27f2-e3a0-44b8-8689-bfd4837d405a"
export const HERO_VIDEO_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fhero%2Fhero-promo-video.mp4?alt=media&token=0b1a53fb-0a9c-4b76-a361-a69899f486b4"
export const HERO_VIDEO_COMPRESSED_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fhero%2Fhero-promo-video-compressed.mp4?alt=media&token=65c77e2e-b356-4595-962c-36cef0155135"
export const LIVING_ROOM_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fproduct%2Fliving-room.webp?alt=media&token=1ef4e6d5-b225-47cb-a656-23c5476b8a07"
export const CONTROLLER_CLOSEUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Flanding_page%2Fcontroller-close-up.webp?alt=media&token=e8c1490f-e220-44e9-b1f2-eaa25f8abab2"
export const VIEWER_LONG_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fviewer-long.webp?alt=media&token=b827d2b0-9fcc-4fd4-aa02-953d7ae99277"
export const GATHER_GROUP_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Flanding_page%2Fgather-group-compressed.mp4?alt=media&token=b8804b4e-1826-4b0b-8b07-67b5e6305ba6"
export const VIDEO_PHOTO_TEXT_URL = "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Flanding_page%2Fvideo-photo-text-compressed.mp4?alt=media&token=7fce078a-c9ac-4bad-92b5-1db92ee67cc9"

export const whatIsTrueDefault = {
    firstTrue: "",
    firstFalse: "",
    secondFalse: ""
}

export const filterOptions = {
    spicyness: ["Mild", "Gekruid", "Pittig"],
    target: ["Vrienden", "Familie", "Collega's"],
    bond: ["Niet echt", "Redelijk", "Jazeker"],
    duration: ["30 min", "1 uur"],
    videos: ["Ja", "Nee"]
}

export const filterInfo = {
    spicyness: [
        "Vermakelijke vragen over elkaars persoonlijkheden, interesses en meer",
        "Uitlokkende vragen over elkaars persoonlijkheden, ervaringen en meer",
        "Pikante vragen over elkaars liefdesleven, blunders en meer"
    ],
    bond: [
        "Je ziet elkaar jaarlijks en/of kent elkaar niet lang",
        "Je ziet elkaar maandelijks en/of kent elkaar al een tijdje",
        "Je ziet elkaar wekelijks en/of kent elkaar al lang"
    ],
    videos: [
        "Je krijgt vragen waarvoor je video's nodig hebt",
        "Je krijgt geen vragen waarvoor je video's nodig hebt"
    ]
}

export const defaultForbiddenCombinations = {
    spicyness: {
        message: null,
        message_short: null,
        disabled_options: []
    },
    duration: {
        message: null,
        message_short: null,
        disabled_options: []
    },
}

export const defaultFilterObject = {
    a: [],
    b: null,
    c: null,
    s: null,
    t: null,
    o: null
}

export const structureFilterObject = {
    a: [],
    d: null,
    t: null
}

export const structureTemplateObject = {
    labels: {
        a: [],
        d: [],
        t: []
    },
    common: {
        "MOSTLIKELY": 0,
        "BESTANSWER": 0,
        "BESTPHOTO": 0,
    },
    dummy: {
        "WHATISTRUE": 0,
        "PHOTOREVEAL": 0,
        "GUESSWHO": 0,
        "WHATDOYOUSEE": 0,
        target: 21
    },
    chosen: {
        "BATTLE": 0,
        target: 0
    }
}

export const DEFAULT_ARTICLE_OBJECT = {
    title: "",
    description: "",
    date: "",
    id: "",
    pathID: "",
    photoURL: "",
    sections: []
}

export const productFilters = {
    age: [18, 30],
    bond: 2,
    duration: 1,
    players: 3,
    spicyness: 2,
    target: 1,
    videos: false
}

export const PRODUCT_IMAGE_URLS = [PRODUCT_CLOSE_UP, LIVING_ROOM_URL, PRODUCT_GROUP_SETTING, INTAKE_MOCKUP_URL]

export const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const questionPoints = {
    "MOSTLIKELY": 20,
    "BESTPHOTO": 5,
    "BESTANSWER": 5,
    "WHATISTRUE": 20,
    "GUESSWHO": 20,
    "PHOTOREVEAL": [20, 10, 5, 5, 5, 5, 5, 5, 5, 5],
    "WHATDOYOUSEE": [20, 10, 5, 5, 5, 5, 5, 5, 5, 5],
    "WHATDOYOUHEAR": [20, 10, 5, 5, 5, 5, 5, 5, 5, 5],
    "BATTLE": 20,
    "SKIPPED": -5
}

export const introductionTypes = {
    BATTLE: {
        name: "Strijd",
        description: "Ga de strijd aan en win alle punten"
    },
    BESTANSWER: {
        name: "Het beste antwoord",
        description: "Kies het beste, grappigste of meest toepasselijke antwoord"
    },
    BESTPHOTO: {
        name: "De beste foto",
        description: "Kies de beste, grappigste of meest toepasselijke foto"
    },
    MOSTLIKELY: {
        name: "Wie zou het eerste?",
        description: "Stem op de speler die het eerst iets zou doen"
    },
    PHOTOREVEAL: {
        timeDependent: true,
        name: "Welke speler?",
        description: "Raad wie je op de foto ziet, alleen de snelste spelers krijgen punten"
    },
    GUESSWHO: {
        name: "Raad wie",
        description: "Raad welke speler antwoord heeft gegeven"
    },
    WHATISTRUE: {
        name: "Wat is waar?",
        description: "Kies het juiste antwoord"
    },
    WHATDOYOUHEAR: {
        timeDependent: true,
        name: "Wat gebeurt er?",
        description: "Kies het juiste antwoord"
    },
    WHATDOYOUSEE: {
        timeDependent: true,
        name: "Wat zie je?",
        description: "Kies het juiste antwoord"
    }
}