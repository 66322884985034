import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import { useSignIn } from '../../hooks/useSignIn';
import QwestoSmall from '../../assets/img/QwestoSmall';

export default function SignInForm({redirect, popUp}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { isPending, trySignIn } = useSignIn()
    const to = redirect ? redirect : null

    const handleSubmit = (e) => {
        e.preventDefault()
        trySignIn(email, password, to)        
    }

    return(
        <form onSubmit={handleSubmit} className={`auth-form ${popUp ? "auth-pop-up" : ""}`}>
            {!popUp &&
                <div className="border-column center">
                    <h2>Inloggen</h2>
                </div>
            }
            <div className="border-column no-border">
                <label>Email
                    <input type="email" id="signinemail"
                    autoComplete="username"
                    required
                    maxLength={256}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email} 
                    />
                </label>
            </div>
            <div className="border-column">
                <label>Wachtwoord
                    <input type="password" id="signinpassword"
                    autoComplete="current-password"
                    required
                    minLength={6}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password} 
                    />
                </label>
                <NavLink to="/reset-password" className="agr"><p><i>Wachtwoord vergeten?</i></p></NavLink>
            </div>
            <div className="border-column">
                {!isPending ? <button className="btn-gr" type="submit">Log in</button> : <button className="btn-gr" disabled><QwestoSmall className="load"/></button>}
            </div>
        </form>  
    )
};