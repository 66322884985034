import React from "react";
import { useState } from 'react';
import PopUp from '../../../components/layout/PopUp';
import DropDown from '../../../components/input/DropDown';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebaseConfig';
import { useMessageContext } from '../../../hooks/useMessageContext';
import { mapLabels } from '../../../utils/pure/mapLabels';
import { LabelDisplay } from "../LabelDisplay";

export function StructureList({filteredStructures, currentStructures}){
    const [structureOpen, setStructureOpen] = useState(false)
    const [structureData, setStructureData] = useState(null)
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [isSaving, setIsSaving] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()
console.log(structureData);
    async function handleSaveStructure(e){
        e.preventDefault()
        setIsSaving(true)
        currentStructures.list[structureData.id] = structureData
        const listRef = doc(db, "shop", "structures")
        console.log(currentStructures);
        try {
            await updateDoc(listRef, {
                list: currentStructures.list
            })
            setNewInfo("Succes a mattie")
            setStructureOpen(false)
            setIsSaving(false)
        } catch (error) {
            console.log(error);
            setNewError("Er ging iets fout")
            setIsSaving(false)
        }
    }

    function handleNextStructureData(category, type, value){
        const nextStructureData = {...structureData}
        nextStructureData[category][type] = value
        let count = 0
        Object.keys(nextStructureData).forEach((category) => {
            switch (category) {
                case "common":
                    Object.entries(nextStructureData[category]).forEach(([type, number]) => {
                        if(number !== null){
                            count = count + parseInt(number) 
                        }
                    }) 
                    break;
                case "chosen":
                case "dummy":
                    count = count + parseInt(nextStructureData[category].target) 
                    break;
                default:
                    break;
            }
        })
        setTotalQuestions(count)
        setStructureData(nextStructureData)
    }

    function handleStructure(structure){
        setStructureData(structure)
        setStructureOpen(true)
    }

    return(
        <>
            <div className="manager-list">
                {filteredStructures && filteredStructures.map((structure, index) => (
                    <div className="manager-item" key={index} onClick={() => {handleStructure(structure)}}>
                        <div className="row stay gap align-flex-start grow">
                            {Object.entries(structure).filter(([category, types]) => category !== "id").sort().map(([category, types]) => (
                                <div className="column" key={category}>
                                    <div className="row stay no-grow">
                                        {types.target !== undefined &&  
                                            <div className="structure-number primary">{types.target}</div>
                                        }
                                        {(() => {
                                            if(category === "common"){
                                                let total = 0
                                                Object.values(types).forEach((value) => {
                                                    total = total + parseInt(value)
                                                })  
                                                return <div className="structure-number primary">{total}</div>
                                            } 
                                        })()}
                                        <span className="text-medium">{category}</span>
                                    </div>
                                    {Object.entries(types).sort().filter(([type, value]) => type !== "target").map(([type, value]) => {
                                        if(category === "labels"){
                                            return(
                                                <React.Fragment key={type}>
                                                    <LabelDisplay label={type} values={value}/>
                                                </React.Fragment>
                                            ) 
                                        }else{
                                            return(
                                                <div className="row stay no-grow" key={type}>
                                                    <div className="structure-number">{value}</div>
                                                    <span className="text-small grey-text">{type}</span>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            ))}
                            <span className="text-small">ID: {structure.id}</span>
                        </div>
                    </div>
                ))}
            </div>
            <PopUp title={`Update structure ${structureData && structureData.id}`} visible={structureOpen} setVisible={setStructureOpen} large unclosable>
                {structureData &&
                    <form className="column gap">
                        <span className="text-large">Totaal aantal vragen: {totalQuestions}</span>
                        <div className="row align-flex-start">
                            {Object.keys(structureData).sort().map((category) => {
                                    if(category === "labels"){
                                        return(
                                            <div className="column" key={category}>
                                                <span className="text-medium">{category}</span>
                                                {Object.keys(structureData[category]).sort().map((type) => (
                                                    <div className="column" key={type}>
                                                        <span className="text-small grey-text">{type}</span>
                                                        <DropDown 
                                                            message="Labels" 
                                                            options={mapLabels((() => {
                                                                switch(type){
                                                                    case "o":
                                                                        return [1]
                                                                    case "a":
                                                                        return [1,2,3,4,5]
                                                                    default:
                                                                        return [1,2,3]
                                                                }
                                                            })())} 
                                                            selectedOption={structureData[category][type] ? mapLabels(structureData[category][type]) : []}
                                                            setSelectedOption={(value) => {handleNextStructureData(category, type, value)}}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    }else if(category !== "id"){
                                        return(
                                            <div className="column" key={category}>
                                                <span className="text-medium">{category}</span>
                                                {Object.keys(structureData[category]).sort().map((type) => (
                                                    <div className="column" key={type}>
                                                        <span className="text-small grey-text">{type}</span>
                                                        <input type="number" id="question"
                                                            step={["dummy", "chosen"].includes(category) ? .1 : 1}
                                                            required
                                                            min={0}
                                                            value={["dummy", "chosen"].includes(category) ? parseFloat(structureData[category][type]) : parseInt(structureData[category][type])}
                                                            onChange={(e) => {handleNextStructureData(category, type, ["dummy", "chosen"].includes(category) ? parseFloat(e.target.value) : parseInt(e.target.value))}}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    }else{
                                        return null
                                    }
                                }
                            )}
                        </div>
                        <div className="row stay">
                            <button className="btn black" type="button" onClick={() => {setStructureOpen(false)}}>Annuleren</button>
                            <button className="btn-gr" type="submit" disabled={isSaving} onClick={(e) => {handleSaveStructure(e)}}>{isSaving ? "Werkende..." : "Opslaan"}</button>
                        </div>
                    </form>
                }
            </PopUp>
        </>
    )
}