import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { shuffleArray } from "../../../utils/pure/shuffleArray";
import { Result } from "./Result";
import { useSubmitAnswer } from "../../../hooks/useSubmitAnswer";
import TextOption from "./TextOption";
import { getMaxPlayerPosition } from "../../../utils/pure/getMaxPlayerPosition";
import Sent from "./Sent";

export function WhatIsTrueController({activeQuestion, gameDoc, groupDoc, positions}){
    const { user } = useAuthContext()
    const [answers, setAnswers] = useState()
    const {submitIsPending, trySubmitAnswer} = useSubmitAnswer()
    const maximumPosition = getMaxPlayerPosition(groupDoc.players)

    useEffect(() => {
        const shuffleAnswers = shuffleArray([activeQuestion.firstFalse, activeQuestion.secondFalse, activeQuestion.firstTrue])
        setAnswers(shuffleAnswers)
    }, [activeQuestion.id, activeQuestion.firstFalse, activeQuestion.secondFalse, activeQuestion.firstTrue])
    
    if(gameDoc.stage === "ANSWERING"){
        return(
            <>
                {gameDoc.answers[user.uid] === "EMPTY" ? 
                    <div className="option-list">
                        {answers && !submitIsPending && answers.map((answer, index) => (
                            <TextOption text={answer} onClick={() => {trySubmitAnswer(groupDoc.qwestoID, user.uid, answer, positions)}} key={index}/>
                        ))}
                    </div>
                :
                    <Sent/>
                }
            </>
            
        )
    }else{
        if(positions){
            return(
                <>
                    {gameDoc.correctAnswer === gameDoc.answers[user.uid].answer ?
                        <>
                        {gameDoc.answers[user.uid].position !== "EMPTY" && gameDoc.answers[user.uid].position < maximumPosition + 1 ? 
                            <Result type="correct" message="Correct" gameDoc={gameDoc} uid={user.uid}/>
                        :
                            <Result type="slow" message="Te langzaam" gameDoc={gameDoc} uid={user.uid}/>                        
                        }
                        </>
                    :
                        <Result type="wrong" message="Fout" gameDoc={gameDoc} uid={user.uid}/>
                    }
                </>
            )
        }else{
            return(
                <>
                    {gameDoc.answers[user.uid] === gameDoc.correctAnswer ?
                        <Result type="correct" message="Correct" gameDoc={gameDoc} uid={user.uid}/>
                    :
                        <Result type="wrong" message="Fout" gameDoc={gameDoc} uid={user.uid}/>
                    }
                </>
            )
        }
    }
}