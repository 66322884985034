import React, { useState } from "react";
import "./ProgressionSteps.css";
import Check from "../../assets/img/Check";
import PopUp from "./PopUp";
import Info from "../../assets/img/Info";

export function ProgressionSteps({steps, activeIndex}){
    const [infoIsVisible, setInfoIsVisible] = useState(false)
    const [activeInfoIndex, setActiveInfoIndex] = useState(0)

    function handleInfoClick(index){
        setActiveInfoIndex(index)
        setInfoIsVisible(true)
    }

    return(
        <>
            <div className="progression-steps-container">
                <div className="progression-step-list">
                    {steps.map((step, index) => (
                            <React.Fragment key={index}>
                                <div key={index} onClick={() => handleInfoClick(index)} className={`progression-step-container ${index < activeIndex ? (index !== activeIndex - 1 ? "completed" : "active") : ""}`}>
                                    <div className={`progression-step-icon ${index === 0 ? "first" : ""}`}>
                                        {index < activeIndex - 1 ? <Check className="icon"/> : step.icon}
                                    </div>
                                    <div className="progression-step">
                                        <div className="progression-step-label">
                                            <span className="text-xsmall">{step.text}</span>
                                            {index < activeIndex - 1 &&
                                                <div className="progression-step-check">
                                                    <Check className="icon"/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>
            <PopUp visible={infoIsVisible} setVisible={setInfoIsVisible} title={
                <div className="row stay gap">
                    <Info className="icon"/>
                    {steps[activeInfoIndex].text}
                </div>}
            >
                {steps[activeInfoIndex].info}
            </PopUp>
        </>
    )
}