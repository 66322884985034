import { React, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useResetPassword } from "../../hooks/useResetPassword"
import { auth } from "../../config/firebaseConfig"
import QwestoSmall from "../../assets/img/QwestoSmall";
import ImageInput from "../../components/input/ImageInput";
import { useChangeName } from "../../hooks/useChangeName";
import PopUp from "../../components/layout/PopUp";
import { getGender } from "../../utils/pure/getGender";
import {useChangeProfilePhoto} from "../../hooks/useChangeProfilePhoto";
import { useSendVerification } from "../../hooks/useSendVerification";

export default function Profile({userDoc, groupDocs}){
    const {user} = useAuthContext()
    const {passwordIsPending, tryResetPassword} = useResetPassword()
    const {nameIsPending, tryChangeName} = useChangeName()
    const {photoIsPending, tryChangeProfilePhoto} = useChangeProfilePhoto()
    const {emailPending, trySendVerification} = useSendVerification()
    const [newDisplayName, setNewDisplayName] = useState()
    const [nameVisible, setNameVisible] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [emailVisible, setEmailVisible] = useState(false)
    const [photoURL, setPhotoURL] = useState(user.photoURL)

    async function handleNameChange(e){
        e.preventDefault()
        tryChangeName(user, userDoc, newDisplayName, groupDocs)
    }

    async function handlePhotoChange(blob){
        if(!photoIsPending){
            await tryChangeProfilePhoto(user, blob, groupDocs)
        }
    }

    return(
        <>
        <div className="container no-grow box-shadow">   
            <div className="column center">
                <ImageInput avatar={true} aspect={1} shape="round" title="Profielfoto" photoURL={photoURL} setPhotoURL={setPhotoURL} onSubmit={handlePhotoChange} closeURL={user.photoURL}/>
                <h4>{user.displayName}</h4>
                <p className="grey-text">{groupDocs.length} actief</p>
            </div>
            <div className="border-column no-border">
                <h4>Naam</h4>    
                <div className="row stay">
                    <p className="grey-text">{user.displayName}</p>
                    <button className="btn text" onClick={() => {setNameVisible(true)}}>Aanpassen</button>
                </div>
            </div>  
            <PopUp title={"Naam aanpassen"} visible={nameVisible} setVisible={setNameVisible}>
                <form className="border-column" onSubmit={handleNameChange}>
                    <div className="row stay">
                        <input type="text" id="displayName" required="required" maxLength={10} minLength={3} onChange={(e) => setNewDisplayName(e.target.value)} defaultValue={user.displayName} />
                        {!nameIsPending ? <button className="btn">Verander</button> : <button className="btn" disabled={true}><QwestoSmall className="load"/></button>}
                    </div>
                    {userDoc && <p className="grey-text" key={userDoc.nameChangesLeft}>{userDoc.nameChangesLeft} resterende wijzigingen</p>}
                </form>
            </PopUp>
            <div className="border-column">
                <h4>Gender</h4>    
                <div className="row stay">
                    <p className="grey-text">{getGender(userDoc.gender)}</p>
                </div>
            </div>  
            <div className="border-column">
                <h4>Email</h4>  
                <div className="row stay">
                    <div className="overflow-x-scroll">
                        <p className="grey-text">{user.email}</p>  
                    </div>
                    {user.emailVerified ? <p className="bg-text small green">Geverifieerd</p> : <button className="btn text" onClick={() => {setEmailVisible(true)}}>Verifiëren</button>} 
                </div>
            </div>   
            <PopUp title={"Email verifiëren"} visible={emailVisible} setVisible={setEmailVisible}>
                <div className="border-column">
                    <p className="grey-text">We hebben je een verficatie-mail gestuurd, klik op de link in de mail om de verficatie te voltooien.</p>        
                    {emailPending ? <button className="btn" disabled={true}><QwestoSmall className="load"/></button> : <button className="btn" onClick={() => {trySendVerification(user)}}>Stuur me een nieuwe email</button>}
                </div>
            </PopUp>
            <div className="border-column">   
                <h4>Wachtwoord</h4>    
                <div className="row stay">
                    <p className="grey-text">Verborgen</p> 
                    <button className="btn text" onClick={() => {setPasswordVisible(true)}}>Aanpassen</button>
                </div>
            </div>                              
            <PopUp title={"Wachtwoord resetten"} visible={passwordVisible} setVisible={setPasswordVisible}>
                <div className="border-column">
                    <p className="grey-text">We sturen je een email met instructies om je wachtwoord te resetten.</p>        
                    {passwordIsPending ? <button className="btn" disabled={true}><QwestoSmall className="load"/></button> : <button className="btn" onClick={() => {tryResetPassword(auth, user.email)}}>Reset mijn wachtwoord</button>}
                </div>
            </PopUp>
        </div>
        </>
    )
}