import { useContext, useEffect } from "react";
import { GroupDataContext } from "../context/GroupDataContext.js";

export function useGroupDataContext(props){
    const {groupData, noGroupData, setNewProps} = useContext(GroupDataContext)

    useEffect(() => {
        if(props){
            setNewProps(props)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return {groupData, noGroupData}
}