import { useEffect } from "react";
import { ref, set} from 'firebase/database';
import { rdb } from "../../../config/firebaseConfig";
import { QuestionBar } from "./QuestionBar";
import { CorrectText } from "./CorrectText";

export function WhatDoYouSeeViewer({activeQuestion, gameDoc, groupDoc}){
    const correctAnswer = activeQuestion.firstTrue

    useEffect(() => {
        set(ref(rdb, "games/" + groupDoc.qwestoID + "/correctAnswer"), correctAnswer)
    }, [correctAnswer, groupDoc.qwestoID])

    return(
        <>
            <QuestionBar question="Welke omschrijving hoort bij de foto?" slideUp/>
            <div className="correct-answer relative">
                <div className="photo-blocker"/>
                <div className={gameDoc.stage === "ANSWERING" ? "photo-reveal active" : "photo-reveal"}>
                    <img src={activeQuestion.photoURL} alt="" className="photo-reveal-img"/>
                </div>
                {gameDoc.stage === "RESULTS" &&
                    <CorrectText text={correctAnswer} fixed/>
                }
            </div>
        </>
    )
}