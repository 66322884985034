import { Outlet } from "react-router-dom";
import { NavigationBarContextProvider } from "./NavigationBarContext";

export function NavigationBarLayout(){
    return(
        <NavigationBarContextProvider>
            <Outlet/>
        </NavigationBarContextProvider>
    )
}

