import Check from "../../../assets/img/Check"
import Exit from "../../../assets/img/Exit"
import SelectionBar from "../../../components/input/SelectionBar"
import VideoTrimmer from "../../../components/input/VideoTrimmer"

export function WhatDoYouHear({whatIsTrue, setWhatIsTrue, setVideo, videoURL, setVideoURL, selectedOption, setSelectedOption, setMetadata}){


    const handleInputChange = (e) => {
        const { name, value } = e.target
        setWhatIsTrue(previousState => ({
            ...previousState,
            [name]: value
        }))
    }

    return(    
    <>
        <div className="border-column center">
            <VideoTrimmer title="Bijsnijden" videoURL={videoURL} setVideoURL={setVideoURL} setVideo={setVideo} setMetadata={setMetadata} minLength={2} maxLength={60}/>
            <p className="grey-text">{videoURL ? 1 : 0}/1</p>
        </div>
        <div className="border-column no-border center">
            <span className="text-small">Kun je de video herkennen aan het geluid?</span>
            <SelectionBar options={["Ja", "Nee"]} customValues={[true, false]} value={selectedOption} setValue={setSelectedOption} unselectable className="white"/>
        </div>
        <div className="border-column no-border center">
            <Check className="icon correct"/><span className="text-small">Geef een juiste omschrijving</span>
            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.firstTrue}
                name="firstTrue"
            />
        </div>
        <div className="border-column no-border center">
            <Exit className="icon wrong"/><span className="text-small">Geef twee foute omschrijvingen</span>
            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.firstFalse}
                name="firstFalse"
            />

            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.secondFalse}
                name="secondFalse"
                />
        </div>
    </>
    )
}