import QwestoBig from "../../assets/img/QwestoBig";
import ComparisonValue from "./ComparisonValue";

export default function Comparison(){
    const rows = [
        {
            subject: "Snel",
            explanation: "Binnen 5 minuten ready om te spelen",
            values: [true, true, false]
        },
        {
            subject: "Makkelijk",
            explanation: "Je hoeft niet creatief of technisch te zijn",
            values: [true, false, false]
        },
        {
            subject: "Persoonlijk",
            explanation: "Gebruik eigen foto's, video's en anekdotes",
            values: [true, false, true]
        },
        {
            subject: "Goedkoop",
            explanation: "Vanaf €0,99 per persoon",
            values: [true, false, true]
        },
        {
            subject: "Iedereen speelt mee",
            explanation: "De organisator kan meedoen",
            values: [true, true, false]
        }
    ]

    return(
        <div className="content-field">
            <div className="content-container min-height-small center no-margin">
                <div className="home-section compare-section">                        
                    <h2 className="text-xlarge">Alles op een rijtje</h2>
                    <div className="compare-scroll-section">
                        <div className="compare-scroll-container">
                            <div className="compare-table">
                                <div className="compare-table-column">
                                    <div className="compare-table-row">
                                        <span className="text-medium">Eigenschappen</span>
                                    </div>
                                    {rows.map((row, index) => (
                                        <div className="compare-table-row" key={index}>
                                            <span className="text-small">{row.subject}</span>
                                            <p className="compare-table-explanation">{row.explanation}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="compare-table-column">
                                    <div className="compare-table-row">
                                        <QwestoBig className="icon big yellow"/>
                                    </div>
                                    {rows.map((row, index) => (
                                        <div className="compare-table-row" key={index}>
                                            <ComparisonValue value={row.values[0]}/>
                                        </div>
                                    ))}
                                </div>
                                <div className="compare-table-column">
                                    <div className="compare-table-row">
                                        <span>Kant en klare pubquiz</span>
                                    </div>
                                    {rows.map((row, index) => (
                                        <div className="compare-table-row" key={index}>
                                            <ComparisonValue value={row.values[1]}/>
                                        </div>
                                    ))}
                                </div>
                                <div className="compare-table-column">
                                    <div className="compare-table-row">
                                        <span>Zelf gemaakte pubquiz</span>
                                    </div>
                                    {rows.map((row, index) => (
                                        <div className="compare-table-row" key={index}>
                                            <ComparisonValue value={row.values[2]}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}