import React, { createContext, useState } from 'react';
import { useMessageContext } from '../hooks/useMessageContext';
import { deactivateQwestoQuestion } from '../utils/pure/groups/deactivateQwestoQuestion';
import {increment, onDisconnect, onValue, ref, set, update} from 'firebase/database';
import { rdb } from '../config/firebaseConfig';
import { useGroupDataContext } from '../hooks/useGroupDataContext';
import { useEffect } from 'react';
import { getActiveQwestoQuestion } from '../utils/pure/getActiveQwestoQuestion';
import { useAuthContext } from '../hooks/useAuthContext';
import { checkIfAnswered } from '../utils/pure/checkIfAnswered';
import { useRef } from 'react';
import {countdownArrays, getRandomCountdownArray} from '../utils/pure/getCountdownArrays';
import PopUp from '../components/layout/PopUp';
import { useGameAudioContext } from '../hooks/useGameAudioContext';

export const GameLogicContext = createContext();

export const GameLogicContextProvider = ({ children }) => {
	const {user} = useAuthContext()
	const {setNewError} = useMessageContext()
	const [countdown, setCountdown] = useState(null)
	const [logicType, setLogicType] = useState(null)
	const [activeQuestion, setActiveQuestion] = useState(null)
	const [isAnswered, setIsAnwered] = useState(false)
	const [answeredProps, setAnsweredProps] = useState(null)
	const [logicIsRunning, setLogicIsRunning] = useState(false)
	const [warningIsVisible, setWarningIsVisible] = useState(false)
	const [gameConnection, setGameConnection] = useState(false)
	const [logID, setLogID] = useState(0)
	const {groupData, noGroupData} = useGroupDataContext() 
	const {isResumed, setDisplayType, playAudio, setAudioSource} = useGameAudioContext()
	const gameDocRef = useRef(groupData.game)
    const isAnsweredRef = useRef(isAnswered)
    const activeQuestionRef = useRef(activeQuestion)
    const cancelRef = useRef(false)

	useEffect(() => {
        gameDocRef.current = groupData.game
    }, [groupData.game])

    useEffect(() => {
        isAnsweredRef.current = isAnswered
    }, [isAnswered])

    useEffect(() => {
        activeQuestionRef.current = activeQuestion
    }, [activeQuestion])

	

	// Set display type
	useEffect(() => {
		setDisplayType(logicType)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logicType])

	// Start question
    useEffect(() => {
        if((logicType === "VIEWER" || logicType === "PHONE_VIEWER") && isResumed && groupData.group?.qwestoStarted && user.uid === groupData.group?.host.uid && !logicIsRunning && 
			(groupData.game?.stage === "INTRODUCTION" || groupData.game?.stage === "COUNTDOWN" || groupData.game?.stage === "ANSWERING")){
				cancelRef.current = false
				startQuestion(isAnsweredRef, gameDocRef, groupData.group, activeQuestionRef, cancelRef)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuestion, isResumed])

	// Stop game logic on unmount
	useEffect(() => {
		return () => {
			stopGameLogic()
		}
	}, [groupData.game?.activeQuestionIndex])

	// Stop game logic on different stage
	useEffect(() => {
		if((groupData.game?.stage === "RESULTS" || groupData.game?.stage === "STATS") && logicIsRunning){
			stopGameLogic()
		}
	}, [groupData.game?.stage, logicIsRunning])

	function stopGameLogic(){
		cancelRef.current = true
		setLogicIsRunning(false)
	}

	// Get active question
	useEffect(() => {
        if(!noGroupData && groupData.group && groupData.qwesto && groupData.game){
            const newActiveQuestion = getActiveQwestoQuestion(groupData.qwesto, groupData.game)
            if(groupData.group?.qwestoStarted && (newActiveQuestion?.id !== activeQuestion?.id || activeQuestion === null)){
                setActiveQuestion(newActiveQuestion)
            }
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData, noGroupData])

	// Check if question is answered
    useEffect(() => {
        if(activeQuestion && groupData.group && groupData.game && groupData.group.qwestoStarted && isResumed &&
			user.uid === groupData.group?.host.uid && groupData.game.activeQuestionIndex !== "NONE" && groupData.game.stage === "ANSWERING"){
            const [isAnswered, newAnsweredProps] = checkIfAnswered(groupData.game, groupData.group, activeQuestion)
            setAnsweredProps(newAnsweredProps);
            setIsAnwered(isAnswered)
        }
    }, [groupData, activeQuestion, user.uid, isResumed])

	// Warning for multiple connections
	useEffect(() => {
		if(logicType === "VIEWER" || logicType === "PHONE_VIEWER"){
			if(groupData.game?.connections[logicType === "VIEWER" ? "VIEWER" : user.uid] > 1){
				setWarningIsVisible(true)
			}else{
				setWarningIsVisible(false)
			}
		}

	}, [groupData.game?.connections, logicType, user.uid])

	// Control connection status
	useEffect(() => {
		if(groupData.group?.qwestoID && logicType){
			const connectionRef = ref(rdb, ".info/connected")
			const playerConnectionRef = ref(rdb, `games/${groupData.group.qwestoID}/connections/${logicType === "VIEWER" ? "VIEWER" : user.uid}`)
			const unsub = onValue(connectionRef, async (snap) => {
				if(snap.val() === true){
					setGameConnection(true)
					console.log("CONNECTED🔒");
					try{
						onDisconnect(playerConnectionRef).set(increment(-1));
						await set(playerConnectionRef, increment(1))
					}catch (error) {
						console.log(error);
					}
				}else{
					setGameConnection(false)
					console.log("NOT CONNECTED🚫");
				}
			});
			async function onUnmount(){
				console.log("DISCONNECTED🔓");
				await set(playerConnectionRef, increment(-1))
				onDisconnect(playerConnectionRef).cancel()
				unsub() 
			}
			return () => onUnmount()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupData.group?.qwestoID, logicType])

	async function startQuestion(isAnsweredRef, gameDocRef, groupDoc, activeQuestionRef, cancelRef){
		setLogID(logID + 1)
		try{
			if(gameDocRef.current.stage === "INTRODUCTION" || gameDocRef.current.stage === "COUNTDOWN"){
				setLogicIsRunning(true)
				if(gameDocRef.current.stage === "INTRODUCTION" && activeQuestionRef.current.type !== "SKIPPED"){ 
					await update(ref(rdb, "games/" + groupDoc.qwestoID), {stage: "INTRODUCTION"}) //vaag
					playAudio("INTRODUCTION_RISER")
					await new Promise(resolve => setTimeout(resolve, 7000))
				}
				await update(ref(rdb, "games/" + groupDoc.qwestoID), {stage: "COUNTDOWN"})
				
				const countdownArray = getRandomCountdownArray(countdownArrays)

				for (let i = 3; i >= 0; i--) {
					if(cancelRef.current){
						setLogicIsRunning(false)
						return
					}
					if(i > 0){
						setAudioSource("COUNTDOWN_VOICE", countdownArray[i - 1])
						playAudio("COUNTDOWN_VOICE")
					}else{
						playAudio("COUNTDOWN_BELL")
					}
					setCountdown(i)
					await new Promise(resolve => setTimeout(resolve, 1000))
				}
				await update(ref(rdb, "games/" + groupDoc.qwestoID), {stage: "ANSWERING"})
				setCountdown(null)   
			}

			if(cancelRef.current){
				setLogicIsRunning(false)
				return
			}

			await new Promise((resolve) => {
				const checkIsAnswered = () => {
					if(isAnsweredRef.current){
						resolve();
					}else{
						if(cancelRef.current){
							return
						}
						setTimeout(checkIsAnswered, 100);
					}
				};
				checkIsAnswered();
			});

			if(cancelRef.current){
				setLogicIsRunning(false)
				return
			}
			
			await deactivateQwestoQuestion(activeQuestionRef.current, gameDocRef.current, groupDoc)
			setLogicIsRunning(false)
		}catch(error){
			setNewError("Er is iets misgegaan")
			setLogicIsRunning(false)
			console.log(error);
		}
	}

	return(
		<GameLogicContext.Provider value={{activeQuestion, countdown, isResumed, answeredProps, gameConnection, setLogicType}}>
			{(logicType === "VIEWER") &&
				<PopUp visible={warningIsVisible} setVisible={setWarningIsVisible} unclosable>
					<>
						<div className="border-column center">
							<h2>Let op</h2>
							<p>het lijkt erop dat je de Qwesto op meerdere vensters weergeeft, het is beter als je de Qwesto op 1 venster weergeeft.</p>
						</div>
						<div className="border-column">
							<button className="btn black" onClick={() => setWarningIsVisible(false)}>Duidelijk</button>
						</div>
					</>
				</PopUp>
			}
			{children}
		</GameLogicContext.Provider>
	);
};