import { useLocation, useNavigate } from "react-router-dom"
import SignInForm from "../../components/authforms/SignInForm"
import Footer from "../../components/layout/Footer"
import SEO from "../../components/utils/SEO"

export default function SignIn() {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    return(
        <>
        <SEO title={"Log in"} description={"Log in om een Qwesto te maken of spelen."}/>
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container min-height">
                    <div className="column center">
                        <SignInForm redirect={redirect}/>
                        <p className="grey-text">of</p>
                        <button className="btn black round" onClick={() => navigate(`/sign-up${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ""}`)}>Aanmelden</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>  
        </>
    )
};