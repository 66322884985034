export default function ArrowRight(props) {
    return (
    <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
        <g id="Arrow / Arrow_Right_MD">
        <path
            id="Vector"
            d="M5 12H19M19 12L13 6M19 12L13 18"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        </g>
    </g>
    </svg>
    );
  }