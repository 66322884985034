import { FilterControls } from '../FilterControls';
import { useEffect, useState } from "react"
import Loader from "../../../components/loaders/Loader"
import { structureFilterObject } from "../../../data/constants"
import "../Manager.css"
import { useDocument } from '../../../hooks/useDocument';
import { StructureCreator } from './StructureCreator';
import { StructureList } from './StructureList';
import { filterUsingLabels } from '../../../utils/pure/filterUsingLabels';


export default function StructureManager(){
    const {document: structures, noData: noStructuresData} = useDocument("shop", "structures")
    const [filteredStructures, setFilteredStructures] = useState()
    const [filters, setFilters] = useState(structureFilterObject)

    useEffect(() => {
        if(structures){
            const identifiedStructures = []
            let structureID = 0
            structures.list.forEach((structure) => {
                structure.id = structureID
                structureID++
                identifiedStructures.push(structure)
            })
            setFilteredStructures(filterUsingLabels(structures.list, filters, true, true))
        }
    }, [filters, structures])

    if(noStructuresData){
        return("kaka")
    }
    
    if(!structures && !filteredStructures){
        return(
            <Loader/>
        )
    }

    return(
        <div className="content-page full">
            <div className="content-field">
                <div className="manager-controls">
                    <FilterControls filters={filters} setFilters={setFilters}/>
                    <div className="row stay">
                        <h3>{filteredStructures && filteredStructures.length} structuren gevonden</h3>
                        <StructureCreator structures={structures.list}/>
                    </div>
                </div>
                <StructureList currentStructures={structures} filteredStructures={filteredStructures}/>
            </div>
        </div>
    )
};