import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../components/loaders/Loader";
import Redirect from "../../../components/layout/Redirect";
import GroupNavigation from "./GroupNavigation";
import GroupInfo from "./GroupInfo";
import { useGroupDataContext } from "../../../hooks/useGroupDataContext";
import { getGroupProgress } from "../../../utils/pure/getGroupProgress";
import SEO from "../../../components/utils/SEO";
import { useMemo } from "react";
import BackButton from "../../../components/input/BackButton";

export default function GroupDetails(){
    const {qwestoID} = useParams()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "GROUP_AND_INTAKE_DATA",
        qwestoID: qwestoID
    }), [qwestoID]))
    const [groupProgress, setGroupProgress] = useState(0)
    const [commonLength, setCommonLength] = useState(null)

    useEffect(() => {
        if(groupData.group){
            if(groupData.group.groupIsConfirmed && groupData.intake && groupData.intake.indexes){
                const {commonQuestionsLength, progress} = getGroupProgress(groupData.group, groupData.intake)
                setCommonLength(commonQuestionsLength)
                setGroupProgress(progress)
            }else{
                setGroupProgress(0)
            }
        }
    }, [groupData.group, groupData.intake])

    if(noGroupData){
        return(
            <Redirect to="/groups" message="Deze Qwesto bestaat niet." action="Terug" arrow="left"/>
        )
    }

    if(!groupData.group){
        return(
            <Loader />
        ) 
    }

    if(groupData.group && groupData.group.groupIsInitialized && groupData.group.groupIsConfirmed && !groupData.intake){
        return(
            <Loader />
        ) 
    }


    return(
    <div className="content-page">
        <SEO title={groupData.group.groupName} description={"Bekijk alle details over je groep en begin met spelen."}/>
        <BackButton sticky responsive to="/groups"/>
        <GroupNavigation groupProgress={groupProgress} groupDoc={groupData.group} intakeData={groupData.intake}/>
        <GroupInfo groupProgress={groupProgress} commonLength={commonLength} groupDoc={groupData.group} intakeData={groupData.intake}/>
    </div>
    )
};
