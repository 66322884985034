import { CorrectPlayers } from "./CorrectPlayers"
import { QuestionBar } from "./QuestionBar"

export function MostLikelyViewer({activeQuestion, gameDoc, groupDoc}){
    return(
        <>
            <QuestionBar question={activeQuestion.question} slideUp={gameDoc.stage === "RESULTS"}/>
            {gameDoc.stage === "RESULTS" &&
                <div className="correct-answer">
                    {gameDoc.correctAnswer === "EMPTY" ?
                        <span className="viewer-message">Niet genoeg antwoorden...</span>
                    :
                        <CorrectPlayers groupDoc={groupDoc} correctPlayers={gameDoc.correctAnswer} check/>
                    }
                </div>
            }
        </>
    )
}