import { useState, useEffect, useRef } from "react"
import { db } from "../config/firebaseConfig"
import { collection, onSnapshot, query, where } from "firebase/firestore"

export const useCollection = (collectionName, _q) => {
    const [documents, setDocuments] = useState(null)
    const [noData, setNoData] = useState(false)
    const q = useRef(_q).current

    useEffect(() => {
        let ref = collection(db, collectionName)
        if(q){
            ref = query(ref, where(...q))
        }
        
        const unsub = onSnapshot(ref, (snapshot) => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id})
            })
            if(results.length > 0){
                setDocuments(results)
                setNoData(false)
            }else{
                setNoData(true)
            }
        }, error => {
            console.log(error);
            setNoData(true)
        })
        return () => unsub()

    }, [collectionName, q])
    return { documents, noData }
}