import "./DescriptionRow.css"
import { useEffect, useState } from "react"

export default function DescriptionRow({description, descriptionTitle, subject, number, swap}){
    const [layoutChange, setLayoutChange] = useState(window.innerWidth < 1000)

    useEffect(() => {
        const handleResize = () => {
            setLayoutChange(window.innerWidth < 1000);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); 

    return(
        <div className="description-row">
            {(swap || layoutChange) &&
                <div className="description-row-item">
                    {subject}
                </div>
            }
            <div className="description-row-item flex-start">
                {number &&
                    <span className="text-xlarge">{number}</span>
                }
                <div className="description-row-column">
                    <h2 className="text-xxlarge">{descriptionTitle}</h2>
                    <p className="text-small grey-text">{description}</p>
                </div>
            </div>
            {!swap && !layoutChange &&
                <div className="description-row-item">
                    {subject}
                </div>
            }
        </div>
    )
}
