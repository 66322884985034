import React, { useState } from 'react'
import { useSignUp } from '../../hooks/useSignUp'
import { NavLink } from 'react-router-dom'
import QwestoSmall from '../../assets/img/QwestoSmall'
import ImageInput from '../input/ImageInput'
import SelectionBar from '../input/SelectionBar'
import ArrowRight from '../../assets/img/ArrowRight'
import Stepper from '../layout/Stepper'
import { useMessageContext } from '../../hooks/useMessageContext'

export default function SignUpForm({redirect, popUp}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [displayName, setDisplayName] = useState("")
    const [photoURL, setPhotoURL] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [gender, setGender] = useState(null)
    const [stepIndex, setStepIndex] = useState(1)
    const { isPending, trySignUp } = useSignUp()
    const { setNewError } = useMessageContext()
    const to = redirect ? redirect : null

    function handleNextStep(e){
        e.preventDefault()
        switch(stepIndex){
            case 4:
                if(gender !== null){
                    setStepIndex((currentIndex) => currentIndex + 1)
                }else{
                    setNewError("Maak een keuze")
                }
                break;
            case 5:
                if(photo){
                    setStepIndex((currentIndex) => currentIndex + 1)
                }else{
                    setNewError("Kies een foto")
                }
                break;
            case steps.length:
                trySignUp(email, password, displayName, photo, gender, to)
                break;
            default:
                setStepIndex((currentIndex) => currentIndex + 1)
                break;
        }
    }

    const onNextButton = <button className="btn" type="submit">Volgende<ArrowRight className="icon"/></button>
    const steps = [
        {
            title: "Wat is je voornaam?",
            content: 
            <input type="text" id="displayName" 
                required
                maxLength={20}
                minLength={3}
                onChange={(e) => setDisplayName(e.target.value.replace(/[^a-z]/gi, ''))}
                value={displayName} 
            />,
            button: onNextButton
        },
        {
            title: "Wat is je email?",
            content:
            <input type="email" id="signupemail"
                placeholder="Email"
                autoComplete="username"
                required
                maxLength={256}
                onChange={(e) => setEmail(e.target.value)}
                value={email} 
            />,
            button: onNextButton
        },
        {
            title: "Verzin een wachtwoord",
            content: 
            <input type="password" id='signuppassword'
                autoComplete="new-password"
                required 
                minLength={6}
                onChange={(e) => setPassword(e.target.value)}
                value={password} 
            />,
            button: onNextButton
        },
        {
            title: "Wat is je geslacht?",
            content: <SelectionBar options={["Man", "Vrouw", "Anders"]} value={gender} setValue={setGender} round className="white" unselectable/>,
            button: onNextButton
        },
        {
            title: "Kies of maak een profielfoto",
            content:
            <div className="column center">
                <ImageInput avatar={true} aspect={1} shape="round" title="Profielfoto" message=" " setPhoto={setPhoto} photoURL={photoURL} setPhotoURL={setPhotoURL} required/>
                <p className="grey-text">{photo ? 1 : 0}/1</p>
            </div>,
            button: onNextButton
        },
        {
            title: "Ben je er klaar voor?",
            content:
            <div className="row stay flex-start">
                <input type="checkbox" id="accept" required/>
                <p>Ik ga akkoord met de <NavLink to="/terms" className="agr"><i>algemene voorwaarden</i></NavLink></p> 
            </div>,
            button: <button className="btn-gr round no-grow" disabled={isPending} type="submit">{isPending ? <QwestoSmall className="load"/> : "Aanmelden"}</button>
        }
    ]

    return(
        <form className={`auth-form ${popUp ? "auth-pop-up" : ""}`} onSubmit={handleNextStep}>
            {!popUp &&
                <div className="border-column center">
                    <h2>Aanmelden</h2>
                </div>
            }
            <Stepper steps={steps} currentStepIndex={stepIndex} setCurrentStepIndex={setStepIndex} overwriteHandleNextStep={handleNextStep}>
                {steps[stepIndex - 1].content}
            </Stepper>            
        </form>
    )
};