import React from "react";
import { useState } from 'react';
import PopUp from '../../../components/layout/PopUp';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../config/firebaseConfig';
import { useMessageContext } from '../../../hooks/useMessageContext';
import { DEFAULT_ARTICLE_OBJECT } from "../../../data/constants";
import { SectionCreator } from "./SectionCreator";
import ImageInput from "../../../components/input/ImageInput";
import ArticleDisplayer from "../../blog/ArticleDisplayer";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export function ArticleCreator({currentList, currentArticle, edit}){
    const [articleOpen, setArticleOpen] = useState(false)
    const [newArticleData, setNewArticleData] = useState(currentArticle ? currentArticle : DEFAULT_ARTICLE_OBJECT)
    const [articlePhoto, setArticlePhoto] = useState(null)
    const [preview, setPreview] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    function handleNewQuestionData(key, value){
        setNewArticleData((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }
console.log(currentList);
console.log(newArticleData);

    function handleAddSection(){
        setNewArticleData((prevState) => ({
            ...prevState,
            sections: [...prevState.sections, {type: "text"}]
        }))
    }

    function handleEdit(){
        setNewArticleData(currentArticle ? currentArticle : DEFAULT_ARTICLE_OBJECT)
        setArticleOpen(true)
    }

    async function handleSaveArticle(e){
        e.preventDefault()
        if(!currentList){
            return
        }
        if(!newArticleData.pathID || !newArticleData.title || !newArticleData.description || !newArticleData.sections){
            setNewError("Vul alle velden in")
            return
        }
        setIsSaving(true)
        const listRef = doc(db, "content", currentList.id)
        const uploadPath = `utilities/${newArticleData.pathID}`
        const finalArticleData = newArticleData
        if(!edit){
            finalArticleData.id = currentList.list.length
        }
        try{
            if(articlePhoto){
                const uploadRef = ref(storage, uploadPath)
                await uploadBytes(uploadRef, articlePhoto)
                const promisedURL = await getDownloadURL(uploadRef)
                finalArticleData.photoURL = promisedURL
            }
            if(edit){
                currentList.list[newArticleData.id] = finalArticleData
                await updateDoc(listRef, {
                    list: currentList.list
                })
            }else{
                await updateDoc(listRef, {
                    list: arrayUnion(finalArticleData)
                })
            }
            setNewInfo("Succes a mattie")
            setArticleOpen(false)
            setIsSaving(false)
        }catch(error){
            console.log(error);
            setNewError("Er ging iets fout")
            setIsSaving(false)
        }
    }

    return(
        <>
            <div className="question-creator">
                <button className="btn round" onClick={handleEdit}>{edit ? "bewerken" : "+"}</button>
            </div>
            <PopUp title={"Add or edit article"} visible={articleOpen} setVisible={setArticleOpen} large unclosable>
                <button className="btn" onClick={() => {setPreview(!preview)}}>{preview ? "close preview" : "preview"}</button>
                {preview ?
                    <div className="column no-gap">
                        <ArticleDisplayer article={newArticleData} preview/>
                    </div>
                :
                    <form className="column gap">
                        <label>foto
                            <ImageInput aspect={16/9} title="Artikel foto" photoURL={newArticleData.photoURL} setPhotoURL={(url) => {handleNewQuestionData("photoURL", url)}} setPhoto={setArticlePhoto} fileType="image/webp"/>
                        </label>
                        <div className="row">
                            <label>path id
                                <input type="text" id="pathid"
                                    required
                                    value={newArticleData.pathID}
                                    onChange={(e) => {handleNewQuestionData("pathID", e.target.value)}}
                                />
                            </label>
                            <label className="no-grow">date
                                <input type="text" id="date"
                                    value={newArticleData.date}
                                    onChange={(e) => {handleNewQuestionData("date", e.target.value)}}
                                />
                            </label>
                        </div>
                        <label>title
                            <input type="text" id="title"
                                required
                                value={newArticleData.title}
                                onChange={(e) => {handleNewQuestionData("title", e.target.value)}}
                            />
                        </label>
                        <label>meta description
                            <input type="text" id="description"
                                required
                                value={newArticleData.description}
                                onChange={(e) => {handleNewQuestionData("description", e.target.value)}}
                            />
                        </label>
                        <div className="column">
                            <label>sections</label>
                            {newArticleData.sections.map((section, index) => (
                                <React.Fragment key={index}>
                                    <SectionCreator keyValue={index} section={section} newArticleData={newArticleData} setNewArticleData={setNewArticleData} sectionIndex={index} key={index}/>
                                </React.Fragment>
                            ))}
                        </div>
                        <button className="btn no-grow" type="button" onClick={handleAddSection}>+ new section</button>
                        <div className="row stay">
                            <button className="btn black" type="button" onClick={() => {setArticleOpen(false)}}>Annuleren</button>
                            <button className="btn-gr" type="submit" disabled={isSaving} onClick={(e) => {handleSaveArticle(e)}}>{isSaving ? "Werkende..." : "Opslaan"}</button>
                        </div>
                    </form>
                }
            </PopUp>
        </>
    )
}