import React, { useEffect, useMemo, useRef, useState } from "react"

export default function ContentFieldBanner({src, sticky, children}){
    const [position, setPosition] = useState(0);
    const parentRef = useRef(null);
    const MemoizedChildren = useMemo(() => {
        return <>{children}</>;
    }, [children]);

    useEffect(() => {
        const handleScroll = () => {
            if(parentRef.current){
                const parentRect = parentRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                const max = 50
                const newPosition = parentRect.top / (windowHeight / 100)
                if(newPosition > max){
                    setPosition(max);
                }else if(newPosition < 0 - max ){
                    setPosition(0 - max)
                }else{
                    setPosition(newPosition);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    

    return(
        <div className="content-field banner" ref={parentRef}>
            <img src={src} alt="" className={`content-field-banner-img ${sticky ? "sticky" : ""}`}
                style={{
                    top: sticky ? position : "auto",
                }}
            />
            {MemoizedChildren}
        </div>
    )
}
