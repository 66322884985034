import Check from "../../../assets/img/Check"
import Exit from "../../../assets/img/Exit"

export function WhatIsTrue({whatIsTrue, setWhatIsTrue}){

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setWhatIsTrue(previousState => ({
            ...previousState,
            [name]: value
        }))
    }

    return(    
    <>
        <div className="border-column no-padding no-border center">
            <Check className="icon correct"/><h4>Geef het juiste antwoord</h4>
            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.firstTrue}
                name="firstTrue"
            />
        </div>
        <div className="border-column no-border center">
            <Exit className="icon wrong"/><h4>Geef twee foute antwoorden</h4>
            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.firstFalse}
                name="firstFalse"
            />

            <input type="text"
                required 
                onChange={handleInputChange}
                maxLength={70}
                value={whatIsTrue.secondFalse}
                name="secondFalse"
                />
        </div>
    </>
    )
}