export default function ArrowUp(props) {
    return (
    <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <g id="Arrow / Arrow_Up_MD">
        <path
        id="Vector"
        d="M12 19V5M12 5L6 11M12 5L18 11"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </g>
    </svg>
    );
  }