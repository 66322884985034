import Check from "../../../assets/img/Check"
import Exit from "../../../assets/img/Exit"
import Time from "../../../assets/img/Time"

export function Result({type, message, gameDoc, uid}){
    return(
        <>
            {type === "wrong" &&
                <div className="result wrong">
                    <Exit className="result-icon"/>
                    <h1>{message}</h1>
                    <h3>+{gameDoc.pointsEarned[uid]}</h3>
                </div>
            }
            {type === "correct" &&
                <div className="result correct">
                    <Check className="result-icon"/>
                    <h1>{message}</h1>
                    <h3>+{gameDoc.pointsEarned[uid]}</h3>
                </div>
            }
            {type === "slow" &&
                <div className="result slow">
                    <Time className="result-icon"/>
                    <h1>{message}</h1>
                    <h3>+{gameDoc.pointsEarned[uid]}</h3>
                </div>
            }
        </>
    )
}