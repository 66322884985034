import { useEffect, useState } from 'react';
import User from '../../../assets/img/User';
import Status from '../../../components/layout/Status';
import { getPlayersReady } from '../../../utils/pure/getPlayersReady';

export function Lobby({groupData}){
    const [playersReady, setPlayersReady] = useState([])

    useEffect(() => {
        if(groupData.game?.connections){
            setPlayersReady(getPlayersReady(groupData.game.connections))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData.game?.connections])

    return( 
        <div className="viewer-container">
            <div className="content-container max-width center no-grow">
                <span className="viewer-text">{groupData.group.groupName}</span>
                <span className="viewer-text xs">Wachtruimte</span>
            </div>
            <div className="content-container">
                <div className="column gap center">

                    <div className="row stay no-gap">
                        <User className="icon"/>
                        <span className="viewer-text xs">{playersReady.length}</span>
                    </div>
                    <div className="player-list center">
                        {groupData.group.players.map(player => {
                            if(playersReady.includes(player.uid)){
                                return <div key={player.uid} className="player-card">
                                            {groupData.group.host.uid === player.uid && <span className="player-card-badge">Host</span>}
                                                <img src={player.photoURL} className="avatar" alt="" />
                                            <div className="column">
                                                <span className="viewer-text xs">{player.nickName}</span>
                                                <Status dot="green" message="Ready"/>
                                            </div>
                                        </div>;
                            }
                            return <div key={player.uid} className="player-card grey">
                                        {groupData.group.host.uid === player.uid && <p className="player-card-badge">Host</p>}
                                            <img src={player.photoURL} className="avatar" alt="" />
                                        <div className="column">
                                            <span className="viewer-text xs">{player.nickName}</span>
                                            <Status dot="grey" message="Niet ready"/>
                                        </div>
                                    </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}