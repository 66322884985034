import { useEffect } from "react";
import { ref, set} from 'firebase/database';
import { rdb } from "../../../config/firebaseConfig";
import { QuestionBar } from "./QuestionBar";
import { CorrectTags } from "./CorrectTags";

export function PhotoRevealViewer({activeQuestion, gameDoc, groupDoc}){
    const correctAnswer = activeQuestion.players

    useEffect(() => {
        set(ref(rdb, "games/" + groupDoc.qwestoID + "/correctAnswer"), correctAnswer)
    }, [correctAnswer, groupDoc.qwestoID])

    return(
        <>
            <QuestionBar question="Welke speler(s) zie je op de foto?" slideUp/> 
            <div className="correct-answer">
                <div className="photo-blocker"/>
                <div className={gameDoc.stage === "ANSWERING" ? "photo-reveal active" : "photo-reveal"}>
                    <img src={activeQuestion.photoURL} alt="" className="photo-reveal-img"/>
                    <div className="photo-reveal-label">
                        {gameDoc.stage === "RESULTS" &&
                            <CorrectTags groupDoc={groupDoc} correctPlayers={correctAnswer}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}