import { useAuthContext } from "../../hooks/useAuthContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import QwestoSmall from "../../assets/img/QwestoSmall";
import { useUserContext } from "../../hooks/useUserContext";
import { usePurchase } from "../../hooks/usePurchase";
import { useProductContext } from "../../hooks/useProductContext";
import { getTotalProductCost } from "../../utils/pure/getTotalProductCost";
import Pencil from "../../assets/img/Pencil";
import { useNavigationBarContext } from "../../hooks/useNavigationBarContext";

export default function ProductForm({product}){
    const {user} = useAuthContext()
    const {userDoc} = useUserContext()
    const {filters} = useProductContext()
    const {setFilterVisible} = useNavigationBarContext()
    const [groupName, setGroupName] = useState("")
    const [nickName, setNickName] = useState("")
    const location = useLocation()
    const navigate = useNavigate()
    const { isPending, tryPurchase } = usePurchase()
    const price = getTotalProductCost(product, filters.players)

    async function handlePurchase(e){
        e.preventDefault()
        tryPurchase(product, filters.players, user, userDoc, groupName, nickName, filters)
    }

    return(
        <form onSubmit={(e) => {handlePurchase(e)}} className="product-form">
            <div className={`login-overlay ${user ? "" : "active"}`}>
                <div className="login-overlay-content gap">
                    <h2 className="grey-text">Je bent er bijna!</h2>
                    <div className="row stay gap">
                        <button className="btn black no-grow" type="button" onClick={() => {navigate(`/login?redirect=${location.pathname}`)}}>Log in</button>
                        <p className="grey-text">of</p>
                        <button className="btn-gr no-grow" type="button" onClick={() => {navigate(`/sign-up?redirect=${location.pathname}`)}}>Meld je aan</button>
                    </div>
                    <p>om verder te gaan</p>
                </div>
                <div className="border-column no-border center">
                    <h2 className="product-price grey-text">{price}</h2>
                    <div className="column center">
                        <h3>{product.name} - {filters.players}p</h3>
                        <button className="btn text small" type="button" onClick={() => setFilterVisible(true)}><Pencil className="icon"/></button>
                    </div>
                </div>
                <div className="border-column no-border">
                    <label>Groepsnaam
                        <input type="text" id="groupname" required minLength={2} maxLength={20} onChange={(e) => setGroupName(e.target.value)} value={groupName}/>
                    </label>
                </div>
                <div className="border-column no-border">
                    <label>Bijnaam
                        <input type="text" id="nickname" required minLength={2} maxLength={15} onChange={(e) => setNickName(e.target.value)} value={nickName}/>
                        <p className="grey-text">Gebruik een naam waar je medespelers je aan kunnen herkennen</p>
                    </label>
                </div>
                <div className="border-column">
                    <div className="row stay no-grow">
                        <input type="checkbox" required className="no-grow" id="terms"/>
                        <p>Ik ga akkoord met de <NavLink to="/terms" className="agr"><i>algemene voorwaarden</i></NavLink>{!filters.age.some(a => a < 18) && "en ben 18 jaar of ouder"}</p>
                    </div>
                </div>    
                <div className="border-column">
                    {!isPending ? <button className="btn-gr product-price" type="submit">Kopen voor {price}</button> : <button className="btn-gr" disabled><QwestoSmall className="load"/></button> }        
                </div> 
            </div>
        </form>
    )
}