export const BLOG_POSTS = [
        {
            "id": 6,
            "date": "1 maart 2024",
            "pathID": "gebruik-schermen",
            "sections": [
                {
                    "text": "Leuk dat je Qwesto gaat spelen! Nog niet helemaal duidelijk hoe je op een groot scherm kunt spelen? Geen probleem! Hier leggen we bijna alle mogelijkheden uit.",
                    "type": "text"
                },
                {
                    "text": "Voordat je begint met spelen, kun je als host zijnde nog aanpassen hoe je de Qwesto wilt spelen (bij de instellingen op de groepspagina). Als je ervoor kiest om op een groot scherm te spelen kun je dit artikel gebruiken. ",
                    "title": "Introductie",
                    "type": "text"
                },
                {
                    "text": "Om te beginnen moet de host op twee verschillende apparaten inloggen: ",
                    "type": "text",
                    "title": "Stap 1 "
                },
                {
                    "type": "list",
                    "items": [
                        {
                            "title": "Telefoon",
                            "text": "Hiermee speelt de host de Qwesto, zoals alle andere spelers dat ook doen."
                        },
                        {
                            "text": "Hiermee weergeeft de host de Qwesto.",
                            "title": "Laptop/tablet"
                        }
                    ]
                },
                {
                    "text": "De laptop kun je aansluiten op een TV of beamer via een HDMI-kabel. Je kunt de Qwesto ook weergeven op de laptop of tablet zelf, zonder er nog een extra groot scherm op aan te sluiten. \n",
                    "type": "text"
                },
                {
                    "text": "Als het aansluiten is gelukt moet je als host met 'het grote scherm' op 'Weergeven' klikken in de groepspagina. Hiermee open je de Qwesto-weergever en ben je klaar met het 'grote scherm'. Vervolgens kunnen jij en de rest van de groep met ieders telefoon op 'Spelen' klikken. ",
                    "title": "Stap 2",
                    "type": "text"
                },
                {
                    "text": "Nu ben je klaar om te beginnen met je Qwesto, veel plezier!",
                    "type": "text"
                },
                {
                    "text": "Lukt het gebruiken van een groot scherm niet? Dan kun je altijd <a href=\"/contact\">een vraag stellen</a> of ervoor kiezen om enkel met telefoons te spelen. Dit kan de host instellen in de groepspagina.",
                    "type": "text",
                    "title": "Problemen?"
                }
            ],
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fgebruik-schermen?alt=media&token=7d22f591-8f89-4726-ac51-5168ae1552c7",
            "description": "We leggen je stap voor stap uit hoe je een groot scherm kan gebruiken voor een Qwesto.",
            "title": "Hoe gebruik ik een groot scherm voor een Qwesto?"
        },
        {
            "description": "Laat familieverhalen herleven met onze quizzen",
            "sections": [
                {
                    "type": "text",
                    "text": "Families vormen de kern van ons leven. Ze zijn de bron van liefde, steun en onvergetelijke momenten. Maar hoe goed ken je eigenlijk je familieleden? Welke verhalen, interesses en passies verbergen zich achter die vertrouwde glimlach? Dit artikel onthult hoe je de banden met je familie kunt versterken door middel van iets dat niet alleen vermakelijk is, maar ook diepgaand en verbindend: familiequizzen. Laten we ontdekken hoe deze leuke activiteit niet alleen lachbuien kan opleveren, maar ook een dieper begrip en een hechtere band tussen jou en je familie."
                },
                {
                    "title": "Het belang van familierelaties",
                    "text": "Familierelaties behoren tot de meest waardevolle en duurzame relaties in ons leven. Ze spelen een cruciale rol in ons emotionele welzijn en kunnen aanzienlijk bijdragen aan ons geluk en onze gemoedsrust. Hieronder bekijken we waarom het opbouwen van sterke familierelaties zo essentieel is en hoe quizzen kunnen fungeren als een effectief hulpmiddel om deze banden te versterken.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Ten eerste, bieden sterke familiebanden een gevoel van veiligheid en geborgenheid. In een wereld vol uitdagingen en onzekerheden, fungeert onze familie als een rots in de branding. Ze zijn er voor ons in goede en slechte tijden, en weten vaak precies wat we nodig hebben om ons te troosten of aan te moedigen."
                },
                {
                    "text": "Bovendien, verminderen sterke familiebanden stress. Familieleden kunnen een belangrijke bron van emotionele steun zijn wanneer we te maken hebben met moeilijkheden in het leven. Het hebben van mensen die naar je luisteren, je begrijpen en met wie je kunt praten, kan een enorm verschil maken in hoe we stressvolle situaties benaderen en verwerken.",
                    "type": "text"
                },
                {
                    "text": "Familiequizzen brengen een unieke combinatie van plezier en educatie met zich mee, waardoor ze een uitstekende manier zijn om te verbinden en te lachen met je naasten. Het zijn geen gewone spelletjes; ze bieden een schat aan leermomenten en leuke ervaringen voor alle generaties.",
                    "title": "Een leuke manier om te verbinden",
                    "type": "text"
                },
                {
                    "text": "Een van de opvallende voordelen van familiequizzen is dat ze educatief zijn zonder saai te worden. Terwijl je geniet van het spel, kun je ook veel nieuwe feiten en weetjes ontdekken. Dit educatieve aspect kan vooral waardevol zijn voor jongere gezinsleden, omdat het hun nieuwsgierigheid stimuleert en hun kennis vergroot op een leuke en interactieve manier.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Maar quizzen zijn niet alleen voor de jongeren. Ze dagen ook de oudere generaties uit met vragen over geschiedenis, popcultuur en persoonlijke anekdotes. Dit creëert een gevoel van gelijkheid tussen de generaties, waarbij iedereen de kans krijgt om te schitteren op basis van hun eigen kennisgebieden."
                },
                {
                    "text": "Doordat gezinsleden tegen elkaar strijden wie de rest het beste kent, leer je niet alleen nieuwe dingen over elkaar, maar herbeleeft ook samen de mooiste momenten uit jullie leven.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Tijdens familiequizzen kunnen er vaak verrassende ontdekkingen naar boven komen. Het is verbazingwekkend om te zien hoe goed je je eigen familie kent, en soms kunnen ze je positief verrassen. Hier zijn enkele manieren waarop quizzen kunnen leiden tot onverwachte ontdekkingen:",
                    "title": "Wat je niet wist over je familie"
                },
                {
                    "items": [
                        {
                            "title": "",
                            "text": "Verborgen talenten: Misschien ontdek je dat je zus een verborgen talent heeft voor het oplossen van wiskundige raadsels, of dat je neefje een encyclopedische kennis heeft van dinosaurussen. Quizzen kunnen deze verborgen talenten aan het licht brengen en familieleden de kans geven om te schitteren op manieren die je nooit had verwacht."
                        },
                        {
                            "title": "",
                            "text": "Diepgaande interesses: Tijdens quizzen kunnen familieleden enthousiast praten over onderwerpen waarvan je niet wist dat ze er zo gepassioneerd over waren. Misschien heeft je oom een diepgaande interesse in geschiedenis, wat leidt tot boeiende gesprekken over historische gebeurtenissen na de quiz."
                        },
                        {
                            "text": "Grappige anekdotes: Familieverhalen en anekdotes kunnen naar voren komen tijdens quizzen, vaak met veel gelach tot gevolg. Misschien herinnert je grootmoeder zich een komische gebeurtenis uit haar jeugd die niemand eerder heeft gehoord. Dit soort gedeelde verhalen versterken de familieband.",
                            "title": ""
                        }
                    ],
                    "type": "list"
                },
                {
                    "title": "Tips en tricks",
                    "text": "Het organiseren van een familiequiz is een hele taak en vaak ook een grote drempel om aan te beginnen. Denk na over de hapjes en drankjes tijdens quiz. Ook is de sfeerbelichting belangrijk. ",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Bent u klaar om de verhalen die uw familie uniek maken te ontdekken en te delen? Begin vandaag nog met het bouwen van diepere connecties door middel van spel en plezier. Laat ons u helpen om een onvergetelijke ervaring te creëren die generaties lang gekoesterd zal worden. Want bij Qwesto is elk antwoord een deur naar een nieuw verhaal. Maak nu uw familiebanden sterker dan ooit en onthoud: bloed is dikker dan water. "
                }
            ],
            "title": "De beste manier om je familie beter te leren kennen",
            "pathID": "de-beste-manier-om-je-familie-beter-te-leren-kennen",
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fde-beste-manier-om-je-familie-beter-te-leren-kennen?alt=media&token=34bbf5b2-f1c1-46ce-8ee1-b6868c842585",
            "id": 5,
            "date": "13 februari 2024"
        },
        {
            "title": "De oorsprong en evolutie van de pubquiz",
            "sections": [
                {
                    "text": "De pubquiz is een geliefde vorm van entertainment die zijn oorsprong heeft in het bruisende nachtleven van Britse pubs. In dit artikel duiken we dieper in de fascinerende geschiedenis en evolutie van de pubquiz, van zijn nederige begin tot de moderne en digitale revolutie.",
                    "type": "text"
                },
                {
                    "text": "Het begon allemaal in het Verenigd Koninkrijk, waar lokale kroegen in de jaren '70 op zoek waren naar innovatieve manieren om klanten aan te trekken. Te midden van traditionele pubspellen zoals darts en poolbiljart, ontstond het concept van de pubquiz. Deze slimme mix van vermaak en intellectuele uitdaging bleek een schot in de roos te zijn.",
                    "title": "Het ontstaan van de pubquiz ",
                    "type": "text"
                },
                {
                    "text": "Het idee achter de pubquiz was simpel maar doeltreffend: stel een reeks vragen over uiteenlopende onderwerpen op, van geschiedenis en aardrijkskunde tot popcultuur en wetenschap, en laat teams van deelnemers hun kennis testen en strijden om de hoogste eer. De pubquiz bood niet alleen een vermakelijke avond uit, maar gaf mensen ook de kans om hun kennis te vergroten en nieuwe vrienden te maken.",
                    "type": "text"
                },
                {
                    "text": "Wat begon als een lokale Britse sensatie, groeide al snel uit tot een wereldwijd fenomeen. In pubs over de hele wereld werden pubquizzen een reguliere activiteit, waar vrienden en kennissen samenkomen om hun hersenen te kraken en plezier te hebben.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "title": "Tijden van de coronacrisis ",
                    "text": "Tijdens de uitdagende periode van de coronacrisis onderging de pubquiz een opmerkelijke transformatie. Fysieke bijeenkomsten werden vervangen door virtuele evenementen, mogelijk gemaakt door technologie en videoconferenties. Dit bleek een welkome afleiding te zijn voor mensen over de hele wereld, die gedwongen waren hun sociale activiteiten te beperken en afstand te houden van anderen."
                },
                {
                    "type": "text",
                    "text": "De overgang naar online pubquizzen bood talloze voordelen. Ten eerste gaf het mensen de mogelijkheid om veilig en comfortabel vanuit hun eigen woonkamers deel te nemen, zonder zich zorgen te hoeven maken over de gezondheidsrisico's van fysieke bijeenkomsten. Dit opende de deur voor deelnemers van verschillende locaties, zelfs vanuit verschillende landen, om samen te komen en deel te nemen aan quizzen."
                },
                {
                    "type": "text",
                    "text": "Bovendien werden deze virtuele pubquizzen vaak gebruikt als sociale verbindingsmiddelen. Mensen konden niet alleen hun kennis testen, maar ook contact houden met vrienden, familieleden en collega's die ze misschien al een tijdje niet hadden gezien. Dit bracht een gevoel van verbondenheid en normaliteit in een tijd waarin die waardevoller was dan ooit."
                },
                {
                    "text": "Pubquizzen zijn flexibel en passen perfect bij verschillende gelegenheden. Denk bijvoorbeeld aan een informele avond met vrienden in je favoriete café, waar je kunt genieten van een gezellige sfeer en een vermakelijke quiz. Of je kunt een pubquiz organiseren als onderdeel van een bedrijfsevenement om teambuilding te bevorderen en de betrokkenheid van medewerkers te vergroten. Het is ook een geweldig idee voor verjaardagsfeesten, jubilea, vrijgezellenfeesten en andere vieringen.",
                    "title": "Ideale momenten voor een pubquiz ",
                    "type": "text"
                },
                {
                    "text": "Wat pubquizzen zo aantrekkelijk maakt voor deze gelegenheden, is dat ze een combinatie zijn van plezier, uitdaging en leren. Het maakt niet uit of je een doorgewinterde quizmaster bent of gewoon op zoek bent naar een leuke manier om tijd door te brengen met vrienden en collega's, een pubquiz biedt voor ieder wat wils. Het kan de competitieve geest aanwakkeren, nieuwe interessante feiten onthullen en bovenal, de lachspieren stimuleren terwijl je geniet van elkaars gezelschap.",
                    "type": "text"
                },
                {
                    "text": "De digitale revolutie heeft de pubquiz naar een hoger niveau getild en heeft het mogelijk gemaakt om dit geliefde tijdverdrijf verder te ontwikkelen. Moderne platforms zoals Qwesto spelen een rol in de transformatie naar innovatieve en gebruiksvriendelijke tools voor het maken en hosten van quizzen. Dit heeft geleid tot een twist van de traditionele pubquiz.",
                    "title": "Opkomst nieuwe technologie ",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Een van de meest opvallende veranderingen is de toegankelijkheid van pubquizzen geworden. Vroeger was je beperkt tot lokale kroegen of evenementenlocaties om deel te nemen aan een pubquiz. Nu, dankzij digitale platforms, kun je overal ter wereld deelnemen aan quizavonden. Of je nu in je eigen woonkamer bent, op vakantie bent, of met vrienden van ver weg, met Qwesto heb je toegang tot duizenden vragen en de mogelijkheid om samen te eindeloos plezier te beleven."
                },
                {
                    "type": "text",
                    "text": "In een wereld die voortdurend evolueert, blijft de pubquiz een tijdloos tijdverdrijf dat ons verbindt, uitdaagt en vermaakt. Of je nu terugverlangt naar de gezellige avonden in de lokale kroeg of geniet van de moderne digitale quizrevolutie, één ding is zeker: de pubquiz zal altijd een plek hebben in onze harten en in de geschiedenis van sociaal entertainment. Dus, nodig je vrienden uit, maak je klaar voor een avond vol plezier en leerzaamheid, en blijf genieten van de magie van Qwesto, in al zijn vormen. Cheers!"
                }
            ],
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fde-oorsprong-en-evolutie-van-de-pubquiz?alt=media&token=c1355ea6-f976-4900-8167-f3b8a0b24f24",
            "date": "1 februari 2024",
            "pathID": "de-oorsprong-en-evolutie-van-de-pubquiz",
            "description": "Ontdek de interessante geschiedenis en evolutie van de pubquiz. Van Britse pubs tot digitale revolutie. Lees hier meer!",
            "id": 4
        },
        {
            "description": "Hier vind je alle stappen om jouw eerste Qwesto vlekkeloos te laten verlopen!",
            "sections": [
                {
                    "text": "De eerste stap, is om de Qwesto samen te stellen naar jouw eigen voorkeuren. Ga naar qwesto.nl en klik op ‘Stel je Qwesto samen’. Hier moet je een aantal dingen invullen over jouw groep. Dit zorgt ervoor dat de Qwesto helemaal is afgestemd op jullie groep. Als je nog geen account hebt aangemaakt moet dat nu gebeuren. Nadat je dit hebt gedaan kan je wat extra’s toevoegen en klik je op ‘Kopen’.",
                    "title": "Stap 1 - Stel de Qwesto samen",
                    "type": "text"
                },
                {
                    "title": "Stap 2 - Nodig vrienden uit via de link",
                    "type": "text",
                    "text": "De tweede stap, als host zijnde, is om jouw vrienden uit te nodigen door ze de uitnodigingslink te sturen. Deze link is als een digitale sleutel waarmee vrienden toegang krijgen tot de quiz en gezellig kunnen deelnemen aan de quizavond. Dit doe je door op de knop ‘Uitnodigen – Kopieer link’ of ‘Uitnodigen – Deel via WhatsApp’ te klikken. Vanuit hier kan je de link gemakkelijk doorsturen naar de groepsleden."
                },
                {
                    "text": "Nadat iedereen de uitnodigingslink heeft ontvangen, moet iedereen hierop klikken en een account aanmaken. Dit stelt hen in staat om deel uit te maken van de groep en zich voor te bereiden op de quizavond. Wanneer er op de link is geklikt, moet diegene zijn/haar voor- en achternaam, email, wachtwoord en geslacht invullen. Daarnaast moet er een profielfoto worden toegevoegd om de speelervaring nog beter te maken. Wanneer er akkoord is gegaan met de algemene voorwaarden, kan de speler een bijnaam kiezen voor de Qwesto en daarna toetreden.",
                    "title": "Stap 3 - Zorg dat alle groepsleden toetreden en een account aanmaken.",
                    "type": "text"
                },
                {
                    "text": "Zodra alle deelnemers stap 2 hebben voltooid door op de uitnodigingslink te klikken en een account aan te maken, kan de groep worden bevestigd. Dit betekent dat iedereen gereed is om deel te nemen aan de Qwesto. Dit kan gedaan worden door te klikken op ‘Bevestigen – Bevestig je groep’.",
                    "title": "Stap 4 - Wanneer iedereen in de groep zit, bevestig de groep",
                    "type": "text"
                },
                {
                    "title": "Stap 5 - Wanneer de groep is bevestigd, moet iedereen de intakevragen invullen",
                    "text": "Nu is het tijd voor alle groepsleden om de intakevragen in te vullen. Deze vragen zullen ervoor zorgen dat de Qwesto super persoonlijk wordt en helemaal bij jouw groep aansluit. Doe dit door op ‘Invullen – Beschikbaar’ te klikken. Let op! De Qwesto kan pas van start gaan, wanneer iedereen de intake heeft ingevuld.",
                    "type": "text"
                },
                {
                    "text": "Wanneer alle groepsleden de vragen hebben ingevuld, is de Qwesto klaar om van start te gaan. De antwoorden die iedereen heeft gegeven, worden gebruikt om een unieke en persoonlijke quiz te creëren. Klik op ‘Spelen – Beschikbaar’. Hopelijk heb je nu genoeg informatie om te beginnen. Mocht dat niet zo zijn, mail dan jouw vraag naar support@qwesto.nl en dan zorgen wij ervoor dat deze zo snel mogelijk wordt beantwoord.",
                    "title": "Stap 6 - Je hebt alle stappen voltooid en kunt beginnen met de Qwesto",
                    "type": "text"
                }
            ],
            "id": 3,
            "title": "Hoe speel ik mijn eerste Qwesto?",
            "pathID": "hoe-speel-ik-mijn-eerste-qwesto",
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fhoe-speel-ik-mijn-eerste-qwesto?alt=media&token=3e25c784-2785-49e5-a46e-54e63a8ec605",
            "date": "30 december 2023"
        },
        {
            "title": "Wat is een pubquiz?",
            "description": "Je hoort het vaker: pubquiz, maar wat is het nou eigenlijk? We leggen hier alles uit wat jij moet weten over pubquizzen!",
            "id": 2,
            "sections": [
                {
                    "title": "Introductie",
                    "text": "In de dynamische wereld van sociaal entertainment en vriendschappelijke uitdagingen is de pubquiz een opvallend fenomeen geworden. Deze bijeenkomsten van quizvragen, antwoorden en gelach hebben zich gevestigd als een favoriete activiteit voor vriendengroepen, families en collega's. In deze blogpost duiken we dieper in de wereld van pubquizzen, van hun geschiedenis, de waarom achter de pubquiz en zelfs een vergelijking met Qwesto.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "title": "Uitleg pubquiz",
                    "text": "Een pubquiz is een interactieve en vaak competitieve sociale activiteit waarbij deelnemers vragen beantwoorden over diverse onderwerpen. Deze quizzen worden meestal georganiseerd in pubs, bars, restaurants of zelfs online platforms zoals <a href='/'>Qwesto</a>. Teams of individuen nemen het tegen elkaar op om hun kennis te testen op gebieden variërend van algemene kennis tot popcultuur, geschiedenis en meer. Het doel is niet alleen om juiste antwoorden te geven, maar ook om plezier te hebben, te leren en te genieten van een gezellige sfeer."
                },
                {
                    "type": "text",
                    "title": "De geschiedenis van pubquizzen",
                    "text": "De wortels van de pubquiz gaan terug tot het Verenigd Koninkrijk in de jaren '70, waar de trivia voor het eerst opdook als een gezellige manier om mensen in lokale pubs te vermaken. Wat begon als een eenvoudige combinatie van vragen en antwoorden, groeide al snel uit tot een volwaardige culturele gebeurtenis, waarbij pubs zich verdrongen om quizavonden te organiseren. De pubquiz bracht mensen samen om te concurreren in kennis, maar bovenal om te genieten van elkaars gezelschap en een ongedwongen sfeer."
                },
                {
                    "type": "text",
                    "text": "Wat de pubquiz zo aantrekkelijk maakt, is het vermogen om plezier te hebben terwijl je tegelijkertijd je hersenen laat kraken. Het is een kans om te lachen, te leren en nieuwe dingen te ontdekken, allemaal binnen het kader van een speelse competitie. Teams kunnen hun krachten bundelen om een breed scala aan vragen te beantwoorden, van algemene kennis tot popcultuur en geschiedenis. Dit maakt pubquizzen tot een veelzijdige activiteit die geschikt is voor mensen van alle leeftijden en achtergronden.",
                    "title": "Waarom mensen voor een pubquiz kiezen"
                },
                {
                    "title": "Het perfecte moment voor een pubquiz",
                    "text": "Een pubquiz is niet zomaar een spel; het is een sociale ervaring die draait om plezier, uitdaging en samenzijn. Om ten volle te genieten van deze unieke activiteit, is het belangrijk om het juiste moment te kiezen. Hier zijn enkele gelegenheden waarop een pubquiz bijzonder goed tot zijn recht komt:",
                    "type": "text"
                },
                {
                    "items": [
                        {
                            "text": "Vriendenquizzen zijn een fantastische manier om tijd door te brengen met je vrienden. Of je nu samenkomt in een lokale kroeg of gewoon thuis op de bank, het competitieve element en de geestige vragen zorgen voor een avond vol lachen en gezelligheid.",
                            "title": "Avondje met vrienden"
                        },
                        {
                            "text": "Breng generaties samen door een pubquiz op te nemen in je volgende familiebijeenkomst. Het is een geweldige manier om herinneringen te delen, verhalen te vertellen en te strijden om de titel van familiemeester. Deze quiz kan het familieweekend helemaal afmaken.",
                            "title": "Samen met familie"
                        },
                        {
                            "text": "Als je de teamgeest binnen je bedrijf wilt versterken, overweeg dan om een pubquiz op te nemen in je volgende bedrijfsuitje. Het zal de samenwerking en het plezier naar een hoger niveau tillen.",
                            "title": "Teambuilding op de werkvloer"
                        }
                    ],
                    "type": "list"
                },
                {
                    "title": "Het verschil tussen Qwesto en pubquizzen",
                    "text": "Hoewel traditionele pubquizzen hun charme hebben, brengt Qwesto een moderne draai aan dit tijdloze concept. Met Qwesto kun je gepersonaliseerde quizzen creëren die speciaal zijn ontworpen voor jouw vriendengroep, familie of collega’s. Het unieke aan Qwesto is dat je de mogelijkheid hebt om de quiz aan te passen aan jouw interesses, waardoor de ervaring nog meeslepender wordt. Of je nu kiest voor een avondje uit in de pub of een gezellige bijeenkomst thuis, <a href='/'>Qwesto</a> biedt de tools om jouw quizervaring volledig op maat te maken.",
                    "type": "text"
                },
                {
                    "title": "Voorbereiden op een pubquiz",
                    "text": "Voorbereiding is het halve werk. Daarom is het belangrijk om van tevoren goed na te denken over wat je allemaal nodig hebt. Een aantal belangrijke factoren zijn: locatie, eten, drinken en sfeer. Voor meer informatie kan je beter <a href='/blog/hoe-organiseer-je-de-perfecte-pubquiz'>dit artikel</a> lezen.",
                    "type": "text"
                },
                {
                    "title": "Conclusie",
                    "text": "In de wereld van sociaal vermaak en vriendschappelijke rivaliteit staat de pubquiz hoog in het vaandel. Met zijn rijke geschiedenis, diverse vragen en ongedwongen sfeer heeft de pubquiz zich gevestigd als een favoriete activiteit voor mensen van alle leeftijden. Of je nu geniet van traditionele pubquizzen of de moderne aanpak van Qwesto verkiest, één ding is zeker: pubquizzen zijn een geweldige manier om samen te komen, te lachen en te leren, en om de banden tussen vrienden en teams te versterken.",
                    "type": "text"
                }
            ],
            "date": "29 september 2023",
            "pathID": "wat-is-een-pubquiz",
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fwat-is-een-pubquiz?alt=media&token=18539fe7-3e06-495f-96ac-7f815dd639e3"
        },
        {
            "pathID": "hoe-organiseer-je-de-perfecte-pubquiz",
            "description": "De ultieme gids voor een perfecte pubquiz. Er wordt stap-voor-stap uitgelegd hoe je de ideale quizavond organiseert.",
            "title": "Hoe organiseer je de perfecte pubquiz?",
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fhoe-organiseer-je-de-perfecte-pubquiz?alt=media&token=a0cd57f1-cd13-42c6-8f60-ece6fbb5fa7b",
            "id": 1,
            "date": "7 september 2023",
            "sections": [
                {
                    "text": "Het organiseren van een pubquiz kan een spannende manier zijn om vrienden, familie of collega's samen te brengen voor een avond vol entertainment, leren en lachen. Of je nu een kroegbaas bent die op zoek is naar een manier om klanten te trekken, of gewoon iemand die een unieke draai wil geven aan een huisfeest, een pubquiz is altijd een hit. Hier is een stapsgewijze handleiding om je te helpen de perfecte pubquiz te organiseren.",
                    "type": "text"
                },
                {
                    "type": "text",
                    "text": "Gebruik betrouwbare bronnen om je vragen op te baseren. Overweeg verschillende categorieën zoals geschiedenis, muziek, film, of wetenschap. Probeer een mix van makkelijke, gemiddelde en moeilijke vragen te hebben. Als je via Qwesto een quiz koopt hoeft dit niet meer te gebeuren en wordt er al binnen één minuut een quiz gegenereerd.",
                    "title": "1. Vragen en antwoorden voorbereiden"
                },
                {
                    "type": "text",
                    "text": "Zorg voor voldoende pennen, papier, en eventueel buzzers als je de quiz interactief wilt maken. Als je voor Qwesto kiest, zorg ervoor dat iedereens telefoon is opgeladen. Het zou ideaal zijn als je ook nog een groot scherm hebt om de quiz op weer te geven. Zorg er ook voor dat iedereen de vragenlijst van tevoren al heeft ingevuld.",
                    "title": "2. Materialen verzamelen"
                },
                {
                    "type": "text",
                    "text": "Als je de quiz in een pub organiseert, zorg er dan voor dat je genoeg ruimte hebt, goede akoestiek en, indien nodig, een microfoon. Wanneer je de quiz thuis doet, zorg ervoor dat je genoeg zitplekken hebt voor iedereen. Door aandacht te besteden aan de locatie en de sfeer, kun je de deelnemers een gedenkwaardige quizervaring bieden, of je nu in een sfeervolle pub of in het comfort van je eigen huis bent. Het creëren van de juiste sfeer is essentieel voor een succesvolle quizavond.",
                    "title": "3. De locatie"
                },
                {
                    "type": "text",
                    "text": "Het definiëren van duidelijke regels voor je quiz is een cruciale stap om een georganiseerde en eerlijke spelomgeving te creëren. Het opstellen van gedetailleerde regels en het transparant communiceren ervan draagt bij aan een soepele en plezierige quizervaring voor alle deelnemers. Met Qwesto kun je vertrouwen op de ingebouwde structuur om de regels consistent toe te passen, waardoor jij je kunt concentreren op het leiden van een geweldige quiz.",
                    "title": "4. De regels afspreken"
                },
                {
                    "type": "text",
                    "title": "5. Voorbereiding",
                    "text": "Als het doel van je quizavond voornamelijk is om plezier te hebben met vrienden, kun je je promotie-inspanningen eenvoudig en persoonlijk houden. Hier zijn enkele aanvullende strategieën om de buzz onder je vrienden te vergroten: maak een groepschat aan, stuur persoonlijke appjes of stuur een mooie kaart naar iedereen. Door deze vriendgerichte promotiestrategieën te gebruiken, creëer je een intieme en leuke quizavond met een groep mensen die je goed kent en waar je van houdt. Het draait allemaal om plezier en verbinding met vrienden."
                },
                {
                    "type": "text",
                    "title": "6. Prijzen",
                    "text": "Het aanbieden van prijzen voegt een opwindend element toe aan je quizavond en moedigt deelnemers aan om hun best te doen. Hier zijn enkele tips en ideeën om het meeste uit je prijzenaanbod te halen: themaprijzen, troostprijzen, veel verschillende soorten prijzen. Prijzen voegen een extra laag plezier en competitie toe aan je quizavond. Ze kunnen deelnemers motiveren om deel te nemen en zorgen voor een blijvende herinnering aan de leuke tijd die ze hebben gehad. Zorg ervoor dat je prijzen aansluiten bij de sfeer en het thema van je quiz voor het beste effect."
                },
                {
                    "type": "text",
                    "title": "7. Geniet!",
                    "text": "De pubquiz is een moment van samenzijn, van lachen, leren en het smeden van herinneringen. Het draait niet alleen om de competitie, maar ook om het delen van plezier en kennis met de mensen om je heen. Dus ontspan, geniet van de reis van het quizzen en omring jezelf met de opwinding van je vrienden. Of je nu wint of verliest, het echte succes van een pubquiz is de stralende glimlach en het geluk dat het met zich meebrengt. Dus, laten we samen genieten van deze onvergetelijke quizavond!"
                },
                {
                    "type": "text",
                    "text": "In conclusie, het organiseren van een pubquiz vereist enige voorbereiding, maar de beloningen - in termen van plezier, verbinding en gemeenschap - zijn het meer dan waard. Qwesto maakt het voor iedereen gemakkelijk. Of je nu ervaren bent of nieuw in de wereld van quizzen, met deze stappen ben je goed op weg om een avond te creëren die mensen niet snel zullen vergeten.",
                    "title": "Conclusie"
                }
            ]
        },
        {
            "id": 0,
            "date": "8 november 2023",
            "title": "Leuke prijzen voor een pubquiz",
            "photoURL": "https://firebasestorage.googleapis.com/v0/b/qwesto-dbece.appspot.com/o/utilities%2Fleuke-prijzen-voor-een-pubquiz?alt=media&token=7888a9aa-aebd-45dd-a009-e7b760116a48",
            "pathID": "leuke-prijzen-voor-een-pubquiz",
            "sections": [
                {
                    "text": "Het spelen van een pubquiz is altijd een garantie voor een avond vol gelach, gezelligheid en een beetje competitie. Terwijl de quizvragen de geesten scherp houden, voegt het organiseren vooruitzicht op prijzen een extra laag van opwinding toe aan de hele ervaring. In deze blogpost verkennen we waarom het aanbieden van prijzen voor pubquizzen van essentieel belang is, en hoe dit de algehele sfeer van je avond met vrienden, familie of collega’s kan verbeteren.",
                    "type": "text"
                },
                {
                    "text": "Het aanbieden van prijzen voegt een extra dimensie toe aan pubquizzen en stimuleert deelnemers om actief deel te nemen en hun best te doen. Het vooruitzicht op een beloning maakt de quiz leuker en competitiever, waardoor de deelnemers meer gemotiveerd zijn om zich in te zetten en hun kennis te testen. Prijzen fungeren ook als een vorm van erkenning voor de inspanningen van de deelnemers en dragen bij aan een positieve en opwindende sfeer tijdens de quizavond.",
                    "type": "text",
                    "title": "Waarom een prijzenpakket belangrijk is"
                },
                {
                    "type": "list",
                    "title": "Lijst met leuke prijzen",
                    "items": [
                        {
                            "title": "Gepersonaliseerde trofeeën",
                            "text": "Een blijvende herinnering aan de quiztriomf. Gepersonaliseerde trofeeën kunnen worden ontworpen met creatieve en grappige elementen die de persoonlijkheid van de winnaars weerspiegelen."
                        },
                        {
                            "text": "Praktisch en leuk, zoals draadloze oordopjes, mini-luidsprekers of gepersonaliseerde telefoonhoesjes. Deze prijzen zijn niet alleen nuttig, maar ook leuk om te ontvangen en te gebruiken in het dagelijks leven.",
                            "title": "Leuke gadgets"
                        },
                        {
                            "text": "Veelzijdig en afgestemd op de interesses van de winnaars. Denk aan bonnen voor restaurants, bioscopen, online winkels of zelfs ervaringen zoals concertkaartjes of spa-dagen. Cadeaubonnen geven de winnaars de vrijheid om zelf te kiezen waar ze van willen genieten.",
                            "title": "Cadeaubonnen"
                        },
                        {
                            "text": "Voor een dosis humor, zelfs voor de niet-winnaars. Troostprijzen kunnen variëren van grappige gadgets tot ludieke certificaten die de deelnemers aanmoedigen om deel te nemen aan de geestige kant van de competitie.",
                            "title": "Grappige troostprijzen"
                        },
                        {
                            "text": "Een waardevolle beloning die stimuleert tot verdere ontwikkeling. Kies boeken die een breed scala aan interesses omvatten, zoals popcultuur, geschiedenis, zelfontwikkeling of fictie. Op deze manier worden de winnaars aangemoedigd om nieuwe kennis op te doen en misschien zelfs een nieuwe passie te ontdekken.",
                            "title": "Leerzame boeken"
                        }
                    ]
                },
                {
                    "type": "text",
                    "text": "Hoewel competitie een rol speelt, draait het bij pubquizzen vooral om het plezier van deelname en de gezelligheid. Prijzen moeten gezien worden als een aanvulling op de ervaring, niet als hoofddoel. Het belangrijkste is dat de deelnemers een leuke en onvergetelijke avond hebben waar ze samen van kunnen genieten.",
                    "title": "Meedoen is belangrijker dan winnen"
                },
                {
                    "type": "text",
                    "text": "Bij <a href='/?filter=true'>Qwesto</a> worden de top 3 scores beloond, waardoor de inspanningen van de deelnemers worden erkend en een blijvende herinnering aan de quizavond wordt gecreëerd. De prijsuitreiking is een hoogtepunt van de avond waarbij de winnaars worden geprezen en de andere deelnemers worden aangemoedigd om de volgende keer weer mee te doen. Het is een moment van opwinding en voldoening dat de banden tussen vrienden, familie of collega's versterkt en de pubquizervaring compleet maakt.",
                    "title": "Prijsuitreiking bij Qwesto"
                }
            ],
            "description": "De prijsuitreiking: hét moment voor de echte winnaars. Lees hier verder voor tips van prijzen met extra uitleg."
        }
    ]