import QwestoSmall from "../../../assets/img/QwestoSmall"
import Replay from "../../../assets/img/Replay"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useReplay } from "../../../hooks/useReplay"

export default function ReplayCard({groupDoc}){
    const { user } = useAuthContext()
    const {isPending: replayIsPending, tryReplay} = useReplay()

    async function onReplayQwesto(){
        if(!replayIsPending){
            await tryReplay(user, groupDoc)
        }
    }

    return(
        <div className="replay-card" onClick={() => {onReplayQwesto()}} >
            <div className="icon badge">
                {replayIsPending ?
                    <QwestoSmall alt="Loading..." className="loader"/>
                :
                    <Replay className="icon big"/>
                }
                </div>
            <div className="column no-gap">
                <span className="align-left">{replayIsPending ? "Aan het laden..." : "Nog eentje spelen?"}</span>
                <span className="status">Ga verder met dezelde samenstelling</span>
            </div>
        </div>
    )
}
