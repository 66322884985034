export default function QwestoSmall(props) {
    return (
      <svg
      viewBox="16.005 1.366 483.995 483.995"
      fill="currentColor"
      {...props}
      >
      <path
        d="M 368.968 161.213 C 368.754 177.502 366.49 193.021 360.622 207.21 C 356.748 218.436 350.984 230.626 344.545 238.837 C 320.675 269.278 326.826 260.103 309.236 279.889 C 297.929 292.608 293.099 299.453 289.007 310.317 C 284.675 321.816 277.29 335.579 273.068 347.361 C 263.568 373.869 219.006 367.324 212.561 351.688 C 205.023 333.403 217.825 325.849 220.091 305.647 C 222.098 287.752 216.666 285.812 188.489 271.166 C 147.075 250.432 118.894 207.35 119.544 157.932 C 120.45 89.056 177.02 33.954 245.896 34.86 C 314.773 35.766 369.874 92.336 368.968 161.213 Z M 246.044 96.895 C 212.521 96.454 184.988 123.271 184.547 156.793 C 184.375 169.806 188.312 181.917 195.154 191.891 C 205.936 207.61 231.585 224.423 252.094 224.693 C 274.204 224.985 286.06 207.014 296.904 189.487 C 302.502 180.44 305.792 169.804 305.942 158.392 C 306.383 124.87 279.566 97.336 246.044 96.895 Z"
        style={{
          strokeWidth: 0,
        }}
      />
      <ellipse
        style={{
          strokeWidth: 0,
        }}
        cx={230.862}
        cy={424.607}
        rx={34.919}
        ry={34.919}
      />
    </svg>
    );
  }