import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import HostControls from "../controller/HostControls"
import SelectionBar from "../../../components/input/SelectionBar"
import InfoBar from "../controller/InfoBar"
import PhoneViewerViewer from "./PhoneViewerViewer"
import AnswerContainer from "../controller/AnswerContainer";

export default function PhoneViewerContainer({activeQuestion, groupDoc, qwestoDoc, gameDoc}) {
    const [switchValue, setSwitchValue] = useState(1)
    const {user} = useAuthContext()

    return (
        <div className="content-field">
            <div className="phone-viewer-container">
                <div className="phone-viewer-row-container">
                    <div className={"phone-viewer-row " + (switchValue === 1 ? "left" : "right")}>
                        <div className="phone-viewer-row-item">
                            <PhoneViewerViewer activeQuestion={activeQuestion} groupDoc={groupDoc} qwestoDoc={qwestoDoc} gameDoc={gameDoc}/>
                        </div>
                        <div className="phone-viewer-row-item margin">
                            <div className="answer-container">
                                <AnswerContainer groupDoc={groupDoc} qwestoDoc={qwestoDoc} gameDoc={gameDoc} activeQuestion={activeQuestion} switchValue={switchValue} setSwitchValue={setSwitchValue}/>
                            </div>
                        </div>
                    </div>
                </div>
                <HostControls qwestoDoc={qwestoDoc} groupDoc={groupDoc} gameDoc={gameDoc} activeQuestion={activeQuestion}>
                    <SelectionBar options={["Vraag", "Antwoord"]} value={switchValue} setValue={setSwitchValue} unselectable round/>
                </HostControls>
                <InfoBar user={user} gameDoc={gameDoc} groupDoc={groupDoc} qwestoDoc={qwestoDoc} small/>
            </div>
        </div>
    );
}