import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Shop from "../../assets/img/Shop";
import SelectionBar from "../../components/input/SelectionBar";
import Slider from "../../components/input/Slider";
import Stepper from "../../components/layout/Stepper";
import { defaultForbiddenCombinations, filterInfo, filterOptions, productFilters } from "../../data/constants";
import { useProductContext } from "../../hooks/useProductContext";
import { getTotalProductCost } from "../../utils/pure/getTotalProductCost";
import { useEffect } from "react";

export default function FilterSteps({setFilterVisible}){
    const [forbiddenCombinations, setForbiddenCombinations] = useState(defaultForbiddenCombinations)
    const [currentStepIndex, setCurrentStepIndex] = useState(1)
    const {filters, products, setFilters} = useProductContext()
    const [tempFilters, setTempFilters] = useState(filters)
    const navigate = useNavigate()

    useEffect(() => {
        checkForbiddenCombinations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex])
    

    function handleChangeFilter(key, value){
        setTempFilters((previous) => ({
            ...previous, 
            [key]: value
        }))
        if(key === "duration"){
            setFilters((previous) => ({
                ...previous, 
                [key]: value
            }))
        }
    }

    function onClearFilters(){
        setTempFilters(productFilters)
        setFilters(productFilters)
        setCurrentStepIndex(1)
    }

    function handleNextStep(e){
        e.preventDefault()
        if(currentStepIndex === steps.length){
            navigate("/product")
            if(setFilterVisible){
                setFilterVisible(false)
            }
        }else{
            setCurrentStepIndex(currentStepIndex + 1)
        }
        if(JSON.stringify(tempFilters) !== JSON.stringify(filters)){
        }
        setFilters(tempFilters)
    }

    function handlePlayerCount(direction){
        if(direction === "UP" && tempFilters.players < 20){
            handleChangeFilter("players", tempFilters.players + 1)
        }
        if(direction === "DOWN" && tempFilters.players > 3){
            handleChangeFilter("players", tempFilters.players - 1)
        }
    }

    function checkForbiddenCombinations(){
        let newForbiddenCombinations = {...forbiddenCombinations}
        if(filters.age[0] < 18){// Spicyness
            newForbiddenCombinations.spicyness = {
                message: `Je kunt '${filterOptions.spicyness[2]}' niet spelen met personen onder de 18`,
                message_short: "18+",
                disabled_options: [filterOptions.spicyness[2]]
            }
        }else if(filters.bond === 1){
            newForbiddenCombinations.spicyness = {
                message: `Je kunt '${filterOptions.spicyness[2]}' niet spelen als je elkaar een '${filterOptions.bond[0]}' kent`,
                message_short: "Te heet",
                disabled_options: [filterOptions.spicyness[2]]
            }
        }else if(filters.target === 2){
            newForbiddenCombinations.spicyness = {
                message: `Je kunt '${filterOptions.spicyness[2]}' niet spelen met je familie`,
                message_short: "Niet voor families",
                disabled_options: [filterOptions.spicyness[2]]
            }  
        }else if(filters.age[1] > 50 || filters.age[0] > 50){
            newForbiddenCombinations.spicyness = {
                message: `'${filterOptions.spicyness[2]}' is iets te heet voor je groep`,
                message_short: "Te heet",
                disabled_options: [filterOptions.spicyness[2]]
            }  
        }else{
            newForbiddenCombinations.spicyness = defaultForbiddenCombinations.spicyness
        }
        if(filters.players > 14){// Duration
            newForbiddenCombinations.duration = {
                message: `Je bent met te veel spelers voor ${filterOptions.duration[0]}`,
                message_short: "Te veel spelers",
                disabled_options: [filterOptions.duration[0]]
            }
            handleChangeFilter("duration", 2)
        }else if(filters.players < 4){// Duration
            newForbiddenCombinations.duration = {
                message: `Je bent met te weinig spelers voor ${filterOptions.duration[1]}`,
                message_short: "Te weinig spelers",
                disabled_options: [filterOptions.duration[1]]
            }
            handleChangeFilter("duration", 1)
        }else{
            newForbiddenCombinations.duration = defaultForbiddenCombinations.duration
        }
        setForbiddenCombinations(newForbiddenCombinations)
    }

    const steps = [
        {
            title: "Met wie speel je?",
            content: <SelectionBar options={filterOptions.target} disabled={[filterOptions.target[2]]} disabledMessage="Coming soon" value={tempFilters.target} setValue={(value) => handleChangeFilter("target", value)} unselectable round/>,
        },
        {
            title: "Hoe jong is de jongste speler?",
            content: 
            <div className="column">
                <div className="row stay center">
                    <span className="text-xlarge">{tempFilters.age[0]}{tempFilters.age[0] > 59 && "+ "}</span>
                    <span className="text-small">jaar jong</span>
                </div>
                <Slider min={16} max={60} step={1} value={tempFilters.age[0]} setValue={(value) => (handleChangeFilter("age", [value, tempFilters.age[1]]))} minLabel="16-" maxLabel="60+"/>
            </div>,
        },
        {
            title: "Hoe oud is de oudste speler?",
            content: 
            <div className="column">
                <div className="row stay center">
                    <span className="text-xlarge">{tempFilters.age[0] > tempFilters.age[1] ? tempFilters.age[0] : tempFilters.age[1]}{(tempFilters.age[0] > 59 || tempFilters.age[1] > 59) && "+ "}</span>
                    <span className="text-small">jaar oud</span>
                </div>
                {tempFilters.age[0] < 60 && <Slider min={tempFilters.age[0]} max={60} step={1} value={tempFilters.age[1]} setValue={(value) => (handleChangeFilter("age", [tempFilters.age[0], value]))} minLabel={tempFilters.age[0] === 16 ? "16-" : tempFilters.age[0]} maxLabel="60+"/>}
            </div>,
        },
        {
            title: "Ken je elkaar goed?",
            content: <SelectionBar options={filterOptions.bond} value={tempFilters.bond} setValue={(value) => handleChangeFilter("bond", value)} unselectable round/>,
            info: filterInfo.bond[tempFilters.bond - 1]
        },
        {
            title: "Hoe spicy wil je de Qwesto?",
            content: <SelectionBar options={filterOptions.spicyness}  
                disabled={forbiddenCombinations.spicyness.disabled_options} 
                disabledMessage={forbiddenCombinations.spicyness.message_short} 
                value={tempFilters.spicyness} setValue={(value) => handleChangeFilter("spicyness", value)} unselectable round/>,
            error: forbiddenCombinations.spicyness.message,
            info: filterInfo.spicyness[tempFilters.spicyness - 1]
        },
        {
            title: "Uit hoeveel personen bestaat de groep?",
            content: 
            <div className="row stay no-gap center">
                <button className="btn no-grow" onClick={() => {handlePlayerCount("DOWN")}} type="button">-</button>
                <span className="filter-player-count">{tempFilters.players}</span>
                <button className="btn no-grow" onClick={() => {handlePlayerCount("UP")}} type="button">+</button>
            </div>,
        },
        {
            title: "Wil je video's gebruiken in je Qwesto?",
            content: <SelectionBar options={filterOptions.videos} customValues={[true, false]} value={tempFilters.videos} setValue={(value) => handleChangeFilter("videos", value)} unselectable round/>,
            info: filterInfo.videos[tempFilters.videos ? 0 : 1]
        },
        {
            title: "Hoelang wil je spelen?",
            content: 
            <>
                <SelectionBar type="button" options={filterOptions.duration} 
                    disabled={forbiddenCombinations.duration.disabled_options} 
                    disabledMessage={forbiddenCombinations.duration.message_short} 
                    value={tempFilters.duration} setValue={(value) => handleChangeFilter("duration", value)} unselectable round />
                <div className="row stay no-grow">
                    <span className="filter-price-price">{products && products[0] ? getTotalProductCost(products[0], tempFilters.players, true) : 0}</span>
                    <span className="text-xxsmall grey-text">per speler</span>
                </div>
            </>,
            error: forbiddenCombinations.duration.message,
            button: <button className="btn-gr round no-grow" onClick={handleNextStep} type="button">Kopen <Shop className="icon"/></button>
        }
    ]

    return(     
        <form className="filter" onSubmit={handleNextStep}>
            <Stepper title="" steps={steps} currentStepIndex={currentStepIndex} setCurrentStepIndex={setCurrentStepIndex} onClearSteps={onClearFilters}>
                {steps[currentStepIndex - 1].content}
            </Stepper>
        </form>
    )
}


