export default function MostLikelyIcon(props) {
    return (
        <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        d="M15 19c0-2.21-2.686-4-6-4s-6 1.79-6 4m18-9-4 4-2-2m-6 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
    );
}