export default function PlayerOption({player, onClick}) {
    return(
        <button onClick={onClick} className="player-option">
            <img src={player.photoURL} alt="" className="player-option-img"/>
            <div className="player-option-label">
                <img src={player.photoURL} alt="" className="avatar big"/>
                <span className="bold">{player.nickName}</span>
            </div>
        </button>
    )
}
