import "./Viewer.css"
import ScoreViewer from './ScoreViewer';
import QuestionViewer from './QuestionViewer';
import ScoreStage from "./ScoreStage";

export default function QuestionContainer({activeQuestion, groupDoc, qwestoDoc, gameDoc}){
    return(
        <div className="viewer-container">
            {activeQuestion &&
                <>
                    {(gameDoc.stage === "INTRODUCTION" || gameDoc.stage === "COUNTDOWN" || gameDoc.stage === "ANSWERING" || gameDoc.stage === "RESULTS") ?
                        <QuestionViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc} qwestoDoc={qwestoDoc}/>
                    :
                        <>
                            {activeQuestion.props && gameDoc.stage === "FINISHED" ?
                                <ScoreStage groupDoc={groupDoc} gameDoc={gameDoc}/>
                            :
                                <ScoreViewer groupDoc={groupDoc} gameDoc={gameDoc}/>
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}
