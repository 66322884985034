export default function WhatDoYouSeeIcon(props) {
    return (
        <svg viewBox="0 0 600 566.67" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Warning / Help">
        <path
            data-name="Vector"
            d="M227.81 163.31a74.038 74.038 0 0 1 22.31-33.41 73.926 73.926 0 0 1 36.47-16.83 73.941 73.941 0 0 1 71.45 29.58 73.995 73.995 0 0 1 13.91 37.68 74.05 74.05 0 0 1-7.84 39.4 74 74 0 0 1-27.26 29.51 74.023 74.023 0 0 1-38.66 10.91v18.49m.93 74.01v1.85h-1.84v-1.85h1.84ZM400 533.33H200M33.33 326.67V140c0-37.34 0-56.02 7.27-70.28a66.622 66.622 0 0 1 29.13-29.13c14.26-7.27 32.94-7.27 70.28-7.27h320c37.34 0 55.98 0 70.24 7.27 12.54 6.39 22.77 16.58 29.16 29.13 7.26 14.25 7.26 32.91 7.26 70.17v186.87c0 37.26 0 55.9-7.26 70.14a66.808 66.808 0 0 1-29.16 29.16c-14.25 7.26-32.88 7.26-70.14 7.26H139.9c-37.26 0-55.92 0-70.17-7.26-12.54-6.39-22.74-16.62-29.13-29.16-7.27-14.26-7.27-32.9-7.27-70.24v.01Z"
            style={{
            fill: "none",
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "66.67px",
            }}
        />
        </g>
    </svg>
    );
}