import { LabelDisplay } from '../LabelDisplay';
import React from "react";
import { useState } from 'react';
import PopUp from '../../../components/layout/PopUp';
import DropDown from '../../../components/input/DropDown';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebaseConfig';
import { useMessageContext } from '../../../hooks/useMessageContext';

export function QuestionList({filteredPool, currentPool, collectionType}){
    const [questionOpen, setQuestionOpen] = useState(false)
    const [questionData, setQuestionData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    function handleQuestion(question){
        setQuestionData(question)
        setQuestionOpen(true)
    }

    function handleQuestionData(key, value){
        setQuestionData((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }

    async function handleSaveQuestion(e){
        e.preventDefault()
        if(!currentPool){
            return
        }
        setIsSaving(true)
        currentPool.pool[questionData.id] = questionData
        const poolRef = doc(db, (collectionType === "draft" ? "pools_draft" : "pools"), currentPool.id)
        try {
            await updateDoc(poolRef, {
                pool: currentPool.pool
            })
            setNewInfo("Succes a mattie")
            setQuestionOpen(false)
            setIsSaving(false)
        } catch (error) {
            console.log(error);
            setNewError("Er ging iets fout")
            setIsSaving(false)
        }
    }

    function mapLabels(labels){
        return labels.map((label) => {
            return{
                value: label,
                label: String(label)
            }
        })
    }

    return(
        <>
            <div className={`manager-list ${collectionType === "draft" ? "draft" : ""}`}>
                {filteredPool && filteredPool.pool.map((question, index) => <div className="manager-item" key={index} onClick={() => {handleQuestion(question)}}>
                    <div className="row stay gap">
                        <p className="grey-text">{question.id}</p>
                        <div className="column">
                            <div className="row stay no-grow">
                                <p className="grey-text">iq: </p>
                                <h3>{question.iq ? question.iq : "X"}</h3>
                            </div>
                            <div className="row stay no-grow">
                                <p className="grey-text">qq/q: </p>
                                <h3>{question.qq ? question.qq : question.q}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row stay no-gap">
                        <LabelDisplay label="age" values={question.a}/>
                        <LabelDisplay label="bond" values={question.b}/>
                        <LabelDisplay label="comp." values={question.c}/>
                        <LabelDisplay label="spicy." values={question.s}/>
                        <LabelDisplay label="targ." values={question.t}/>
                        <LabelDisplay label="occ." values={question.o}/>
                    </div>
                </div>)}
            </div>
            <PopUp title={`Update ${currentPool.id} question in ${collectionType}`} visible={questionOpen} setVisible={setQuestionOpen} large unclosable>
                {questionData &&
                    <form className="column gap">
                        <h3>ID: {questionData.id}</h3>
                        {"iq" in questionData && 
                            <label>iq
                                <input type="text" id="question"
                                    required
                                    onChange={(e) => {handleQuestionData("iq", e.target.value)}}
                                    defaultValue={questionData.iq}
                                />
                            </label>
                        }
                        <label>qq/q
                            <input type="text" id="question"
                                required
                                onChange={(e) => {handleQuestionData("q" in questionData ? "q" : "qq", e.target.value)}}
                                defaultValue={"q" in questionData ? questionData.q : questionData.qq}
                            />
                        </label>
                        {Object.keys(questionData).sort().map((key) => {
                            if(["a","b","c","o","s","t"].includes(key)){
                                return(
                                    <div className="row stay gap center" key={key}>
                                        <h3>{key}</h3>
                                        <DropDown 
                                            message="Labels" 
                                            options={mapLabels((() => {
                                                switch(key){
                                                    case "o":
                                                        return [1]
                                                    case "a":
                                                        return [1,2,3,4,5]
                                                    default:
                                                        return [1,2,3]
                                                }
                                            })())} 
                                            selectedOption={mapLabels(questionData[key])}
                                            setSelectedOption={(value) => {handleQuestionData(key, value)}}
                                        />
                                    </div>
                                )
                            }else{
                                return null
                            }
                        })}
                        <div className="row stay">
                            <button className="btn black" type="button" onClick={() => {setQuestionOpen(false)}}>Annuleren</button>
                            <button className="btn-gr" type="submit" disabled={isSaving} onClick={(e) => {handleSaveQuestion(e)}}>{isSaving ? "Werkende..." : "Opslaan"}</button>
                        </div>
                    </form>
                }
            </PopUp>
        </>
    )
}