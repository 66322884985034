import "./Slider.css"
import ReactSlider from "react-slider"

export default function Slider({min, max, step, value, setValue, minLabel, maxLabel}){

    return(
        <div className="slider">
            <ReactSlider
                className="customSlider"
                trackClassName="customSlider-track"
                thumbClassName="customSlider-thumb"
                renderThumb={(props, state) => {
                    const { key, ...restProps } = props; // Extract the key from props
                    return <div key={key} {...restProps}>{state.valueNow}</div>;
                }}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(value) => setValue(value)}
            />
            {minLabel && maxLabel &&
                <div className="slider-labels">
                    <div className="slider-min-value">{minLabel}</div>
                    <div className="slider-max-value">{maxLabel}</div>
                </div>
            }
        </div>
    )
}
