import { useEffect, useState } from "react"

export default function IntakeQuestion({activeQuestion}){
    const [question, setQuestion] = useState(null)
    
    useEffect(() => {
        switch(activeQuestion.type) {
            case "PHOTOREVEAL":
                    setQuestion("Upload een foto van een speler")
                break
            case "WHATDOYOUSEE":
                    setQuestion("Upload een foto")
                break
            case "WHATDOYOUHEAR":
                    setQuestion("Upload een video")
                break
            default:
                    setQuestion(activeQuestion.question)
                break
        }

    }, [activeQuestion])

    return(
        <h2 className="intake-question">{question}</h2>
    )
};
