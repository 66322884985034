import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref as refStorage, uploadBytes } from "firebase/storage";

import {useState} from "react";
import { db, storage } from "../config/firebaseConfig";
import { setIntakeIndexes } from "../utils/pure/setIntakeIndexes";

import { useMessageContext } from "./useMessageContext";

export function useNextIntakeQuestion(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError} = useMessageContext()

    async function tryNextQuestion(user, qwestoID, activeQuestion, onResetValues, whatIsTrue, textAnswer, photo, video,selectedOption, metadata){
        if(!isPending){
            try {
                const docRef = doc(db, "qwestos", qwestoID)
                switch(activeQuestion.type) {
                    case "WHATISTRUE":
                        if(whatIsTrue.firstTrue === "" || whatIsTrue.firstFalse === "" || whatIsTrue.secondFalse === ""){
                            setNewError("Vul alle velden in om verder te gaan")
                            return
                        }else if(whatIsTrue.firstTrue.length < 1 || whatIsTrue.firstFalse.length < 1 || whatIsTrue.firstFalse.length < 1){
                            setNewError("1 letter maar?")
                            return
                        }else{
                            setIsPending(true)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.firstTrue`]: whatIsTrue.firstTrue,
                                [`qwestoQuestions.${activeQuestion.id}.firstFalse`]: whatIsTrue.firstFalse,
                                [`qwestoQuestions.${activeQuestion.id}.secondFalse`]: whatIsTrue.secondFalse,
                                [`qwestoQuestions.${activeQuestion.id}.dummy`]: user.uid
                            })
                        }
                        break;
                    case "GUESSWHO":
                        if(textAnswer === ""){
                            setNewError("Vul iets in om verder te gaan")
                            return
                        }else{
                            setIsPending(true)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.answer`]: textAnswer,
                                [`qwestoQuestions.${activeQuestion.id}.dummy`]: user.uid
                            })
                        }
                        break;
                    case "BESTANSWER":
                        if(textAnswer === "" || textAnswer.length < 2){
                            setNewError("Vul iets in om verder te gaan")
                            return
                        }else{
                            setIsPending(true)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.answers`]: arrayUnion({
                                    uid: user.uid,
                                    answer: textAnswer
                                })
                            })
                        }
                        break;
                    case "BESTPHOTO":
                        if(!photo){
                            setNewError("Upload een foto om verder te gaan")
                            return
                        }else{
                            //create new URL by uploading provided image
                            setIsPending(true)
                            const uploadPath = `groupMedia/${qwestoID}/${user.uid + activeQuestion.id}`
                            const uploadRef = refStorage(storage, uploadPath)
                            await uploadBytes(uploadRef, photo)
                            const promisedURL = await getDownloadURL(uploadRef)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.answers`]: arrayUnion({
                                    uid: user.uid,
                                    photoURL: promisedURL
                                })
                            })
                        }
                        break;
                    case "PHOTOREVEAL":
                        if(!photo || !selectedOption || selectedOption.length === 0){
                            setNewError(!photo ? "Upload een foto om verder te gaan" : "Kies een speler om verder te gaan")
                            return
                        }else{
                            setIsPending(true)
                            //create new URL by uploading provided image
                            const uploadPath = `groupMedia/${qwestoID}/${user.uid + activeQuestion.id}`
                            const uploadRef = refStorage(storage, uploadPath)
                            await uploadBytes(uploadRef, photo)
                            const promisedURL = await getDownloadURL(uploadRef)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.dummy`]: user.uid,
                                [`qwestoQuestions.${activeQuestion.id}.photoURL`]: promisedURL,
                                [`qwestoQuestions.${activeQuestion.id}.players`]: selectedOption
                            })
                        }
                        break;
                    case "WHATDOYOUSEE":
                        if(!photo){
                            setNewError("Upload een foto om verder te gaan")
                            return
                        }else if(whatIsTrue.firstTrue === "" || whatIsTrue.firstFalse === "" || whatIsTrue.secondFalse === ""){
                            setNewError("Vul alle velden in om verder te gaan")
                            return
                        }else if(whatIsTrue.firstTrue.length < 1 || whatIsTrue.firstFalse.length < 1 || whatIsTrue.firstFalse.length < 1){
                            setNewError("1 letter maar?")
                            return
                        }else{
                            setIsPending(true)
                            //create new URL by uploading provided image
                            const uploadPath = `groupMedia/${qwestoID}/${user.uid + activeQuestion.id}`
                            const uploadRef = refStorage(storage, uploadPath)
                            await uploadBytes(uploadRef, photo)
                            const promisedURL = await getDownloadURL(uploadRef)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.dummy`]: user.uid,
                                [`qwestoQuestions.${activeQuestion.id}.photoURL`]: promisedURL,
                                [`qwestoQuestions.${activeQuestion.id}.firstTrue`]: whatIsTrue.firstTrue,
                                [`qwestoQuestions.${activeQuestion.id}.firstFalse`]: whatIsTrue.firstFalse,
                                [`qwestoQuestions.${activeQuestion.id}.secondFalse`]: whatIsTrue.secondFalse
                            })
                        }
                        break;
                    case "WHATDOYOUHEAR":
                        if(!video){
                            setNewError("Upload een video om verder te gaan")
                            return
                        }else if(whatIsTrue.firstTrue === "" || whatIsTrue.firstFalse === "" || whatIsTrue.secondFalse === ""){
                            setNewError("Vul alle velden in om verder te gaan")
                            return
                        }else if(selectedOption === null){
                            setNewError("Geef aan of de video geluid heeft")
                            return
                        }else if(whatIsTrue.firstTrue.length < 1 || whatIsTrue.firstFalse.length < 1 || whatIsTrue.firstFalse.length < 1){
                            setNewError("1 letter maar?")
                            return
                        }else{
                            setIsPending(true)
                            //create new URL by uploading provided image
                            const uploadPath = `groupMedia/${qwestoID}/${user.uid + activeQuestion.id}`
                            const uploadRef = refStorage(storage, uploadPath)
                            await uploadBytes(uploadRef, video, metadata)
                            const promisedURL = await getDownloadURL(uploadRef)
                            await updateDoc(docRef, {
                                [`qwestoQuestions.${activeQuestion.id}.dummy`]: user.uid,
                                [`qwestoQuestions.${activeQuestion.id}.videoHasSound`]: selectedOption,
                                [`qwestoQuestions.${activeQuestion.id}.videoURL`]: promisedURL,
                                [`qwestoQuestions.${activeQuestion.id}.firstTrue`]: whatIsTrue.firstTrue,
                                [`qwestoQuestions.${activeQuestion.id}.firstFalse`]: whatIsTrue.firstFalse,
                                [`qwestoQuestions.${activeQuestion.id}.secondFalse`]: whatIsTrue.secondFalse
                            })
                        }
                        break;
                    default:
                        console.log("NO TYPE RECOGNIZED");
                }

                await setIntakeIndexes(qwestoID, user.uid)

                onResetValues()
                setIsPending(false)
            } catch (error) {
                setNewError("Er is iets misgegaan")
                console.log(error)
                setIsPending(false)
            }
        }
    }

    return { tryNextQuestion, isPending }
}