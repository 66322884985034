import "./Avatar.css"
import React from 'react'
import ArrowLeft from "../../assets/img/ArrowLeft"
import ArrowRight from "../../assets/img/ArrowRight"
import { useNavigate } from "react-router-dom"

export default function Redirect({to, message, action, arrow, small}) {
    const navigate = useNavigate()

    return (
        <div className="content-page full">
            {small ? 
            <div className="column center gap">
                <span className="text-small">{message}</span>
                {to &&
                    <button className="btn black round" onClick={() => {navigate(to)}}>
                        {arrow === "left" && <ArrowLeft className="icon no-margin"/>}
                        {action}
                        {arrow === "right" && <ArrowRight className="icon no-margin"/>}
                    </button>
                }
            </div>
            :
            <div className="content-field">
                <div className="content-container">
                    <div className="column center gap">
                        <span className="text-small">{message}</span>
                        {to &&
                            <button className="btn black round" onClick={() => {navigate(to)}}>
                                {arrow === "left" && <ArrowLeft className="icon no-margin"/>}
                                {action}
                                {arrow === "right" && <ArrowRight className="icon no-margin"/>}
                            </button>
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
