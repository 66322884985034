import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
import Error from '../assets/img/Error';
import Info from '../assets/img/Info';

export const MessageContext = createContext();

export const MessageContextProvider = ({ children }) => {
  const [messageList, setMessageList] = useState([])
  const [messageID, setMessageID] = useState(0)

  useEffect(() => {
    const timeoutIds = [];
    
    messageList.forEach((message) => {
      const timeoutId = setTimeout(() => {
        removeMessage(message.id);
      }, 3000);
      
      timeoutIds.push(timeoutId);
    });

    return () => {
      // Clear timeouts when the component unmounts or when messages change
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [messageList]);

  const removeMessage = (id) => {
    setMessageList((prevMessages) => prevMessages.filter((message) => message.id !== id));
  };

  function addMessage(content, type){
    let newMessageList = messageList
    if(messageList.length + 1 > 3){
      newMessageList = messageList.slice(1,3)
    }
    setMessageList(
      [...newMessageList, {
        content,
        type,
        id: messageID
      }]
    )
    setMessageID(messageID + 1)
  }

  const setNewError = async (newError) => {
    addMessage(newError, "error")
  }
  const setNewInfo = async (newInfo) => {
    addMessage(newInfo, "info")
  }

  return(
    <MessageContext.Provider value={{setNewError, setNewInfo}}>
      <div className="message-list">
        {messageList.map((message, index) => (
          <div className={`message ${message.type}`} key={message.id}>
            {message.type === "error" &&
              <Error className="icon"/>
            }
            {message.type === "info" &&
              <Info className="icon"/>
            }
            <span className="text-small">{message.content}</span>
          </div>
        ))}
      </div>
      {children}
    </MessageContext.Provider>
  );
};