import "./SelectionBar.css"
import React from 'react'

export default function SelectionBar({options, disabled, disabledMessage, customValues, value, setValue, unselectable, round, type, className}) {
    return (
        <div className={`selection-bar ${unselectable ? " unselectable" : ""} ${round ? " round" : ""} ${className ? className : ""}`}>
            {options.map((option, index) => (
                <React.Fragment key={index}>
                    {disabled && disabled.includes(option) ? 
                        <>
                            <button className="selection-bar-option disabled" disabled type="button">
                                {options[index]}
                                {disabledMessage && 
                                    <span className="disabled-message">{disabledMessage}</span>
                                }
                            </button>
                        </>
                    :
                        <>
                            {value === (customValues ? customValues[index] : index + 1 ) ?
                                <button className="selection-bar-option active" onClick={() => {!unselectable && setValue(null)}} type={type ? type : "button"}>{options[index]}</button>
                            :
                                <button className="selection-bar-option" onClick={() => {setValue && setValue(customValues ? customValues[index] : index + 1)}} type="button">{options[index]}</button>
                            }
                        </>
                    }
                </React.Fragment>
            ))}
        </div>
    )
}
