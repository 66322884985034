import { NavLink } from "react-router-dom"
import QwestoBig from "../../assets/img/QwestoBig"
import "./Footer.css"
import { useNavigationBarContext } from "../../hooks/useNavigationBarContext"

export default function Footer(){
    const {setFilterVisible} = useNavigationBarContext()

    return(
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-item no-gap">
                    <QwestoBig className="icon"/>
                    <span className="text-xxsmall grey-text">© Qwesto 2024</span>
                </div>
                <nav className="footer-item">
                    <span className="text-small">Navigeer</span>
                    <div className="footer-link" onClick={() => {setFilterVisible(true)}}>Koop een Qwesto</div>
                    <NavLink className="footer-link" to="/faq">Veelgestelde vragen</NavLink>
                    <NavLink className="footer-link" to="/groups">Mijn producten</NavLink>
                    <NavLink className="footer-link" to="/dashboard">Mijn profiel</NavLink>
                    <NavLink className="footer-link" to="/blog">Blog</NavLink>
                </nav>
                <nav className="footer-item">
                    <span className="text-small">Over ons</span>
                    <NavLink className="footer-link" to="/contact">Contact</NavLink>
                    <NavLink className="footer-link" to="/terms">Algemene Voorwaarden</NavLink>
                    <NavLink className="footer-link" to="/privacy">Privacy Verklaring</NavLink>
                    <NavLink className="footer-link" to="/disclaimer">Disclaimer</NavLink>
                    <NavLink className="footer-link" to="/cookies">Cookies</NavLink>
                </nav>
            </div>
        </footer>
    )
}
