import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "./NavigationBar.css";
import Avatar from "../Avatar";
import User from "../../../assets/img/User";
import Menu from "../../../assets/img/Menu";
import Exit from "../../../assets/img/Exit";
import { useSignOut } from "../../../hooks/useSignOut";

export function NavigationBarMenu({menuVisible, setMenuVisible, setFilterVisible}){
	const { user } = useAuthContext()
	const navigate = useNavigate()
	const { trySignOut, isPending: signOutIsPending } = useSignOut()

	function handleNavigation(to){
		if(to){
			navigate(to)
		}
		setMenuVisible(false)
	}

	function handleOpenMenu(){
		setMenuVisible(!menuVisible)
		setFilterVisible(false)
	}

	return(
		<>
			<div className={`nb-item ${menuVisible ? "active" : ""}`} onClick={handleOpenMenu}>
				<div className={`nb-menu-btn ${menuVisible ? "active" : ""}`}>
					{menuVisible ? <Exit className="nb-menu-btn-icon"/> : <Menu className="nb-menu-btn-icon"/>}
					{user ? 
						<Avatar src={user.photoURL} border={true}/>
					:
						<User className="nb-logo user"/>
					}
				</div>
				<span className="nb-label">Menu</span>
			</div>
			
			<div className={`nb-menu ${menuVisible ? "active" : ""}`}>
				<span className="text-small">Menu</span>
					{user ?
						<>
							<NavLink to="/dashboard" onClick={() => {handleNavigation("")}}>Mijn profiel</NavLink>
						</>
					:
						<>
							<button className="btn-gr" onClick={() => {handleNavigation("/sign-up")}}>Aanmelden</button>
							<button className="btn black" onClick={() => {handleNavigation("/login")}}>Inloggen</button>
							<div className="horizontal-divider"/>
						</>
					}
					<NavLink to="/groups" onClick={() => {handleNavigation("")}}>Spelen</NavLink>
					<NavLink to="/how-it-works" onClick={() => {handleNavigation("")}}>Hoe werkt het?</NavLink>
					<NavLink to="/contact" onClick={() => {handleNavigation("")}}>Contact</NavLink>
					{user &&
						<>
							<div className="horizontal-divider"/>
							{!signOutIsPending && <button className="btn black" onClick={trySignOut}>Log uit</button>}
						</>
					}
			</div>
			{menuVisible &&
				<>
					<div className="nb-overlay-exit" onClick={() => {setMenuVisible(false)}}/>
					<div className="nb-overlay-backdrop"/>
				</>
			}
		</>
	)
}