import { MostLikelyViewer } from './MostLikelyViewer';
import { BestAnswerViewer } from './BestAnswerViewer';
import { WhatIsTrueViewer } from './WhatIsTrueViewer';
import { BestPhotoViewer } from './BestPhotoViewer';
import { GuessWhoViewer } from './GuessWhoViewer';
import { PhotoRevealViewer } from './PhotoRevealViewer';
import Countdown from './Countdown';
import { SkippedViewer } from './SkippedViewer';
import { WhatDoYouSeeViewer } from './WhatDoYouSeeViewer';
import { WhatDoYouHearViewer } from './WhatDoYouHearViewer';
import { Introduction } from '../Introduction';
import { useAuthContext } from '../../../hooks/useAuthContext';
import PlayerCountdown from '../controller/PlayerCountdown';
import { useGameLogicContext } from '../../../hooks/useGameLogicContext';

export default function QuestionViewer({activeQuestion, groupDoc, gameDoc, qwestoDoc}){
    const {user} = useAuthContext()
    const {countdown} = useGameLogicContext()
    if(gameDoc.stage === "INTRODUCTION" && activeQuestion.type !== "SKIPPED"){
        return(
            <Introduction activeQuestion={activeQuestion} groupDoc={groupDoc}/>
        )   
    }

    if(gameDoc.stage === "COUNTDOWN" && user.uid !== groupDoc.host.uid){
        return(
            <PlayerCountdown players={groupDoc.players}/>
        )   
    }

    if(!(gameDoc.stage === "COUNTDOWN")){
        switch(activeQuestion.type){
            case "MOSTLIKELY":
                return <MostLikelyViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "WHATISTRUE":
                return <WhatIsTrueViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc} qwestoDoc={qwestoDoc}/>
            case "BESTANSWER":
                return <BestAnswerViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "BESTPHOTO":
                return <BestPhotoViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "PHOTOREVEAL":
                return <PhotoRevealViewer activeQuestion={activeQuestion} groupDoc={groupDoc} gameDoc={gameDoc}/>
            case "WHATDOYOUSEE":
                return <WhatDoYouSeeViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "WHATDOYOUHEAR":
                return <WhatDoYouHearViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "GUESSWHO":
                return <GuessWhoViewer activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
            case "SKIPPED":
                return <SkippedViewer activeQuestion={activeQuestion} groupData={groupDoc}/>
            default:
                return null
        }
    }else if(countdown !== null){
        return(
            <Countdown countdown={countdown}/>
        )
    }

    return(
        <div className="content-field"/>
    )
}
