import React from 'react'
import "./Share.css"
import Status from "../layout/Status";
import WhatsApp from "../../assets/img/WhatsApp";
import { useMessageContext } from "../../hooks/useMessageContext";
import Copy from "../../assets/img/Copy";
import { handleShareClick } from '../../utils/pure/handleShareClick';

export default function Share({type, message, className, title, status}){
    const {setNewInfo} = useMessageContext()


    return (
        <div onClick={() => handleShareClick(type, message, setNewInfo)} className={className}>
            {type === "WHATSAPP" &&
                <WhatsApp className="icon badge"/>
            }
            {type === "COPY" &&
                <Copy className="icon badge"/>
            }
            <div className="column no-gap">
                <h3>{title}</h3>
                <Status message={status}/>
            </div>
        </div>
    )
}
