import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";

export function useSaveGroupSettings(){
    const [settingsIsPending, setSettingsIsPending] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    async function trySaveGroupSettings(qwestoID, hasPhoneViewer){
        setSettingsIsPending(true)
        updateDoc(doc(db, "groups", qwestoID), {
            phoneViewer: hasPhoneViewer
        })
        .then(() => {
            setNewInfo("Je instellingen zijn opgeslagen")
            setSettingsIsPending(false)
        })
        .catch((error) => {
            setNewError("Er is iets misgegaan, probeer het later opnieuw")
            console.log(error);
            setSettingsIsPending(false)
        })
    }

    return { settingsIsPending, trySaveGroupSettings }
}
