import { Lobby } from './Lobby';
import { Navigate, useParams } from "react-router-dom"
import Loader from "../../../components/loaders/Loader";
import "./Viewer.css"
import Redirect from '../../../components/layout/Redirect';
import ControlBar from './ControlBar';
import QuestionContainer from './QuestionContainer';
import { useGroupDataContext } from '../../../hooks/useGroupDataContext';
import { useMemo } from 'react';
import { useGameLogicContext } from '../../../hooks/useGameLogicContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { NoGameConnection } from '../NoGameConnection';

export default function Viewer(){
    const {qwestoID} = useParams()
    const {user} = useAuthContext()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "GROUP_AND_GAME_DATA",
        qwestoID: qwestoID
    }), [qwestoID]))
    const {activeQuestion, isResumed, gameConnection} = useGameLogicContext("VIEWER")

    if(groupData.group && user.uid !== groupData.group.host.uid){
        return <Navigate to={`/groups/${qwestoID}`}/>
    }

    if(groupData.group && groupData.group.phoneViewer === true){
        return(
            <Navigate to={`/groups/${qwestoID}`}/>
        )
    }

    return(
        <>
            {noGroupData ?
                <Redirect to="/groups" message={"Deze Qwesto bestaat niet"} action="Terug" arrow="left"/>
            :
                <>
                    {!groupData.qwesto || !groupData.game|| !groupData.group ?
                        <Loader />
                    :
                        <>
                            {!groupData.group.groupIsConfirmed ?
                                <Redirect to={`/groups/${qwestoID}`} message={"Nog niet beschikbaar"} action="Terug" arrow="left"/>
                            :
                                <div className="content-page full">      
                                    {isResumed && 
                                        <>
                                            <ControlBar activeQuestion={activeQuestion} groupDoc={groupData.group} qwestoDoc={groupData.qwesto} gameDoc={groupData.game} qwestoID={qwestoID} qwestoProps={activeQuestion?.props}/>
                                            {!gameConnection && <NoGameConnection/>}
                                            {groupData.group.qwestoStarted ?
                                                <QuestionContainer activeQuestion={activeQuestion} groupDoc={groupData.group} qwestoDoc={groupData.qwesto} gameDoc={groupData.game}/>
                                            :
                                                <Lobby groupData={groupData}/>
                                            }
                                        </>
                                    }   
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}