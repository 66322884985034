import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ArrowUp from "../../assets/img/ArrowUp";
import Footer from "../../components/layout/Footer";
import SEO from "../../components/utils/SEO";
import ArticleList from "../blog/ArticleList";
import Comparison from "./Comparison";
import Explanation from "./Explanation";
import FAQList from "../info/FAQList";
import Hero from "./Hero";
import "./Home.css"
import Reviews from "../products/Reviews";
import { getDynamicHeroTitle } from "../../utils/pure/getDynamicHeroTitle";
import WhatsApp from "../../assets/img/WhatsApp";
import { handleShareClick } from "../../utils/pure/handleShareClick";
import { CONTROLLER_CLOSEUP_URL } from "../../data/constants";
import ContentFieldBanner from "../../components/layout/ContentFieldBanner";

export default function Home(){
    const [filterVisible, setFilterVisible] = useState(false)
    const callRef = useRef()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {target} = useParams()
    const dynamicHeroTitle = getDynamicHeroTitle(target)

    useEffect(() => {
        setFilterVisible(searchParams.get("filter") ? true : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    function openFilter(){
        if(window.innerWidth > 1000){
            window.scrollTo({top: 300})
        }else{
            window.scrollTo({top: 400})
        }
        if(!filterVisible){
            setFilterVisible(true)
        }
    }

    useEffect(() => {
        if(filterVisible){
            openFilter()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterVisible])

    return(
        <>
            <SEO title={dynamicHeroTitle} description={"Ervaar een onvergetelijke avond met je vrienden, familie of collega's. Allemaal dankzij onze uniek gegenereerde quizzen."} canonical=""/>
            <div className="content-page" ref={callRef}>
                <Hero filterVisible={filterVisible} setFilterVisible={setFilterVisible} dynamicHeroTitle={dynamicHeroTitle}/>
                <Explanation/>
                <div className="content-field white">
                    <div className="home-section">
                        <div className="content-container center">
                            <h1 className="text-xxlarge">Hoe goed ken jij je vrienden nu echt?</h1>
                            <p className="text-medium grey-text">Met een Qwesto leer je je medespelers nóg beter kennen.</p>
                        </div>
                    </div>
                </div>
                <ContentFieldBanner src={CONTROLLER_CLOSEUP_URL} sticky>
                        <div className="home-section">
                            <div className="content-container max-width center no-margin-vertical">
                                <h2 className="text-large">Wat onze spelers zeggen</h2>
                            </div>
                            <Reviews />
                        </div>
                </ContentFieldBanner>
                <div className="content-field">
                    <div className="content-container center">
                        <h2>Al 200+ tevreden spelers gingen je voor</h2>
                    </div>
                </div>
                <div className="content-field white">
                    <div className="content-container max-width-wide min-height center">
                        <FAQList />
                    </div>
                </div>
                <Comparison/>
                <div className="content-field cgr-dynamic">
                    <div className="content-container min-height-small">
                        <div className="column wide gap center">
                            <h2 className="text-xlarge">Ben je er klaar voor?</h2>
                            <button className="btn black round" onClick={() => {openFilter()}}>Begin met samenstellen<ArrowUp className="icon"/></button>
                            <span className="text-small grey-text">of</span>
                            <button className="btn green round gap" onClick={() => {handleShareClick("WHATSAPP", "Kijk eens wat ik heb gevonden! Lijkt jullie dit wat? www.qwesto.nl", null)}}>Stel het voor in je favoriete groep<WhatsApp className="icon"/></button>
                        </div>
                    </div>
                </div>
                <div className="content-container max-width">
                    <div className="articles-section">
                        <ArticleList small/>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}


