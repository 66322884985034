
import { useProductContext } from "../../hooks/useProductContext";
import FilterInfo from "./FilterInfo";

export default function ProductInfo({product}) {
    const {filters} = useProductContext()

    return(
        <div className="product-info">
            <div className="column">
                <h1>Gepersonaliseerde Qwesto</h1>
                <p className="grey-text">Samengesteld door en voor jou</p>
            </div>
            <div className="column">
                <h2>Eigenschappen</h2>
                <p className="grey-text">De opbouw van een Qwesto wordt bepaald door de volgende eigenschappen:</p>
                    <FilterInfo filters={filters}/>
            </div>
        </div>
    )
}