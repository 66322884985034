import { doc, onSnapshot } from 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import { db } from '../config/firebaseConfig';
import { productFilters } from '../data/constants';
import { formatCurrency } from '../utils/pure/payments/formatCurrency';

export const ProductContext = createContext();

export const ProductContextProvider = ({ children }) => {
  const [products, setProducts] = useState(null);
  const [productsUnfiltered, setProductsUnfiltered] = useState(null);
  const [noProductData, setNoProductData] = useState(false)
  const [filters, setFilters] = useState(productFilters)

  function handleNoData(){
    setProductsUnfiltered(null)
    setNoProductData(true)
  }

  useEffect(() => {
    const docRef = doc(db, "shop", "products")
    const unsub = onSnapshot(docRef, (doc) => {
        if(doc.data()){
          let productArray = []
          Object.values(doc.data()).forEach((product) => {
            switch(product.metadata.type){
              case "PRODUCT":
                productArray.push({
                  price: formatCurrency(product.prices[0].unit_amount, product.prices[0].currency), 
                  target: Number(product.metadata.target), 
                  duration: Number(product.metadata.duration), 
                  ...product
                })
                break;
              default:
                return
            }
          })
          setProductsUnfiltered(productArray)
          setNoProductData(false)
        }else{
          handleNoData()
        }
      }, error => {
        handleNoData()
        console.log(error);
      }
    )
    return () => unsub()
  }, [])

  useEffect(() => {
    if(productsUnfiltered){
      const filteredProducts = productsUnfiltered.filter((product) => {
        if((!filters.target || product.target === filters.target) 
        && (!filters.duration || product.duration === filters.duration)){
            return true
        }
        return false
      })
      setProducts(filteredProducts)
    }
  }, [productsUnfiltered, filters])

  return(
    <ProductContext.Provider value={{products, noProductData, filters, setFilters, }}>
      {children}
    </ProductContext.Provider>
  );
};
