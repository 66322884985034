export function getPlayerByUID(uid, groupData){
    let result = null;
    if(!groupData || !groupData.players){
        console.log("No players found");
        return result;
    }
    groupData.players.forEach(player => {
        if(player.uid === uid){
            result = player;
        }
    });
    return result;
}