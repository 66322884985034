import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import PopUp from "../../../components/layout/PopUp";
import ActionCard from "./ActionCard"
import Check from "../../../assets/img/Check";
import Complete from "../../../assets/img/Complete";
import Play from "../../../assets/img/Play";
import Screen from "../../../assets/img/Screen";
import { useMessageContext } from "../../../hooks/useMessageContext";
import { functions } from "../../../config/firebaseConfig";
import { httpsCallable } from "firebase/functions";
import Trash from "../../../assets/img/Trash";
import ReplayCard from "./ReplayCard";
import Share from "../../../components/input/Share";

export default function CardList({groupProgress, groupDoc, intakeData}){
    const { user } = useAuthContext()
    const { qwestoID } = useParams()
    const {setNewError} = useMessageContext()
    const [confirmIsPending, setConfirmIsPending] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const onConfirmGroup = async (e) => {
        e.preventDefault()
        if(groupDoc.players.length > 2){
            setConfirmIsPending(true)
            const handleConfirmGroup = httpsCallable(functions, "handleConfirmGroup")
            handleConfirmGroup({qwestoID: qwestoID})
            .then((result) => {
                console.log(result);
                setConfirmIsPending(false)
            })
            .catch((error) => {
                setNewError("Er is iets misgegaan, probeer het later opnieuw")
                setConfirmOpen(false)
                console.log(error);
                setConfirmIsPending(false)
            })
        }else{
            setNewError("Voeg meer spelers toe")
            setConfirmOpen(false)
        }
    }

    if(!groupDoc.product.qwestoIsDeleted){
        return(
            <>
            <PopUp title={groupDoc.groupIsConfirmed ? "Je groep is bevestigd!" : "Groep bevestigen"} visible={confirmOpen} setVisible={setConfirmOpen} unclosable={confirmIsPending}>
                {groupDoc.groupIsConfirmed ?
                    <div className="border-column">
                        <span className="text-small grey-text">Laat je groep snel weten dat ze kunnen beginnen met invullen:</span>
                        <div className="row">
                            <Share type="WHATSAPP" message={`Hey '${groupDoc.groupName}', vul de intake in: https://qwesto.nl/groups/${qwestoID}`} className="player-card hover" title="Delen" status="Deel via WhatsApp"/>
                            <Share type="COPY" message={`https://qwesto.nl/groups/${qwestoID}`} className="player-card hover" title="Delen" status="Kopiëer link"/>
                        </div>
                    </div>
                :
                    <form className="border-column gap" onSubmit={onConfirmGroup}>
                        {groupDoc.players.length !== groupDoc.product.properties.maxPlayers && <span className="text-small red-text">Let op: je groep zit nog niet vol. Er zijn pas {groupDoc.players.length} van de {groupDoc.product.properties.maxPlayers} spelers toegetreden.</span>}
                        <div className="row stay">
                            <input type="checkbox" required/>
                            <p>Ja, ik begrijp dat na deze bevestiging geen spelers meer kunnen toetreden of verwijderd kunnen worden.</p>
                        </div>
                        <button className="btn-gr" disabled={confirmIsPending}>{confirmIsPending ? <QwestoSmall className="load"/> : "Bevestig groep"}</button>
                    </form>
                }
            </PopUp>    
            {groupDoc.qwestoFinished &&
                <>
                    {groupDoc.host.uid === user.uid &&
                        <ReplayCard groupDoc={groupDoc}/>
                    }
                </>
            }
            <div className="card-list">
                {groupDoc.groupIsConfirmed ? 
                    <>  
                        {groupProgress === 100 ?
                            <>
                                {groupDoc.phoneViewer ?
                                    <ActionCard to={`/groups/${qwestoID}/phone-viewer`} title="Spelen" dot="orange" status="Beschikbaar" color={groupDoc.qwestoFinished ? "black-white" : null} icon=<Play className="icon badge"/>/>
                                    :
                                    <>
                                        <ActionCard to={`/groups/${qwestoID}/controller`} title="Spelen" dot="" status="met telefoon" color={groupDoc.qwestoFinished ? "black-white" : null} icon=<Play className={"icon badge"}/>/>
                                        {groupDoc.host.uid === user.uid && 
                                            <ActionCard to={`/groups/${qwestoID}/viewer`} title="Weergeven" dot="" status="met groot scherm" color={groupDoc.qwestoFinished ? "black-white" : null} icon=<Screen className="icon badge"/>/>
                                        }
                                    </>
                                }
                            </>
                        :   
                            <>
                            {intakeData.indexes && intakeData.indexes !== "NONE" &&
                                <>
                                    {intakeData.indexes[user.uid][0] === "FINISHED" ? 
                                        <ActionCard title="Invullen" dot="green" status="Voltooid" icon=<Complete className="icon badge"/> color="green"/>
                                    :
                                        <ActionCard to={`/groups/${qwestoID}/intake`} title="Invullen" dot="orange" status="Beschikbaar" icon=<Complete className="icon badge"/>/>
                                    }
                                </>
                            }
                            </>
                        }
                    </>
                    :
                    <>
                        {groupDoc.host.uid === user.uid ?
                            <ActionCard onClick={() => {setConfirmOpen(true)}} title="Bevestigen" dot="orange" status="Bevestig je groep" icon=<Check className="icon badge"/>/>
                            :
                            <ActionCard title="Invullen" dot="orange" status={`Beschikbaar als ${groupDoc.host.nickName} de groep heeft bevestigd`} icon=<Complete className="icon badge"/> color="black-white"/>
                        }
                    </>
                }
            </div>
            </>
        )
    }else{
        return(
            <div className="card-list">
                <ActionCard title="Verwijderd" status="" icon=<Trash className="icon badge"/> color="black-white"/>
            </div>
        )
    }
};
