import { useState } from "react";
import PopUp from "../../../components/layout/PopUp";
import Status from "../../../components/layout/Status";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useChangeNickname } from "../../../hooks/useChangeNickname";
import { useDeletePlayer } from "../../../hooks/useDeletePlayer";
import { getTimeDifference } from "../../../utils/pure/getTimeDifference";
import Trash from "../../../assets/img/Trash";

export default function PlayerPopUp({groupDoc, profileData, profileIsVisible, setProfileIsVisible}){
    const {user} = useAuthContext()
    const {deleteIsPending, tryDeletePlayer} = useDeletePlayer()
    const {changeIsPending, tryChangeNickname} = useChangeNickname()
    const [nickName, setNickName] = useState(profileData.nickName)

    function handleDelete(e){
        e.preventDefault()
        tryDeletePlayer(groupDoc, groupDoc.qwestoID, profileData)
        setProfileIsVisible(false)
    }

    function handleChangeNickName(e){
        e.preventDefault()
        tryChangeNickname(groupDoc, groupDoc.qwestoID, user, nickName)
        setProfileIsVisible(false)
    }

    return(
        <PopUp title={"Profiel"} visible={profileIsVisible} setVisible={setProfileIsVisible}>
            <div className="border-column gap center">
                <img src={profileData.photoURL} alt="" className="avatar big"/>
                <span className="text-small">{profileData.displayName}</span>
            </div>
            <div className="border-column center">
                <span className="text-small">Bijnaam</span>
                {(profileData.uid === user.uid && !groupDoc.groupIsConfirmed) ?
                        <form className="row stay" onSubmit={(e) => {handleChangeNickName(e)}}>
                            <input type="text" id="displayName" 
                                required
                                maxLength={20}
                                minLength={3}
                                onChange={(e) => setNickName(e.target.value.replace(/[^a-z]/gi, ''))}
                                value={nickName} 
                            />
                            <button className="btn" type="submit" disabled={changeIsPending}>Aanpassen</button>
                        </form>
                :
                    <span className="text-large">{profileData.nickName}</span>
                }
            </div>
            <div className="border-column center">
                    {groupDoc.host.uid === user.uid && profileData.uid !== user.uid && !groupDoc.groupIsConfirmed &&
                        <button className="btn round small-padding" disabled={deleteIsPending} onClick={(e) => {handleDelete(e)}}><Trash className="icon"/></button>
                    }
                    <Status message={`${getTimeDifference(profileData.joinedAt)} aanwezig`} dot="green"/>
            </div>
        </PopUp>
    )
};
