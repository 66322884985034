import { Link } from "react-router-dom";

export default function ArticleItem({article}){
    return(
        <Link to={`/blog/${article.pathID}`} className="article-list-item">
            <div className="article-list-item-label">
                <span className="text-medium">{article.title}</span>
                <span className="text-xxsmall">{article.date}</span>
            </div>
            <img src={article.photoURL} alt="" className="article-list-item-img"/>
        </Link>
    )
};