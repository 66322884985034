import React, { useState } from "react";
import ArrowDown from "../../assets/img/ArrowDown";
import ArrowUp from "../../assets/img/ArrowUp";
import FilterSteps from "../products/FilterSteps";
import "./Hero.css"
import { useNavigate } from "react-router-dom";
import { HERO_VIDEO_COMPRESSED_URL } from "../../data/constants";

export default function Hero({filterVisible, setFilterVisible, dynamicHeroTitle}){
    const navigate = useNavigate()
    const [videoIsLoaded, setVideoIsLoaded] = useState(false)

    function handleVideoLoad(){
        setVideoIsLoaded(true)
    }

    function handleFilterClick(){
        if(!filterVisible){
            setFilterVisible(true)
        }
    }

    return(
        <div className="content-field white no-overflow">
            <div className="content-container min-height not-relative">
                <div className="hero-banner">
                    {!videoIsLoaded && 
                        "test jonge"
                    }
                    <video className="hero-banner-video" src={HERO_VIDEO_COMPRESSED_URL} autoPlay muted playsInline loop
                        onCanPlay={handleVideoLoad}
                    />
                </div>
                <div className="hero-column">
                    <div className="hero-row">
                        <div className="hero-row-item front">
                            <h1 className="hero-title">{dynamicHeroTitle}</h1>
                            <h2 className="hero-description">Speel gemakkelijk gepersonaliseerde quizzen gebaseerd op jouw foto’s, video’s en verhalen.</h2>
                            <div className="row stay gap flex-start">
                                {filterVisible ? 
                                    <button className="btn-gr round no-grow hero" onClick={() => {setFilterVisible(false)}}>Beginnen<ArrowUp className="icon"/></button>
                                : 
                                    <button className="btn-gr round no-grow hero active" onClick={() => {setFilterVisible(true)}}>Beginnen<ArrowDown className="icon"/></button>
                                }
                                <button className="btn text no-grow" onClick={() => {navigate("/how-it-works")}}>Hoe werkt het?</button>
                            </div>
                            <div className={`hero-filter-container ${filterVisible ? "active" : ""}`}>
                                <div className="hero-filter-container-blocker" onClick={handleFilterClick}/>
                                <FilterSteps />
                            </div>
                        </div>
                        <div className="hero-row-item">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


