import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { doc, arrayUnion, increment, runTransaction, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";
import { getCurrentTime } from "../utils/pure/getCurrentTime";

export const useInvite = () => {
    const [isPending, setIsPending] = useState(false)
    const [groupDoc, setGroupDoc] = useState(null)
    const [noData, setNoData] = useState(false)
    const navigate = useNavigate()
    const {setNewInfo, setNewError} = useMessageContext()

    function tryPrepareJoin(qwestoID, inviteKey, user){
        if(!groupDoc){
            const groupDocRef = doc(db, "groups", qwestoID)
            updateDoc(groupDocRef, {
                playersInvited: arrayUnion(user.uid),
                inviteKey: inviteKey
            })
            .then(async () => {
                const unsub = onSnapshot(groupDocRef, (doc) => {
                    if (doc.exists()) {
                        setGroupDoc({ ...doc.data(), id: doc.id });
                        setNoData(false);
                    } else {
                        setNoData(true);
                    }
                });

                return () => unsub();
            })
            .catch(error => {
                console.log(error);
                setNoData(true)
            })
        }
    }

    async function tryJoinGroup(inviteKey, qwestoID, user, userDoc, nickName){
        if(isPending){
            return
        }
        setIsPending(true)
            try {
                const groupDocRef = doc(db, "groups", qwestoID)
                await updateDoc(groupDocRef, {
                    playersAllowed: arrayUnion(user.uid),
                    inviteKey: inviteKey
                })
                await runTransaction(db, async (transaction) => {
                    const userDocRef = doc(db, "users", user.uid)
                    const groupDocSnap = await transaction.get(groupDocRef);
                    const groupDocData = {...groupDocSnap.data(), id: groupDocSnap.id}
                    const userDocData = (await transaction.get(userDocRef)).data();

                    if(!groupDocData || !userDocData || groupDocData.groupIsConfirmed){
                        // Group or user document does not exist, handle error condition
                        setNewError("Er is iets misgegaan, probeer het later opnieuw")
                        setIsPending(false)
                        return;
                    }
                    if(groupDocData.players.some(player => player.uid === user.uid)){
                        setNewInfo(`Je bent al lid van ${groupDocData.groupName}`)
                        setIsPending(false)
                        navigate(`/groups/${qwestoID}`)
                        return;
                    }
                    if(groupDocData.players.some(player => player.nickName === nickName)){
                        setNewError(`Iemand in deze groep heeft de naam ${nickName} al in gebruik, kies iets anders`)
                        setIsPending(false)
                        return;
                    }
                    if(groupDocData.groupIsConfirmed){
                        setNewError("De host heeft deze Qwesto al gestart")
                        setIsPending(false)
                        return;
                    }
                    if(groupDocData.players.length >= groupDoc.product.properties.maxPlayers){
                        setNewError("Deze Qwesto zit vol")
                        setIsPending(false)
                        return;
                    }

                    transaction.update(groupDocRef, {
                        players: arrayUnion({
                            nickName: nickName,
                            displayName: user.displayName,
                            photoURL: user.photoURL,
                            uid: user.uid,
                            joinedAt: getCurrentTime(),
                            gender: userDoc.gender
                        }),
                        playersAllowed: arrayUnion(user.uid),
                        inviteKey: inviteKey
                    });
                    transaction.update(userDocRef, {
                        totalQwestos: increment(1)
                    });

                    setIsPending(false)
                    setNewInfo("Welkom!")
                    navigate(`/groups/${qwestoID}`)
                });
            }catch (error) {
                setNewError("Er is iets misgegaan, probeer het later opnieuw")
                console.log(error);
                setIsPending(false)
            }
        }
        
    return {tryJoinGroup, tryPrepareJoin, isPending, noData, groupDoc}
}