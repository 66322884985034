import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useState, useEffect } from "react";
import IntakeContainer from "./IntakeContainer"
import "./Intake.css"
import Loader from "../../../components/loaders/Loader";
import Redirect from "../../../components/layout/Redirect";
import { getActiveIntakeQuestion } from "../../../utils/pure/getActiveIntakeQuestion";
import BackButton from "../../../components/input/BackButton";
import { useGroupDataContext } from "../../../hooks/useGroupDataContext";
import Instructions from "./Instructions";
import { useMemo } from "react";

export default function Intake(){
    const {qwestoID} = useParams()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "GROUP_AND_INTAKE_DATA",
        qwestoID: qwestoID
    }), [qwestoID]))
    const {user} = useAuthContext()
    const navigate = useNavigate()

    // Intake variables
    const [intakeIndex, setIntakeIndex] = useState(null)
    const [intakeLength, setIntakeLength] = useState(null)
    const [activeQuestion, setActiveQuestion] = useState(null)
    
    useEffect(() => {
        if(groupData.group && groupData.intake){
            const [question, index, length] = getActiveIntakeQuestion(groupData.group, groupData.intake, user.uid)
            setIntakeIndex(index)
            setIntakeLength(length)
            setActiveQuestion(question)
            if(!question){
                navigate(`/groups/${qwestoID}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData, user.uid, qwestoID]);

    if(noGroupData){
        return(
            <Redirect to={`/groups/${qwestoID}`} message="Deze Intake bestaat niet." action="Terug" arrow="left"/>
        )
    }

    if(!groupData.group){
        return(
            <Loader/>
        )
    }

    if(!groupData.group.groupIsConfirmed){
        return(
            <Redirect to={`/groups/${qwestoID}`} message="Deze Intake is nog niet gestart." action="Terug" arrow="left"/>
        )
    }

    if(!groupData.intake){
        return(
            <Loader/>
        )
    }

    return(
        <>
            <div className="content-page full">
                <div className="content-field white">
                    <BackButton to={`/groups/${qwestoID}`}/>
                    <IntakeContainer groupDoc={groupData.group} activeQuestion={activeQuestion} intakeLength={intakeLength} intakeIndex={intakeIndex}/>
                    {activeQuestion && <Instructions activeQuestion={activeQuestion} intakeIndex={intakeIndex}/>}
                </div>
            </div>
        </>
    )
};
