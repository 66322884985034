import QwestoSmall from "../../assets/img/QwestoSmall"
import "./Loader.css"

export default function Loader({small}){
    return(
    <>
    {small ? 
        <QwestoSmall alt="Loading..." className="loader"/>
    :
    <div className="content-page full">
        <div className="content-container center">
            <QwestoSmall alt="Loading..." className="loader"/>
        </div>
    </div>
    }
    </>
    )    
};
