export default function ArrowDown(props) {
    return (
    <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <g id="Arrow / Arrow_Down_MD">
        <path
        id="Vector"
        d="M12 5V19M12 19L18 13M12 19L6 13"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </g>
    </svg>
    );
  }