import React, { useEffect, useRef, useState } from 'react';
import ReactDOM  from "react-dom"
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import "./VideoTrimmer.css"
import Video from '../../assets/img/Video';
import { useMessageContext } from '../../hooks/useMessageContext';
import Pause from '../../assets/img/Pause';
import Play from '../../assets/img/Play';

export default function VideoTrimmer({title, setMetadata, setVideo, videoURL, setVideoURL, minLength, maxLength}){
    const { setNewError } = useMessageContext()
    const [videoDuration, setVideoDuration] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoFile, setVideoFile] = useState(null);
    const [trimmerOpen, setTrimmerOpen] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const videoRef = useRef();
    let initialSliderValue = 0;

    // Handle Upload of the video
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file){
            setNewError("Geen bestand geselecteerd.")
            return
        }
        if(!file.type.includes("video")){
            setNewError("Het geselecteerde bestand moet een foto zijn.")
            return
        }
        if(file.size > 200000000){
            setNewError("De bestandgrootte moet kleiner zijn dan 200MB")
            return
        }
        const blobURL = URL.createObjectURL(file);
        setVideoFile(file);
        setVideoURL(blobURL);
        setTrimmerOpen(true)
        URL.revokeObjectURL(file)
    };

    // Get the duration of the video using videoRef
    useEffect(() => {
        if (videoRef && videoRef.current) {
            const currentVideo = videoRef.current;
            currentVideo.onloadedmetadata = () => {
                setVideoDuration(currentVideo.duration);
                setEndTime(currentVideo.duration);
            };
        }
    }, [videoURL]);

    // Called when handle of the nouislider is being dragged
    const updateOnSliderChange = (values, handle) => {
        let readValue;
        if(handle){
            readValue = values[handle];
            if(endTime !== readValue){
                setEndTime(readValue);
            }
        }else{
            readValue = values[handle];
            if(initialSliderValue !== readValue){
                initialSliderValue = readValue;
                if (videoRef && videoRef.current) {
                    videoRef.current.currentTime = readValue;
                    setStartTime(readValue);
                }
            }
        }
        if(videoRef && videoRef.current) {
            setIsPlaying(true)
            videoRef.current.play();
        }
    };

    // Pause the video when then the endTime matches the currentTime of the playing video
    const onTimeUpdate = (e) => {
        const currentTimeFloat = parseFloat(e.currentTarget.currentTime.toFixed(2))
        const startTimeFloat = parseFloat(startTime)
        const endTimeFloat = parseFloat(endTime)

        if(currentTimeFloat >= endTimeFloat){
            e.currentTarget.currentTime = startTime
            setCurrentTime(e.currentTarget.currentTime)
        }else if(currentTimeFloat < startTimeFloat){
            e.currentTarget.currentTime = startTime
            setCurrentTime(e.currentTarget.currentTime)
        }else{
            setCurrentTime(e.currentTarget.currentTime)
        }
    };

    function handleUseVideo(){
        setMetadata({
            customMetadata: {
                "startTime":  startTime.toString(),
                "endTime": endTime.toString(),
                "isCompressed": "false"
            }
        })
        setVideo(videoFile)
        setTrimmerOpen(false)
    }

    function handleVideo(){
        if(videoRef.current.paused){
            videoRef.current.play()
            setIsPlaying(true)
        }else{
            videoRef.current.pause()
            setIsPlaying(false)
        }
    }

    return(
        <>
            <input type="file" id="videoInput" accept=".mov, .mp4"
                onChange={handleFileChange}
                onClick={(e)=> { 
                    e.target.value = null
                }}
            /> 
            {(videoURL && !trimmerOpen) ? 
                <label htmlFor="videoInput" className={`video-input active`}>
                    <div className="video-input-label">
                        <Video className="icon"/>
                    </div>
                    <video playsInline className="video-input-video" autoPlay src={videoURL} onTimeUpdate={onTimeUpdate} ref={videoRef} type={videoFile.type}></video>
                </label>
            :
                <label htmlFor="videoInput" className={`video-input`}>
                    <Video className="icon"/>
                </label>
            }
            {videoURL && 
                <React.Fragment>
                    {trimmerOpen && ReactDOM.createPortal(
                        <div className="media-input trimmer">
                            <video playsInline src={videoURL} ref={videoRef} loop onTimeUpdate={onTimeUpdate} className="media-input-video">
                                <source src={videoURL} type={videoFile.type} />
                            </video>
                            <div className="media-input-trimmer-container">
                                <button className="btn no-grow" onClick={handleVideo}>{isPlaying ? <Pause className="icon"/> : <Play className="icon"/>}</button>
                                <div className="media-input-trimmer">
                                    <Nouislider
                                        behaviour="tap-drag"
                                        step={.01}
                                        margin={minLength}
                                        limit={maxLength}
                                        range={{ 
                                            min: 0, 
                                            max: videoDuration || 2 
                                        }}
                                        start={[0, videoDuration || 2]}
                                        connect
                                        onUpdate={updateOnSliderChange}
                                    />
                                    {videoRef.current && <div className="media-input-trimmer-current" style={{left: `calc(${currentTime / videoRef.current.duration * 100}%)`}}></div>}
                                </div>
                            </div>
                            <div className="media-input-controls">
                                <span className="text-small">{title}</span>
                                <div className="row stay gap">
                                    <button className="btn-gr" onClick={handleUseVideo}>Gebruiken</button>
                                </div>
                            </div>
                        </div>, 
                        document.querySelector("#index"))
                    } 
                </React.Fragment>
            }
        </>
    );
}