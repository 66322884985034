import { useEffect, useState } from "react";
import Footer from "../../components/layout/Footer";
import SEO from "../../components/utils/SEO";
import ArticleDisplayer from "../blog/ArticleDisplayer";
import Redirect from "../../components/layout/Redirect";
import { LEGALS } from "../../data/legals";


export default function Legals({pathID}){
    const [article, setArticle] = useState(null)

    useEffect(() => {
        if(LEGALS){
            setArticle(LEGALS.find((article) => article.pathID === pathID))
        }
    }, [pathID])

    if(!article){
        return(
            <Redirect to="/blog" message="We hebben dit artikel niet kunnen vinden" arrow="left" action="Terug"/>
        )
    }

    return(
        <>
            <SEO title={article.title} description={article.description} canonical={article.pathID}/>
            <div className="content-page overflow">
                <ArticleDisplayer article={article} noPhoto noFilter/>
                <Footer/>
            </div>
        </>
    )
};