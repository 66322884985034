import { Outlet } from "react-router-dom";
import { GameAudioContextProvider } from "./GameAudioContext";

export const GameAudioLayout = () => {
    return(
        <GameAudioContextProvider>
            <Outlet/>
        </GameAudioContextProvider>
    )
}

