import { WhatIsTrueController } from "./WhatIsTrueController";
import { BestAnswerController } from "./BestAnswerController";
import { BestPhotoController } from "./BestPhotoController";
import "./Controller.css"
import { PlayerController } from "./PlayerController";

export default function AnswerController({activeQuestion, gameDoc, groupDoc}) {
    switch(activeQuestion.type){
        case "MOSTLIKELY":
        case "GUESSWHO":
            return <PlayerController groupDoc={groupDoc} gameDoc={gameDoc} />
        case "PHOTOREVEAL":
            return <PlayerController groupDoc={groupDoc} gameDoc={gameDoc} positions={true}/>
        case "WHATISTRUE":
            return <WhatIsTrueController activeQuestion={activeQuestion} groupDoc={groupDoc} gameDoc={gameDoc}/>
        case "WHATDOYOUSEE":
        case "WHATDOYOUHEAR":
            return <WhatIsTrueController activeQuestion={activeQuestion} groupDoc={groupDoc} gameDoc={gameDoc} positions/>
        case "BESTANSWER":
            return <BestAnswerController activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
        case "BESTPHOTO":
            return <BestPhotoController activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
        case "SKIPPED":
            return <>
                <span className="text-large">Helaas</span>
                <span className="text-medium">Iemand heeft deze vraag overgeslagen</span>
            </>
        default:
            return null
    }
}
