import React, { useState } from 'react'
import { useSignOut } from "../../hooks/useSignOut";
import { useAuthContext } from "../../hooks/useAuthContext";
import QwestoSmall from '../../assets/img/QwestoSmall';
import PopUp from '../../components/layout/PopUp';
import { useDeleteProfile } from '../../hooks/useDeleteProfile';
import { useNavigate } from 'react-router-dom';

export default function Actions({groupDocs}){
    const { trySignOut, isPending } = useSignOut()
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [password, setPassword] = useState("")
    const { user } = useAuthContext()
    const {tryDeleteProfile, deleteIsPending} = useDeleteProfile()
    const navigate = useNavigate()

    const handleDelete = async (e) => {
        e.preventDefault()
        if(user){
            tryDeleteProfile(user, password, groupDocs)
        }
    }

    return(
        <div className="container no-grow box-shadow">
            <div className="border-column">
                <h4>Profiel verwijderen</h4>    
                <div className="row stay">
                    <p className="grey-text">Weet je het zeker?</p>
                    <button className="btn text" onClick={() => {setDeleteVisible(true)}}>Verwijderen</button>
                </div>
                <PopUp title={"Profiel verwijderen"} visible={deleteVisible} setVisible={setDeleteVisible}>
                    <form className="border-column" onSubmit={handleDelete}>
                        <p className="grey-text">Vul je wachtwoord in ter bevestiging:</p>
                            <input type="password" id='password'
                            autoComplete="password"
                            required
                            minLength={6}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password} 
                            />
                            {!deleteIsPending ? <button className="btn">Ja, ik weet het zeker</button> : <button className="btn" disabled><QwestoSmall className="load"/></button>}
                    </form>
                </PopUp>
            </div>
            <div className="border-column">
                <h4>Support</h4>
                <p className="grey-text">support@qwesto.nl</p>
            </div>
            {user.uid === "M14xiNvxS9SLyokCUX98MLJagp82" &&
                <div className="border-column">
                    <div className="row">
                        <button className="btn" onClick={() => {navigate("/manager")}}>Manager</button>
                    </div> 
                </div>
            }
            <div className="border-column">
                <div className="row">
                    {!isPending && <button className="btn black" onClick={trySignOut}>Log uit</button>}
                    {isPending && <button className="btn black" disabled><QwestoSmall className="load"/></button>}
                </div>
            </div>
        </div>
    )
}