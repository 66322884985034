import { ref as refRDB, runTransaction } from "firebase/database";
import { rdb } from "../../config/firebaseConfig";


export async function setIntakeIndexes(qwestoID, uid){
    await runTransaction(refRDB(rdb, `intakes/${qwestoID}/indexes/${uid}`), (indexArray) => {
        if(indexArray){
            indexArray.shift()
        }
        if(indexArray.length === 0){
            indexArray = ["FINISHED"]
        }
        return indexArray
    })
}