import { useState } from "react";
import Pencil from "../../../assets/img/Pencil";
import Share from "../../../components/input/Share";
import Status from "../../../components/layout/Status";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { getInviteURL } from "../../../utils/pure/getInviteURL";
import { getTimeDifference } from "../../../utils/pure/getTimeDifference";
import PlayerPopUp from "./PlayerPopUp";

export default function PlayerList({commonLength, groupDoc, intakeData}){
    const {user} = useAuthContext()
    const [profileIsVisible, setProfileIsVisible] = useState(false)
    const [profileData, setProfileData] = useState(null)
    const inviteURL = getInviteURL(groupDoc)

    function handleProfile(data){
        setProfileData(data)
        setProfileIsVisible(true)
    }

    if(!groupDoc.product.qwestoIsDeleted){
        return(
            <div className="player-list">
                {!groupDoc.groupIsConfirmed && groupDoc.host.uid === user.uid && groupDoc.players.length !== groupDoc.product.properties.maxPlayers &&
                    <>
                        <Share type="WHATSAPP" message={`Hoi, kom je meedoen met mijn '${groupDoc.groupName}' Qwesto? Klik op de link om de uitnodiging te accepteren: ${inviteURL}`} className="player-card hover" title="Uitnodigen" status="Deel via WhatsApp"/>
                        <Share type="COPY" message={inviteURL} className="player-card hover" title="Uitnodigen" status="Kopiëer link"/>
                    </>
                }
                {groupDoc.players.map(player => (
                    <div key={player.uid} className="player-card hover" onClick={() => {handleProfile(player)}}>
                        {groupDoc.host.uid === player.uid && <span className="player-card-badge">Host</span>}
                            <img src={player.photoURL} className="avatar" alt=""/>
                        <div className="column no-gap">
                            <div className="row stay">
                                <h3>{player.nickName}</h3>
                                {player.uid === user.uid && <Pencil className="icon"/>}
                            </div>
                            {groupDoc.groupIsConfirmed && intakeData.indexes[player.uid] ? 
                                <>
                                {intakeData.indexes[player.uid][0] === "FINISHED" ?
                                    <Status dot="green" message="Klaar met invullen"/>
                                    :
                                    <>
                                        {intakeData.indexes[player.uid].length === (commonLength + Object.keys(groupDoc.intakeQuestions[player.uid]).length) ? 
                                            <Status dot="grey" message="Invullen niet begonnen"/>:
                                            <Status dot="orange" message="Bezig met invullen"/>
                                        }
                                    </>}
                                </>
                            :
                                <Status message={`${getTimeDifference(player.joinedAt)} aanwezig`}/>
                            }
                        </div>
                    </div>
                ))}
                {!groupDoc.groupIsConfirmed && [...Array(groupDoc.product.properties.maxPlayers - groupDoc.players.length)].map((element, index) => (
                    <div key={index} className="player-card empty"/>
                ))}
                {profileData &&
                    <PlayerPopUp groupDoc={groupDoc} profileData={profileData} profileIsVisible={profileIsVisible} setProfileIsVisible={setProfileIsVisible}/>
                }
            </div>
        )
    }else{
        return(
            <div className="player-list">
                {groupDoc.players.map(player => (
                    <div key={player.uid} className="player-card hover" onClick={() => {handleProfile(player)}}>
                        {groupDoc.host.uid === player.uid && <p className="player-card-badge">Host</p>}
                        <img src={player.photoURL} className="avatar" alt=""/>
                        <div className="column no-gap">
                            <h3>{player.nickName}</h3>
                            <Status message={`${getTimeDifference(player.joinedAt)} aanwezig`}/>
                        </div>
                    </div>
                ))}
                {profileData &&
                    <PlayerPopUp groupDoc={groupDoc} profileData={profileData} profileIsVisible={profileIsVisible} setProfileIsVisible={setProfileIsVisible}/>
                }
            </div>
        )
    }
};
