import { Link } from "react-router-dom"

export default function ActionCard({to, title, dot, status, icon, onClick, color}) {
    return (
        <>
            {to ?
                <Link to={to} className={`action-card ${color ? color : ""}`}>
                    {icon}
                    <h3>{title}</h3>
                    <div className="row stay flex-start">
                        {dot &&
                            <div className={"dot " + dot}></div>
                        }
                        <span className="status">{status}</span>
                    </div>
                </Link>
            :
                <div className={`action-card ${color ? color : ""}`} onClick={onClick}>
                    {icon}
                    <h3>{title}</h3>
                    <div className="row stay flex-start">
                        {dot &&
                            <div className={"dot " + dot}></div>
                        }
                        <span className="status">{status}</span>
                    </div>
                </div>
            }
        </>
    )
}
