import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { getCurrentTime } from "../utils/pure/getCurrentTime";

export function usePurchase(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError} = useMessageContext()
    const navigate = useNavigate()

    async function tryPurchase(product, playerQuantity, user, userDoc, groupName, nickName, filters){  
        if(!user){
            navigate("/login?redirect=/product")
        }else if(!isPending){
            setIsPending(true)
            const productItem = {price: product.prices[0].id, quantity: playerQuantity}
            const checkoutRef = collection(db, "users", user.uid, "checkout_sessions")
            const checkoutDocRef = await addDoc(checkoutRef, {
                line_items: [productItem],
                mode: "payment",
                success_url: (window.location.origin + "/payment-success"),
                cancel_url: window.location.origin + "/product",
                metadata: {
                    replay: false,
                    productID: product.id,
                    uid: user.uid,
                    nickName: nickName,
                    displayName: user.displayName,
                    userPhotoURL: user.photoURL,
                    joinedAt: getCurrentTime(),
                    gender: userDoc.gender,
                    groupName: groupName,
                    filters: JSON.stringify(filters)
                }
            })
            onSnapshot(checkoutDocRef, (snap) => {
                const { error, url } = snap.data();
                if(error){
                    setIsPending(false)
                    console.log(error.message);
                    setNewError("Er is iets misgegaan");
                }
                if(url){
                    window.location.assign(url);
                }
            })
        }
    }

    return { isPending, tryPurchase }
}
