import React, { useEffect, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import "./CookieConsent.css";
import { setGoogleTags } from '../../utils/pure/tracking/setGoogleTags';

export default function Cookies(){
    const [accepted, setAccepted] = useState(getCookieConsentValue("cookieConsent"));

    useEffect(() => {
        setAccepted(getCookieConsentValue("cookieConsent")); // set the state
    }, []);

    function handleAccept(){
        setAccepted(true);
        setGoogleTags();
    }

    function handleDecline(){
        setAccepted(false);
    }

    return (
        <>
            {accepted === undefined &&
                <div className="cookie-consent">
                    <div className="column flex-start">
                        <span>Qwesto maakt gebruik van cookies.</span>
                        <Link to="/cookies" target="_blank" rel="noopener noreferrer" className="text-xxsmall">Lees meer</Link>
                    </div>
                    <CookieConsent
                        location="none" // Position of the cookie banner
                        buttonText="Accepteren" // Text for the accept button
                        enableDeclineButton
                        declineButtonClasses="btn"
                        declineButtonText="Weigeren" // Text for the decline button
                        buttonClasses="btn black"
                        cookieName="cookieConsent" // Change this to your desired cookie name
                        style={{position: "relative", display: "flex", flexGrow: 0, padding: 0, width: "auto", zIndex: 100, background: "none"}}
                        buttonStyle={{ margin: 0, background: "white", color: "black", fontSize: '16px', fontWeight: 500, padding: "10px 15px", borderRadius: 10, width: "100%"}}
                        declineButtonStyle={{ margin: 0, background: "none", color: "var(--grey-text)", fontSize: '16px', fontWeight: 500, padding: "10px 15px", borderRadius: 10, width: "100%"}}
                        contentStyle={{ margin: 0 }}
                        expires={365} // Number of days until the cookie expires
                        onAccept={handleAccept}
                        onDecline={handleDecline}
                        debug={false}
                        >
                    </CookieConsent>
                </div>
            }
        </>
    );
};
