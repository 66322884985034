import { getCurrentTime } from "./getCurrentTime";

export function getTimeDifference(time) {
    const now = getCurrentTime()
    const difference = now - time
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if(days === 1){
        return days + " dag";
    }
    if(days >= 1){
        return days + " dagen";
    }
    if(hours >= 1){
        return hours + " uur";
    }
    if(minutes === 1){
        return minutes + " minuut";
    }
    if(minutes >= 1){
        return minutes + " minuten";
    }
    return seconds + " seconden";
}