import {useState} from "react";
import {ref, runTransaction, update} from 'firebase/database';
import {rdb} from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";

export function useSubmitAnswer(){
    const {setNewError} = useMessageContext()
    const [isPending, setIsPending] = useState(false)

    function trySubmitAnswer(qwestoID, uid, answer, positions){
        if(!isPending){
            if(!positions){
                setIsPending(true)
                update(ref(rdb, "games/" + qwestoID + "/answers"), {
                    [uid]: answer
                }).then(() => {
                    setIsPending(false)
                })
                .catch(() => {
                    setNewError("Er is een fout opgetreden...")
                    setIsPending(false)
                })
            }else{
                runTransaction(ref(rdb, "games/" + qwestoID), (gameDoc) => {
                    let position = 1
                    Object.values(gameDoc.answers).forEach((currentAnswer) => {
                        if(currentAnswer !== "EMPTY" && currentAnswer.position !== "EMPTY"){
                            position++
                        }
                    })

                    gameDoc.answers[uid] = {
                        position: ((gameDoc.correctAnswer === answer || gameDoc.correctAnswer.includes(answer)) ? position : "EMPTY"),
                        answer: answer
                    }
                    return gameDoc
                })
                .catch(() => {
                    setNewError("Er is een fout opgetreden...")
                    setIsPending(false)
                })
            }


        }
    }

    return { isPending, trySubmitAnswer }
}