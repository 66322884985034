import Check from "../../assets/img/Check"
import QwestoSmall from "../../assets/img/QwestoSmall"
import Time from "../../assets/img/Time"
import BestAnswerIcon from "../../assets/img/question_types/BestAnswerIcon"
import BestPhotoIcon from "../../assets/img/question_types/BestPhotoIcon"
import GuessWhoIcon from "../../assets/img/question_types/GuessWhoIcon"
import MostLikelyIcon from "../../assets/img/question_types/MostLikelyIcon"
import PhotoRevealIcon from "../../assets/img/question_types/PhotoRevealIcon"
import WhatDoYouHearIcon from "../../assets/img/question_types/WhatDoYouHearIcon"
import WhatDoYouSeeIcon from "../../assets/img/question_types/WhatDoYouSeeIcon"
import WhatIsTrueIcon from "../../assets/img/question_types/WhatIsTrueIcon"
import { introductionTypes, questionPoints } from "../../data/constants"

export function Introduction({activeQuestion}){

    if(activeQuestion.type !== "SKIPPED"){
        return(
            <div className="content-field question-introduction-backdrop">
                <div className="content-container center">
                    <div className="question-introduction">
                        <div className="question-introduction-container">
                            {(() => {
                                switch(activeQuestion.type){
                                    case "BESTPHOTO":
                                        return <BestPhotoIcon className="icon massive"/>
                                    case "BESTANSWER":
                                        return <BestAnswerIcon className="icon massive"/>
                                    case "WHATDOYOUSEE":
                                        return <WhatDoYouSeeIcon className="icon massive"/>
                                    case "WHATDOYOUHEAR":
                                        return <WhatDoYouHearIcon className="icon massive"/>
                                    case "WHATISTRUE":
                                        return <WhatIsTrueIcon className="icon massive"/>
                                    case "PHOTOREVEAL":
                                        return <PhotoRevealIcon className="icon massive"/>
                                    case "GUESSWHO":
                                        return <GuessWhoIcon className="icon massive"/>
                                    case "MOSTLIKELY":
                                        return <MostLikelyIcon className="icon massive"/>
                                    default:
                                        return <QwestoSmall className="icon big"/>
                                }
                            })()}
                            <span className="text-large responsive">{introductionTypes[activeQuestion.type].name}</span>
                            <span className="question-description">{introductionTypes[activeQuestion.type].description}</span>
                        </div>
                        <div className="question-introduction-info">
                            <div className="column center grey-text">
                                <div className="row stay">
                                    <Time className="icon big"/>
                                    <span className="text-small">{introductionTypes[activeQuestion.type].timeDependent ? 
                                        "Wees de snelste"
                                    : 
                                        "Geen tijdsdruk"
                                    }</span>
                                </div>
                                <div className="row stay">
                                    <Check className="icon big"/>
                                    <span className="text-small">
                                        {(() => {
                                            switch(activeQuestion.type){
                                                case "BESTPHOTO":
                                                case "BESTANSWER":
                                                    return questionPoints[activeQuestion.type] + " punten per stem"
                                                case "WHATDOYOUHEAR":
                                                case "PHOTOREVEAL":
                                                case "WHATDOYOUSEE":
                                                    return questionPoints[activeQuestion.type][questionPoints[activeQuestion.type].length - 1] + " tot " + questionPoints[activeQuestion.type][0] + " punten"
                                                default:
                                                    return questionPoints[activeQuestion.type] + " punten"
                                            }
                                        })()}  
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}