import { useAuthContext } from "../../../hooks/useAuthContext";
import { useUserContext } from "../../../hooks/useUserContext";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import Redirect from "../../../components/layout/Redirect";
import { useInvite } from "../../../hooks/useInvite";
import Loader from "../../../components/loaders/Loader";
import { useEffect } from "react";

export default function InviteSignedIn() {
    const {user} = useAuthContext()
    const {userDoc} = useUserContext()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const inviteKey = searchParams.get("invite_key")
    const qwestoID = searchParams.get("qwesto_id")

    const [nickName, setNickName] = useState("")
    const {tryJoinGroup, tryPrepareJoin, isPending, noData, groupDoc} = useInvite()

    useEffect(() => {
        if(qwestoID && inviteKey){
            tryPrepareJoin(qwestoID, inviteKey, user)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function submitJoin(e){
        e.preventDefault()
        tryJoinGroup(inviteKey, qwestoID, user, userDoc, nickName)
    }
    

    if(!inviteKey || !qwestoID){
        return(
            <Redirect to={"/groups"} message={"Deze link is niet geldig"} action={"Terug"} arrow={"left"}/>
        )
    }

    if(noData){
        return(
            <Redirect to={"/groups"} message={"Deze link is niet meer geldig"} action={"Terug"} arrow={"left"}/>
        )
    }
    if(!groupDoc){
        return(
            <Loader/>
        )
    }

    if(groupDoc.players.some(player => player.uid === user.uid) || groupDoc.groupIsConfirmed || groupDoc.players.length >= groupDoc.product.properties.maxPlayers){
        return(  
        <>
            {groupDoc.players.some(player => player.uid === user.uid) &&
                <Redirect to={`/groups/${qwestoID}`} message={"Je hebt deze uitnodiging al geaccepteerd"} action={"Ga naar de Qwesto"} arrow={"right"}/>
            }
            {groupDoc.groupIsConfirmed &&
                <Redirect to={"/groups"} message={"De host heeft deze Qwesto al gestart, laat je host weten als je denkt dat dit een fout is"} action={"Terug"} arrow={"left"}/>
            }
            {groupDoc.players.length >= groupDoc.product.properties.maxPlayers &&
                <Redirect to={"/groups"} message={"Deze Qwesto zit vol"} action={"Terug"} arrow={"left"}/>
            }
        </>
        )
    }

    return(
        <div className="group-banner-container">
            <img className="group-banner-img dark" src={groupDoc.groupPhotoURL} alt=""/> 
            <div className="content-container min-height center large-gap">
                <div className="column center">
                    <img src={groupDoc.host.photoURL} className="avatar big center" alt="" height="100px"/>
                    <span className="text-small bold">{groupDoc.host.nickName}</span>
                    <span className="text-small">heeft je uitgenodigd voor</span>
                    <span className="text-xlarge">"{groupDoc.groupName}"</span>
                </div>
                <form className="container no-grow box-shadow" onSubmit={(e) => submitJoin(e)} >
                    <div className="border-column no-border">
                        <label>Kies een naam of bijnaam
                            <input type="text" id="nickname" required minLength={2} maxLength={15} onChange={(e) => setNickName(e.target.value)} value={nickName}/>
                            <p className="grey-text">Gebruik een naam waar je medespelers je aan kunnen herkennen</p>
                        </label>
                    </div>
                    <div className="border-column">
                        {!isPending ?
                            <button className="btn-gr">Ik doe mee</button>
                            :
                            <button className="btn-gr" disabled><QwestoSmall className="load"/></button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
