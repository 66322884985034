import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import QwestoSmall from "../../../assets/img/QwestoSmall";
import { useDeleteQwesto } from "../../../hooks/useDeleteQwesto";
import { useSaveGroupSettings } from "../../../hooks/useSaveGroupSettings";
import PopUp from "../../../components/layout/PopUp";
import Trash from "../../../assets/img/Trash";
import Settings from "../../../assets/img/Settings";
import SelectionBar from "../../../components/input/SelectionBar";

export default function GroupSettings({groupDoc}){
    const { qwestoID } = useParams()
    const {deleteIsPending, tryDeleteQwesto} = useDeleteQwesto()
    const {settingsIsPending, trySaveGroupSettings} = useSaveGroupSettings()
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [phoneViewer, setPhoneViewer] = useState(groupDoc.phoneViewer)

    const onDeleteGroup = (e) => {
        e.preventDefault()
        tryDeleteQwesto(qwestoID)
    }

    async function onSaveSettings(){
        await trySaveGroupSettings(qwestoID, phoneViewer)
        setSettingsOpen(false)
    }

    return(
    <>
        <PopUp title="Qwesto verwijderen" visible={deleteOpen} setVisible={setDeleteOpen}>
            <form className="border-column border" onSubmit={onDeleteGroup}>
                <div className="row stay">
                    <input type="checkbox" required/>
                    <p>Ja, ik begrijp dat ik dit niet terug kan draaien.</p>
                </div>
                <button className="btn" disabled={deleteIsPending}>{deleteIsPending ? <QwestoSmall className="load"/> : "Verwijder deze Qwesto"}</button>
            </form>
        </PopUp>
        <PopUp title="Instellingen" visible={settingsOpen} setVisible={setSettingsOpen}>
            <div className="border-column no-border">
                <span className="text-small">Heeft je groep een groot scherm ter beschikking?</span>
                <p className="grey-text">Kies "Ja" als je groep naast alle telefoons nog beschikt over een extra scherm om de Qwesto op te spelen: <Link to="/blog/gebruik-schermen" target="_blank" rel="noopener noreferrer">meer uitleg</Link></p>
                <SelectionBar options={["Ja", "Nee"]} customValues={[false, true]} value={phoneViewer} setValue={setPhoneViewer} unselectable/>
            </div>
            <div className="border-column">
                <span className="text-small">Groepsinfo</span>
                <p className="grey-text">Qwesto ID: {groupDoc.qwestoID}</p>
            </div>
            <div className="border-column">
                <button className="btn black" disabled={settingsIsPending} onClick={onSaveSettings}>{settingsIsPending ? <QwestoSmall className="load"/> : "Opslaan"}</button>
            </div>
        </PopUp>
        <div className="group-settings">
            <button className="btn settings" onClick={() => {setSettingsOpen(settingsOpen ? false : true)}}><Settings className="icon no-margin"/></button>
            <button className="btn settings" onClick={() => {setDeleteOpen(settingsOpen ? false : true)}}><Trash className="icon no-margin"/></button>
        </div>
    </>
    )
}