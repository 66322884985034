import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessageContext } from "./useMessageContext";

export const useResetPassword = () => {
    const {setNewError, setNewInfo} = useMessageContext()
    const [isPending, setIsPending] = useState()
    const navigate = useNavigate()

    function tryResetPassword(auth, email, redirect){
        setIsPending(true)
        sendPasswordResetEmail(auth, email).then(() => {
            setNewInfo("We hebben je een email gestuurd.")
            if(redirect){
                navigate(-1)
            }
        })
        .catch((error) => {
            console.log(error);
            setNewError("Er is iets misgegaan.")
            setIsPending(false)
        });
    }

    return { tryResetPassword, isPending }
}