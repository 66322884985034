import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { setIntakeIndexes } from "../utils/pure/setIntakeIndexes";

export function useSkipIntakeQuestion(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    async function trySkipQuestion(qwestoID, activeQuestion, user){
        if(!isPending){
            setIsPending(true)
            try {
                if(activeQuestion.type !== "BESTPHOTO" && activeQuestion.type !== "BESTANSWER"){
                    await updateDoc(doc(db, "qwestos", qwestoID), {[`qwestoQuestions.${activeQuestion.id}.type`]: "SKIPPED"})
                    setNewInfo("De vraag is succesvol overgeslagen")
                    await setIntakeIndexes(qwestoID, user.uid)
                }else{
                    setNewError("Je kunt dit type vraag niet overslaan")
                }
                setIsPending(false)
            } catch (error) {
                setNewError("Er is iets misgegaan")
                setIsPending(false)
            }
        }
    }

    return { isPending, trySkipQuestion }
}