import { useAuthContext } from "../../../hooks/useAuthContext";
import { Result } from "./Result";
import { useSubmitAnswer } from "../../../hooks/useSubmitAnswer";
import PlayerOption from "./PlayerOption";
import Sent from "./Sent";
import { getMaxPlayerPosition } from "../../../utils/pure/getMaxPlayerPosition";


export function PlayerController({gameDoc, groupDoc, positions}){
    const {user} = useAuthContext()
    const {submitIsPending, trySubmitAnswer} = useSubmitAnswer()
    const maximumPosition = getMaxPlayerPosition(groupDoc.players)
    
    if(gameDoc.stage === "ANSWERING"){
        return(
            <>
                {gameDoc.answers[user.uid] === "EMPTY" ? 
                    <div className="player-option-list">
                        {!submitIsPending && groupDoc.players.map((player) => (
                            <PlayerOption player={player} onClick={() => {trySubmitAnswer(groupDoc.qwestoID, user.uid, player.uid, positions)}} key={player.uid}/>
                        ))}
                    </div>
                :
                    <Sent/>
                }
            </>
        )
    }else{
        if(positions){
            return(
                <>
                    {gameDoc.correctAnswer !== "EMPTY" && gameDoc.correctAnswer.includes(gameDoc.answers[user.uid].answer) ?
                        <>
                        {gameDoc.answers[user.uid].position !== "EMPTY" && gameDoc.answers[user.uid].position < maximumPosition + 1 ? 
                            <Result type="correct" message="Correct" gameDoc={gameDoc} uid={user.uid}/>
                        :
                            <Result type="slow" message="Te langzaam" gameDoc={gameDoc} uid={user.uid}/>                        
                        }
                        </>
                    :
                        <Result type="wrong" message="Fout" gameDoc={gameDoc} uid={user.uid}/>
                    }
                </>
                )
        }else{
            return(
            <>
                {gameDoc.correctAnswer !== "EMPTY" && gameDoc.correctAnswer.includes(gameDoc.answers[user.uid]) ?
                    <Result type="correct" message="Correct" gameDoc={gameDoc} uid={user.uid}/>
                :
                    <Result type="wrong" message="Fout" gameDoc={gameDoc} uid={user.uid}/>
                }
            </>
            )
        }
    }
}