import { CorrectPlayer } from './CorrectPlayer';
import React from "react"

export function CorrectPlayers({groupDoc, correctPlayers, nickName, photoURL, check}){
    return(
        <>
            {correctPlayers ? 
                <div className="correct-player-list">
                    {Array.isArray(correctPlayers) && correctPlayers.map((uid) => (
                        <React.Fragment key={uid}>
                            {groupDoc && groupDoc.players.map((player) => {
                                if(uid === player.uid){
                                    return(
                                        <React.Fragment key={player.uid}>
                                            <CorrectPlayer photoURL={player.photoURL} check={check} nickName={player.nickName}/>
                                        </React.Fragment>
                                    )
                                }else{
                                    return null
                                }
                            })}
                        </React.Fragment>
                        )
                    )}
                </div>
            :
                <CorrectPlayer photoURL={photoURL} check={check} nickName={nickName}/>
            }
        </>
    )
}