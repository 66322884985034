import { useEffect } from "react"
import { useState } from "react"
import ArrowLeft from "../../assets/img/ArrowLeft"
import ArrowRight from "../../assets/img/ArrowRight"
import Info from "../../assets/img/Info"
import Trash from "../../assets/img/Trash"
import "./Stepper.css"

export default function Stepper({steps, currentStepIndex, setCurrentStepIndex, onClearSteps, children}){
    const [currentStep, setCurrentStep] = useState(steps[currentStepIndex - 1])

    useEffect(() => {
        setCurrentStep(steps[currentStepIndex - 1])
    }, [currentStepIndex, steps])

    function handlePreviousStep(e){
        e.preventDefault()
        setCurrentStepIndex(currentStepIndex - 1)
    }

    if(currentStep){
        return(
            <div className="stepper">
                <div className="step">
                    {currentStepIndex !== 1 &&
                        <button className="btn round step-btn" onClick={handlePreviousStep}><ArrowLeft className="icon"/></button>
                    }
                    {onClearSteps && currentStepIndex !== 1 &&
                        <button className="btn round step-btn right" onClick={onClearSteps}><Trash className="icon"/></button>
                    }
                    {currentStep.content &&
                        <div className="step-content">
                            <div className="step-label">
                                <div className="stepper-dots">
                                    {steps.map((value, index) => {
                                        if(currentStepIndex - 1 === index){
                                            return <div className="step-dot active" key={index}/>
                                        }else{
                                            return <div className="step-dot" key={index}/>
                                        }
                                    })}
                                </div>
                                <span className="text-medium">{currentStep.title}</span>
                            </div>
                            <div className="column gap">
                                {children}                            
                                {currentStep.info && <div className="row stay no-grow grey-text align-left"><Info className="icon"/><span>{currentStep.info}</span></div>}
                            </div>
                            <div className="column">
                                {currentStep.error && <span className="step-error">{currentStep.error}</span>}
                                {currentStep.button ?
                                    <>
                                        {currentStep.button}
                                    </>
                                :
                                    <>
                                        {currentStepIndex !== steps.length && <button type="submit" className="btn round">Verder<ArrowRight className="icon"/></button>}
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
