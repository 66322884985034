import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { deactivateQwestoQuestion } from "../utils/pure/groups/deactivateQwestoQuestion";

export function useSkipQwestoQuestion(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError} = useMessageContext()

    async function trySkipQuestion(activeQuestion, gameDoc, groupDoc, qwestoDoc){
        if(!isPending){
            setIsPending(true)
            try{
                await deactivateQwestoQuestion(activeQuestion, gameDoc, groupDoc, qwestoDoc)
            }catch(error){
                console.log(error);
                setNewError("Er is iets misgegaan")
            }
            setIsPending(false)
        }
    }

    return { isPending, trySkipQuestion }
}