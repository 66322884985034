import { Navigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useGroupDataContext } from "../../../hooks/useGroupDataContext";
import "./Controller.css"
import Loader from "../../../components/loaders/Loader";
import Redirect from "../../../components/layout/Redirect";
import Lobby from "./Lobby";
import HostControls from "./HostControls";
import InfoBar from "./InfoBar";
import AnswerContainer from "./AnswerContainer";
import { useMemo } from "react";
import { useGameLogicContext } from "../../../hooks/useGameLogicContext";
import { NoGameConnection } from "../NoGameConnection";

export default function Controller() {
    const {qwestoID} = useParams()
    const {user} = useAuthContext()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "GROUP_AND_GAME_DATA",
        qwestoID: qwestoID
    }), [qwestoID]))
    const {activeQuestion, gameConnection} = useGameLogicContext("CONTROLLER")

    if(noGroupData){
        return(
            <Redirect to="/groups" message={"Deze Qwesto bestaat niet"} action="Terug" arrow="left"/>
        )
    }

    if(!groupData.qwesto || !groupData.game || !groupData.group){
        return(
            <Loader/>
        ) 
    }

    if(!groupData.group.groupIsConfirmed){
        return(
            <Redirect to={`/groups/${qwestoID}`} message={"Nog niet beschikbaar"} action="Terug" arrow="left"/>
        )
    }

    if(groupData.group.phoneViewer === true){
        return(
            <Navigate to={`/groups/${qwestoID}`}/>
        )
    }

    return(
        <div className="content-page full">
            {!gameConnection && <NoGameConnection/>}
            {groupData.group.qwestoStarted ? 
                <div className="content-field">
                    <div className="controller-container">
                        <div className="answer-container">
                            <AnswerContainer groupDoc={groupData.group} qwestoDoc={groupData.qwesto} gameDoc={groupData.game} activeQuestion={activeQuestion}/>
                        </div>
                    </div>
                    <HostControls qwestoDoc={groupData.qwesto} groupDoc={groupData.group} gameDoc={groupData.game} activeQuestion={activeQuestion}/>
                    <InfoBar user={user} gameDoc={groupData.game} groupDoc={groupData.group} qwestoDoc={groupData.qwesto}/>
                </div>
            :
                <Lobby group={groupData.group}/>
            }
        </div>
    )
}
