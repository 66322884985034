export const FAQs = [
    {
        q: "Wat is Qwesto?",
        a: "Qwesto verzorgt gepersonaliseerde quizzen voor vriendengroepen, families en bedrijven. Laat iedereen uit je groep foto's, video's en anekdotes invullen en er wordt automatisch een quiz gegenereerd. Wij zijn niet alleen het allereerste platform dat dit mogelijk maakt, maar we maken het ook nog eens super eenvoudig en vooral heel erg leuk!"
    },
    {
        q: "Kan ik mijn Qwesto pauzeren?",
        a: "Ja, de host bepaalt het tempo van de quiz. Een plaspauze of een refill van drinken en snacks kan dus makkelijk ingelast worden."
    },
    {
        q: "Zit er een tijdslimiet op mijn Qwesto?",
        a: "Nee, de tijd die wordt gekozen (30 of 60 minuten) is slechts een indicatie. Als je iets langer de tijd neemt of vaker een pauze inlast is dat geen probleem."
    },
    {
        q: "Wat heb ik nodig voor een Qwesto?",
        a: `Voor de beste ervaring raden wij aan om de Qwesto te tonen op een groot scherm (tv, beamer etc.). Als je geen groot scherm ter beschikking hebt, <a href="/blog/gebruik-schermen" target="_blank" rel="noopener noreferrer">is het altijd mogelijk om de Qwesto op alle telefoons te tonen</a>.`
    },
    {
        q: "Met hoeveel personen kan ik een Qwesto spelen?",
        a: "Je kunt met minimaal 3 en maximaal 20 spelers een Qwesto spelen. Wil je met een grotere groep spelen? Kies er dan voor om met teams te spelen of stuur een mailtje naar contact@qwesto.nl."
    },
    {
        q: "Wat is het verschil tussen Qwesto en andere quizplatforms?",
        a: "Wat ons uniek maakt is de personalisatie van de quizzen. In plaats van een standaard quiz die door iemand is gemaakt, heb jij nu zelf invloed op de quiz. Zonder dat je er veel tijd in hoeft te steken. Iedere Qwesto is dus volledig uniek. Overigens liggen onze prijzen lager dan bij andere quizplatforms."
    },
    {
        q: "Heb ik zelf invloed op de Qwesto?",
        a: "Ons systeem stelt de Qwesto voor je groep samen. Voordat de quiz begint krijgt iedereen een aantal intakevragen, deze vragen bepalen de toon van de Qwesto. Hoe creatiever je groep, hoe leuker de Qwesto."
    },
    {
        q: "Is Qwesto geschikt voor zowel kinderen als volwassenen?",
        a: "Zeker! Wanneer de filter correct is ingevuld, zullen de vragen gekozen worden op basis van de leeftijd. Dit zorgt ervoor dat kinderen geen ongepaste vragen te zien krijgen. "
    }
]

export const intakeInstructions = {
    intake: {
        type: "INTRODUCTION",
        title: "Welkom bij de intake!",
        paragraphs: [
            {
                label: "Wat is het?",
                content: "De intake zijn een aantal vragen die je moet invullen voor een Qwesto. Deze worden gebruikt bij het maken van de Qwesto."
            },
            {
                label: "Hoe werkt het?",
                content: "Je beantwoord de vragen zoals er wordt aangegeven. Verder ben je vrij in wat je gaat antwoorden en kun je zelf de toon van de vragen bepalen, dus wees creatief!"
            },
            {
                label: "Goed om te weten",
                content: "Wil je een vraag niet beantwoorden, dan kun je deze overslaan. Dit zorgt wel voor een kortere Qwesto"
            }
        ],
        mention: "Behoefte aan extra uitleg over een vraag? Klik dan op de instructieknop rechtsboven",
    },
    "BESTPHOTO": {
        title: "De beste foto",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Het de bedoeling dat je, als reactie op de vraag, de grappigste, leukste of meest toepasselijke foto upload. Schrik niet als je je eigen naam tegenkomt in de vraag!"
            }
        ]
    },
    "BESTANSWER": {
        title: "Het beste Antwoord",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Verzin het grappigste, leukste of meest toepasselijke antwoord op de gestelde vraag. Je groep gaat stemmen op het beste antwoord."
            }
        ]
    },
    "WHATISTRUE": {
        title: "Wat is waar?",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Je krijgt een vraag waarop je gaat antwoorden. Één antwoord moet kloppen, de andere twee zijn fout. Je kunt zelf bepalen in hoeverre je de leugens laat lijken op de waarheid."
            }
        ]
    },
    "GUESSWHO": {
        title: "Raad wie",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Je krijgt een zin met een open plek. Het is de bedoeling dat jij deze open plek eerlijk invult, zodat er een kloppende zin ontstaat."
            }
        ]
    },
    "PHOTOREVEAL": {
        title: "Wie zie je?",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Upload een foto waarop één of meerdere medespelers te zien zijn. Het is van belang dat de spelers herkenbaar zijn."
            }
        ]
    },
    "WHATDOYOUSEE": {
        title: "Wat zie je?",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Upload een foto waarop iets gebeurt of te zien is. Omschrijf drie keer wat er gebeurt in de foto. Één omschrijving moet kloppen, de andere twee zijn fout."
            }
        ]
    },
    "WHATDOYOUHEAR": {
        title: "Wat gebeurt er?",
        paragraphs: [
            {
                label: "Hoe werkt het?",
                content: "Upload een video waarop iets gebeurt of te zien is. Omschrijf drie keer wat er gebeurt in de video. Één omschrijving moet kloppen, de andere twee zijn fout."
            }
        ]
    }
}