import { useState, useEffect } from "react"
import { db } from "../config/firebaseConfig"
import { onSnapshot, doc } from "firebase/firestore"

export const useDocument = (collectionName, documentID) => {
    const [document, setDocument] = useState(null)
    const [noData, setNoData] = useState(false)

    useEffect(() => {

        const docRef = doc(db, collectionName, documentID)
        const unsub = onSnapshot(docRef, (doc) => {
            if(doc.data()){
                setDocument({...doc.data(), id: doc.id})
                setNoData(false)
            }else{
                setNoData(true)
            }
        })

        return () => unsub()
    }, [collectionName, documentID])

    return { document, noData }
}