export function GuessWho({ textAnswer, setTextAnswer}){

    return(
        <div className="border-column grow">    
            <h4>Antwoord de vraag naar waarheid</h4>   
                <textarea type="text"
                    required 
                    onChange={(e) => {setTextAnswer(e.target.value)}}
                    maxLength={70}
                    value={textAnswer}
                    name="secondFalse"
                    />       
            <p className="grey-text">{textAnswer.length}/70</p>
        </div>
    )
    }