import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { storage, db } from "../config/firebaseConfig"
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export function useInitGroup(){
    const [changeIsPending, setChangeIsPending] = useState(false)
    const {setNewError} = useMessageContext()

    async function tryInitGroup(qwestoID, groupPhoto, setWelcomeOpen, phoneViewer){
        if(!groupPhoto){
            setNewError("Upload een foto om verder te gaan")
            return
        }
        if(!(phoneViewer === true || phoneViewer === false)){
            setNewError("Geef aan of je op een extern scherm gaat spelen")
            return
        }
        setChangeIsPending(true)
        try {
            const uploadPath = `groupMedia/${qwestoID}/groupPhoto`
            const uploadRef = ref(storage, uploadPath)
            await uploadBytes(uploadRef, groupPhoto)
            const promisedURL = await getDownloadURL(uploadRef)
            await updateDoc(doc(db, "groups", qwestoID), {
                groupPhotoURL: promisedURL,
                groupIsInitialized: true,
                phoneViewer: phoneViewer
            })
            setWelcomeOpen(false)
            setChangeIsPending(false)
        } catch (error) {
            setNewError("Er is iets misgegaan")
            console.log(error);
            setChangeIsPending(false)
        }
    }

    return { changeIsPending, tryInitGroup }
}
