export const LEGALS = [
    {
        "id": 3,
        "title": "Privacyverklaring",
        "description": "Onze privacyverklaring",
        "pathID": "privacy",
        "date": "",
        "sections": [
            {
                "type": "text",
                "title": "Wie is Qwesto?",
                "text": "Qwesto is een webapplicatie waar je digitale producten kunt kopen. Met deze producten kun je een quiz genereren, hosten en spelen. Qwesto (hierna ook: wij, onze en Qwesto) is verantwoordelijk voor de gegevensverwerking. In deze privacyverklaring leggen wij uit welke persoonsgegevens Qwesto verzamelt en met welk doel. We raden u daarom aan om deze privacyverklaring zorgvuldig te lezen.\n\n"
            },
            {
                "text": "Qwesto<br>\nSjeng Vaasstraat 3<br>\n6031 HE Nederweert<br>\ncontact@qwesto.nl<br>\n<br>\nKVK-nummer: 90491270<br>\nBTW-nummer: NL865334705B01",
                "type": "text",
                "title": "Contactgegevens Qwesto"
            },
            {
                "text": "Via zowel onze website als onze producten worden privacygevoelige informatie, of met andere woorden, persoonsgegevens verwerkt. Qwesto.nl verwerkt deze gegevens op een zorgvuldige en veilige manier. Wij voldoen hierbij aan de eisen die de privacywetgeving stelt. Qwesto neemt uw privacy serieus, wat onder meer betekent dat:\n\n",
                "type": "text"
            },
            {
                "items": [
                    {
                        "text": "Wij door middel van deze privacyverklaring duidelijk willen maken voor welke doeleinden wij persoonsgegevens verwerken;\n",
                        "title": ""
                    },
                    {
                        "text": "Wij alleen persoonsgegevens verzamelen die nodig zijn voor legitieme doeleinden;\n",
                        "title": ""
                    },
                    {
                        "text": "Wij uw toestemming vragen voordat wij uw persoonsgegevens verwerken, indien dit vereist is;\n",
                        "title": ""
                    },
                    {
                        "text": "Wij passende beveiligingsmaatregelen nemen om uw persoonsgegevens te beschermen en dat ook verwachten van derde partijen die in onze opdracht persoonsgegevens verwerken;\n",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Wij uw recht respecteren om persoonsgegevens op aanvraag in te zien, te corrigeren of te verwijderen."
                    }
                ],
                "type": "list",
                "dots": "disc"
            },
            {
                "title": "Persoonsgegevens die wij verwerken",
                "text": "De persoonsgegevens die wij gebruiken en/of bewaren zijn ofwel rechtstreeks door u opgegeven of het is vooraf duidelijk gemaakt dat gegevens aan ons worden verstrekt voor verwerking. We zullen deze gegevens niet gebruiken tenzij we daar expliciete toestemming voor hebben gevraagd. Wij verwerken de volgende gegevens voor de volgende doeleinden:\n\n",
                "type": "text"
            },
            {
                "dots": "disc",
                "type": "list",
                "items": [
                    {
                        "text": "NAW-gegevens (Naam, Adres en Woonplaats)\n",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Geslacht\n"
                    },
                    {
                        "title": "",
                        "text": "E-mailadres\n"
                    },
                    {
                        "title": "",
                        "text": "Geboortedatum\n"
                    },
                    {
                        "text": "Transactiegegevens\n",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Identiteits-/verificatiegegevens\n"
                    },
                    {
                        "text": "IP-adres\n",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "User agents\n"
                    },
                    {
                        "title": "",
                        "text": "Apparaat specificaties (zoals uw apparaat, besturingssysteem en browser)\n"
                    },
                    {
                        "title": "",
                        "text": "Surf-gedrag (zoals klikdrag, het tijdstip en de duur van het bezoek aan onze website)\n"
                    },
                    {
                        "title": "",
                        "text": "Door u opgegeven persoonlijk materiaal (beeldmateriaal en/of tekst)"
                    }
                ]
            },
            {
                "title": "Bijzondere en/of gevoelige persoonsgegevens die wij verwerken",
                "type": "text",
                "text": "Onze website en/of producten hebben niet de intentie gegevens te verzamelen van gebruikers jonger dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders en voogden dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over iemand jonger dan 16 jaar, neem dan contact met ons op via support@qwesto.nl, dan verwijderen wij deze informatie.\n\n"
            },
            {
                "items": [
                    {
                        "title": "",
                        "text": "Om u de mogelijkheid te geven een account aan te maken;"
                    },
                    {
                        "text": "Om het gebruik van Qwesto.nl mogelijk te maken;\n",
                        "title": ""
                    },
                    {
                        "text": "Om onze digitale omgeving/producten naar uw behoren te personaliseren;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Om anonieme statistische gegevens op te stellen;"
                    },
                    {
                        "title": "",
                        "text": "Om u mogelijk interessante aanbiedingen te doen;"
                    },
                    {
                        "title": "",
                        "text": "Om Qwesto.nl aan te passen, te verbeteren en te beveiligen;"
                    },
                    {
                        "text": "Om uw gegevens te verstrekken aan derde partijen en/of om derde partijen toegang tot uw gegevens te geven. Mits u hier toestemming voor heeft gegeven;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Het door u aangeleverde materiaal dat is aangeleverd gedurende het gebruik van ons product (beeldmateriaal en/of tekst) wordt uitsluitend gebruikt voor het mogelijk maken van dit product. Dit materiaal wordt dus niet voor andere doeleinden gebruikt."
                    }
                ],
                "dots": "disc",
                "title": "Voor welke doeleinden gebruiken wij jouw informatie?",
                "type": "list"
            },
            {
                "text": "Qwesto werkt samen met verschillende bedrijven die één of meerdere van de hierboven genoemde persoonsgegevens kunnen ontvangen (met uitzondering van het door u opgegeven materiaal dat is aangeleverd gedurende het gebruik van ons product).",
                "title": "Delen van persoonsgegevens met derden",
                "type": "text"
            },
            {
                "dots": "disc",
                "items": [
                    {
                        "text": "Voor het afhandelen van onze betalingen maken wij gebruik van het Payment Processing Platform Stripe. Stripe verwerkt uw betaalgegevens, zoals uw bankrekening- of creditcardnummer. Dit platform neemt passende maatregelen om de bescherming van uw persoonsgegevens te waarborgen. Stripe heeft het recht om uw gegevens te gebruiken om hun dienstverlening te verbeteren en kan deze gegevens delen met derden met datzelfde doel. Stripe bewaart uw gegevens niet langer dan wettelijk is toegestaan. Voor meer informatie over hoe Stripe precies omgaat met uw persoonsgegevens, verwijzen wij u naar de privacyverklaring van Stripe zelf, te vinden onder de koptekst 'Eindklanten'.\n\n",
                        "title": "Stripe"
                    },
                    {
                        "title": "Firebase",
                        "text": "Qwesto maakt gebruik van verschillende backend cloud computing-services en platforms van Firebase, een product van Google. Dit omvat Firebase Hosting, Firebase Storage, Firebase Authentication, Firebase Realtime Database, Firebase Firestore en Firebase Analytics. Hierdoor worden de persoonsgegevens die u verstrekt opgeslagen door Firebase. Voor meer informatie over hoe Firebase precies omgaat met uw persoonsgegevens, verwijzen wij u naar de privacyverklaring van Firebase zelf."
                    },
                    {
                        "text": "Qwesto maakt gebruik van Google Analytics, een webanalyseservice aangeboden door Google, om het websiteverkeer te volgen en te rapporteren. Dit stelt ons in staat om het gebruik van onze website door gebruikers bij te houden. Om dit mogelijk te maken plaatst Google cookies en verzamelt informatie over uw surfgedrag. Deze informatie wordt gedeeld met Google, die deze gebruikt voor verschillende doeleinden, waaronder het aanbieden van gerichte advertenties. Voor meer informatie over hoe Google Analytics precies omgaat met uw persoonsgegevens, verwijzen wij u naar de privacyverklaring van Google Analytics zelf.\n\n",
                        "title": "Google Analytics"
                    },
                    {
                        "title": "Postmark",
                        "text": "Qwesto maakt gebruik van Postmark voor het toepassen van de e-mailmarketing. Dit geautomatiseerde platform stelt ons in staat om op het juiste moment de juist e-mail te sturen. Ze gebruiken hiervoor persoonsgegevens zoals: voor- en achternaam. Voor meer informatie over hoe Postmark omgaat met uw persoonsgegevens, verwijzen wij u naar de privacyverklaring van Postmark zelf."
                    }
                ],
                "type": "list"
            },
            {
                "type": "text",
                "text": "Qwesto neemt verantwoordelijkheid voor geautomatiseerde verwerkingen en/of besluiten die gevolgen kunnen hebben voor personen. Dit omvat besluiten die worden genomen door ons eigen systeem, zonder menselijke tussenkomst van bijvoorbeeld een medewerker van Qwesto.\n\n",
                "title": "Geautomatiseerde besluitvorming"
            },
            {
                "text": "Qwesto bewaart uw persoonsgegevens niet langer dan noodzakelijk is om de doeleinden waarvoor uw gegevens zijn verzameld te realiseren, tenzij er een wettelijke verplichting is om de gegevens langer te bewaren. Uw accountnaam, e-mailadres en geslacht worden bewaard zolang uw account actief is. Als u ervoor kiest om uw account te verwijderen, worden deze persoonsgegevens vernietigd. Het materiaal dat u aanlevert gedurende het gebruik van ons product wordt één week nadat het volledige product is verbruikt, vernietigd. Betaalgegevens worden wettelijk verplicht bewaard voor een periode van zeven jaar. Voor de bewaartermijnen van persoonsgegevens door derden verwijzen wij naar de privacy verklaringen van de desbetreffende derde partijen.\n\n",
                "title": "Hoelang wij persoonsgegevens bewaren",
                "type": "text"
            },
            {
                "text": "Qwesto neemt de bescherming van uw gegevens serieus en implementeert passende maatregelen om de veiligheid van uw gegevens te waarborgen. Dit omvat het voorkomen van misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijzigingen. Indien u vermoedt dat uw gegevens niet goed beveiligd zijn of als er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice via support@qwesto.nl.\n\n",
                "title": "Hoe wij uw persoonsgegevens beschermen",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Uw gegevens worden opgeslagen door Firebase, een platform dat gecertificeerd is volgens belangrijke privacy- en beveiligingsstandaarden. Firebase heeft succesvol het evaluatieproces voor SO 27001, SOC 1, SOC 2 en SOC 3 doorlopen, en sommige services zijn ook gecertificeerd volgens ISO 27017 en ISO 27018. Voor meer informatie over hoe Firebase omgaat met de verwerking en beveiliging van uw gegevens, verwijzen wij u naar de ‘Firebase Data Processing and Security Terms’ op de volgende link: https://firebase.google.com/support/privacy.\n\n"
            },
            {
                "text": "U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van uw account. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door ons bedrijf en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.\n\n",
                "type": "text",
                "title": "Gegevens inzien, aanpassen of verwijderen"
            },
            {
                "type": "text",
                "text": "Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u om een kopie van uw identiteitsbewijs mee te sturen. Gelieve uw pasfoto, MRZ (machine readable zone), paspoortnummer en Burgerservicenummer (BSN) onleesbaar te maken of zwart te maken om uw privacy te beschermen. Qwesto zal zo snel mogelijk, maar uiterlijk binnen vier weken, reageren op uw verzoek.\n\n"
            },
            {
                "type": "text",
                "title": "Cookies, of vergelijkbare technieken, die wij gebruiken",
                "text": "Qwesto maakt uitsluitend gebruik van technische, functionele en analytische cookies, die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij uw eerste bezoek aan onze website op uw apparaat (computer, tablet of smartphone) wordt opgeslagen. Deze cookies zijn noodzakelijk voor de technische werking van de website en zorgen voor uw gebruiksgemak. Ze stellen ons in staat om de website naar behoren te laten functioneren en uw voorkeursinstellingen te onthouden. Bovendien helpen ze ons om de website te optimaliseren.\n\n"
            },
            {
                "type": "text",
                "text": "U kunt ervoor kiezen om zich af te melden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle eerder opgeslagen informatie via de instellingen van uw browser verwijderen.\n\n"
            },
            {
                "type": "text",
                "text": "Wij willen u erop wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan <a href=\"https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons\">via deze link</a>.\n\n",
                "title": "Klacht indienen"
            },
            {
                "title": "Wijzigingen privacyverklaring",
                "text": "Qwesto behoudt zich het recht voor om deze privacyverklaring op elk gewenst moment te wijzigen. Wij raden u dan ook aan om regelmatig deze privacyverklaring te raadplegen, zodat u op de hoogte blijft van eventuele wijzigingen.",
                "type": "text"
            }
        ],
        "photoURL": ""
    },
    {
        "pathID": "disclaimer",
        "sections": [
            {
                "text": "U heeft enige invloed op de soort content die in de ‘Qwesto’ wordt opgenomen, door het invullen van de filter. Met deze filter kunt u onder meer bepalen op welke doelgroep de content gericht moet zijn en hoe 'spicy' (spannend/heftig) de content moet zijn. Qwesto gebruikt deze filter om het aanbod van content af te stemmen op de verschillende gebruikers en probeert zo te voorkomen dat u te maken krijgt met ongepaste content. Echter is het belangrijk om te weten dat Qwesto niet kan garanderen dat u niet in aanraking zult komen met ongepaste content. Dit omdat 'ongepast' subjectief is en kan verschillen van persoon tot persoon. Daarnaast kunnen er (mogelijk) fouten zitten in de indeling van content. Mocht u toch in aanraking komen met ongepaste content, kunt u dit laten weten aan Qwesto door een e-mail te sturen naar support@qwesto.nl.",
                "title": "Voor aanschaf",
                "type": "text",
                "dots": "disc"
            },
            {
                "text": "Voordat u een product en/of dienst aanschaft, gaat u akkoord met de mogelijke risico's die betrekking hebben op bijvoorbeeld ongepaste content, en bevestigt u dat u voldoet aan de minimale leeftijd die vereist is om het desbetreffende product en/of dienst aan te mogen schaffen.",
                "type": "text"
            },
            {
                "type": "text",
                "title": "Invullen van de ‘Intake’",
                "text": "De intake is een vragenlijst die spelers moeten invullen voordat de 'Qwesto' kan worden gespeeld. De 'Qwesto' wordt namelijk gecreëerd op basis van deze vragenlijst. Hierin kan (mogelijk) gevraagd worden naar persoonlijk materiaal zoals beeldmateriaal en/of tekst.\n\n"
            },
            {
                "text": "Deel geen gevoelige informatie zoals wachtwoorden of andere informatie waarvan u niet wilt dat anderen dit te weten komen.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Als u een vraag ongepast vindt en/of niet wilt beantwoorden, heeft u te allen tijde de mogelijkheid om deze vraag over te slaan door deze over te slaan. Wees er echter van bewust dat hierdoor de 'Qwesto' mogelijk korter wordt, omdat in sommige gevallen deze vraag, als gevolg van het overslaan, niet wordt opgenomen tijdens het spelen van de 'Qwesto'. Het overslaan van een vraag is onomkeerbaar. Als u een ongepaste vraag tegenkomt, kunt u dit laten weten aan Qwesto door een e-mail te sturen naar support@qwesto.nl."
            },
            {
                "text": "Elke vraag in de intake kan slechts één keer worden beantwoord. Zodra u op 'volgende' hebt geklikt, kunt u niet meer teruggaan naar de vorige vraag.",
                "type": "text"
            },
            {
                "text": "Een 'Qwesto' is een gepersonaliseerde quiz die gaat over de groep waarmee u ervoor kiest de 'Qwesto' te spelen. De 'Qwesto' is gebaseerd op de, door de spelers, ingevulde intake.\n\n",
                "type": "text",
                "title": "Spelen van de ‘Qwesto’"
            },
            {
                "type": "text",
                "text": "Op het moment dat u als ‘host’ zijnde een groep gaat samenstellen, is het van belang dat u die uitnodigingslink alleen naar personen stuurt waarvan u ook daadwerkelijk wilt dat deze deelnemen."
            },
            {
                "type": "text",
                "text": "Voor het spelen van de 'Qwesto' heeft elke speler een mobiele telefoon nodig, die als controller wordt gebruikt. Daarnaast is er één apparaat nodig waarop de 'Qwesto' wordt afgespeeld. Dit kan op elk apparaat, maar we raden aan om dit op een zo groot mogelijk scherm te doen voor de beste ervaring."
            },
            {
                "text": "De ‘Qwesto’ kan maar één keer worden gespeeld.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Het is belangrijk dat alle spelers beschikken over een goede internetverbinding om gebruik te kunnen maken van de diensten en/of producten die worden aangeboden door Qwesto.nl."
            },
            {
                "text": "Qwesto is niet verantwoordelijk noch aansprakelijk voor content die mogelijk enige vorm van schade veroorzaakt. Dit kan bijvoorbeeld content zijn die door de consument als ongepast of kwetsend wordt ervaren, of schade die wordt opgelopen tijdens het uitvoeren van een opdracht. De 'host' kan te allen tijde content overslaan, en elke speler heeft het recht om opdrachten niet uit te voeren als ze zich hier oncomfortabel bij voelen. Het is aan de spelers zelf om een setting en sfeer te creëren waarin iedereen zich comfortabel en veilig voelt.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Qwesto is niet verantwoordelijk noch aansprakelijk voor schade veroorzaakt door onverantwoord gedrag van spelers. Dit kan bijvoorbeeld gebeuren doordat één of meer spelers ongepast materiaal aanleveren tijdens de intake die mogelijk smadelijk, lasterlijk of racistisch is. Of doordat spelers andere spelers onder druk zetten om dingen te doen waar zij zich niet veilig of comfortabel bij voelen.\n\n"
            },
            {
                "type": "text",
                "text": "We zijn ons ervan bewust dat 'Qwesto's' vaak worden gespeeld in combinatie met alcohol. U bent uiteraard vrij om de diensten en/of producten te gebruiken voor de doeleinden die u geschikt acht. Qwesto is echter uitdrukkelijk niet aansprakelijk voor onverantwoord gedrag, ongelukken, verwondingen of andere gevolgen van (overmatig) alcoholgebruik tijdens of na het gebruik van de producten en/of diensten die worden aangeboden door Qwesto. Het is de verantwoordelijkheid van de consument om het alcoholgebruik te monitoren en alleen alcohol te nuttigen als de speler 18 jaar of ouder is.\n\n"
            }
        ],
        "title": "Disclaimer",
        "photoURL": "",
        "description": "Onze disclaimer",
        "date": "",
        "id": 2
    },
    {
        "pathID": "cookies",
        "date": "",
        "title": "Cookies",
        "photoURL": "",
        "sections": [
            {
                "type": "text",
                "title": "Wie is verantwoordelijk voor de verwerking van uw gegevens?",
                "text": "Qwesto, gevestigd in Sjeng Vaasstraat 3 6031 HE Nederweert, is verantwoordelijk voor de verwerking van de gegevens zoals beschreven in dit cookiebeleid. Dit cookiebeleid is van toepassing op alle aan Qwesto.nl gerelateerde domeinen en communicatie.\n<br>\ncontact@qwesto.nl<br>\nKVK-nummer: 90491270<br>\nBTW-nummer: NL865334705B01<br>"
            },
            {
                "text": "Cookies zijn kleine tekstbestanden die op uw computer of mobiel apparaat worden geplaatst door een website op het moment dat u deze website bezoekt. Cookies bevatten informatie over uw voorkeuren en de activiteiten die u op de website verricht, zoals de pagina's die u hebt bezocht en de duur van uw bezoek.\n\n",
                "title": "Wat zijn cookies?",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Cookies worden ingezet om uw ervaring op de website te verbeteren door het mogelijk te maken uw voorkeuren te onthouden en deze bij een volgend bezoek te herkennen. Op deze manier kan Qwesto.nl u bijvoorbeeld automatisch inloggen wanneer u terugkomt. Daarnaast worden cookies ook gebruikt voor analytische doeleinden, bijvoorbeeld om te zien hoeveel mensen Qwesto.nl bezoeken en welke pagina's het meest worden bekeken.\n\n"
            },
            {
                "text": "Er zijn verschillende soorten cookies. Sessiecookies worden automatisch verwijderd op het moment dat u de website verlaat. Permanente cookies blijven op uw computer of mobiele apparaat staan totdat deze verlopen of worden verwijderd. Sommige cookies worden geplaatst door de website zelf, andere worden door derden geplaatst.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "title": "Wat zijn de overige cookie-achtige technieken?",
                "text": "Web beacons, Local en Session Storage en SDK's zijn standaard internettechnieken die, soms in combinatie met cookies, het mogelijk maken dat een systeem informatie kan verzamelen en versturen. Door gebruik te maken van deze technieken zorgen wij er onder meer voor dat:\n\n"
            },
            {
                "type": "list",
                "items": [
                    {
                        "title": "",
                        "text": "Het surfen op Qwesto.nl makkelijker en leuker wordt;"
                    },
                    {
                        "title": "",
                        "text": "U niet bij ieder verzoek dezelfde informatie wordt getoond;"
                    },
                    {
                        "title": "",
                        "text": "Wij u speciale functionaliteiten kunnen aanbieden;"
                    },
                    {
                        "text": "Wij kunnen zien hoe Qwesto.nl wordt gebruikt;",
                        "title": ""
                    },
                    {
                        "text": "Wij kunnen herkennen welk apparaat u gebruikt.",
                        "title": ""
                    }
                ],
                "dots": "disc"
            },
            {
                "text": "Via Qwesto.nl worden ook cookies van derden geplaatst. Op het gebruik van cookies door derden is het privacy- en cookiebeleid van de desbetreffende derde partij van toepassing.\n\n",
                "type": "text",
                "title": "Cookies van derden"
            },
            {
                "title": "Welke cookies gebruiken wij?",
                "type": "text",
                "text": "Functionele/noodzakelijke cookies zorgen ervoor dat Qwesto.nl naar behoren functioneert. Analytische cookies maken het mogelijk om met behulp van derden statistieken over Qwesto.nl te verzamelen.\n\n"
            },
            {
                "text": "Qwesto.nl gebruikt functionele/noodzakelijke cookies voor bijvoorbeeld:\n\n",
                "title": "Functionele of noodzakelijke cookies",
                "type": "text"
            },
            {
                "items": [
                    {
                        "title": "",
                        "text": "Het opslaan van uw browserinstellingen, zodat u de website zo optimaal mogelijk kunt bekijken;"
                    },
                    {
                        "text": "Het geven van de mogelijkheid om uw inloggegevens op te slaan, zodat u niet telkens opnieuw hoeft in te loggen;",
                        "title": ""
                    },
                    {
                        "text": "Het gelijkmatig belasten van de website, zodat deze zo optimaal mogelijk blijft werken.",
                        "title": ""
                    }
                ],
                "dots": "disc",
                "type": "list"
            },
            {
                "text": "Qwesto.nl gebruikt analytische cookies om onder andere de volgende gegevens op te slaan:\n\n",
                "type": "text",
                "title": "Analytische cookies"
            },
            {
                "type": "list",
                "dots": "disc",
                "items": [
                    {
                        "text": "Het IP-adres (dat anoniem wordt gemaakt);",
                        "title": ""
                    },
                    {
                        "text": "Technische kenmerken, zoals uw browser, de resolutie van uw scherm en welk apparaat u gebruikt;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Via welke pagina of advertentie u op Qwesto.nl bent gekomen;"
                    },
                    {
                        "text": "Wanneer en hoelang u de website heeft bezocht;",
                        "title": ""
                    },
                    {
                        "text": "Welke pagina's u bezoekt.",
                        "title": ""
                    }
                ]
            },
            {
                "type": "text",
                "text": "Deze gegevens worden vervolgens gebruikt om bijvoorbeeld:"
            },
            {
                "dots": "disc",
                "items": [
                    {
                        "title": "",
                        "text": "Het aantal bezoekers van Qwesto.nl bij te houden;"
                    },
                    {
                        "text": "Te meten hoelang een bezoek duurt;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Het succes van advertenties te meten;"
                    },
                    {
                        "title": "",
                        "text": "Te bepalen in welke volgorde onze pagina's worden bezocht."
                    }
                ],
                "type": "list"
            },
            {
                "text": "Indien u niet wilt dat websites cookies op uw computer opslaan, kunt u uw browserinstellingen aanpassen. Dit stelt u in staat om een waarschuwing te ontvangen voordat er cookies worden geplaatst. U heeft ook de mogelijkheid om uw instellingen zo aan te passen dat uw browser alle cookies of alleen cookies van derden weigert. Tevens kunt u in de browserinstellingen cookies die al geplaatst zijn verwijderen. Wees er wel van bewust dat u deze instellingen apart moet aanpassen voor iedere browser en apparaat.",
                "title": "Browserinstellingen",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Het is belangrijk om te weten dat als u ervoor kiest geen gebruik te maken van cookies, wij de correcte werking van Qwesto.nl niet kunnen garanderen. Het kan namelijk van invloed zijn op de functionaliteit en uw gebruikservaring op de website.\n\n"
            },
            {
                "text": "Als u cookies van specifieke partijen wilt uitzetten, kunt u dit doen via www.youronlinechoices.com\n\n",
                "type": "text"
            },
            {
                "title": "Cookieshistorie verwijderen",
                "type": "text",
                "text": "Het is dus, zoals hierboven genoemd, mogelijk om uw cookie historie verwijderen. Hieronder vindt u de manieren om dit te doen voor verschillende browsers:\n\n"
            },
            {
                "text": "Chrome:\n<br>\nKlik op de 3 puntjes, rechtsboven op het scherm. Klik vervolgens op geschiedenis > Geschiedenis > Browsegegevens wissen;\n<br>\nIn het menu ‘Browsegegevens wissen’ heeft u boven in de optie om browsegegevens te wissen vanaf het eerste gebruik tot en met alleen het laatste uur. Daaronder kiest u welke items u wilt wissen. Zorg ervoor dat u in ieder geval ‘Cookies en andere site- en plug-ingegevens’ aan klikt.\n<br>\nDruk ten slotte op de knop ‘Browsegegevens wissen’.",
                "type": "text"
            },
            {
                "text": "Safari:\n<br>\nOpen Safari en ga naar Safari > voorkeuren > Privacy > Bewaarde cookies en gegevens verwijderen > Beheer websitegegevens > Verwijder alles.",
                "type": "text"
            },
            {
                "text": "Firefox:\n<br>\nKlik op de 3 streepjes rechtsboven op het scherm, klik vervolgens op opties > Privacy > Individuele cookies verwijderen.\n<br>\nDruk ten slotte op ‘Alles verwijderen’",
                "type": "text"
            },
            {
                "type": "text",
                "text": "Microsoft Edge:\n<br>\nKlik op de 3 puntjes rechtsboven in het scherm, klik vervolgens op instellingen.\n<br>\nKlik onder ‘Browsegegevens wissen’ de optie ‘Kies wat u wilt wissen’.\n<br>\nSelecteer de cookies (voorgeselecteerd) > Druk ten slotte op de knop ‘Wissen’."
            },
            {
                "text": "Internet Explorer:\n<br>\nKlik op het tandwiel rechtsboven in het scherm > kies Internetopties > tab Algemeen. Klik onder ‘Browsegeschiedenis’ op ‘Verwijderen’\n<br>\nMocht u niet alles willen verwijderen, vink dan in ieder geval ‘Cookies en Websitegegevens’ aan en klik op ‘Verwijderen’",
                "type": "text"
            },
            {
                "text": "Bij het gebruik van sommige cookies is het mogelijk dat persoonsgegevens worden verwerkt. In onze privacyverklaring kunt u meer lezen over hoe Qwesto omgaat met uw gegevens.\nQwesto past dit cookiebeleid aan op het moment dat Qwesto.nl of regels rondom cookies wijzigen.",
                "type": "text",
                "title": "Onze privacyverklaring"
            }
        ],
        "description": "Over onze cookies",
        "id": 1
    },
    {
        "sections": [
            {
                "title": "Artikel 1 – Definities",
                "text": "1.1 Consument: De natuurlijke persoon die niet handelt in de uitoefening van een beroep of bedrijf en een overeenkomst op afstand aangaat met Qwesto.",
                "type": "text"
            },
            {
                "text": "1.2 Dag: Kalenderdag.",
                "type": "text"
            },
            {
                "text": "1.3 Duurzame gegevensdrager: Elk middel dat de consument of Qwesto in staat stelt om informatie die aan de consument is gericht, op te slaan op een manier die toekomstige raadpleging en ongewijzigde reproductie van de opgeslagen informatie mogelijk maakt.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "1.4 Overeenkomst op afstand: Een overeenkomst waarbij in het kader van een door de ondernemer georganiseerd systeem voor verkoop op afstand van producten en/of diensten, tot en met het sluiten van de overeenkomst uitsluitend gebruik gemaakt wordt van één of meer technieken voor communicatie op afstand."
            },
            {
                "text": "1.5 Algemene Voorwaarden: De onderhavige Algemene Voorwaarden van Qwesto.",
                "type": "text"
            },
            {
                "text": "Qwesto<br>\nSjeng Vaasstraat 3<br>\n6031 HE Nederweert<br>\nNederland<br>\ncontact@qwesto.nl<br><br>\nKVK-nummer: 90491270<br>\nBTW-nummer: NL865334705B01<br>",
                "type": "text",
                "title": "Artikel 2 – Identiteit van de ondernemer"
            },
            {
                "text": "3.1 Deze algemene voorwaarden zijn van toepassing op elk aanbod van Qwesto, op elke tot stand gekomen overeenkomst op afstand en op bestellingen tussen ondernemer en consument.",
                "type": "text",
                "title": "Artikel 3 – Toepasselijkheid"
            },
            {
                "text": "3.2 Voordat er een overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden beschikbaar gesteld aan de consument. Mocht dit redelijkerwijs niet mogelijk zijn, zal Qwesto, voordat de overeenkomst op afstand wordt gesloten, aangeven op welke wijze de algemene voorwaarden kunnen worden ingezien. Qwesto zal op verzoek van de consument de algemene voorwaarden zo spoedig mogelijk en kosteloos toezenden.",
                "type": "text"
            },
            {
                "text": "3.3 Indien de overeenkomst op afstand elektronisch wordt gesloten, kan in afwijking van het vorige lid en voordat de overeenkomst op afstand wordt gesloten, de tekst van deze algemene voorwaarden langs elektronische weg aan de consument ter beschikking worden gesteld op zodanige wijze dat deze op een eenvoudige manier kunnen worden opgeslagen op een duurzame gegevensdrager. Indien dit redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op afstand wordt gesloten, worden aangegeven waar de algemene voorwaarden langs elektronische weg kan worden kennisgenomen en dat zij op verzoek van de consument langs elektronische weg of op andere wijze kosteloos zullen worden toegezonden.",
                "type": "text"
            },
            {
                "text": "3.4 In het geval dat er naast deze algemene voorwaarde specifieke product- en/of dienstvoorwaarden van toepassing zijn, zijn het tweede en derde lid van overeenkomstige toepassing en kan de consument zich in geval van tegenstrijdige voorwaarden steeds beroepen op de toepasselijke bepaling die voor hem het meest gunstig is.",
                "type": "text"
            },
            {
                "text": "3.5 Indien één of meerdere bepalingen in deze algemene voorwaarden op enig moment gedeeltelijk of geheel nietig zijn of vernietigd worden, blijft het overige gedeelte van de overeenkomst en deze voorwaarde van kracht. De betreffende bepaling zal in onderling overleg onverwijld vervangen worden door een bepaling die de strekking van het oorspronkelijke zoveel mogelijk benadert.",
                "type": "text"
            },
            {
                "text": "3.6 Situaties die niet in deze algemene voorwaarden zijn opgenomen, dienen te worden beoordeeld ‘naar de geest’ van deze algemene voorwaarden (beoordelen volgens de intentie van deze voorwaarden).",
                "type": "text"
            },
            {
                "type": "text",
                "text": "3.7 Eventuele onduidelijkheden over de uitleg en/of inhoud van één of meerdere bepalingen van deze voorwaarden, dienen te worden uitgelegd ‘naar de geest’ van deze algemene voorwaarden."
            },
            {
                "title": "Artikel 4 – Het aanbod",
                "text": "4.1 In het geval dat een aanbod een beperkte geldigheidsduur heeft of onder bepaalde voorwaarde valt, dan wordt dit nadrukkelijk in het aanbod vermeld.",
                "type": "text"
            },
            {
                "text": "4.2 Het aanbod bevat een volledige en nauwkeurige beschrijving van de aangeboden producten en/of diensten. Deze beschrijving is gedetailleerd genoeg voor de consument om een goede beoordeling van het aanbod te maken. Indien Qwesto gebruikmaakt van afbeeldingen, zijn deze een waarheidsgetrouwe weergave van de aangeboden producten en/of diensten. Kennelijke vergissingen en/of fouten in het aanbod verbinden Qwesto niet.",
                "type": "text"
            },
            {
                "text": "4.3 Alle afbeeldingen en specificaties die gebruikt worden voor de weergave van het aanbod zijn slechts een indicatie en kunnen in geen enkel geval aanleiding zijn voor schadevergoedingen of ontbinding van de overeenkomst.",
                "type": "text"
            },
            {
                "text": "4.4 Afbeeldingen die bedoeld zijn om een indicatie te geven van de producten en/of diensten zijn een waarheidsgetrouwe weergave. Qwesto kan echter niet garanderen dat de afbeeldingen exact overeenkomen met de uiteindelijke weergave van de producten en/of diensten van de consument (bijvoorbeeld in termen van kleur en formaat).",
                "type": "text"
            },
            {
                "type": "text",
                "text": "4.5 Het aanbod is geheel vrijblijvend. Qwesto is gerechtigd het aanbod te wijzigen en aan te passen, indien dit passend wordt geacht."
            },
            {
                "type": "text",
                "text": "4.6 Elk aanbod bevat een beschrijving die de consument informeert over de rechten en verplichtingen, die verbonden zijn aan het aanvaarden van het betreffende aanbod. Dit omvat onder meer:"
            },
            {
                "type": "list",
                "items": [
                    {
                        "title": "",
                        "text": "De prijs inclusief belastingen;"
                    },
                    {
                        "text": "De wijze waarop de overeenkomst tot stand zal komen en welke handelingen daarvoor verricht moeten worden;",
                        "title": ""
                    },
                    {
                        "text": "De wijze van betaling, aflevering en uitvoering van de overeenkomst;",
                        "title": ""
                    },
                    {
                        "text": "De termijn voor de aanvaarding van het aanbod, zo ook de termijn waarbinnen Qwesto de prijs garandeert;",
                        "title": ""
                    },
                    {
                        "text": "De eventuele hoogte van het tarief voor communicatie op afstand, mochten deze kosten afwijken van het reguliere basistarief van het desbetreffende communicatiemiddel;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "Of de overeenkomst na de totstandkoming wordt gearchiveerd, en zo ja op welke wijze deze voor de consument te raadplegen is;"
                    },
                    {
                        "title": "",
                        "text": "Hoe de consument, vóór het sluiten van de overeenkomst, de door hem in het kader van de overeenkomst verstrekte gegevens kan controleren en indien gewenst kan herstellen;"
                    },
                    {
                        "text": "De taal/talen waarin de overeenkomst kan worden gesloten;",
                        "title": ""
                    },
                    {
                        "text": "De minimale duur van de overeenkomst op afstand in geval van een duurtransactie;",
                        "title": ""
                    },
                    {
                        "text": "Mogelijke extra voorwaarden die bovenop deze algemene voorwaarden gelden voor sommige specifieke producten en/of diensten.",
                        "title": ""
                    }
                ],
                "dots": "disc"
            },
            {
                "type": "text",
                "text": "5.1 De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4, tot stand op het moment dat de consument het aanbod aanvaardt en/of een account aanmaakt en daarbij voldoet en/of toestemming geeft aan de daarbij gestelde voorwaarden.",
                "title": "Artikel 5 – De overeenkomst"
            },
            {
                "type": "text",
                "text": "5.2 Indien de consument het aanbod elektronische heeft aanvaard, bevestigt Qwesto zo spoedig mogelijk elektronische de ontvangst en aanvaarding van het aanbod. Op het moment dat de overeenkomst van deze aanvaarding niet door Qwesto is bevestigd, kan de consument de overeenkomst ontbinden."
            },
            {
                "type": "text",
                "text": "5.3 Indien de overeenkomst elektronisch tot stand komt, neemt Qwesto passende technische en organisatorische maatregelen om de elektronische overdracht te beveiligen. Daarnaast zorgen wij voor een veilige webomgeving. Indien de consument elektronisch kan betalen, zal Qwesto passende veiligheidsmaatregelen treffen."
            },
            {
                "text": "5.4 Qwesto kan zich binnen wettelijke kaders op de hoogte stellen of de consument aan zijn betalingsverplichtingen kan voldoen, zo ook van al die feiten en factoren die van belang zijn voor het verantwoord aangaan van de overeenkomst op afstand. Indien Qwesto op grond van dit onderzoek goede redenen heeft om de overeenkomst niet aan te gaan, is Qwesto gerechtigd gemotiveerd een bestelling of aanvraag te weigeren of aan de uitvoering bijzondere voorwaarden te verbinden.",
                "type": "text"
            },
            {
                "text": "5.5 Qwesto zal bij het product en/of dienst de volgende informatie, schriftelijk of op zodanige wijze dat deze door de consument op een toegankelijke manier kan worden opgeslagen op een duurzame gegevensdrager, meesturen:",
                "type": "text"
            },
            {
                "items": [
                    {
                        "title": "",
                        "text": "Het bezoekadres van de vestiging van Qwesto, waar de consument terecht kan met eventuele klachten;"
                    },
                    {
                        "text": "De informatie over garanties en bestaande service na aankoop;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "De in artikel 4 lid 2 van deze voorwaarden opgenomen gegevens, tenzij Qwesto deze gegevens al aan de consument heeft verstrekt vóór de uitvoering van de overeenkomst."
                    },
                    {
                        "text": "De prijs met inbegrip van alle belastingen van het product, de dienst of de digitale inhoud, evenals voor zover van toepassing de kosten van aflevering en de wijze van betaling, aflevering of uitvoering van de overeenkomst op afstand;",
                        "title": ""
                    },
                    {
                        "title": "",
                        "text": "De vereiste voor opzegging van de overeenkomst indien de overeenkomst een duur heeft van meer dan één jaar of van onbepaalde duur is."
                    }
                ],
                "type": "list",
                "dots": "disc"
            },
            {
                "type": "text",
                "text": "5.6 In geval van een duurtransactie is de bepaling in het vorige lid slechts van toepassing op de eerste levering."
            },
            {
                "title": "Artikel 6 – Gebruik van het product en/of dienst",
                "text": "6.1 Qwesto.nl biedt u de mogelijkheid om een, éénmalig te spelen, gepersonaliseerde quiz (ook wel ‘Qwesto’ genoemd) aan te schaffen. Deze ‘Qwesto’ wordt gegenereerd aan de hand van een vragenlijst (ook wel ‘Intake’ genoemd) die beschikbaar is na de aanschaf. Iedere deelnemer vult deze vragenlijst in. Hierbij kan mogelijk gevraagd worden om persoonlijk materiaal (beeldmateriaal en/of tekst), mits hier vooraf door iedere deelnemer toestemming voor is gegeven (door deze overeenkomst te sluiten). U bepaalt zelf hoe u de ‘Qwesto’ inzet en voor welke specifieke doeleinde.",
                "type": "text"
            },
            {
                "text": "6.2 Om gebruik te kunnen maken van sommige producten en/of diensten aangeboden op Qwesto.nl, dient u een ‘Qwesto’ te selecteren en deze af te rekenen via één van de betalingsmogelijkheden die Stripe aanbiedt.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "6.3 Na het afronden van de betaling krijgt de consument de mogelijkheid om een groep te maken met deelnemers naar keuze. De consument die de betaling heeft afgerond wordt ook wel ‘host’ genoemd. De ‘host’ kan deelnemers uitnodigen door middel van een uitnodigingslink. Voordat deze uitnodiging geaccepteerd kan worden, moet de deelnemer een account aanmaken. Om een account aan te kunnen maken, moet er eerst akkoord worden gegaan met deze algemene voorwaarden. Nadat de alle door de ‘host’ gewenste deelnemers zijn toegetreden, met en maximaal aantal spelers dat voor aanschaf kenbaar is gemaakt, kan de ‘host’ de groep éénmalig bevestigen. Na bevestiging kunnen er geen nieuwe deelnemers meer worden uitgenodigd en deze bevestiging is onomkeerbaar."
            },
            {
                "type": "text",
                "text": "6.4 Nadat de groep is bevestigd, wordt het vragenlijstje beschikbaar gesteld. Hierbij kan mogelijk gevraagd worden naar persoonlijk materiaal (beeldmateriaal en/of tekst). Iedere deelnemer heeft te allen tijde het recht en de mogelijkheid om vragen niet te beantwoorden. Dit kan door een vraag over te slaan. De betreffende deelnemer wordt voorafgaand aan het overslaan van één of meerdere vragen op de hoogte gesteld wat voor gevolgen dit heeft voor het product en/of dienst."
            },
            {
                "text": "6.5 Nadat alle deelnemers uit de groep de ‘Intake’ hebben afgerond kan de ‘host’ de ‘Qwesto’ starten. Vooraf en/of tijdens het spelen wordt uitleg gegeven waar Qwesto dit nodig acht.\n\n",
                "type": "text"
            },
            {
                "text": "6.6 Eén week na het volledig afronden van de ‘Qwesto’ worden zowel de groep, als al het door de deelnemers aangeleverde materiaal vernietigd.",
                "type": "text"
            },
            {
                "text": "6.7 Elke Qwesto kan maar één keer gespeeld worden. Dit is vooraf duidelijk gemaakt aan de consument, op wijzen beschreven in artikel 4 lid 2.",
                "type": "text"
            },
            {
                "title": "Artikel 7 – Gebruiksregels",
                "text": "7.1 Het is verboden Qwesto.nl te gebruiken voor handelingen en/of doeleinden die in strijd zijn met de Nederlandse of andere van toepassing zijnde wet- en regelgeving. Hieronder valt onder andere het opslaan en/of verspreiden van informatie die smadelijk, lasterlijk of racistisch is via producten en/of diensten geleverd door Qwesto.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "7.2 Indien Qwesto constateert dat u de bovengenoemde voorwaarden overtreedt of hier een klacht over ontvangt, behoudt Qwesto het recht om zelf ingrijpen om een einde te maken aan de overtreding(en)."
            },
            {
                "text": "7.3 Indien naar het oordeel van Qwesto schade, hinder of een ander gevaar ontstaat voor het functioneren van bijvoorbeeld het netwerk en de computersystemen van Qwesto en/of derden, met name door het overmatig verzenden en/of aanvragen van gegevens, het lekken van persoonsgegevens, verzenden van e-mails, aanwezigheid van virussen, Trojans en vergelijkbare malware, heeft Qwesto het recht alle maatregelen te nemen die Qwesto redelijkerwijs nodig acht om dit gevaar af te wenden of te voorkomen.",
                "type": "text"
            },
            {
                "type": "text",
                "text": "7.4 Qwesto is te alle tijden gerechtigd om aangifte te doen na het constateren van enig strafbaar feit."
            },
            {
                "text": "7.5 Indien u enige vorm van schade ondervindt als gevolg van het overtreden van één of meer gedragsregels, kan Qwesto de schade op u verhalen, U vrijwaart Qwesto van alle aanspraken van derden die betrekking hebben op schade als gevolg van een overtreding van deze gebruiksregels.",
                "type": "text"
            },
            {
                "text": "8.1 Het gebruik van de website van Qwesto.nl en haar producten en/of diensten is onderhevig aan een fair use policy.",
                "title": "Artikel 8 – Fair Use Policy",
                "type": "text"
            },
            {
                "type": "text",
                "text": "8.2 Indien Qwesto constateert dat het gebruik van een consument significant hoger is dan het gemiddelde gebruik, is Qwesto gerechtigd om dit als overmatig te beschouwen. Qwesto zal de consument in dit geval op de hoogte stellen, en verzoeken om zijn gebruik aan te passen. Indien de consument geen gehoor geeft aan dit verzoek, is Qwesto gerechtigd om beperkingen op te leggen."
            },
            {
                "text": "9.1 Qwesto streeft ernaar om haar diensten en/of producten te alle tijden beschikbaar te stellen. Desondanks kunnen wij geen voortdurende beschikbaarheid garanderen.\n\n",
                "type": "text",
                "title": "Artikel 9 – Beschikbaarheid en onderhoud"
            },
            {
                "type": "text",
                "text": "9.2 Qwesto is genoodzaakt om onderhoud te plegen aan Qwesto.nl om de kwaliteit en functionaliteit zo hoog mogelijk te houden. Indien gepland onderhoud resulteert in een beperking van de beschikbaarheid, wordt dit vooraf aangekondigd. Qwesto zal proberen dit onderhoud uit te voeren wanneer het gebruik laag is, maar kan dit niet garanderen. In het geval van calamiteiten kan onderhoud op elk moment plaatsvinden zonder voorafgaande aankondiging.\n\n"
            },
            {
                "text": "9.3 Indien onderhoud leidt tot schade aan producten en/of diensten die zijn aangeschaft door de consument, is de aansprakelijkheid van Qwesto beperkt tot het bedrag dat de consument heeft betaald voor de desbetreffende diensten en/of producten.\n\n",
                "type": "text"
            },
            {
                "text": "9.4 Qwesto is ten alle tijden gerechtigd om de functionaliteit van Qwesto.nl aan te passen, op de wijze die zij geschikt achten.\n\n",
                "type": "text"
            },
            {
                "text": "10.1 De software van Qwesto, de producten en/of diensten en alle overige informatie en afbeeldingen van Qwesto.nl is intellectueel eigendom van Qwesto. Het is op geen enkele wijze toegestaan om deze te kopiëren of te gebruiken zonder schriftelijke toestemming van Qwesto, tenzij dit wettelijk is toegestaan.\n\n",
                "type": "text",
                "title": "Artikel 10 - intellectueel eigendom"
            },
            {
                "text": "10.2 Informatie en/of gegevens die de consument opslaat of verwerkt via Qwesto.nl blijven zijn eigendom. Het gebruiksrecht van Qwesto om deze informatie in te zetten voor Qwesto.nl is beperkt. De consument kan dit gebruiksrecht intrekken door informatie te verwijderen en/of de overeenkomst te beëindigen.\n\n",
                "type": "text"
            },
            {
                "text": "10.3 Qwesto neemt geen kennis van informatie en/of gegevens die de consument opslaat en/of verspreidt via Qwesto.nl, tenzij dit noodzakelijk is voor een goede dienstverlening of wettelijk verplicht is. In dat geval zal Qwesto het kennisnemen van de informatie zo veel mogelijk proberen te beperken.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "10.4 Indien de consument uit eigen initiatief informatie naar Qwesto stuurt, verleent hij Qwesto automatisch een onbeperkt en eeuwigdurend gebruiksrecht om deze informatie te gebruiken, tenzij de consument de informatie bij de verzending uitdrukkelijk als vertrouwelijk markeert.\n\n"
            },
            {
                "text": "11.1 Voor het gebruik van verschillende producten en/of diensten aangeboden op Qwesto.nl is een prijs van toepassing. Deze prijs is te vinden op de website en wordt vermeld in de artikel 4 lid 2 van deze voorwaarden opgenomen gegevens. Deze prijs dient betaald te zijn voordat er toegang wordt verleend tot het desbetreffende product en/of dienst. De prijs kan variëren per ‘Qwesto’.\n\n",
                "type": "text",
                "title": "Artikel 11 – De prijs"
            },
            {
                "text": "11.2 De prijzen van de producten en/of diensten die in het aanbod zijn genoemd zijn inclusief btw.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "11.3 De betaling dient te worden voldaan via één van de betalingsmethoden die worden aangeboden door Stripe, zoals IDEAL-betaling of creditcard.\n\n"
            },
            {
                "type": "text",
                "text": "11.4 Het product en/of dienst wordt op verzoek direct geleverd. Het is daarom niet mogelijk om een betaling ongedaan te maken op basis van de wet koop op afstand.\n\n"
            },
            {
                "type": "text",
                "text": "11.5 Alle prijzen zijn onder voorbehoud van druk- en zetfouten. Voor eventuele gevolgen van dergelijke fouten aanvaarden wij geen aansprakelijkheid. Bovendien is Qwesto niet verplicht het product te leveren tegen de foutieve prijs.\n\n"
            },
            {
                "type": "text",
                "title": "Artikel 12 – Betaling",
                "text": "12.1 Voordat de betaling wordt voltooid, dienen deze algemene voorwaarden te zijn aanvaard.\n\n"
            },
            {
                "text": "12.2 De betaling dient te worden voltooid via één van de betalingsmethoden die mogelijk worden gemaakt door Stripe. Nadat de betaling is bevestigd, krijgt de consument toegang tot de desbetreffende producten en/of diensten.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "12.3 De consument is verplicht om onjuistheden in de vermelde en/of verstrekte betaalgegevens onmiddellijk aan Qwesto te melden.\n\n"
            },
            {
                "type": "text",
                "title": "Artikel 13 - Duur en opzegging",
                "text": "13.1 De overeenkomst met deze algemene voorwaarden blijft bestaan zolang dat de consument over een account beschikt.\n\n"
            },
            {
                "text": "13.2 Na de aanschaf van een ‘Qwesto’ heeft de consument een onbeperkte tijd om een groep te maken, de ‘Qwestions’ in te vullen en de Qwesto één keer te spelen.\n\n",
                "type": "text"
            },
            {
                "text": "13.3 Nadat de consument de ‘Qwesto’ volledig heeft voltooid, blijven de aangeleverde gegevens voor deze ‘Qwesto’ nog één week bewaard. Tenzij wij wettelijk verplicht zijn om anders te handelen. Na een week vervallen eventuele extra overeenkomsten die zijn gesloten met betrekking tot specifieke voorwaarden die van toepassing zijn op (sommige) producten en/of diensten in het aanbod. Dit heeft echter geen invloed op de geldigheid van deze algemene voorwaarden en deze overeenkomst blijft van kracht.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "13.4 De consument kan de overeenkomst, die is gesloten na het aanmaken van een account, beëindigen door zijn account te verwijderen.\n\n"
            },
            {
                "text": "14.1 Qwesto staat ervoor in dat de geleverde producten en/of diensten voldoen aan de overeenkomst en aan de specificaties en beschrijving vermeld in het aanbod.\n\n",
                "title": "Artikel 14 – garantie",
                "type": "text"
            },
            {
                "type": "text",
                "text": "14.2 Indien de producten en/of diensten niet of onjuist geleverd zijn, dient de consument dit binnen 2 maanden na aankoop schriftelijk aan Qwesto te melden.\n\n"
            },
            {
                "text": "14.3 In het geval zoals vermeld lid kan de consument aanspraak maken op garantie en hiermee een maximale vergoeding krijgen van het toenmalige bedrag dat de consument heeft betaald voor het desbetreffende product en/of dienst.\n\n",
                "type": "text"
            },
            {
                "text": "14.4 Deze garantie is niet van toepassing indien de consument:\n\n",
                "type": "text"
            },
            {
                "items": [
                    {
                        "title": "",
                        "text": "Zich niet (succesvol) kan identificeren;"
                    },
                    {
                        "title": "",
                        "text": "Niet kan aantonen dat er sprake is van een onjuist of niet geleverd product en/of dienst;"
                    },
                    {
                        "title": "",
                        "text": "Zich niet binnen 2 maanden na aankoop een schriftelijke melding bij Qwesto heeft gemaakt;"
                    },
                    {
                        "text": "Een vergissing heeft gemaakt bij de aanschaf van een ‘ongewenst’ product en/of dienst.",
                        "title": ""
                    }
                ],
                "type": "list",
                "dots": "disc"
            },
            {
                "title": "Artikel 15 – Aansprakelijkheid",
                "type": "text",
                "text": "15.1 Tenzij er sprake is van opzet of ernstige nalatigheid, is de aansprakelijkheid van Qwesto beperkt tot het bedrag dat de consument heeft betaald voor het desbetreffende product en/of dienst.\n\n"
            },
            {
                "type": "text",
                "text": "15.2 Qwesto stelt zich niet aansprakelijk voor zowel materiele en immateriële schade die mogelijk wordt veroorzaakt door producten en/of diensten (bijvoorbeeld het onbedoeld kwetsen van een consument door een ongepaste vraag), Zolang de consument voor de aankoop van de risico’s op de hoogte is gesteld.\n\n"
            },
            {
                "type": "text",
                "text": "15.3 Qwesto is uitdrukkelijk niet aansprakelijk voor enige vorm van indirecte schade, gederfde winst, gevolgschade, schade door bedrijfsstagnatie en gemiste besparingen.\n\n"
            },
            {
                "text": "15.4 Om enig recht op een schadevergoeding te hebben, dient de consument uiterlijk binnen 2 maanden na het ontdekken van de schade, dit schriftelijk bij Qwesto melden.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "15.5 In geval van overmacht is Qwesto niet aansprakelijk voor enige vorm van vergoeding. Van overmacht is onder meer sprake bij storingen, uitvallen van internet of telecommunicatie-infrastructuur, een slechte internetverbinding (van de consument), stroomstoringen, binnenlandse onlusten, brand, overstromingen, staking, bedrijfsstoornissen, stagnatie in toelevering, mobilisatie en oorlog.\n\n"
            },
            {
                "text": "16.1 Klachten over de uitvoering van de overeenkomst moeten binnen 2 maanden nadat de consument de gebreken heeft geconstateerd, volledig en duidelijk omschreven schriftelijk worden ingediend bij Qwesto.\n\n",
                "type": "text",
                "title": "Artikel 16 – Klachtenregeling"
            },
            {
                "text": "16.2 De klachten die bij Qwesto worden ingediend, worden binnen 14 dagen na ontvangst beantwoord. Indien een klacht een voorzienbaar langere verwerkingstijd vereist, zal Qwesto binnen 14 dagen na ontvangst van de klacht bevestigen dat deze is ontvangen en een indicatie geven van wanneer de consument een meer uitvoerig antwoord kan verwachten\n\n",
                "type": "text"
            },
            {
                "text": "16.3 Tenzij Qwesto schriftelijk anders aangeeft, blijven de verplichtingen van Qwesto van kracht en deze worden niet opgeschort door een klacht.\n\n",
                "type": "text"
            },
            {
                "text": "16.4 Indien Qwesto een klacht gegrond acht, zal Qwesto naar eigen keuze het product en/of dienst vervangen of vergoeden.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "title": "Artikel 17 – Wijzigingen voorwaarden",
                "text": "17.1 Qwesto behoudt zich het recht om deze voorwaarden op ieder gewenst moment aan te passen.\n\n"
            },
            {
                "type": "text",
                "text": "17.2 Qwesto zal eventuele wijzigingen of aanvullingen ten minste 30 dagen voor inwerkingtreding aankondigen.\n\n"
            },
            {
                "type": "text",
                "text": "17.3 Indien de consument een wijziging of aanvulling niet wenst te accepteren, heeft de consument tot de datum van inwerkingtreding de tijd om de overeenkomst te beëindigen door zijn account te verwijderen. Het gebruik van Qwesto.nl na de datum van inwerkingtreding wordt beschouwd als een automatische goedkeuring van de gewijzigde en/of aangevulde voorwaarden.\n\n"
            },
            {
                "type": "text",
                "text": "18.1 Op deze overeenkomst is het Nederlands recht van toepassing.\n\n",
                "title": "Artikel 18 – overige bepalingen"
            },
            {
                "text": "18.2 Tenzij dwingendrechtelijke bepalingen anders voorschrijven, worden geschillen met betrekking tot Qwesto.nl voorgelegd aan de bevoegde rechtbank in Nederland, in het arrondissement waar Qwesto is gevestigd.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "18.3 In gevallen waarin Qwesto vereist dat informatie schriftelijk wordt verstrekt, kan een mededeling via e-mail ook volstaan. Deze e-mail moet worden verzonden naar het volgende e-mailadres: support@qwesto.nl. De consument moet in dit geval kunnen bewijzen dat hij de daadwerkelijke afzender is.\n\n"
            },
            {
                "type": "text",
                "text": "18.4 De informatie en communicatie die Qwesto opslaat wordt als juist beschouwd, tenzij de consument tegenbewijs levert\n\n"
            },
            {
                "text": "18.5 Indien één of meerdere bepalingen van deze voorwaarden nietig blijken te zijn, blijft de geldigheid van de overige bepalingen onaangetast. In dat geval zal er ter vervanging een nieuwe bepaling worden opgesteld die zo goed mogelijk aansluit bij de strekking van de oorspronkelijke bepaling(en).\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "18.6 De consument is op de hoogte van en begrijpt de privacyverklaring. De consument stemt ermee in dat wij en/of derden de verzamelde gegevens verwerken op de wijze zoals beschreven in de privacyverklaring.\n\n"
            },
            {
                "text": "18.7 De consument is op de hoogte van en begrijpt de risico's zoals vermeld in de disclaimer en stemt hiermee in. Dit betekent dat de consument geen recht heeft op schadevergoeding als een risico vermeld in de disclaimer leidt tot enige vorm van schade.\n\n",
                "type": "text"
            },
            {
                "type": "text",
                "text": "18.8 In geval van afwijking tussen deze Nederlandse versie van het document en andere versies, prevaleert deze Nederlandse versie.\n\n"
            }
        ],
        "description": "Onze algemene voorwaarden",
        "title": "Algemene voorwaarden",
        "pathID": "terms",
        "date": "",
        "photoURL": "",
        "id": 0
    }
]