import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

export const PrivateContext = () => {
    //get user info
    const { user } = useAuthContext()
    const location = useLocation();

    return(
        user ? <Outlet/> : <Navigate to={`/login${location.pathname ? "?redirect=" + encodeURIComponent(location.pathname) : ""}`}/>
    )
}

