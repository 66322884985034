import { useEffect } from "react";
import { ref, set} from 'firebase/database';
import { rdb } from "../../../config/firebaseConfig";
import { useRef } from "react";
import Play from "../../../assets/img/Play";
import Pause from "../../../assets/img/Pause";
import { useState } from "react";
import { CorrectText } from "./CorrectText";
import { QuestionBar } from "./QuestionBar";

export function WhatDoYouHearViewer({activeQuestion, gameDoc, groupDoc}){
    const correctAnswer = activeQuestion.firstTrue
    const [isPlaying, setIsPlaying] = useState(true)
    const videoRef = useRef(null)

    useEffect(() => {
        set(ref(rdb, "games/" + groupDoc.qwestoID + "/correctAnswer"), correctAnswer)
    }, [correctAnswer, groupDoc.qwestoID])

    useEffect(() => {
        const video = videoRef.current 

        const handlePlay = () => {
            setIsPlaying(true);
        };

        const handlePause = () => {
            setIsPlaying(false);
        };
    
        video.addEventListener("play", handlePlay);
        video.addEventListener("pause", handlePause);
    
        return () => {
            video.removeEventListener("play", handlePlay);
            video.removeEventListener("pause", handlePause);
        };
    }, [])

    function handleVideo(){
        if(videoRef.current.paused){
            videoRef.current.play()
            setIsPlaying(true)
        }else{
            videoRef.current.pause()
            setIsPlaying(false)
        }
    }

    return(
        <>
            <QuestionBar question={activeQuestion.videoHasSound ? "Wat hoor je?" : "Wat gebeurt er?"} slideUp={activeQuestion.videoHasSound ? (gameDoc.stage === "ANSWERING" ? "" : " slide-up") : " slide-up"}/>
            <button className="btn video-play-btn" onClick={handleVideo}>{isPlaying ? <Pause className="icon"/> : <Play className="icon"/>}</button>
            <div className={`correct-answer column relative ${activeQuestion.videoHasSound ? (gameDoc.stage === "ANSWERING" ? "static" : " slide-up") : ""}`}>
                <div className={activeQuestion.videoHasSound ? (
                    gameDoc.stage === "ANSWERING" ? "video-reveal active hidden" : "video-reveal"
                ) : (
                    gameDoc.stage === "ANSWERING" ? "video-reveal active blur" : "video-reveal"
                )}>
                    <video ref={videoRef} src={activeQuestion.videoURL} autoPlay controls={false} loop playsInline alt="" className={"video-reveal-video"}/>
                    <div className="video-blocker"/>
                </div>
                {gameDoc.stage === "RESULTS" &&
                    <CorrectText text={correctAnswer} fixed/>
                }
            </div>
        </>
    )
}