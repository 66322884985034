import React, { useState } from "react";
import { useEffect } from "react";
import { shuffleArray } from "../../../utils/pure/shuffleArray";
import "./PlayerCountdown.css"


export default function PlayerCountdown({players}){
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        var isMounted = true
        
        async function switchAvatar(playerIndex){
            let newPlayerIndex = playerIndex
            setActiveIndex(newPlayerIndex)

            await new Promise(resolve => setTimeout(resolve, 1000))
            if(newPlayerIndex < players.length - 1){
                newPlayerIndex++
            }else{
                newPlayerIndex = 0
            }
            if(isMounted){
                switchAvatar(newPlayerIndex)
            }
        }

        switchAvatar(0)

        return () => {
            isMounted = false
        }
    }, [players])

    return(
        <div className="player-countdown-label">
            <div className="player-countdown">
                <div className="player-countdown-text">
                    {shuffleArray(players).map((player, index) => (
                        <React.Fragment key={index}>
                            <img src={player.photoURL} alt="" className={`player-countdown-avatar ${activeIndex === index ? "active" : ""}`} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}
