import Message from "../../../assets/img/Message";

export default function Sent(){
    return(
        <>
            <Message className="icon big"/>
            <h2>Verstuurd</h2>
        </>
    )
}
