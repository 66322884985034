import React, { useState } from "react"
import QwestoSmall from "../../assets/img/QwestoSmall"
import Footer from "../../components/layout/Footer"
import SEO from "../../components/utils/SEO"
import { auth } from "../../config/firebaseConfig"
import { useResetPassword } from "../../hooks/useResetPassword"

export default function ResetPassword(){
    const [email, setEmail] = useState("")
    const {tryResetPassword, passwordIsPending} = useResetPassword()

    const handleSubmit = (e) => {
        e.preventDefault()
        tryResetPassword(auth, email, true)
    }

    return(
    <>
    <SEO title={"Wachtwoord resetten"} description={"Reset je wachtwoord."}/>
    <div className="content-page">
        <div className="content-field white">
            <div className="content-container min-height">
                <div className="column center">
                    <form onSubmit={handleSubmit} className="auth-form">
                        <h2 className="center">Wachtwoord resetten</h2>
                        <div className="border-column no-border">
                            <label>Email
                                <input type="email" id="email" 
                                autoComplete="username"
                                required
                                maxLength={256}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email} 
                                />
                            </label>
                        </div>
                        <div className="border-column">
                            <p className="grey-text">We sturen je een email met instructies om je wachtwoord te resetten.</p>
                            {!passwordIsPending ? <button className="btn-gr">Verzenden</button> : <button className="btn-gr" disabled><QwestoSmall className="load"/></button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    </>
    )
};