import { GATHER_GROUP_URL, HERO_VIDEO_COMPRESSED_URL, VIDEO_PHOTO_TEXT_URL } from "../../data/constants";
import DescriptionRow from "../../components/layout/DescriptionRow";

export default function Explanation(){
    return(
        <div className="content-field dark-black">
            <div className="home-section margin-sides gap max-width">
                <div className="description-row-list">
                    <DescriptionRow description="Verzamel je favoriete groep vrienden, familie of collega's. Je kunt ze gemakkelijk uitnodigen voor je Qwesto" descriptionTitle="Verzamel je groep" subject={
                            <video src={GATHER_GROUP_URL} className="description-row-img large" playsInline muted autoPlay loop/>
                        }
                    />
                    <DescriptionRow swap description="Deel de leukste, gekste en mooiste foto’s, video’s en verhalen van jouw favoriete groep mensen" descriptionTitle="Gebruik fotos, videos en verhalen" subject={
                            <video src={VIDEO_PHOTO_TEXT_URL} className="description-row-img large" playsInline muted autoPlay loop/>
                        }
                    />
                    <DescriptionRow description="Jullie foto’s, video’s en verhalen worden omgetoverd tot de perfecte quiz om herinneringen te herbeleven, elkaar nog beter te leren kennen en vooral om heel veel te lachen!" descriptionTitle="...en spelen maar!" subject={
                            <>
                                <video src={HERO_VIDEO_COMPRESSED_URL} className="description-row-img large" playsInline muted autoPlay loop/>
                            </>
                        } 
                    />
                </div>
            </div>
        </div>
    )
}