import { DEFAULT_USER_PHOTO_URL } from "../../../data/constants";
import { useGameLogicContext } from "../../../hooks/useGameLogicContext";
import ControlBarAvatarLabels from "./ControlBarAvatarLabels";

export default function ControlBarAvatars({gameDoc, activeQuestion}) {
    const {answeredProps} = useGameLogicContext()

    return(
        <>
            {answeredProps &&
                <div className="control-bar-avatars">
                    {[...Array(answeredProps.total)].map((value, index) => (
                        <div className="control-bar-avatar" key={index}>
                            {answeredProps.answered[index] &&
                                <img src={(gameDoc.stage === "ANSWERING") ? DEFAULT_USER_PHOTO_URL : answeredProps.answered[index].photoURL} className="control-bar-avatar-img" alt="" key={index}/>
                            }
                            <ControlBarAvatarLabels gameDoc={gameDoc} activeQuestion={activeQuestion} answeredProps={answeredProps} index={index}/>
                        </div>
                        ))
                    }
                </div>
            }
        </>
    )
}
