export function getDynamicHeroTitle(target){
    switch(target){
        case "gezelschapsspellen":
            return "Qwesto, leuker dan een gezelschapsspel."            
        case "bordspel":
        case "gezelschapsspellen-bordspel":
        case "kickstarter-bordspel":
            return "Qwesto, leuker dan een bordspel."          
        case "gezelschapsspellen-kaartspellen":
        case "kaartspel":
            return "Het beste kaartspel alternatief."
        case "nox-spellenzolder":
            return "Qwesto, leuker dan Nox' spellenzolder."
        case "quiz":
            return "Qwesto, het gezelligste quizspel van Nederland."
        case "pappen":
            return "Qwesto, leuker dan pappen."                
        case "hartenjagen":
            return "Qwesto, leuker dan hartenjagen."        
        case "klaverjassen":
            return "Qwesto, leuker dan Klaverjassen."        
        case "diepr":
            return "Qwesto, leuker dan DIEP'R."        
        case "gezelligste":
            return "Qwesto, het gezelligste spel van Nederland."        
        case "pubquiz":
        case "pubquiz-broad":
        case "pubquiz-vragen":
        case "pubquiz-raoul":
            return "De pubquiz opnieuw uitgevonden."            
        case "beverbende":
            return "Qwesto, leuker dan Beverbende."            
        case "droomtelefoon":
            return "Qwesto, het droom-telefoon alternatief."            
        case "monopoly":
            return "Qwesto, leuker dan Monopoly."            
        case "kwartet":
            return "Qwesto, leuker dan Kwartet."            
        case "uilenbos":
            return "Qwesto, leuker dan Het Uilenbos."       
        default:
            return "Het gezelligste spel van Nederland."      
    }
}