import GroupListActive from "./GroupListActive"
import "../home/Home.css"
import Loader from "../../components/loaders/Loader";
import "./MyQwesto.css"
import Footer from "../../components/layout/Footer";
import { useGroupDataContext } from "../../hooks/useGroupDataContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import SEO from "../../components/utils/SEO";
import { useMemo } from "react";
import { useNavigationBarContext } from "../../hooks/useNavigationBarContext";

export default function MyQwesto() {
    const {user}  = useAuthContext()
    const {setFilterVisible} = useNavigationBarContext()
    const {groupData, noGroupData} = useGroupDataContext(useMemo(() => ({
        type: "ALL_GROUPS",
        qwestoID: null
    }), []))


    if(user && (groupData.multiple === null && !noGroupData)){
        return(
            <Loader/>
        )
    }


    return(
        <>
        <SEO title={"Mijn Qwesto's"} description={"Bekijk en speel jouw Qwesto's."}/>
        <div className="content-page">
            <div className="content-field white">
                <div className="content-container min-height max-width gap">
                    <div className="row stay space-between">
                        <h1>Mijn Qwesto's</h1>
                        <button className="btn text no-grow round" onClick={() => {setFilterVisible(true)}}>Groep toevoegen</button>
                    </div>
                    <div className="group-list">
                        <GroupListActive groupDocs={groupData.multiple} noData={noGroupData}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        </>
    )
}