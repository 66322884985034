import Footer from "../../../components/layout/Footer";
import { useAuthContext } from "../../../hooks/useAuthContext";
import InviteSignedIn from "./InviteSignedIn";
import InviteSignedOut from "./InviteSignedOut";

export default function Invite() {
    const { user } = useAuthContext()

    return(
        <div className="content-page">   
        {user ? 
            <InviteSignedIn/>
        :
            <InviteSignedOut/>
        }
        <Footer/>
        </div>
    )
}
