export default function PhotoRevealIcon(props) {
    return (
        <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
            data-name="Vector"
            d="M493.792 667.064c0-62.301-98.289-112.803-219.55-112.803-121.261 0-219.549 50.502-219.549 112.803"
            style={{
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "66.67px",
            }}
            transform="translate(74.77 18.86) scale(.65306)"
            />
            <path
            data-name="Vector"
            d="M212.129 172.326c3.926-11.4 11.125-21.554 20.796-29.34 9.668-7.783 21.433-12.899 33.992-14.779 25.615-3.834 51.346 6.205 66.587 25.98 7.477 9.693 11.966 21.147 12.967 33.094.994 11.947-1.535 23.92-7.306 34.599-5.771 10.679-14.562 19.65-25.403 25.917-10.846 6.26-23.317 9.574-36.036 9.575v16.242m.858 64.987v1.627h-1.718v-1.627h1.718Z"
            style={{
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "38.2816px",
            }}
            transform="translate(74.77 18.86) scale(.65306)"
            />
            <path
            data-name="Vector"
            d="M274.556 438.824c-113.465 0-205.456-91.982-205.456-205.457S161.091 27.92 274.556 27.92c113.467 0 205.456 91.982 205.456 205.457 0 113.474-91.982 205.455-205.456 205.455v-.008Z"
            style={{
                fill: "none",
                stroke: "currentColor",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "66.67px",
            }}
            transform="translate(74.77 18.86) scale(.65306)"
            />
        </svg>
    );
}