export default function WhatIsTrueIcon(props) {
    return (
        <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g
        data-name="Communication / Chat_Circle_Dots"
        transform="translate(15.19 23.857) scale(.68628)"
        />
        <g data-name="Communication / Chat_Circle_Dots">
        <path
            data-name="Vector"
            d="M255.93 435.89c0 110.27 87.81 188.07 198.08 188.07 36.27 0 70.29-9.67 99.6-26.58l.09-.05c2.84-1.63 4.26-2.46 5.61-2.83 1.27-.35 2.4-.47 3.71-.38 1.41.1 2.89.59 5.8 1.56l51.17 17.06.13.05c10.75 3.58 16.12 5.37 19.71 4.09 3.14-1.12 5.62-3.59 6.74-6.73 1.28-3.6-.52-9-4.12-19.79l-.02-.07-17.06-51.17c-.97-2.92-1.45-4.39-1.55-5.79-.09-1.31 0-2.45.36-3.72.37-1.34 1.19-2.77 2.82-5.59l.07-.11c16.91-29.32 26.58-63.33 26.58-99.61 0-110.27-89.39-199.66-199.66-199.66m-58.81 259.27 59.61-59.61m0 0 59.61-59.61m-59.61 59.61-48.98-48.98m48.98 48.98 59.61 59.61M322.41 184.44 203.19 303.66l-59.61-59.61m-10.2 162.02c29.32 16.91 63.33 26.58 99.6 26.58 110.27 0 199.67-89.39 199.67-199.66S343.26 33.33 232.99 33.33 33.33 122.72 33.33 232.99c0 36.27 9.67 70.29 26.58 99.61l.07.11c1.63 2.82 2.45 4.24 2.82 5.59.35 1.27.45 2.41.36 3.72-.1 1.41-.57 2.87-1.55 5.79l-17.06 51.17-.02.07c-3.6 10.79-5.4 16.19-4.12 19.79 1.12 3.13 3.6 5.61 6.74 6.73 3.59 1.28 8.96-.51 19.71-4.09l.13-.05 51.17-17.06c2.91-.97 4.39-1.46 5.8-1.56 1.31-.09 2.44.03 3.71.38 1.35.37 2.77 1.19 5.61 2.83l.09.05h.01Z"
            style={{
            fill: "none",
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "66.67px",
            }}
            transform="translate(15.19 23.857) scale(.68628)"
        />
        </g>
        <g
        data-name="Communication / Chat_Circle_Close"
        transform="translate(15.19 23.857) scale(.68628)"
        />
        <g
        data-name="Communication / Chat_Circle_Check"
        transform="translate(15.19 23.857) scale(.68628)"
        />
    </svg>
    );
}