import {useState} from "react";
import {increment, ref, update} from 'firebase/database';
import {rdb} from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";

export function useGoBackQwestoQuestion(){
    const [isPending, setIsPending] = useState(false)
    const { setNewError } = useMessageContext()

    async function tryGoBackQuestion(groupDoc, gameDoc){
        if(!isPending && gameDoc.activeQuestionIndex !== 1){
            const gameDocObject = {
                activeQuestionIndex: increment(-1),
                answers: {},
                correctAnswer: "EMPTY",
                stage: "INTRODUCTION"
            }
    
            groupDoc.players.forEach((player) => {
                gameDocObject.answers[player.uid] = "EMPTY"
            })
    
            update(ref(rdb, "games/" + groupDoc.qwestoID), gameDocObject)
            .then(() => {
                setIsPending(false)
            })
            .catch((error) => {
                console.log(error);
                setNewError("Er is iets misgegaan")
                setIsPending(false)
            })
        }
    }

    return { isPending, tryGoBackQuestion }
}