import DropDown from "../../../components/input/DropDown"
import ImageInput from "../../../components/input/ImageInput"

export function PhotoReveal({groupDoc, photo, setPhoto, photoURL, setPhotoURL, selectedOption, setSelectedOption}){
    const options = [...groupDoc.players.map((player) => (
        {value: player.uid, label: player.nickName}
    ))]

    return(
    <>
        <div className="border-column grow center">  
            <div className="img-input-field">
                <ImageInput aspect={1} title="Foto met speler(s)" message=" " photoURL={photoURL} setPhoto={setPhoto} setPhotoURL={setPhotoURL} className="intake"/>
                <p className="grey-text">{photo ? 1 : 0}/1</p>
            </div>
        </div>
        <div className="border-column">   
            <h4>Selecteer de speler(s) die op de foto staan</h4>
            <DropDown message="Spelers" options={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
        </div>   
    </>    
    )
    }