import React, { useEffect } from "react";
import SelectionBar from "../../../components/input/SelectionBar";
import ArrowUp from "../../../assets/img/ArrowUp";
import ArrowDown from "../../../assets/img/ArrowDown";

export function SectionCreator({section, newArticleData, setNewArticleData, sectionIndex}){
    function handleSwapSection(index, direction){
        let tempArticleData = {...newArticleData}
        let tempSection;
        switch(direction){
            case "UP":
                if(index > 0){
                    tempSection = tempArticleData.sections[index - 1]
                    tempArticleData.sections[index - 1] = tempArticleData.sections[index]
                    tempArticleData.sections[index] = tempSection
                }
                //setNewArticleData(tempArticleData)
                break
            case "DOWN":
                if(index < tempArticleData.sections.length - 1){
                    tempSection = tempArticleData.sections[index + 1]
                    tempArticleData.sections[index + 1] = tempArticleData.sections[index]
                    tempArticleData.sections[index] = tempSection
                }
                //setNewArticleData(tempArticleData)
                break
            default:
        }
        console.log(tempArticleData);
    }

    function handleNewSectionData(key, value){
        setNewArticleData((prevState) => {
            const newSections = [...prevState.sections];
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                [key]: value
            };
            
            return {
                ...prevState,
                sections: newSections
            };
        })
    }

    function handleNewListData(key, value, itemIndex){
        setNewArticleData((prevState) => {
            const newSections = [...prevState.sections];
            const newItems = [...newSections[sectionIndex].items];
            newItems[itemIndex] = {
                ...newItems[itemIndex],
                [key]: value
            }
            newSections[sectionIndex].items = newItems;
            
            return {
                ...prevState,
                sections: newSections
            };
        })
    }

    function handleDeleteSection(sectionIndex){
        setNewArticleData((prevState) => {
            const newSections = [...prevState.sections];
            console.log(sectionIndex);
            newSections.splice(sectionIndex, 1);
            
            return {
                ...prevState,
                sections: newSections
            };
        })
    }

    function handleDeleteListItem(itemIndex) {
        setNewArticleData((prevState) => {
            const newSections = [...prevState.sections];
            const newItems = [...newSections[sectionIndex].items];
            newItems.splice(itemIndex, 1);
            newSections[sectionIndex].items = newItems;
            
            return {
                ...prevState,
                sections: newSections
            };
        })
    }

    function handleAddList(){
        setNewArticleData((prevState) => {
            const newSections = [...prevState.sections];
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                items: newSections[sectionIndex].items ? [...newSections[sectionIndex].items, {title: "", text: ""}] : [{title: "", text: ""}]
            };
            
            return {
                ...prevState,
                sections: newSections
            };
        })
    }

    useEffect(() => {
        return () => {
            console.log("cleaned up");
        }
    }, [])

    return(
        <div className="container border gap">
            <div className="row stay">
                <button className="btn no-grow" type="button" onClick={() => {handleDeleteSection(sectionIndex)}}>delete section {sectionIndex}</button>
                <div className="row no-grow">
                    <button className="btn no-grow" type="button" onClick={() => handleSwapSection(sectionIndex, "UP")}><ArrowUp className="icon"/></button>
                    <button className="btn no-grow" type="button" onClick={() => handleSwapSection(sectionIndex, "DOWN")}><ArrowDown className="icon"/></button>
                </div>
            </div>
            <SelectionBar options={["text", "list", "image", "video"]} customValues={["text", "list", "image", "video"]} value={section.type} setValue={(value) => handleNewSectionData("type", value)} unselectable className="white"/>
            {section.type === "text" && 
                <>
                    <label>title
                        <input type="text" id="title" value={section.title} onChange={(e) => {handleNewSectionData("title", e.target.value)}}/>
                    </label>
                    <label>paragraph
                        <textarea value={section.text} onChange={(e) => handleNewSectionData("text", e.target.value)}></textarea>
                    </label>
                </>
            }
            {section.type === "list" && 
                <div className="column">
                    <label>title
                        <input type="text" id="title" value={section.title} onChange={(e) => {handleNewSectionData("title", e.target.value)}}/>
                    </label>
                    <label>dots
                        <SelectionBar options={["disc", "auto"]} customValues={["disc", false]} value={section.dots ? section.dots : false} setValue={(value) => handleNewSectionData("dots", value)} unselectable className="white"/>
                    </label>
                    <div className="column">
                    <label>list items</label>
                        {section.items?.map((item, index) => (
                                <div className="container border" key={index}>
                                    <button className="btn text no-grow" type="button" onClick={() => {handleDeleteListItem(index)}}>delete list item</button>
                                    <label>item {index} title
                                        <input type="text" id="title"
                                            value={item.title}
                                            onChange={(e) => {handleNewListData("title", e.target.value, index)}}
                                        />
                                    </label>
                                    <label>item {index} paragraph
                                        <textarea value={item.text} onChange={(e) => handleNewListData("text", e.target.value, index)}></textarea>
                                    </label>
                                </div>
                            )
                        )}
                    </div>
                    <button className="btn" type="button" onClick={handleAddList}>+ new list item</button>
                </div>
            }
        </div>
    )
}