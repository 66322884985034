import { useEffect } from "react";
import { ref, set} from 'firebase/database';
import { rdb } from "../../../config/firebaseConfig";
import { DEFAULT_USER_PHOTO_URL } from "../../../data/constants";
import { QuestionBar } from "./QuestionBar";
import { CorrectPlayer } from "./CorrectPlayer";
import { getPlayerByUID } from "../../../utils/pure/groups/getPlayerByUID";

export function GuessWhoViewer({activeQuestion, gameDoc, groupDoc}){
    const correctAnswer = activeQuestion.dummy
    const correctPlayer = getPlayerByUID(correctAnswer, groupDoc)

    useEffect(() => {
        set(ref(rdb, "games/" + groupDoc.qwestoID + "/correctAnswer"), correctAnswer)
    }, [correctAnswer, groupDoc.qwestoID])

    return(
        <>
            <QuestionBar question={activeQuestion.question} slideUp/>
            <div className="correct-answer column center">
                <div className="text-bubble">
                    <span className="viewer-text">'{activeQuestion.answer}'</span>
                </div>
                {gameDoc.stage === "ANSWERING" ? 
                    <CorrectPlayer photoURL={DEFAULT_USER_PHOTO_URL} nickName="?" fixedAmount/>
                :
                    <CorrectPlayer photoURL={correctPlayer.photoURL} nickName={correctPlayer.nickName} fixedAmount/>
                }
                <span className="viewer-text">Van wie is dit antwoord?</span>
            </div>
        </>
    )
}