import { getPlayerByUID } from "../../../utils/pure/groups/getPlayerByUID";
import { QuestionBar } from "./QuestionBar";

export function SkippedViewer({activeQuestion, groupData}){
    const impostor = getPlayerByUID(activeQuestion.dummy, groupData)

    return(
        <QuestionBar 
            question={`${impostor ? impostor.nickName : "Iemand"} heeft deze vraag overgeslagen`} 
            description={"Helaas,"}
        />
    )
}