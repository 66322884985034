import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

export const LoggedOutContext = () => {
    //get user info
    const { user } = useAuthContext()

    return(
        !user ? <Outlet/> : <Navigate to="/groups"/>
    )
}

