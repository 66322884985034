export function getPlayersByPoints(gameDoc){
    // Convert the object into an array of key-value pairs
    const playerEntries = Object.entries(gameDoc.points);

    // Sort the array based on player points in descending order
    const sortedPlayers = playerEntries.sort((a, b) => b[1] - a[1]);

    // Create an array of objects containing UID and points
    const sortedPlayersWithScores = sortedPlayers.map(([uid, points]) => ({
        uid,
        points,
        pointsEarned: gameDoc.pointsEarned[uid]
    }))
    return sortedPlayersWithScores;

}