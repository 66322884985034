import React, { useEffect } from "react"

export default function ScoreList({stats, groupDoc, stage, stageRest}){

    async function slideUp(item, delay, offset){
        await new Promise(resolve => setTimeout(resolve, offset));
        setTimeout(() => {
            item.classList.add("active")
        }, delay)
    }

    useEffect(() => {
        var items = document.getElementsByClassName("score-card");
        if(items){
            for (let i = 0; i < items.length; ++i){
                slideUp(items[i], i * 500, 1000)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(stage){
            var items = document.getElementsByClassName("stage-card");
            if(items){
                for (let i = 2; i >= 0; i--){
                    slideUp(items[i], 2000 / (i + 1), 1000)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage])

    if(!stage){
        return(
            <>
            {stats.map((profile, index) => (
                <div className="score-card" key={index}>
                    <span className="viewer-text s">{stageRest ? index + 4 : index + 1}</span>
                    <div className="player-card space-between">
                        {groupDoc.players.map((player, index) => {
                            if(profile.uid === player.uid){
                                return(
                                    <div className="row stay center" key={player.uid}>
                                        <img src={player.photoURL} className="avatar" alt="" />
                                        <span className="viewer-text s">{player.nickName}</span>
                                    </div>
                                )
                            }else{
                                return null
                            }
                        })}
                        <span className="viewer-text s bg-text black">{profile.points}</span>
                    </div>
                    <span className={`viewer-text s score-card-earned ${profile.pointsEarned > 0 ? "green" : "red"}`}>{parseInt(profile.pointsEarned) > 0 ? "+" + profile.pointsEarned : profile.pointsEarned}</span>
                </div>
            ))}
            </>
        )   
    }else{
        return(
            <>
            {stats.map((profile, index) => (
                <div className="stage-card" key={index}>
                    <span className="viewer-text">{index + 1}</span>
                    <div className="column center">
                        {groupDoc.players.map((player, index) => {
                            if(profile.uid === player.uid){
                                return(
                                    <React.Fragment key={player.uid}>
                                        <img src={player.photoURL} className="stage-card-img" alt="" />
                                        <span className="viewer-text s">{player.nickName}</span>
                                    </React.Fragment>
                                )
                            }else{
                                return null
                            }
                        })}
                        <span className="viewer-text xs">{profile.points}</span>
                    </div>
                </div>
            ))}
            </>
        )
    }
}