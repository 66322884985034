import React, { createContext, useState } from 'react';
import { useGroupDataContext } from '../hooks/useGroupDataContext';
import { useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRef } from 'react';
import { releaseWakeLock, requestWakeLock } from '../utils/pure/controlWakeLock';
import PopUp from '../components/layout/PopUp';
import ArrowRight from '../assets/img/ArrowRight';

// Audio files
import COUNTDOWN_BELL from "../assets/sound/countdown/COUNTDOWN_BELL.mp3"
import ANSWER_PING from "../assets/sound/ANSWER_PING.mp3"
import BACKGROUND_LOOP from "../assets/sound/BACKGROUND_LOOP.mp3"
import { INTRODUCTION_RISER, INTRODUCTION_WHISTLE, STATS_CHEER } from '../data/audio';

export const GameAudioContext = createContext();

export const GameAudioContextProvider = ({ children }) => {
	const {user} = useAuthContext()
	const [displayType, setDisplayType] = useState(null)
	const [backgroundIsPlaying, setBackgroundIsPlaying] = useState(false)
	const [resumeIsVisible, setResumeIsVisible] = useState(false)
	const [isResumed, setIsResumed] = useState(false)
	const [gameStringObject, setGameStringObject] = useState(null)
	const {groupData} = useGroupDataContext() 

	const BACKGROUND_LOOP_REF = useRef()
    const ANSWER_PING_REF = useRef()
	const COUNTDOWN_BELL_REF = useRef()
	const COUNTDOWN_VOICE_REF = useRef()
	const STATS_CHEER_REF = useRef()
	const INTRODUCTION_WHISTLE_REF = useRef()
	const INTRODUCTION_RISER_REF = useRef()

	useEffect(() => {
		if(groupData.group && user.uid !== groupData.group?.host.uid){
			setIsResumed(true)
		}
	}, [groupData, user])

	function initializeAudio(){
        try{
			COUNTDOWN_BELL_REF.current = new Audio(COUNTDOWN_BELL)
            ANSWER_PING_REF.current = new Audio(ANSWER_PING)
			COUNTDOWN_VOICE_REF.current = new Audio(COUNTDOWN_BELL)
			STATS_CHEER_REF.current = new Audio(STATS_CHEER)
			INTRODUCTION_WHISTLE_REF.current = new Audio(INTRODUCTION_WHISTLE)
			INTRODUCTION_RISER_REF.current = new Audio(INTRODUCTION_RISER)
            BACKGROUND_LOOP_REF.current = new Audio(BACKGROUND_LOOP)
            BACKGROUND_LOOP_REF.current.loop = true
            BACKGROUND_LOOP_REF.current.play()
            if(!BACKGROUND_LOOP_REF.paused){
                setBackgroundIsPlaying(true)
                requestWakeLock()
            }else{
                setBackgroundIsPlaying(false)
            }
			console.log("AUDIO MOUNTED🔊");
        } catch (error) {
            console.log(error);
        }
    }

	// Confirm loop is playing
	useEffect(() => {
		if((displayType === "VIEWER" || displayType === "PHONE_VIEWER")){
			if(backgroundIsPlaying){
				setIsResumed(true)
				setResumeIsVisible(false)
			}else{
				setIsResumed(false)
				setResumeIsVisible(true)
			}
		}
    }, [backgroundIsPlaying, setIsResumed, displayType]);

	useEffect(() => {
        return () => {
            if(BACKGROUND_LOOP_REF.current){
                BACKGROUND_LOOP_REF.current.pause()
            }
            releaseWakeLock()
        }
    }, [BACKGROUND_LOOP_REF])

	// Play sound on answer
	useEffect(() => {
        if((displayType === "VIEWER" || displayType === "PHONE_VIEWER") && isResumed && isHost() && groupData.game?.stage === "ANSWERING"){
            if(!Object.values(groupData.game.answers).every(answer => answer === "EMPTY") && JSON.stringify(groupData.game.answers) !== gameStringObject){
                playAudio("ANSWER_PING")
            }
			setGameStringObject(JSON.stringify(groupData.game.answers))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData.game?.answers])

	// Play sound when showing stats
	useEffect(() => {
        if((displayType === "VIEWER" || displayType === "PHONE_VIEWER") && isResumed && isHost() && groupData.game?.stage === "STATS"){
            playAudio("STATS_CHEER")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData.game?.stage])

	function playAudio(type){
		try {
			const audio = getAudioByType(type)
			if(audio){
				audio.play()
			}
		} catch (error) {
			console.log(error);
		}
	}

	function setAudioSource(type, src){
		const audio = getAudioByType(type)
		if(audio){
			audio.src = src
		}
	}

	function getAudioByType(type){
		switch(type){
			case "ANSWER_PING":
				return ANSWER_PING_REF.current
			case "COUNTDOWN_BELL":
				return COUNTDOWN_BELL_REF.current
			case "COUNTDOWN_VOICE":
				return COUNTDOWN_VOICE_REF.current
			case "STATS_CHEER":
				return STATS_CHEER_REF.current
			case "INTRODUCTION_WHISTLE":
				return INTRODUCTION_WHISTLE_REF.current
			case "INTRODUCTION_RISER":
				return INTRODUCTION_RISER_REF.current
			default:
				break;
		}
	}

	function isHost(){
		return user.uid === groupData.group?.host.uid
	}

	return(
		<GameAudioContext.Provider value={{isResumed, setDisplayType, playAudio, setAudioSource}}>
			{(displayType === "VIEWER" || displayType === "PHONE_VIEWER") && !isResumed &&
				<PopUp visible={resumeIsVisible} setVisible={setResumeIsVisible} unclosable>
					<>
						<div className="border-column center">
							<h2>Ga verder</h2>
							<p>als je klaar bent om te spelen</p>
						</div>
						<div className="border-column">
							<button className="btn-gr" onClick={() => initializeAudio()}><ArrowRight className="icon"/></button>
						</div>
					</>
				</PopUp>
			}
			{children}
		</GameAudioContext.Provider>
	);
};