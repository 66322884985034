export function filterUsingLabels(items, labels, nested, sorted){
    let result = []
    if(nested){
        result = items.filter(item =>
            Object.entries(labels).every(([label, labelValue]) => 
                labelValue !== null ? (
                    Array.isArray(item.labels[label]) && (
                        Array.isArray(labelValue) ? 
                            labelValue.every(element => item.labels[label].includes(element)) 
                        : 
                            item.labels[label].includes(labelValue)
                    )
                )
            : 
                true
            )
        )
    }else{
        result = items.filter(item =>
            Object.entries(labels).every(([label, labelValue]) => 
                labelValue !== null ? (
                    Array.isArray(item[label]) && (
                        Array.isArray(labelValue) ? 
                            labelValue.every(element => item[label].includes(element)) 
                        : 
                            item[label].includes(labelValue)
                    )
                )
            : 
                true
            )
        )
    }

    
    if(sorted){
        return result.sort(compareLabels)
    }
    
    return result
}

function compareLabels(obj1, obj2) {
    const combinedLengthObj1 = getLabelValuesLength(obj1.labels);
    const combinedLengthObj2 = getLabelValuesLength(obj2.labels);

    return combinedLengthObj1 - combinedLengthObj2;
}

function getLabelValuesLength(obj) {
    let combinedLength = 0;

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            combinedLength += obj[key].length;
        }
    }

    return combinedLength;
}
