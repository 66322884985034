import QwestoBig from "../../assets/img/QwestoBig";
import DescriptionRow from "../../components/layout/DescriptionRow";
import Footer from "../../components/layout/Footer";
import VideoEmbedder from "../../components/layout/VideoEmbedder";
import { FAVOURITE_GROUP_URL, INTAKE_MOCKUP_URL, SCORE_MOCKUP_URL, VIEWER_MOCKUP_URL } from "../../data/constants";
import FilterSteps from "../products/FilterSteps";

export default function HowItWorks(){
    return(
        <div className="content-page">
            <div className="content-container min-height max-width flex-start center">
                <QwestoBig className="icon big"/>
                <div className="description-row-list">
                    <h1 className="text-xxlarge">Hoe werkt het?</h1>
                    <DescriptionRow swap number={1} 
                        description="Verzamel je favoriete mensen met wie je een Qwesto wilt spelen." 
                        descriptionTitle="Verzamel je favoriete mensen" 
                        subject={<img src={FAVOURITE_GROUP_URL} alt="" className="description-row-img"/>} 
                    />
                    <DescriptionRow number={2} 
                        description="Maak je Qwesto persoonlijk en nodig je groep uit." 
                        descriptionTitle="Personaliseer" 
                        subject={<FilterSteps />} 
                    />
                    <DescriptionRow swap number={3} 
                        description="Deel de leukste, gekste en mooiste foto’s, video’s en verhalen. Op basis van deze vragen wordt de quiz gegenereerd." 
                        descriptionTitle="Vul de intake in" 
                        subject={<img src={INTAKE_MOCKUP_URL} alt="" className="description-row-img"/>} 
                    />
                    <DescriptionRow number={4} 
                        description="Start de Qwesto en ontdek hoe goed jullie elkaar kennen!" 
                        descriptionTitle="Start de Qwesto" 
                        subject={<img src={VIEWER_MOCKUP_URL} alt="" className="description-row-img"/>} 
                    />
                    <DescriptionRow swap number={5} 
                        description="Scoor zoveel mogelijk punten door vragen goed te beantwoorden." 
                        descriptionTitle="De winnaar" 
                        subject={<img src={SCORE_MOCKUP_URL} alt="" className="description-row-img"/>} 
                    />
                </div>
            </div>
            <div className="content-container max-width">
                <div className="column center gap">
                    <div className="column">
                        <span className="text-large">Nog meer uitleg nodig?</span>
                        <span className="text-small grey-text">In deze video leggen we je uit hoe je een Qwesto speelt</span>
                    </div>
                    <VideoEmbedder src={`https://www.youtube.com/embed/BSNAO0PxKhs`} title="explanation"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


