import "./PopUp.css"
import React from 'react'
import ReactDOM  from "react-dom"
import Exit from "../../assets/img/Exit"

export default function PopUp({title, visible, setVisible, children, unclosable, large, banner, uncontrollable}) {
    return (
    <>
    {visible && ReactDOM.createPortal(
        <>
        <div className={`pop-up ${large ? "large" : ""}`}>
            <div className="pop-up-msg">
                {(title || !unclosable) && !uncontrollable &&
                    <div className="pop-up-controls">
                        <div className="row stay">
                            <span className="text-medium">{title}</span>
                            {!unclosable && <button className="close-btn" onClick={() => {setVisible(false)}}><Exit className="icon"/></button>}
                        </div>
                    </div>
                }
                {banner && 
                    <div className="pop-up-banner">
                        {banner}
                    </div>
                }
                <div className="pop-up-content">
                    {children}
                </div>
            </div>
        </div>
        <div className="pop-up-exit" onClick={() => {!unclosable && setVisible(false)}}/>
        </>
    , document.querySelector("#index"))}
    </>
    )
}
