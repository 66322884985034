import React from "react";
import { useState } from 'react';
import PopUp from '../../../components/layout/PopUp';
import DropDown from '../../../components/input/DropDown';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebaseConfig';
import { useMessageContext } from '../../../hooks/useMessageContext';
import { useEffect } from "react";
import { mapLabels } from "../../../utils/pure/mapLabels";

export function QuestionCreator({currentPool, collectionType}){
    const [questionOpen, setQuestionOpen] = useState(false)
    const [questionData, setQuestionData] = useState(currentPool.pool[0])
    const [newQuestionData, setNewQuestionData] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    useEffect(() => {
        setQuestionData(currentPool.pool[0])
        setNewQuestionData({})
    }, [currentPool])
    

    function handleNewQuestionData(key, value){
        setNewQuestionData((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }

    async function handleSaveQuestion(e){
        e.preventDefault()
        if(!currentPool){
            return
        }
        setIsSaving(true)
        const poolRef = doc(db, (collectionType === "draft" ? "pools_draft" : "pools"), currentPool.id)
        const finalQuestionData = newQuestionData
        finalQuestionData.id = currentPool.pool.length

        try {
            await updateDoc(poolRef, {
                pool: arrayUnion(finalQuestionData)
            })
            setNewInfo("Succes a mattie")
            setQuestionOpen(false)
            setIsSaving(false)
        } catch (error) {
            console.log(error);
            setNewError("Er ging iets fout")
            setIsSaving(false)
        }
    }

    return(
        <>
            <div className="question-creator">
                <button className="btn black small round" onClick={() => {setQuestionOpen(true)}}>+</button>
            </div>
            <PopUp title={`Add ${currentPool.id} question in ${collectionType}`} visible={questionOpen} setVisible={setQuestionOpen} large unclosable>
                {questionData &&
                <form className="column gap">
                    {"iq" in questionData && 
                        <label>iq
                            <input type="text" id="question"
                                required
                                onChange={(e) => {handleNewQuestionData("iq", e.target.value)}}
                            />
                        </label>
                    }
                    <label>qq/q
                        <input type="text" id="question"
                            required
                            onChange={(e) => {handleNewQuestionData("q" in questionData ? "q" : "qq", e.target.value)}}
                        />
                    </label>
                    {Object.keys(questionData).sort().map((key) => {
                        if(["a","b","c","o","s","t"].includes(key)){
                            return(
                                <div className="row stay gap center" key={key}>
                                    <h3>{key}</h3>
                                    <DropDown 
                                        message="Labels" 
                                        options={mapLabels((() => {
                                            switch(key){
                                                case "o":
                                                    return [1]
                                                case "a":
                                                    return [1,2,3,4,5]
                                                default:
                                                    return [1,2,3]
                                            }
                                        })())} 
                                        selectedOption={newQuestionData[key] ? mapLabels(newQuestionData[key]) : []}
                                        setSelectedOption={(value) => {handleNewQuestionData(key, value)}}
                                    />
                                </div>
                            )
                        }else{
                            return null
                        }
                    })}
                    <div className="row stay">
                        <button className="btn black" type="button" onClick={() => {setQuestionOpen(false)}}>Annuleren</button>
                        <button className="btn-gr" type="submit" disabled={isSaving} onClick={(e) => {handleSaveQuestion(e)}}>{isSaving ? "Werkende..." : "Opslaan"}</button>
                    </div>
                </form>
                }
            </PopUp>
        </>
    )
}