import "./Controller.css"
import AnswerController from "./AnswerController";
import { useAuthContext } from "../../../hooks/useAuthContext";
import PlayerCountdown from "./PlayerCountdown";
import ScoreStage from "./ScoreStage";
import { Introduction } from "../Introduction";
import { useEffect } from "react";
import ControllerMessage from "./ControllerMessage";
import User from "../../../assets/img/User";

export default function AnswerContainer({groupDoc, gameDoc, activeQuestion, switchValue, setSwitchValue}) {
    const { user } = useAuthContext()

    useEffect(() => {
        if(setSwitchValue && gameDoc && switchValue === 2 && (gameDoc.stage === "RESULTS")){
            async function waitTwo(){
                await new Promise(resolve => setTimeout(resolve, 2000))
                if(switchValue === 2){
                    setSwitchValue(1)
                }
            }
            waitTwo()
        }
    }, [gameDoc, setSwitchValue, switchValue])

    if(activeQuestion){
        switch(gameDoc.stage){
            case "INTRODUCTION":
                return <Introduction activeQuestion={activeQuestion}/>
            case "COUNTDOWN":
                return <PlayerCountdown players={groupDoc.players}/>
            case "FINISHED":
                return <ScoreStage user={user} gameDoc={gameDoc} qwestoID={groupDoc.qwestoID}/>
            default:
                if(activeQuestion.dummy && activeQuestion.dummy === user.uid){
                    return(
                        <ControllerMessage title="Van jou" message="Deze vraag is van jou" icon={<User className="icon big"/>}/>
                    )
                }else{
                    return(
                        <AnswerController activeQuestion={activeQuestion} gameDoc={gameDoc} groupDoc={groupDoc}/>
                    )
                }
        }
    }
}
