import { updateProfile } from "firebase/auth";
import { doc, increment, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../config/firebaseConfig";
import { useMessageContext } from "./useMessageContext";

export const useChangeName = () => {
    const {setNewError, setNewInfo} = useMessageContext()
    const [nameIsPending, setNameIsPending] = useState()

    async function tryChangeName(user, userDoc, newDisplayName, groupDocs){
        if(groupDocs.length > 0){
            setNewError("Het is niet mogelijk je gebruikersnaam aan te passen als je actief bent in een Qwesto.")
            return
        }
        if(!newDisplayName || newDisplayName === user.displayName){
            setNewError("Vul eerst een nieuwe gebruikersnaam in om deze te veranderen.")
            return
        }
        setNameIsPending(true)
        if(userDoc.nameChangesLeft > 0){
            await updateProfile(user, {displayName: newDisplayName})
            await updateDoc(doc(db, "users", user.uid), {
                nameChangesLeft: increment(-1)
            })
            setNewInfo("Je gebruikersnaam is aangepast " + newDisplayName + "!")
        }else{
            setNewError("Het aanpassen van je gebruikersnaam is mislukt: je gebruikersnaam is al 3 keer aangepast.")
        }
        setNameIsPending(false)
    }

    return { tryChangeName, nameIsPending }
}