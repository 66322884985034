import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavigationBar.css";
import Home from "../../../assets/img/Home";
import Play from "../../../assets/img/Play";
import { useState } from "react";
import { useEffect } from "react";
import QwestoBig from "../../../assets/img/QwestoBig";
import FilterSteps from "../../../pages/products/FilterSteps";
import { NavigationBarMenu } from "./NavigationBarMenu";
import Shop from "../../../assets/img/Shop";
import { useAuthContext } from "../../../hooks/useAuthContext";

function NavigationBarAction({filterVisible, setFilterVisible, setMenuVisible}){
	const [pathName, setPathName] = useState(window.location.pathname)
	const {user} = useAuthContext()
	const location = useLocation()

	function handleOpenFilter(e){
		e.preventDefault()
		setFilterVisible(!filterVisible)
		setMenuVisible(false)
	}

	useEffect(() => {
		setPathName(location.pathname)
	}, [location])
	

	return(
		<>
			<div className="nb-row">
				<NavLink to="/" className="nb-item">
					<QwestoBig className="nb-logo brand"/>
				</NavLink>
				<NavLink to="/product" onClick={(e) => handleOpenFilter(e)} className={`nb-action left ${(filterVisible) ? "active" : ""}`}>
					<span className="text-xsmall">Kopen</span>
				</NavLink>
				<NavLink to="/groups" onClick={() => {setFilterVisible(false)}} className="nb-action">
					<span className="text-xsmall">Spelen</span>
			</NavLink>
			</div>
			<div className="nb-action-container">
				<NavLink to={user ? "/groups" : "/product"} onClick={(e) => user ? setFilterVisible(false) : handleOpenFilter(e)} className="nb-icon">
					<div className="nb-icon-background">
					</div>
						<div className="nb-icon-list" style={{"left": (filterVisible || pathName === "/product") ? "-40px" : "0px"}}>
							<Play className="icon"/>
							<Shop className="icon"/>
						</div>
				</NavLink>
			</div>
			<div className={`nb-filter ${filterVisible ? "active" : ""}`}>
				<FilterSteps setFilterVisible={setFilterVisible}/>
			</div>
			{filterVisible &&
				<>
					<div className="nb-overlay-exit" onClick={() => {setFilterVisible(false)}}/>
					<div className="nb-overlay-backdrop"/>
				</>
			}
		</>
	)
}

export default function NavigationBar({filterVisible, setFilterVisible, menuVisible, setMenuVisible}){
	const [isSmall, setIsSmall] = useState(window.innerWidth < 800)

    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 800);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

	return(
		<nav className="nb">
			<div className="nb-container">
				{isSmall ?
					<>
						<NavLink to="/" className="nb-item">
							<Home className="nb-logo"/>
							<span className="nb-label">Home</span>
						</NavLink>
						<NavigationBarAction filterVisible={filterVisible} setFilterVisible={setFilterVisible} setMenuVisible={setMenuVisible}/>
						<NavLink to="/groups" className="nb-item">
							<Play className="nb-logo"/>
							<span className="nb-label">Spelen</span>
						</NavLink>
						<NavigationBarMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} setFilterVisible={setFilterVisible}/>

					</>
				:	
					<>
						<NavigationBarAction filterVisible={filterVisible} setFilterVisible={setFilterVisible} setMenuVisible={setMenuVisible}/>
						<div className="row stay">
							<NavLink to="/how-it-works" className="nb-action">
								<span className="text-xsmall">Hoe werkt het?</span>
							</NavLink>
							<NavigationBarMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} setFilterVisible={setFilterVisible}/>
						</div>
					</>
				}
				
			</div>
		</nav>
	)
}