import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ArrowRight from "../../assets/img/ArrowRight";
import QwestoSmall from "../../assets/img/QwestoSmall";
import { FAQs } from "../../data/content";
import Exit from "../../assets/img/Exit";

export default function FAQList({small, large}){
    const [active, setActive] = useState(null)
    const contents = large ? FAQs : FAQs.slice(0, 3)

    return(
        <div className={`faq-section ${small ? "small" : ""}`}>
            <div className="column gap">
                {!small && <QwestoSmall className="icon massive"/>}
                <h2 className="text-xlarge">Veelgestelde vragen</h2>
            </div>
            <div className="faq-section-container">
                {contents.map((content, index) => (
                    <div className={`faq ${active === index ? "active" : ""}`} onClick={() => {setActive(active === index ? null : index)}} key={index}>
                        <span className="faq-label text-medium">{content.q}<Exit className={`icon ${active === index ? "active" : ""}`}/></span>
                        <p className="faq-content" dangerouslySetInnerHTML={{__html: content.a}}/>
                    </div>
                ))}
            </div>
            {!large && <NavLink to={"/faq"} className="btn black round">Alle veelgestelde vragen <ArrowRight className="icon"/></NavLink>}
        </div>                                    
    )
}