export default function Fullscreen(props) {

    if(props.fullscreen === "true"){
        return (
            <svg
                fill="currentColor"
                viewBox="0 0 96 96"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                <title />
                <g>
                    <path d="M30,60H6A6,6,0,0,0,6,72H24V90a6,6,0,0,0,12,0V66A5.9966,5.9966,0,0,0,30,60Z" />
                    <path d="M90,60H66a5.9966,5.9966,0,0,0-6,6V90a6,6,0,0,0,12,0V72H90a6,6,0,0,0,0-12Z" />
                    <path d="M66,36H90a6,6,0,0,0,0-12H72V6A6,6,0,0,0,60,6V30A5.9966,5.9966,0,0,0,66,36Z" />
                    <path d="M30,0a5.9966,5.9966,0,0,0-6,6V24H6A6,6,0,0,0,6,36H30a5.9966,5.9966,0,0,0,6-6V6A5.9966,5.9966,0,0,0,30,0Z" />
                </g>
                </g>
            </svg>
        );
    }
    
    return(
        <svg
            fill="currentColor"
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
            <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
            <title />
            <g>
                <path d="M30,0H6A5.9966,5.9966,0,0,0,0,6V30a6,6,0,0,0,12,0V12H30A6,6,0,0,0,30,0Z" />
                <path d="M90,0H66a6,6,0,0,0,0,12H84V30a6,6,0,0,0,12,0V6A5.9966,5.9966,0,0,0,90,0Z" />
                <path d="M30,84H12V66A6,6,0,0,0,0,66V90a5.9966,5.9966,0,0,0,6,6H30a6,6,0,0,0,0-12Z" />
                <path d="M90,60a5.9966,5.9966,0,0,0-6,6V84H66a6,6,0,0,0,0,12H90a5.9966,5.9966,0,0,0,6-6V66A5.9966,5.9966,0,0,0,90,60Z" />
            </g>
            </g>
        </svg>
    );
  }