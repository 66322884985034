import React from "react";
import DropDown from "../../components/input/DropDown";
import SelectionBar from "../../components/input/SelectionBar";
import { mapLabels } from "../../utils/pure/mapLabels";

export function FilterControls({filters, setFilters}) {
    function handleFilterChange(key, value){
        setFilters((prevState) => ({
            ...prevState,
            [key]: value
        }))
    }

    return(
        <div className="filter-controls">
            {Object.keys(filters).map((key) => (
                <div className="row stay gap" key={key}>
                    <h3>{key}</h3>
                    {(() => {
                        switch(key){
                            case "a":
                                return(
                                    <DropDown 
                                        message="age" 
                                        options={mapLabels([1,2,3,4,5])} 
                                        selectedOption={filters[key] ? mapLabels(filters[key]) : []}
                                        setSelectedOption={(value) => {handleFilterChange(key, value)}}
                                    />
                                )
                            case "o":
                                return(
                                    <SelectionBar options={[1]} customValues={[1]} value={filters[key]} setValue={(value) => {handleFilterChange(key, value)}}/>
                                )
                            case "d":
                                return(
                                    <SelectionBar options={[1,2]} customValues={[1,2]} value={filters[key]} setValue={(value) => {handleFilterChange(key, value)}}/>
                                )
                            default:
                                return(
                                    <SelectionBar options={[1,2,3]} customValues={[1,2,3]} value={filters[key]} setValue={(value) => {handleFilterChange(key, value)}}/>
                                )
                        }
                    })()}
                </div>
            ))}
        </div>
    )
}