import { formatCurrency } from "./payments/formatCurrency"

export function getTotalProductCost(product, numberOfPlayers, perPlayer){
    let totalCost = 0

    if(product && numberOfPlayers){
        totalCost = totalCost + (product.prices[0].unit_amount * numberOfPlayers)
    }
    if(perPlayer){
        totalCost = totalCost / numberOfPlayers
    }

    return formatCurrency(totalCost, product.prices[0].currency)
}