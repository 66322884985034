export default function ArrowLeft(props) {
    return (
    <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
        <g id="Arrow / Arrow_Left_MD">
        <path
            id="Vector"
            d="M19 12H5M5 12L11 18M5 12L11 6"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        </g>
    </g>
    </svg>
    );
  }