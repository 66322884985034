import { getPlayersByPoints } from '../../../utils/pure/getPlayersByPoints';
import { useState, useEffect } from "react";
import ScoreList from './ScoreList';
import QwestoBig from '../../../assets/img/QwestoBig';

export default function ScoreStage({groupDoc, gameDoc}){
    const [stats, setStats] = useState(null)

    useEffect(() => {
        if(gameDoc){
            setStats(getPlayersByPoints(gameDoc))
        }
    }, [gameDoc])

    return(
        <div className="content-field score-viewer">
            <div className="content-container gap center">
                <div className="column no-gap">
                    <QwestoBig className="icon big"/>
                    <span className="text-xxlarge">{groupDoc.groupName}</span>
                </div>
                <div className="score-stage">
                    {stats && <ScoreList stats={stats.slice(0,3)} groupDoc={groupDoc} stage/>}
                </div>
                <div className="score-board small">
                    {stats && stats.length > 3 && <ScoreList stats={stats.slice(3)} groupDoc={groupDoc} stageRest/>}
                </div>
            </div>
        </div>
    )
}
