export function checkIfAnswered(gameDoc, groupDoc, activeQuestion) {
    let isAnswered = true
    let playersTotal = 0
    let playersAnswered = []

    if(activeQuestion){
        Object.keys(gameDoc.answers).forEach((uid) => {
            switch(activeQuestion.type){
                case "WHATISTRUE":
                case "GUESSWHO":
                case "PHOTOREVEAL":
                case "WHATDOYOUSEE":
                case "WHATDOYOUHEAR":
                case "BATTLE":
                    if(uid !== activeQuestion.dummy && uid !== activeQuestion.chosen && uid !== activeQuestion.random){
                        playersTotal++
                        if(gameDoc.answers[uid] === "EMPTY"){
                            isAnswered = false
                            playersAnswered.push(null)
                        }else{
                            playersAnswered.push(getPlayerData(groupDoc, uid))
                        }
                    }
                    break;
                case "BESTANSWER":
                    if(gameDoc.answers[uid] === "EMPTY" || gameDoc.answers[uid].vote === "EMPTY"){
                        isAnswered = false
                        playersAnswered.push(null)
                    }else{
                        playersAnswered.push(getPlayerData(groupDoc, uid))
                    }
                    playersTotal++
                    break;
                default:
                    if(gameDoc.answers[uid] === "EMPTY"){
                        isAnswered = false
                        playersAnswered.push(null)
                    }else{
                        playersAnswered.push(getPlayerData(groupDoc, uid))
                    }
                    playersTotal++
                    break;
            }
        })

        const newAnsweredProps = {
            total: playersTotal,
            answered: playersAnswered
        }

        return [isAnswered, newAnsweredProps]
    }
}

function getPlayerData(groupDoc, uid){
    if(groupDoc){
        let playerData = null
        groupDoc.players
        .forEach((player) => {
            if(player.uid === uid){
                playerData = player
            }
        })

        return playerData
    }
}