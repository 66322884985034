export function getPlayersByVotes(answers, branched) {
    const votesCount = {};

    if(branched){
        Object.entries(answers).forEach(([key, value]) => {
            if(value.vote){
                answers[key] = {
                    ...value,
                    uid: key
                }
            }else{
                answers[key] = {
                    vote: "EMPTY",
                    uid: key
                }
            }
        })
    }

    // Count the votes for each player depending on the structure
    for(const uid in answers){
        let votedPlayerID = "EMPTY"
        if(branched && answers[uid].vote){
            votedPlayerID = answers[uid].vote;
        }else{
            votedPlayerID = answers[uid]
        }

        if (votedPlayerID !== 'EMPTY') {
            if(votesCount[votedPlayerID]){
                votesCount[votedPlayerID]++;
            }else{
                votesCount[votedPlayerID] = 1;
            }
        }
    }

    // Create an array of all players with their ID and vote counts
    const players = Object.keys(answers).map(uid => ({
        uid,
        votes: votesCount[uid] || 0
    }));

    // Sort the players array based on vote counts in descending order
    players.sort((a, b) => b.votes - a.votes);

    return players;
}