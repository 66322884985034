import { WhatIsTrue } from './WhatIsTrue';
import { BestPhoto } from './BestPhoto';
import Loader from "../../../components/loaders/Loader";
import { useParams } from "react-router-dom";
import IntakeQuestion from './IntakeQuestion';
import { useState } from "react";
import Redirect from '../../../components/layout/Redirect';
import Submitter from './Submitter';
import "./Intake.css"
import { whatIsTrueDefault } from '../../../data/constants';
import { GuessWho } from './GuessWho';
import ProgressionBar from '../../../components/layout/ProgressionBar';
import { PhotoReveal } from './PhotoReveal';
import Skipper from './Skipper';
import { WhatDoYouSee } from './WhatDoYouSee';
import { WhatDoYouHear } from './WhatDoYouHear';
import { useDocument } from '../../../hooks/useDocument';

export default function IntakeContainer({groupDoc, activeQuestion, intakeLength, intakeIndex}) {
    const { qwestoID } = useParams()
    const {document: qwestoDoc, noData: noQwestoData} = useDocument("qwestos", qwestoID)
    // WHATISTRUE
    const [whatIsTrue, setWhatIsTrue] = useState(whatIsTrueDefault)
    // BESTANSWER
    const [textAnswer, setTextAnswer] = useState("")
    const [selectedOption, setSelectedOption] = useState(null)

    const [photo, setPhoto] = useState(null)
    const [photoURL, setPhotoURL] = useState(null)
    const [video, setVideo] = useState(null)
    const [videoURL, setVideoURL] = useState(null)
    const [submitIsPending, setSubmitIsPending] = useState(false)

    const [metadata, setMetadata] = useState(null)

    if(noQwestoData){
        return <Redirect to={`/groups/${qwestoID}`} message="Er is iets misgegaan" action="Terug" arrow="left"/>
    }

    if(!groupDoc || !qwestoDoc){
        return <Loader/>    
    }    

    return(
    <>                     
        <div className="content-container max-width intake">
            <div className="intake-info">
                {activeQuestion && <>
                    <div className="column center">
                        <h3>{intakeIndex + 1}/{intakeLength}</h3>
                        <ProgressionBar progress={intakeIndex === 0 ? 0 : (((intakeIndex + 1)/intakeLength) * 100).toFixed(0)} small/>
                    </div>
                    <IntakeQuestion activeQuestion={activeQuestion}/>
                </>}
            </div>
            <div className="intake-container-column">
                <div className={`intake-container ${submitIsPending ? "slide-out" : "slide-in"}`}>
                    {activeQuestion.type === "WHATISTRUE" && 
                        <WhatIsTrue whatIsTrue={whatIsTrue} setWhatIsTrue={setWhatIsTrue}/>
                    }
                    {activeQuestion.type === "BESTPHOTO" && 
                        <BestPhoto photo={photo} setPhoto={setPhoto} photoURL={photoURL} setPhotoURL={setPhotoURL}/>
                    }
                    {activeQuestion.type === "PHOTOREVEAL" && 
                        <PhotoReveal groupDoc={groupDoc} photo={photo} setPhoto={setPhoto} photoURL={photoURL} setPhotoURL={setPhotoURL} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
                    }
                    {activeQuestion.type === "GUESSWHO" && 
                        <GuessWho textAnswer={textAnswer} setTextAnswer={setTextAnswer}/>
                    }
                    {activeQuestion.type === "WHATDOYOUSEE" && 
                        <WhatDoYouSee whatIsTrue={whatIsTrue} setWhatIsTrue={setWhatIsTrue} setPhoto={setPhoto} photoURL={photoURL} setPhotoURL={setPhotoURL}/>
                    }
                    {activeQuestion.type === "WHATDOYOUHEAR" && 
                        <WhatDoYouHear whatIsTrue={whatIsTrue} setWhatIsTrue={setWhatIsTrue} setVideo={setVideo} videoURL={videoURL} setVideoURL={setVideoURL} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setMetadata={setMetadata}/>
                    }
                </div>      
            </div>
            <div className="intake-control">
                <Skipper qwestoID={qwestoID} activeQuestion={activeQuestion}/>
                <Submitter 
                    submitIsPending={submitIsPending}
                    setSubmitIsPending={setSubmitIsPending}
                    activeQuestion={activeQuestion}
                    intakeIndex={intakeIndex}
                    intakeLength={intakeLength}
                    whatIsTrue={whatIsTrue} 
                    setWhatIsTrue={setWhatIsTrue}
                    textAnswer={textAnswer}
                    setTextAnswer={setTextAnswer}
                    photo={photo}
                    setPhoto={setPhoto}
                    setPhotoURL={setPhotoURL}
                    video={video}
                    setVideo={setVideo}
                    setVideoURL={setVideoURL}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    metadata={metadata}
                    setMetadata={setMetadata}
                />
            </div>
        </div>
    </>
    )
}