import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { functions } from "../config/firebaseConfig"
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

export function useDeleteQwesto(){
    const [deleteIsPending, setDeleteIsPending] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()
    const navigate = useNavigate()

    async function tryDeleteQwesto(qwestoID){
        setDeleteIsPending(true)
        const handleDeleteQwesto = httpsCallable(functions, "handleDeleteQwesto")
        handleDeleteQwesto({qwestoID: qwestoID})
        .then((result) => {
            navigate("/groups")
            setNewInfo("De Qwesto is verwijderd")
            console.log(result);
            setDeleteIsPending(false)
        })
        .catch((error) => {
            setNewError("Er is iets misgegaan, probeer het later opnieuw")
            console.log(error);
            setDeleteIsPending(false)
        })
    }

    return { deleteIsPending, tryDeleteQwesto }
}
