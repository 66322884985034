import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../config/firebaseConfig";
import { useAuthContext } from "./useAuthContext";
import { useMessageContext } from "./useMessageContext";
import { useNavigate } from "react-router-dom";

export const useSignIn = () => {
    const [isPending, setIsPending] = useState(false)
    const { setNewError, setNewInfo } = useMessageContext()
    const { dispatch } = useAuthContext()
    const navigate = useNavigate()

    const trySignIn = (email, password, to) => {
        setIsPending(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((credentials) => {
                dispatch({type: "LOGIN", payload: credentials.user })
                setNewInfo("Je bent succesvol ingelogd.")
                if(to){
                    navigate(decodeURIComponent(to))
                }
                setIsPending(false)
            })
            .catch((error) => {
                if(error.message === "Firebase: Error (auth/invalid-login-credentials)."){
                    setNewError("Ongeldige inloggegevens.")
                }else{
                    setNewError("Er is iets misgegaan.")
                }
                setIsPending(false)
            })
    }

    return { isPending, trySignIn }
}
