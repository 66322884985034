import React from "react";
import Check from "../../../assets/img/Check";

export function CorrectText({text, fixed}){
    return(
        <div className={`correct-text ${fixed ? "fixed" : ""}`}>
            <Check className="correct-icon"/>   
            <span className="viewer-text">{text}</span>
        </div>
    )
}
