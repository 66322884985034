import { doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { ref, update } from "firebase/database"
import { db, rdb } from "../../../config/firebaseConfig"

export async function finishQwesto(qwestoID, playerResults){
    if(!qwestoID) throw new Error("No qwestoID provided")

    let gameDocObject = {
        stage: "FINISHED"
    }
    if(playerResults){
        gameDocObject = {
            ...gameDocObject,
            ...playerResults
        }
    }
    try{
        await updateDoc(doc(db, "groups", qwestoID), {
            qwestoFinished: true,
            qwestoFinishedTimestamp: serverTimestamp()
        })
        await update(ref(rdb, "games/" + qwestoID), gameDocObject)
    }catch(error){
        console.log(error);
        throw new Error("Er is iets misgegaan met het afsluiten van de Qwesto")
    }
}