import { useParams } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Redirect from "../../components/layout/Redirect";
import SEO from "../../components/utils/SEO";
import ArticleDisplayer from "./ArticleDisplayer";
import { useEffect, useState } from "react";
import { BLOG_POSTS } from "../../data/blog";

export default function Article(){
    const {pathID} = useParams()
    const [article, setArticle] = useState(null)

    useEffect(() => {
        if(BLOG_POSTS){
            const newArticle = BLOG_POSTS.find((article) => article.pathID === pathID);
            if(newArticle){
                setArticle(newArticle)
            }else{
                setArticle(null)
            }
        }
    }, [pathID])

    if(!article){
        return(
            <Redirect to="/blog" message="We hebben dit artikel niet kunnen vinden" arrow="left" action="Terug"/>
        )
    }

    return(
        <>
            <SEO title={article.title} description={article.description} canonical={article.pathID}/>
            <div className="content-page overflow">
                <ArticleDisplayer article={article}/>
                <Footer/>
            </div>
        </>
    )
};