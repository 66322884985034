import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useMessageContext } from "./useMessageContext";
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth";

export function useDeleteProfile(){
    const {setNewError, setNewInfo} = useMessageContext()
    const {dispatch} = useAuthContext()
    const [deleteIsPending, setDeleteIsPending] = useState()

    async function tryDeleteProfile(user, password, groupDocs){
        setDeleteIsPending(true)
        const credentials = EmailAuthProvider.credential(user.email, password)
        reauthenticateWithCredential(user, credentials).then(async() => {
            if(groupDocs.length > 0){
                setNewError("Je kan je profiel niet verwijderen als je nog actief bent in een Qwesto.")
                setDeleteIsPending(false)
                return
            }
            try{
                await deleteUser(user)
                dispatch({ type: "LOGOUT"})
                setNewInfo("Je account is succesvol verwijderd.")
            } catch(error){
                console.log(error);
                setNewError("Er is een fout opgetreden.")
            }
            setDeleteIsPending(false)
        }).catch((error) => {
            setNewError("Er is een fout opgetreden, probeer het nog een keer.")
            setDeleteIsPending(false)
        })
    }

    return { tryDeleteProfile, deleteIsPending }
}