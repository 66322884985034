import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { doc, updateDoc } from "firebase/firestore";
import {ref, update} from 'firebase/database';
import { db, rdb } from "../config/firebaseConfig";

export function useStartQwesto(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError} = useMessageContext()

    function tryStartQwesto(groupDoc){
        if(groupDoc){
            setIsPending(true)
            
            const gameObject = {
                activeQuestionIndex: 1,
                correctAnswer: "EMPTY",
                points: {},
                pointsEarned: {},
                answers: {},
                stage: "INTRODUCTION"
            }
            
            groupDoc.players.forEach((player) => {
                gameObject.points[player.uid] = 0
                gameObject.pointsEarned[player.uid] = 0
                gameObject.answers[player.uid] = "EMPTY"
            })
            updateDoc(doc(db, "groups", groupDoc.qwestoID), {
                qwestoStarted: true
            })
            .then(() => {
                update(ref(rdb, "games/" + groupDoc.qwestoID), gameObject).then(() => {
                    console.log("Qwesto is gestart!")
                })
                .catch((error) => {
                    setNewError("Er is een fout opgetreden (rdb): " + error)
                });
            }).catch((error) => {
                setNewError("Er is een fout opgetreden (db): " + error)
            })

            
            setIsPending(false)
        }
    }

    return { isPending, tryStartQwesto }
}