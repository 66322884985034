import NavigationBar from "../components/layout/navigation-bar/NavigationBar"
import { createContext, useState } from "react";

export const NavigationBarContext = createContext()

export function NavigationBarContextProvider({children}){

    const [filterVisible, setFilterVisible] = useState(false)
    const [menuVisible, setMenuVisible] = useState(false)

    return(
        <NavigationBarContext.Provider value={{filterVisible, setFilterVisible}}>
            <NavigationBar filterVisible={filterVisible} setFilterVisible={setFilterVisible} menuVisible={menuVisible} setMenuVisible={setMenuVisible}/>
            {children}
        </NavigationBarContext.Provider>
    )
}
