import "./Countdown.css"

export default function Countdown({countdown}){
    return(
        <div className="countdown-label">
            <div className="countdown">
                <h1 className="countdown-text">{countdown}</h1>
            </div>
        </div>  
    )
}
