import { useState } from "react";
import { useMessageContext } from "./useMessageContext";
import { db } from "../config/firebaseConfig"
import { arrayRemove, doc, runTransaction } from "firebase/firestore";

export function useDeletePlayer(){
    const [isPending, setIsPending] = useState(false)
    const {setNewError, setNewInfo} = useMessageContext()

    async function tryDeletePlayer(groupDoc, qwestoID, playerData){
        if(!groupDoc.groupIsConfirmed){
            setIsPending(true)
            try {
                const groupDocRef = doc(db, "groups", qwestoID);
                const qwestoDocRef = doc(db, "qwestos", qwestoID);
                await runTransaction(db, async (transaction) => {
                    transaction.update(qwestoDocRef, {
                        players: arrayRemove(playerData),
                        playersAllowed: arrayRemove(playerData.uid)
                    });
                    transaction.update(groupDocRef, {
                        players: arrayRemove(playerData),
                        playersAllowed: arrayRemove(playerData.uid)
                    });
                });
                setNewInfo(playerData.nickName + " (" + playerData.displayName + ") is verwijderd")
                setIsPending(false)
            } catch (error) {
                setNewError("Er is een fout opgetreden")
                setIsPending(false)
            }
        }else{
            setNewError("Je kan geen spelers meer verwijderen als je de groep hebt bevestigd")
            setIsPending(false)
        }
    }

    return { isPending, tryDeletePlayer }
}
