import { useState } from "react"
import { useAuthContext } from "./useAuthContext"
import { auth, functions } from "../config/firebaseConfig"
import { sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'
import { useMessageContext } from "./useMessageContext"
import { validateEmail } from "../utils/pure/validateEmail"
import { useNavigate } from "react-router-dom"
import { httpsCallable } from "firebase/functions"
import { fileToBase64 } from "../utils/pure/fileToBase64"

export const useSignUp = () => {
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()
    const navigate = useNavigate()
    const { setNewError, setNewInfo } = useMessageContext()

    async function trySignUp(email, password, displayName, profilePhoto, gender, to){
        setIsPending(true)
        if(!email || !password || !displayName || !gender){
            setNewError("Je hebt nog niet alles ingevuld.")
            setIsPending(false)
            return
        }
        if(!validateEmail(email)){
            setNewError("Je email is ongeldig")
            setIsPending(false)
            return
        }
        if(!profilePhoto){
            setNewError("Voeg een profielfoto toe")
            setIsPending(false)
            return
        }
        try {
            const handleCreateUser = httpsCallable(functions, "handleCreateUser")
            await handleCreateUser({
                email: email,
                password: password,
                profilePhoto: await fileToBase64(profilePhoto),
                displayName: displayName,
                gender: gender
            })
            signInWithEmailAndPassword(auth, email, password)
            .then(async (credentials) => {
                dispatch({type: "LOGIN", payload: credentials.user })
                await sendEmailVerification(credentials.user)
                if(to){
                    navigate(decodeURIComponent(to))
                }
                setNewInfo("Je bent succesvol aangemeld. Check je mail om je account te bevestigen.")
                setIsPending(false)
            })
        } catch (error) {
            if(error.message && error.message.includes("The email address is already in use by another account")){
                setNewError("Deze email is al in gebruik, probeer in te loggen of kies een andere email.")
            }else{
                setNewError("Er is iets misgegaan.")
            }
            setIsPending(false)
        }
    }

    return { isPending, trySignUp }
}